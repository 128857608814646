import { BaseService } from '../base';
import { RegisterRequest, RegisterResponse } from '@payment-mfe/shared/models';

export class RegisterService extends BaseService<RegisterResponse> {
  async registerAsync(
    apiUrl: string,
    registerRequest: RegisterRequest
  ): Promise<RegisterResponse | null> {
    const baseResponse = await this.postAsync(
      apiUrl,
      JSON.stringify(registerRequest)
    );
    if (baseResponse.errCode === '00') {
      return baseResponse.data;
    }

    return null;
  }
}
