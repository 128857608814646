import { useEffect, useState } from 'react';
import styles from './game-list.module.css';
import { Link } from 'react-router-dom';
import { BreadcrumbWrapper, NotiModal } from '@payment-mfe/shared/commons';
import actions, {
  useAppDispatch,
  useAppSelector,
} from '@payment-mfe/shared/store';
import { GameResponse } from '@payment-mfe/shared/models';
import { useDebounce } from '@payment-mfe/shared/utils';

/* eslint-disable-next-line */
export interface GameListProps {
  productApiUrl: string;
}

export function GameList(props: GameListProps) {
  // #region Redux
  const dispatch = useAppDispatch();
  const allGames = useAppSelector((state) => state.home.game.all_games);
  const isLoggedIn = useAppSelector((state) => state.appState.isLoggedIn);
  // #endregion

  //#region Component State
  const [searchValue, setSearchValue] = useState('');
  const [isOpenLoginModal, setIsOpenLoginModal] = useState(false);
  const [searchGame, setSearchGame] = useState<GameResponse[]>(allGames);
  const debounceValue = useDebounce(searchValue, 800);

  //#endregion

  // #region Effects
  useEffect(() => {
    dispatch(
      actions.home.game.getAllGames({
        baseProductApiUrl: props.productApiUrl,
      })
    );
  }, [dispatch, props.productApiUrl]);

  useEffect(() => {
    setSearchGame(allGames);
  }, [allGames]);

  useEffect(() => {
    const game = allGames.filter((game) =>
      game.title.toLowerCase().includes(searchValue.toLocaleLowerCase())
    );
    setSearchGame(game);
  }, [debounceValue, allGames]); // eslint-disable-line

  // Scrool to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // #endregion

  const handleCheckLoggedIn = (id: string) => {
    if (isLoggedIn === false) {
      setIsOpenLoginModal(true);
      dispatch(
        actions.auth.login.setRedirectPath('/nap-game/sieu-hoc-vien-3d')
      );
    } else {
      dispatch(actions.home.game.setCurrentGameID(id));
    }
  };

  const handleSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  return (
    <BreadcrumbWrapper link="/" text="Trở lại trang chủ">
      <div className={styles['gamelist-wrapper']}>
        <div className={styles['gameList-page']}>
          <div className={styles['release-game']}>
            <div className={styles['release-game__title']}>
              <div className={styles['release-game-header']}>
                <span>Danh sách game</span>
              </div>
              <div className={styles['search-game']}>
                <input
                  type="text"
                  placeholder="Nhập tên game"
                  onChange={handleSearchValue}
                />
                <img src="/assets/images/icons/search.svg" alt="" />
              </div>
            </div>
            <div className={styles['list-game']}>
              {searchGame?.map((item) => (
                <Link
                  onClick={() => handleCheckLoggedIn(item.id)}
                  to={isLoggedIn ? `/nap-game/${item?.slug}` : ''}
                  key={item?.id}
                  className={styles['game-item']}
                >
                  <p>{item?.title}</p>
                  <div className={styles['game-card']}>
                    <img
                      src={`/assets/images${
                        item?.avatar_path || '/games/sieu-hoc-vien-3D.png'
                      }`}
                      alt={item?.title}
                    />
                    <button>Nạp game</button>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
      <NotiModal
        type="require_login"
        open={isOpenLoginModal}
        onCancle={() => {
          setIsOpenLoginModal(false);
        }}
        onCancleRequireLogin={() => {
          setIsOpenLoginModal(false);
          dispatch(actions.auth.login.resetRedirectPath());
        }}
      />
    </BreadcrumbWrapper>
  );
}

export default GameList;
