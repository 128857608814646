// @ts-check

// #region "Import"
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
// #endregion

// #region "Contants"
const FEATURE_KEY = 'payment/notification';
// #endregion

// #region "States"

/**
 * A state of the notification.
 * @since 1.1
 */
export interface NotificationState {
  success: boolean;
  transactionId: string | null;
}

// #endregion

// #region "Slices"

/**
 * A redux slice of an notification resource.
 * @since 1.1
 */
const notificationSlice = createSlice({
  name: FEATURE_KEY,
  initialState: {
    success: false,
    transactionId: null,
  } as NotificationState,
  reducers: {
    // The transaction received from socket.
    qrReceived: (
      state,
      action: PayloadAction<{success: boolean, transactionId: string}>
    ) => {
      state.success = action.payload.success;
      state.transactionId = action.payload.transactionId;
    },

    // Reset the transaction
    resetStates: (state) => {
      state.success = false;
      state.transactionId = null;
    }
  }
})

// #endregion

// #region "Exports"

// the actions
export const actions = {
  ...notificationSlice.actions,
}

// the reducer
export default notificationSlice.reducer;
// #endregion