// @ts-check

/**
 * Export the utility methods.
 * @since 1.0
 */
export {
    hideNumber,
    hideEmailVerified,
    useDebounce,
    formatWithCommas,
    calculateDiscount
} from './utils'