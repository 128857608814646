// @ts-check

import { BannerResponse, BannerService } from "@payment-mfe/shared/service";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


type InitialState = {
  loading: 'idle' | 'pending' | 'failed' | 'succeeded'
  banners: BannerResponse[] | [] | null
}

//#region "Constants"
const FEATURE_KEY = "banner/search";
const ACTION_FETCH_SEARCH_KEY = FEATURE_KEY + "/fetchSearch";
//#endregion

//#region "Thunk Actions"
const fetchSearch = createAsyncThunk(
  ACTION_FETCH_SEARCH_KEY,
  async (
    payload: {
      baseApiUrl: string
      bannerGroup: string
    }) => {
    const service = new BannerService();
    const pagination = service.getAllBanner(payload.baseApiUrl, payload.bannerGroup);
    return pagination;
  }
);
//#endregion

//#region "Initial State"
const initialState: InitialState = {
  loading: "idle",
  banners: []
};
//#endregion

const bannerSlice = createSlice({
  name: FEATURE_KEY,
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSearch.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(fetchSearch.rejected, (state) => {
      state.loading = 'failed';
    });
    builder.addCase(fetchSearch.fulfilled, (state, action) => {
      state.loading = 'succeeded';
      state.banners = action.payload
    });
  }
});

//#region "Exports"
export const actions = {
  ...bannerSlice.actions,
  fetchSearch,
};

export default bannerSlice.reducer;
//#endregion