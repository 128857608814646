// eslint-disable-next-line @nx/enforce-module-boundaries
import { BreadcrumbWrapper } from '@payment-mfe/shared/commons';
import { TransactionResponse } from '@payment-mfe/shared/service';
import actions, {
  useAppDispatch,
  useAppSelector,
} from '@payment-mfe/shared/store';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Table,
  TableProps,
} from 'antd';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import styles from './payment-history.module.css';

/* eslint-disable-next-line */
export interface PaymentHistoryProps {
  basePaymentApiUrl: string;
}

//#region "Declaration Types"
type SearchRequest = {
  fromDate: dayjs.Dayjs | null;
  toDate: dayjs.Dayjs | null;
  type: string | null;
  page: number;
  itemsPerPage: number;
};

interface Transaction extends TransactionResponse {
  key: React.Key;
}

//#endregion

export function PaymentHistory(props: PaymentHistoryProps) {
  //#region "Variables"
  const [form] = Form.useForm();
  const now = dayjs();
  const toDate = now.add(1, 'day');
  const fromDate = now.subtract(30, 'day');
  //#endregion

  //#region "Local State"
  const [searchRequest, setSearchRequest] = useState<SearchRequest>({
    fromDate: fromDate,
    toDate: toDate,
    type: null,
    page: 1,
    itemsPerPage: 10,
  });
  //#endregion

  //#region "Redux States"
  const dispatch = useAppDispatch();
  const paging = useAppSelector((state) => state.member.transaction.paging);
  //#endregion

  /**
   * Build value for 'Kiểu nạp' cell
   */
  const buildTypeCell = useCallback((record: Transaction) => {
    if (record.type === 'debit' && record.source === 'wallet') {
      return 'Giao dịch tiêu';
    }
    // Google Play
    else if (record.type === 'debit' && record.source === 'iap_google') {
      return 'Mua vật phẩm trên Google Play';
    }
    // Apple Store
    else if (record.type === 'debit' && record.source === 'iap_apple') {
      return 'Mua vật phẩm trên Apple Store';
    }
    // VA
    else if (record.type === 'credit' && record.source === 'ib') {
      return 'Chuyển khoản trực tiếp';
    }
    // ATM
    else if (
      record.type === 'credit' &&
      record.source === 'gw' &&
      record.merchant === 'onepay_domestic'
    ) {
      return 'Nạp qua thẻ nội địa';
    }
    // Visa
    else if (
      record.type === 'credit' &&
      record.source === 'gw' &&
      record.merchant === 'onepay_international'
    ) {
      return 'Nạp qua thẻ quốc tế';
    }
    // Momo
    else if (
      record.type === 'credit' &&
      record.source === 'gw' &&
      record.merchant === 'momo_'
    ) {
      return 'Nạp qua ví MoMo';
    }
    // ACB
    else if (
      record.type === 'credit' &&
      record.source === 'gw' &&
      record.merchant === 'acb'
    ) {
      return 'Chuyển khoản qua ngân hàng ACB';
    }
    return null;
  }, []);
  /**
   * The properties of a table.
   */
  const tableProps: TableProps<Transaction> = {
    columns: [
      {
        title: 'Tác vụ',
        align: 'center',
        dataIndex: 'type',
        key: 'action',
        render: (type: string, record: Transaction) => (
          <div className={styles['table-action']}>
            <img
              src={record?.game?.avatar_path || '/assets/images/icons/bxu.svg'}
              alt=""
            />
            <span className={styles['table-text']}>
              {type === 'credit' ? 'Nạp Xu' : type === 'debit' ? 'Tiêu' : ''}
            </span>
          </div>
        ),
      },
      {
        title: 'Game',
        dataIndex: 'game',
        align: 'center',
        render: (game) => {
          return <span className={styles['table-text']}>{game}</span>;
        },
      },
      {
        title: () => (
          <span>
            {searchRequest.type === 'credit' ? 'Kiểu nạp' : 'Kiểu tiêu'}
          </span>
        ),
        dataIndex: 'type',
        align: 'center',
        render: (_: string, record: Transaction) => (
          <span className={styles['table-text']}>{buildTypeCell(record)}</span>
        ),
      },
      {
        title: 'Thời gian',
        dataIndex: 'trans_time',
        align: 'center',
        render: (time) => (
          <span className={styles['table-text']}>
            {dayjs(time).format('HH:mm:ss DD/MM/YYYY')}
          </span>
        ),
      },
      {
        title: 'Giá trị',
        dataIndex: 'amount',
        align: 'center',
        render: (value: string, record: Transaction) => {
          if (value && typeof value === 'number') {
            return (
              <span
                className={
                  styles[
                    `${
                      record?.type === 'credit'
                        ? 'add-value'
                        : record?.type === 'debit'
                        ? 'subtract-value'
                        : ''
                    }`
                  ]
                }
              >
                {record?.type === 'credit'
                  ? '+'
                  : record?.type === 'debit'
                  ? '-'
                  : 'N/A'}{' '}
                {new Intl.NumberFormat('vi-VN', {
                  style: 'currency',
                  currency: 'VND',
                })
                  .formatToParts(
                    record.type === 'credit' ? record.nxu_amount : value
                  )
                  .map((p) =>
                    p.type !== 'literal' && p.type !== 'currency' ? p.value : ''
                  )
                  .join('')}
              </span>
            );
          }
        },
      },
    ],
    dataSource:
      paging != null
        ? paging.items.map((trans, index) => {
            return {
              key: index.toString(),
              amount: trans.amount,
              nxu_amount: trans.nxu_amount,
              code: trans.code,
              merchant: trans.merchant,
              source: trans.source,
              trans_time: trans.trans_time,
              type: trans.type,
              game: trans.game?.title,
            } as Transaction;
          })
        : [],
    pagination: {
      position: ['bottomCenter'],
      pageSize: paging?.itemsPerPage,
      pageSizeOptions: [10, 20, 50],
      total: paging?.totalItems,
      showSizeChanger: true,
      onChange(page, pageSize) {
        setSearchRequest({
          ...searchRequest,
          page: page,
          itemsPerPage: pageSize,
        });
      },
    },
    style: {
      // overflowX: 'scroll',
    },
    scroll: { x: 200 },
  };

  //#region "Effects"

  /**
   * Dispatcher an search action
   */
  useEffect(() => {
    const fromDate: string | null =
      searchRequest.fromDate != null &&
      typeof searchRequest.fromDate !== 'undefined'
        ? dayjs(searchRequest.fromDate).format('YYYY-MM-DD')
        : null;
    const toDate: string | null =
      searchRequest.toDate != null &&
      typeof searchRequest.toDate !== 'undefined'
        ? dayjs(searchRequest.toDate).format('YYYY-MM-DD')
        : null;

    dispatch(
      actions.member.transaction.searchAsync({
        basePaymentApiUrl: props.basePaymentApiUrl,
        req: {
          fromDate: fromDate,
          toDate: toDate,
          type: searchRequest.type,
          page: searchRequest.page,
          itemsPerPage: searchRequest.itemsPerPage,
        },
      })
    );
  }, [dispatch, searchRequest, props.basePaymentApiUrl]);
  //#endregion

  //#region "Actions"
  function onFinish(values: SearchRequest) {
    // format values
    setSearchRequest({
      ...values,
      page: 1,
      itemsPerPage: 10,
    });
  }
  //#endregion

  return (
    <BreadcrumbWrapper link="/" text="Trở lại trang chủ">
      <div className={styles['payment-history']}>
        <h4 className={styles['payment-history__title']}>Lịch sử giao dịch</h4>
        <Form
          className="payment-history-form"
          form={form}
          labelAlign="left"
          onFinish={onFinish}
          initialValues={{
            type: '',
            fromDate: fromDate,
            toDate: toDate,
          }}
        >
          <Row
            gutter={[24, 0]}
            align="stretch"
            className={styles['search-history']}
          >
            <Col xs={24} sm={24} md={11} lg={11} xl={11} xxl={6}>
              <Form.Item
                labelCol={{ xs: 10 }}
                wrapperCol={{ xs: 14 }}
                label="Từ ngày"
                name="fromDate"
              >
                <DatePicker
                  placeholder="DD/MM/YY"
                  format={'DD/MM/YYYY'}
                  style={{ width: '100%' }}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={11} lg={11} xl={11} xxl={6}>
              <Form.Item
                labelCol={{ xs: 10 }}
                wrapperCol={{ xs: 14 }}
                label="Đến ngày"
                name="toDate"
              >
                <DatePicker
                  placeholder="DD/MM/YY"
                  format={'DD/MM/YYYY'}
                  style={{ width: '100%' }}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={11} lg={11} xl={11} xxl={7}>
              <Form.Item
                labelCol={{ xs: 10 }}
                wrapperCol={{ xs: 14 }}
                label="Loại giao dịch"
                name="type"
              >
                <Select
                  style={{ width: '100%' }}
                  placeholder={'Chọn kiểu...'}
                  options={[
                    { value: '', label: 'Tất cả' },
                    { value: 'credit', label: 'Giao dịch nạp' },
                    { value: 'debit', label: 'Giao dịch tiêu' },
                  ]}
                />
              </Form.Item>
            </Col>

            <Col xxl={1} xl={1} lg={1} md={1} xs={24}>
              <Button size="large" type="primary" htmlType="submit">
                Tìm kiếm
              </Button>
            </Col>
          </Row>
        </Form>

        {/* Table */}
        <Table className="payment-history-table" {...tableProps} />
      </div>
    </BreadcrumbWrapper>
  );
}

export default PaymentHistory;
