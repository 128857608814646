import { Link } from 'react-router-dom';
import styles from './payment-footer.module.css';

/* eslint-disable-next-line */
export interface PaymentFooterProps {}

export function PaymentFooter(props: PaymentFooterProps) {
  return (
    <div className={styles['payment-footer']}>
      <div className={styles['footer-container']}>
        <div className={styles['footer-logo']}>
          <img src="/assets/images/Logo_Boom_White.png" alt="" />
        </div>

        <div className={styles['footer-content']}>
          <h4>CÔNG TY CỔ PHẦN BOOM GAMES</h4>
          <p>
            Số 33, Khu BT4-3, Khu nhà ở Trung Văn, đường Trung Thư, Phường Trung
            Văn, Quận Nam Từ Liêm, Thành phố Hà Nội, Việt Nam
          </p>
          <p>Giấy phép G1 số: Số 224/GP-BTTT cấp ngày 30/6/2023</p>
          <p>Email: hotro@boomstudio.vn</p>

          <div className={styles['footer-menu']}>
            <div>
              <Link to={'/faq'}>Câu hỏi thường gặp</Link>
              <Link to={'/dieu-khoan-su-dung'}>Điều khoản dịch vụ</Link>
              <Link to={'/chinh-sach-quyen-rieng-tu'}>Chính sách bảo mật</Link>
              <Link to={'/huong-dan-thanh-toan-vnpay'}> Hướng dẫn thanh toán VNPay</Link>
            </div>
          </div>

          <p className={styles['copy-right']}>
            © COPYRIGHT 2023 BOOM JSC. All rights reserved.
          </p>
        </div>
      </div>
      <div className={styles['footer-partner']}>
        <h5> Đối tác </h5>
        <div className={styles['container']}>
          <img 
            src='/assets/images/vnpay/vnpay-logo.png' alt='VNPay'
            height={75} />
        </div>
      </div>
    </div>
  );
}

export default PaymentFooter;
