import { Modal } from 'antd';
import styles from './style.module.css';

interface StatusModalProps {
  open: boolean;
  onOk: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onCancle: (e: React.MouseEvent<HTMLButtonElement>) => void;
  title: string;
  subTitle?: string;
  status: 'success' | 'failed';
}

export function StatusModal({
  open,
  onOk,
  onCancle,
  title,
  subTitle,
  status,
}: StatusModalProps) {
  return (
    <div className={styles['noti-modal']}>
      <Modal
        centered
        title=""
        open={open}
        onOk={onOk}
        onCancel={onCancle}
        footer={null}
      >
        <div className={styles['noti-content']}>
          {status === 'success' ? (
            <svg
              className={styles['success']}
              xmlns="http://www.w3.org/2000/svg"
              width="69"
              height="72"
              viewBox="0 0 69 72"
              fill="none"
            >
              <path d="M64 3L34 63L4 33" stroke="var(--main-color)" strokeWidth="10" />
            </svg>
          ) : (
            <svg
              className={styles['failed']}
              xmlns="http://www.w3.org/2000/svg"
              width="68"
              height="68"
              viewBox="0 0 68 68"
              fill="none"
            >
              <path
                d="M4 4L64 64M64 4L4 64"
                stroke="#DA0707"
                strokeWidth="10"
              />
            </svg>
          )}

          {/* Desceiption */}
          <p className={styles['title']}>{title}</p>
          <p className={styles['sub-title']}>{subTitle}</p>
        </div>
      </Modal>
    </div>
  );
}

export default StatusModal;
