import {
  ChangePasswordModal,
  ForgotPasswordModal,
  LoginModal,
  MessageModal,
  RegisterModal,
} from '@payment-mfe/shared/commons';

import type { MenuProps } from 'antd';
import { Dropdown } from 'antd';

import actions, {
  loadingChangePasswordModal,
  loadingLoginModal,
  loadingRegisterModal,
  logout,
  resetLoading,
  useAppDispatch,
  useAppSelector,
} from '@payment-mfe/shared/store';

import { Wallet } from '@payment-mfe/shared/service';
import { formatWithCommas } from '@payment-mfe/shared/utils';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './payment-header.module.css';

/* eslint-disable-next-line */
export interface PaymentHeaderProps {
  authApiUrl: string;
  paymentApiUrl: string;
  notificationApiUrl: string;
}

export function PaymentHeader(props: PaymentHeaderProps) {
  const navigate = useNavigate();
  const loading = useAppSelector((state) => state.modalState.loading);
  const redirectPath = useAppSelector((state) => state.auth.login.redirectPath);

  //#region Redux
  const dispatch = useAppDispatch();

  const isLoggedIn: boolean = useAppSelector(
    (state) => state.appState.isLoggedIn
  );

  /**
   * A variable reference to current wallet balance.
   */
  const currentWallet: Wallet | null = useAppSelector(
    (state) => state.wallet.getWallet.wallet
  );

  /**
   * A variable reference to information of an user when user logged in.
   *
   * @type {({uid: string, name: string, email: string | null, expiresTime: number} | null)}
   */
  const currentUser: {
    uid: string;
    username: string;
    name: string;
    avatarName: string;
    email: string | null;
    expiresTime: number;
    avatar?: string | null;
  } | null = useAppSelector((state) => state.appState.currentUser);
  //#endregion

  const items: MenuProps['items'] = [
    {
      key: '0',
      label: currentUser?.avatar ? (
        <img
          className={styles['header-user__avatar']}
          src={`${currentUser?.avatar}` || '/assets/images/avatar_blank.png'}
          alt="avatar"
        />
      ) : (
        <div className={styles['header-user__wrapper']}>
          <img
            src="/assets/images/avatar_blank.png"
            className={styles['dropdown-avatar']}
            alt=""
          />
          <div
            className={styles['header-user__coint']}
            style={{ display: 'flex', gap: '30px' }}
          >
            <div>
              <div className={styles['user-name']}>{currentUser?.username}</div>
            </div>
          </div>
        </div>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: '1',
      label: (
        <Link
          rel="noopener noreferrer"
          to="/thong-tin-tai-khoan"
          className={styles['drop-down-item']}
        >
          Thông tin tài khoản
        </Link>
      ),
    },

    {
      key: '2',
      label: (
        <Link
          rel="noopener noreferrer"
          to="/lich-su-giao-dich"
          className={styles['drop-down-item']}
        >
          Lịch sử giao dịch
        </Link>
      ),
    },
    {
      key: '3',
      label: (
        <span
          className={styles['drop-down-item']}
          onClick={(e) => {
            e.preventDefault();
            dispatch(loadingChangePasswordModal());
          }}
        >
          Đổi mật khẩu
        </span>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: '4',
      label: (
        <div
          onClick={async () => {
            dispatch(logout());
            dispatch(actions.payment.ui.resetPayment());
            navigate('/');
          }}
          className={`${styles['drop-down-item']} ${styles['drop-down-logout']}`}
        >
          <img
            src="assets/images/icons/logout.svg"
            className={styles['drop-down-logout-icon']}
            alt=""
          />
          <span>Đăng xuất</span>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (!isLoggedIn && loading === 'loginSuccess') {
      dispatch(actions.auth.login.resetRedirectPath());
      dispatch(resetLoading());
    }
  }, [isLoggedIn, loading, dispatch]);

  //#region "Effects"
  useEffect(() => {
    if (loading === 'loginSuccess' && redirectPath !== null) {
      navigate(redirectPath);
      dispatch(actions.auth.login.resetRedirectPath());
      dispatch(resetLoading());
    }
  }, [loading, redirectPath, navigate, dispatch]);

  // Fetch the wallet information of the user when user logged in.
  useEffect(() => {
    if (isLoggedIn === true) {
      dispatch(actions.wallet.wallet.getByMemberAsync(props.paymentApiUrl));
    }
  }, [dispatch, props.paymentApiUrl, isLoggedIn]);
  //#endregion

  return (
    <>
      <div className={styles['payment-header']}>
        {/* Logo block */}
        <Link to="/" className={styles['payment-header__logo']}>
          <img
            src="/assets/images/Logo_Boom.png"
            alt=""
            style={{ width: '74px' }}
          />
        </Link>
        {/* End logo */}

        {isLoggedIn ? (
          <>
            <div className="">
              <div className={styles['payment-header__user']}>
                <div className={styles['header-left']}>
                  <Link to="/huong-dan-nap" className={styles['header-link']}>
                    Hướng dẫn nạp
                  </Link>
                  <Link to="/ho-tro" className={styles['header-link']}>
                    Hỗ trợ
                  </Link>
                  <Link
                    to="/lich-su-giao-dich"
                    className={styles['header-link']}
                  >
                    Lịch sử giao dịch
                  </Link>
                </div>
                <div className={styles['user-summary']}>
                  <img
                    src="/assets/images/icons/bxu.svg"
                    alt=""
                    className={styles['user-summary-nxu-icon']}
                  />
                  <span>
                    {currentWallet !== null
                      ? formatWithCommas(+currentWallet.balance, '.')
                      : 0}{' '}
                  </span>
                  <img
                    src="/assets/images/icons/plus-nxu.svg"
                    alt=""
                    className={styles['user-summary-plus']}
                    onClick={() => navigate('/danh-sach-game')}
                  />
                </div>
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
                >
                  <Dropdown
                    overlayClassName="drop-down-container"
                    menu={{ items }}
                    placement="bottom"
                    arrow
                  >
                    <div className={styles['header-user']}>
                      {/* Avatar */}
                      {currentUser?.avatar ? (
                        <img
                          className={styles['header-user__avatar']}
                          src={
                            `${currentUser?.avatar}` ||
                            '/assets/images/avatar_1.png'
                          }
                          alt="avatar"
                        />
                      ) : (
                        <img
                          src="/assets/images/avatar_blank.png"
                          className={styles['dropdown-avatar']}
                          alt=""
                        />
                      )}
                    </div>
                  </Dropdown>
                  {/* Mobile user info */}
                  <div className={styles['user-info-wrapper']}>
                    <div className={styles['user-info-group']}>
                      <div className={styles['user-info-name']}>
                        {currentUser?.username}
                      </div>
                      {/* Coin */}
                      <div style={{ display: 'flex', gap: '5px' }}>
                        <img
                          src="/assets/images/icons/bxu.svg"
                          alt=""
                          className={styles['user-summary-nxu-icon']}
                        />
                        <div className={styles['user-info-coin']}>
                          {currentWallet !== null
                            ? formatWithCommas(+currentWallet.balance, '.')
                            : 0}
                        </div>
                      </div>
                    </div>

                    <div className={styles['user-info-group-coin']}>
                      <img
                        src="/assets/images/icons/plus-nxu.svg"
                        alt=""
                        className={`${styles['user-summary-plus']} ${styles['user-info-coin']}`}
                        onClick={() => navigate('/danh-sach-game')}
                      />
                    </div>
                  </div>
                  {/* End mobile user info */}
                </div>
              </div>
            </div>

            {/* Display when responsive */}
            <div className={styles['header-respon']}>
              <Link to="/huong-dan-nap" className={styles['header-link']}>
                Hướng dẫn nạp
              </Link>
              <Link to="/ho-tro" className={styles['header-link']}>
                Hỗ trợ
              </Link>
              <Link to="/lich-su-giao-dich" className={styles['header-link']}>
                Lịch sử giao dịch
              </Link>
            </div>
            {/* End */}

            {/* Display in mobile screen */}
            <div className={styles['mobile-history']}>
              <Link to="/huong-dan-nap" className={styles['header-link']}>
                Hướng dẫn nạp
              </Link>
              <Link to="/ho-tro" className={styles['header-link']}>
                Hỗ trợ
              </Link>
              <Link to="/lich-su-giao-dich" className={styles['header-link']}>
                Lịch sử giao dịch
              </Link>
            </div>
            {/* End */}
          </>
        ) : (
          <div className={styles['login-group']}>
            <Link to="/huong-dan-nap" className={styles['guide-btn']}>
              Hướng dẫn nạp
            </Link>
            <Link to="/ho-tro" className={styles['guide-btn']}>
              Hỗ trợ
            </Link>
            <button
              onClick={() => {
                dispatch(loadingLoginModal());
              }}
              className={styles['login-btn']}
            >
              Đăng nhập
            </button>
            <button
              className={styles['register-btn']}
              onClick={() => {
                dispatch(loadingRegisterModal());
              }}
            >
              Đăng ký
            </button>
          </div>
        )}
      </div>
      {!isLoggedIn && (
        <div className={styles['mobile-history']}>
          <Link to="/huong-dan-nap" className={styles['header-link']}>
            Hướng dẫn nạp
          </Link>
          <Link to="/ho-tro" className={styles['header-link']}>
            Hỗ trợ
          </Link>
        </div>
      )}

      {/** Common modal components, can use everywhere in app. */}
      <RegisterModal baseAuthApiUrl={props.authApiUrl} />
      <LoginModal authApiUrl={props.authApiUrl} />
      <ForgotPasswordModal
        baseAuthApiUrl={props.authApiUrl}
        baseNotificationApiUrl={props.notificationApiUrl}
      />
      <ChangePasswordModal baseAuthApiUrl={props.authApiUrl} />
      <MessageModal title="Thông báo" baseAuthApiUrl={props.authApiUrl} />
    </>
  );
}

export default PaymentHeader;
