//#region "Imports"

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import homeReducer from './home';
import authReducer from './auth';
import memberReducer from './members';
import paymentReducer from './payment';
import walletReducer from './wallet';
import bannerReducer from './banner';

import appReducer from './app.slice';
import modalReducer from './modal.slice';

//#endregion

//#region "Business"

const store = configureStore({
  reducer: combineReducers({
    appState: appReducer,
    modalState: modalReducer,

    // Auth
    auth: authReducer,

    // Home
    home: homeReducer,

    // Member
    member: memberReducer,

    // Payment
    payment: paymentReducer,

    // Wallet
    wallet: walletReducer,
    banner: bannerReducer,
  }),
});

//#endregion

//#region "Exports"
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
//#endregion
