// @ts-check
// #region "Imports"
import { Image, Modal } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';

import {
  BreadcrumbWrapper,
  ExchangeModal,
 } from '@payment-mfe/shared/commons';
import actions, {
  loadingLoginModal,
  useAppDispatch,
  useAppSelector,
} from '@payment-mfe/shared/store';

import { PaymentConfirmation } from './payment-confirmation';
import PaymentMethod from './payment-method';
import PaymentQRCode from './payment-qr';
import PaymentRate from './payment-rate';
import styles from './payment.module.css';
// #endregion

// #region "Props"
/**
 * The component properties.
 * @version 1.0
 */
export interface PaymentProps {
  baseAuthApiUrl: string;
  basePaymentApiUrl: string;
  baseNotificationUrl: string;
  productApiUrl: string;
}
// #endregion

/**
 * A component support for purchase an item.
 * 
 * @param props A component properties.
 * @returns 
 */
export function Payment(props: PaymentProps) {
  // #region "States"
  const { slug } = useParams();
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [scroll, setScroll] = React.useState(false);
  const [timeLeft, setTimeLeft] = React.useState(3);
  // #endregion

  //#region "Redux"
  // A dispatcher an action to the redux.
  const dispatch = useAppDispatch();

  // check if a screen is small size.
  const isSmallScreen = useAppSelector((state) => state.appState.isSmallScreen);

  // the conversion rate.
  const selectedRate = useAppSelector(
    (state) => state.payment.paymentUI.currentRate
  );
  // list all of the game.
  const allGames = useAppSelector((state) => state.home.game.all_games);

  // get current game.
  const currentGame = allGames?.find((game) => game?.slug === slug);
  
  // the payment method.
  const currentMethod = useAppSelector(
    (state) => state.payment.paymentUI.method
  );

  // the payment bank.
  const selectedBank = useAppSelector((state) => state.payment.paymentUI.bank);

  // list of the bank
  const listBankings = useAppSelector(
    (state) => state.payment.paymentBank.entities
  );

  // check an user is authenticated.
  const isLoggedIn = useAppSelector((state) => state.appState.isLoggedIn);

  // received an notfiication from server.
  const qrPaymentReceived = useAppSelector((state) => state.payment.paymentNotification.success)

  // /**
  //  * Selected the payment method.
  //  */
  // const method = useAppSelector((state) => state.payment.paymentUI.method);
  // /**
  //  * The list of active payment methods.
  //  */
  // const methods = useAppSelector(
  //   (state) => state.payment.paymentMethod.entities
  // );

  //#endregion

  const aboveQrRef = React.useRef<HTMLDivElement>(null);

  //#region "Effects"
  /**
   * Select a bank component.
   * @since 1.0
   */
  const BankSelection = React.useCallback(() => {
    const confirmationOnClick = (): void => {
      // check if the user is logged in.
      if (isLoggedIn === true) {
        // Show QR Code Block
        dispatch(
          actions.payment.ui.changesStep({
            newStep: 'qr',
          })
        );
        setScroll(true);
      } else {
        // show login popup
        dispatch(loadingLoginModal());
      }
    };
    
    return (
      <div>
        <div style={{ position: 'relative' }}>
          <p>Chọn ngân hàng</p>
        </div>
        <div className={styles['bank-option']}>
          {[...Object.values(listBankings)].map((item) => {
            if (item !== null && typeof item !== 'undefined') {
              return (
                <div
                  key={item.id}
                  onClick={(event) => {
                    event.preventDefault();
                    //  changes the payment bank
                    dispatch(
                      actions.payment.ui.changesBank({
                        id: item.id,
                        name: item.name,
                        bankAccountNumber: item.bank_account_number,
                        bnbId: item.bnb_id,
                      })
                    );
                    confirmationOnClick();
                    aboveQrRef.current?.scrollIntoView()
                  }}
                  className={`${styles['bank-item']} 
                    ${
                      selectedBank?.id === item.id && styles['bank-item-active']
                    }`}
                >
                  <img
                    src={item.icon_path} 
                    alt={item.name}
                    />
                  <span
                    className={`${
                      selectedBank?.id === item.id &&
                      styles['bank-item-text-active']
                    }`}
                  >
                    {item.code.toUpperCase()}
                  </span>
                </div>
              );
            }
            return null;
          })}
        </div>
        
        <div ref={aboveQrRef} />

        <PaymentQRCode
          baseAuthApiUrl={props.baseAuthApiUrl}
          baseNotificationApiUrl={props.baseNotificationUrl} />
      </div>
    );
  }, [
    dispatch,
    isLoggedIn,
    listBankings,
    selectedBank?.id,
    props.baseAuthApiUrl,
    props.baseNotificationUrl,
  ]);

  /**
   * Scroll to a specifical position, based on method and screen size.
   */
  React.useEffect(() => {
    if (window.innerWidth > 993 && currentMethod?.code !== 'va') {
      window.scrollTo({
        top: 500,
        left: 0,
        behavior: 'smooth',
      });
    } else if (window.innerWidth < 992 && window.innerWidth > 611) {
      window.scrollTo({
        top: 850,
        left: 0,
        behavior: 'smooth',
      });
    }
    setScroll(false);
  }, [currentMethod, scroll]);

  /**
   * Changes the document title.
   * @since 1.0
   */
  React.useEffect(() => {
    document.title = 'Xác nhận thanh toán';
  }, []);
  
  /**
   * Reset the payment ui state.
   * @since 1.0
   */
  React.useEffect(() => {
    dispatch(
      actions.payment.ui.changesStep({
        newStep: 'method',
      })
    );
  }, [dispatch]);

  /**
   * List all of the bank
   */
  React.useEffect(() => {
    dispatch(
      actions.payment.bank.getAll({
        basePaymentApiUrl: props.basePaymentApiUrl,
      })
    );
  }, [dispatch, props.basePaymentApiUrl]);

  /**
   * Default payment bank, first load page.
   */
  React.useEffect(() => {
    if (selectedBank === null) {
      const defaultBank = [...Object.values(listBankings)][0];

      if (typeof defaultBank !== 'undefined') {
        dispatch(
          actions.payment.ui.changesBank({
            id: defaultBank.id,
            name: defaultBank.name,
            bankAccountNumber: defaultBank.bank_account_number,
            bnbId: defaultBank.bnb_id,
          })
        );
      }
    }
  }, [dispatch, listBankings, selectedBank]);

  /**
   * Dispatching to action loading all payment methods
   */
  React.useEffect(() => {
    dispatch(
      actions.payment.method.getAll({
        basePaymentApiUrl: props.basePaymentApiUrl,
      })
    );
  }, [dispatch, props.basePaymentApiUrl]);

  /**
   * Setup default payment method.
   */
  // useEffect(() => {
  //   if (methods !== null && method === null) {
  //     const defaultMethod = [...Object.values(methods)][0];
  //     if (typeof defaultMethod !== 'undefined') {
  //       // Select default of payment method.
  //       dispatch(
  //         actions.payment.ui.changesMethod({
  //           code: defaultMethod.code,
  //           name: defaultMethod.name,
  //         })
  //       );
  //       // After select method, show confirmation screen.
  //       dispatch(
  //         actions.payment.ui.changesStep({
  //           newStep: 'confirmation',
  //         })
  //       );
  //     }
  //   }
  // }, [dispatch, methods, method]);

  /**
   * List all of the game.
   * @since 1.0
   */
  React.useEffect(() => {
    if (!allGames || allGames?.length === 0) {
      dispatch(
        actions.home.game.getAllGames({
          baseProductApiUrl: props.productApiUrl,
        })
      );
    }
  }, [allGames, dispatch, props.productApiUrl]);

  /**
   * Time left count down to the hidden popup.
   * @since 1.0
   */
  React.useEffect(() => {
    if (qrPaymentReceived === true) {
      let myTimeLeft = 3
      const interval = setInterval(() => {
        setTimeLeft(myTimeLeft);
        myTimeLeft = myTimeLeft - 1;
      }, 1000)

      return () => clearInterval(interval);
    }
  }, [qrPaymentReceived])
  
  /**
   * Hidden the qr noficiation popup
   */
  React.useEffect(() => {
    if (timeLeft === 0) {
      hiddenNotificationDialog();
    }
  }, [timeLeft]) // eslint-disable-line
  
  React.useEffect(() => {
    if (slug) {
      localStorage.setItem('paymentCallbackUrl', `/nap-game/${slug}`)
    }
  }, [slug])

  //#endregion

  // #region "Actions"

  /**
   * Hidden the received socket message.
   * @since 1.0
   */
  const hiddenNotificationDialog = (): void => {
    dispatch(
      actions
        .payment
        .notification
        .resetStates()
    )
    // fetch the wallet information
    dispatch(
      actions
        .wallet
        .wallet
        .getByMemberAsync(
          props.basePaymentApiUrl
      )
    )

    // scroll to the top of page.
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  // #endregion

  // #region "View"
  return (
    <BreadcrumbWrapper link="/" text="Trở lại trang chủ">
      <div className={styles['payment-page']}>
        <div className={styles['payment-container']}>
          <div className={styles['payment-content']}>
            {/* User top */}
            <div className={styles['content-top']}>
              <div className={styles['content-top__user']}>
                <div className={styles['user-info']}>
                  <img src="/assets/images/avatar_2.png" alt="" />
                </div>
                <div className={styles['user-coint']}>
                  <h4>{currentGame?.title}</h4>
                  <div className={styles['transaction']}>
                    <div
                      onClick={() => setShowModal(true)}
                      className={styles['coint-exchange']}
                    >
                      <img 
                        src="/assets/images/icons/exchange.svg"
                        alt="Tỉ lệ Quy đổi BXu"
                      />
                      Bảng quy đổi
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End User */}

            {/* payment method */}
            <PaymentMethod
              slug={slug}
              basePaymentApiUrl={props.basePaymentApiUrl}
            />
            {/* End payment method */}

            {/* Rate block */}
            {!isSmallScreen && (
              <div
                className={styles['exchange-block']}
                onClick={() => setScroll(true)}
              >
                <PaymentRate
                  type={currentMethod?.code}
                  basePaymentApiUrl={props.basePaymentApiUrl}
                />
                {selectedRate && (
                  <div className={styles['exchange-submit']}>
                    {currentMethod?.code === 'va' ? (
                      <BankSelection />
                    ) : (
                      <PaymentConfirmation
                        basePaymentApiUrl={props.basePaymentApiUrl}
                      />
                    )}
                  </div>
                )}
              </div>
            )}
            {/* End Rate block */}
          </div>
        </div>
      </div>

      <ExchangeModal
        isShown={showModal} 
        setShowModal={setShowModal} 
        />
      
      <Modal
        title={null}
        footer={null}
        destroyOnClose={true}
        open={qrPaymentReceived === true}
        onCancel={() => hiddenNotificationDialog()}
      >
        <div className={styles['card-notification']}>
          <Image
            preview={false}
            height={70}
            alt='Nạp tiền thành công'
            src='/assets/images/icon-success.png'
          />
          <h3 className={styles['card-notification__message']}>
            Giao dịch thành công, vui lòng kiểm tra số BXu trong tài khoản.
          </h3>
          <h5
            className={
              styles['card-notification__desc']
            }> Cửa sổ sẽ tự động đóng sau {timeLeft} giây... </h5>
        </div>
      </Modal>
    </BreadcrumbWrapper>
  );
  // #endregion
}

export default Payment;
