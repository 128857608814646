//#region "Imports"
import { BaseService } from '../base';
import {
  LoginRequest,
  LoginResponse,
  OAuthRequest,
  OAuthResponse,
} from '@payment-mfe/shared/models';
//#endregion

/**
 * Communication to NotA login service.
 *
 * @author VinhPD
 * @since 1.0
 * @version 1.0 - 02/19/23
 */
export class LoginService extends BaseService<LoginResponse> {
  /**
   * Send a login request to NotA auth service
   *
   * @since 1.0
   *
   * @param loginRequest A payload contains the member credentials
   * @returns {Promise<LoginResponse | null>} The login response
   */
  async loginAsync(
    authApiUrl: string,
    loginRequest: LoginRequest
  ): Promise<LoginResponse | null> {
    const postJsonBody = JSON.stringify(loginRequest);
    const apiUrl = authApiUrl + '/auth/member';

    const baseResponse = await this.postAsync(apiUrl, postJsonBody);
    if (baseResponse.errCode === '00') {
      return baseResponse.data;
    }

    return null;
  }

  async oauthLoginAsync(
    authApiUrl: string,
    req: OAuthRequest
  ): Promise<OAuthResponse | null> {
    const postJsonBody = JSON.stringify(req);
    const apiUrl = authApiUrl + '/auth/member/oauth2';

    const baseResponse = await this.postAsync(apiUrl, postJsonBody);
    if (baseResponse.errCode === '00') {
      return baseResponse.data;
    }

    return null;
  }
}
