//@ts-check

//#region "Imports"
import { combineReducers } from '@reduxjs/toolkit';

import bankReducer, { actions as bankActions } from './bank.slice';
import gatewayReducer, { actions as gatewayActions } from './gateway.slice';
import methodReducer, { actions as methodActions } from './method.slice';
import rateReducer, { actions as rateActions } from './rate.slice';
import transactionReducer, { actions as transactionActions } from './transaction.slice';
import uiReducer, { actions as uiActions } from './newui.slice';
import vaReducer, { actions as vaActions } from './virtual-account.slice';
import configReducer, {actions as configActions} from './config.slice';

// 
import notificationReducer, { actions as notificationActions } from './notification.slice';

//#endregion

//#region "Exports"
/**
 * Declaration actions of the payment.
 * @since 1.0
 */
export const actions = {
  bank: bankActions,
  gateway: gatewayActions,
  method: methodActions,
  rate: rateActions,
  transaction: transactionActions,
  ui: uiActions,
  va: vaActions,
  notification: notificationActions,
  config: configActions,
};

/**
 * Declaration reducers of the payment.
 * @since 1.0
 */
export default combineReducers({
  paymentBank: bankReducer,
  paymentGateway: gatewayReducer,
  paymentMethod: methodReducer,
  paymentRate: rateReducer,
  paymentTransaction: transactionReducer,
  paymentUI: uiReducer,
  paymentVA: vaReducer,
  paymentNotification: notificationReducer,
  paymentConfig: configReducer,
});
//#endregion
