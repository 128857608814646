import {
  ForgotOTPRequest,
  ForgotPasswordRequest,
  ForgotPasswordResponse
} from '@payment-mfe/shared/models';
import { BaseService } from '../base';

export class ForgotPasswordService extends BaseService<ForgotPasswordResponse> {
  /**
   *
   * @param apiUrl An api url
   * @param request
   * @returns
   */
  async forgotPasswordAsync(
    apiUrl: string,
    request: ForgotPasswordRequest | ForgotOTPRequest
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  ): Promise<ForgotPasswordResponse | null | any> {
    const baseResponse = await this.postAsync(apiUrl, JSON.stringify(request));
    if (baseResponse.errCode === '00') {
      return baseResponse;
    }

    return null;
  }

  /**
   * Verifying forgot passward salt number string.
   * @since 1.0
   * @param apiUrl The end-point of the verify forgot passward salt number string.
   * @param salt The salt number string
   * @returns
   */
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  async verifyForgotPasswordSalt(apiUrl: string, salt: string): Promise<any> {
    const baseResponse = await this.postAsync(
      apiUrl,
      JSON.stringify({ salt: salt })
    );
    if (baseResponse.errCode === '00') {
      return baseResponse.data;
    }

    return null;
  }

  /**
   * Verifying and changes passward was sended by email to end user.
   * @since 1.0
   * @param apiUrl The end-point of validation and changes new passward of the member.
   * @param payload The payload of member
   * @returns
   */
  async verifyForgotPasswordByEmail(
    apiUrl: string,
    payload: {
      newPassward: string;
      confirmNewPassward: string;
      salt: string;
    }
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  ): Promise<any> {
    const baseResponse = await this.postAsync(apiUrl, JSON.stringify(payload));
    if (baseResponse.errCode === '00') {
      return baseResponse;
    }

    return null;
  }

  async verifyOTP(
    authApiUrl: string,
    request: {
      trackingId?: string;
      otp: string;
    }
  ) {
    const response = await this.postAsync(authApiUrl + '/zalo/otp/verify', JSON.stringify(request));

    return response;
  }

  async confirmPassword(
    authApiUrl: string,
    request: {
      tracking_id?: string;
      otp: string;
      new_password: string
    }
  ) {
    const response = await this.postAsync(authApiUrl + '/auth/forgot-password/verification-otp', JSON.stringify(request));

    return response;
  }
}


