import { combineReducers } from '@reduxjs/toolkit';
import passwordReducer, { actions as password } from './password.slice';
import profileReducer, { actions as profile } from './profile.slice';
import transactionReducer, { actions as transaction } from './transaction.slice';
import sendOtpReducer, { actions as sendOtp } from './send-otp.slice';
import verifyOtpReducer, { actions as verifyOtp } from './verify-otp.slice';

export const actions = {
  password,
  profile,
  transaction,
  sendOtp,
  verifyOtp,
};

export default combineReducers({
  password: passwordReducer,
  profie: profileReducer,
  transaction: transactionReducer,
  sendOtp: sendOtpReducer,
  verifyOtp: verifyOtpReducer,
});
