// @ts-check

//#region "Types"
//#endregion

import { BaseService } from '../base';
export type GatewayResponse = {
  transactionId: string;
  redirectUrl: string;
};

export class GatewayService extends BaseService<GatewayResponse> {
  /**
   * Create new an order and get redirect url from payment gateway.
   *
   * @since 1.0
   *
   * @param basePaymentApiUrl An end-point of the create new payment
   * @param req A payload contains order information
   * @returns {Promise<GatewayResponse | null>} Created order information, if present
   */
  async generatePaymentLink(
    basePaymentApiUrl: string,
    transactionId: string
  ): Promise<GatewayResponse | null> {
    const baseResponse = await this.postAsync(
      basePaymentApiUrl,
      JSON.stringify({
        transactionId: transactionId,
      })
    );

    if (baseResponse.errCode === '00') {
      return baseResponse.data;
    }

    return null;
  }
}
