// @ts-check

// #region "Imports"
import actions, {
  useAppDispatch,
  useAppSelector,
} from '@payment-mfe/shared/store';
import {
  Button,
  Col,
  Form,
  FormRule,
  Input,
  Modal,
  Row,
  Skeleton,
  Space,
} from 'antd';
import { useCallback, useEffect, useState } from 'react';
import styles from './change-mobile.module.css';
// #endregion

/* eslint-disable-next-line */
export interface ChangeMobileProps {
  baseAuthApiUrl: string;
  baseNotificationApiUrl: string;
  isModalOpen: boolean;
  mobileNumber: string | null;
  otpType: 'phone' | 'email' | 'change-email' | 'change-phone' | null;
  onCancel: () => void;
  onSuccess: () => void;
}

export function ChangeMobile(props: ChangeMobileProps) {
  // #region "Redux"
  const dispatch = useAppDispatch();

  const trackingId = useAppSelector((state) => state.member.sendOtp.trackingId);
  const sendOtpStatus = useAppSelector((state) => state.member.sendOtp.status);

  const verifyStatus = useAppSelector((state) => state.member.verifyOtp.status);
  const isOtpValid = useAppSelector(
    (state) => state.member.verifyOtp.isOtpValid
  );
  // #endregion

  // #region "Enums"
  enum Screen {
    currentMobile,
    newMobile,
  }
  // #endregion

  //#region "Component State"
  const [form] = Form.useForm();
  const [formNewPhone] = Form.useForm();
  const [formVerify] = Form.useForm();
  const [disableOtpNewPhoneInput, setDisableOtpNewPhoneInput] = useState(true);
  const [newPhone, setNewPhone] = useState('');
  const [newPhoneOtp, setNewPhoneOtp] = useState('');

  const TIME_LEFT_IN_THE_SECONDS = 30;
  const [timeLeft, setTimeLeft] = useState<number>(TIME_LEFT_IN_THE_SECONDS);
  const [currentScreen, setCurrentScreen] = useState<Screen>(
    Screen.currentMobile
  );

  //#endregion

  // #region "Hooks"

  /**
   * Controlable the visibility of modal.
   */
  useEffect(() => {
    if (props.isModalOpen === false) return;

    // exit early when we reach 0
    if (!timeLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const interval = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(interval);
    // add timeLeft as a dependency to the re-return the effect
    // when we update it
  }, [props, timeLeft]);

  /**
   * The callback to make an notificaiton to the parent component.
   * @since 1.0
   */
  const onSuccessCallback = useCallback(() => {
    if (
      isOtpValid === true &&
      verifyStatus === 'successed' &&
      currentScreen === Screen.newMobile
    ) {
      props.onSuccess();
      setDisableOtpNewPhoneInput(true);
    }
  }, [props, currentScreen, verifyStatus, isOtpValid, Screen]);

  /**
   * A handle of the verification result.
   * @since 1.0
   */
  const handleVerifyResultMobileCallback = useCallback(
    (currentScreen: Screen) => {
      if (currentScreen === Screen.currentMobile) {
        // in the prompt the one-time password for current email address screen.
        setCurrentScreen(Screen.newMobile);
        dispatch(actions.member.sendOtp.doResetState());
        dispatch(actions.member.verifyOtp.doResetState());
      }
      setTimeLeft(-1);
    },
    [dispatch, Screen.currentMobile, Screen.newMobile]
  );

  /**
   * Grab the result of an verification request.
   * @since 1.0
   */
  useEffect(
    () => {
      if (
        isOtpValid === true &&
        verifyStatus === 'successed' &&
        props.otpType === 'change-phone'
      ) {
        handleVerifyResultMobileCallback(currentScreen);
      }
    },
    [isOtpValid, verifyStatus, handleVerifyResultMobileCallback] // eslint-disable-line
  );
  // #endregion

  // Implement success function && show modal success
  useEffect(() => {
    if (
      isOtpValid === true &&
      verifyStatus === 'successed' &&
      currentScreen === Screen.newMobile
    ) {
      onSuccessCallback();
    }
  }, [
    isOtpValid,
    verifyStatus,
    onSuccessCallback,
    currentScreen,
    Screen.newMobile,
  ]);

  // Validate opt wrong or true when submit otp
  useEffect(() => {
    form.validateFields();
    formVerify.validateFields();
  }, [isOtpValid, form, formVerify]);

  /**
   * A component allowed to the verification the otp of current email.
   * @since 1.0
   *
   * @returns
   */
  const CurrentMobileComponent = (props: {
    baseAuthApiUrl: string;
    baseNotificationApiUrl: string;
    mobileNumber: string;
    trackingId: string;
  }) => {
    // #region "Validation"
    /**
     * The validation rules of an one-time password.
     * @since 1.0
     */
    const otpRules = [
      { pattern: /^\d{6}$/, message: 'Mã xác thực không đúng định dạng.' },
      {
        validator: async (_, values) => {
          if (values === '') {
            return Promise.reject(
              new Error('Mã xác thực không được để trống.')
            );
          } else if (values !== '') {
            if (isOtpValid || isOtpValid === null) {
              return Promise.resolve();
            }
            return Promise.reject(new Error('Mã xác thực không chính xác.'));
          }
          return Promise.resolve();
        },
      },
    ] as FormRule[];
    //#endregion

    // #region "Business"
    /**
     * Verification the one time password, prompted by end user.
     * @since 1.0
     *
     * @param values The form values.
     */
    const onFinish = (values: { otp: string }): void => {
      dispatch(
        actions.member.verifyOtp.doVerifyAsync({
          baseAuthApiUrl: props.baseAuthApiUrl,
          trackingId: props.trackingId,
          otp: values.otp,
        })
      );
    };

    /**
     * Resent an one-time password.
     *
     * @since 1.0
     */
    const onResent = (): void => {
      if (props.mobileNumber) {
        const mobileNumber = props.mobileNumber
          .replace(/^0/, '84')
          .replace(/^\+/, '');

        dispatch(
          actions.member.sendOtp.doSendAsync({
            baseNotificationApiUrl: props.baseNotificationApiUrl,
            emailOrMobile: mobileNumber,
            type: 'mobile',
          })
        );

        // Reset the countdown number.
        setTimeLeft(TIME_LEFT_IN_THE_SECONDS);
      }
    };
    //#endregion

    // #region "View Render"
    return (
      <div className={styles['modal-content']}>
        <section className={styles['text-message']}>
          Mã xác thực đã được gửi tới ứng dụng Zalo của bạn, vui lòng kiểm tra
          tin nhắn trên ứng dụng Zalo.
        </section>

        <Form
          form={form}
          layout={'vertical'}
          autoComplete="off"
          style={{ marginTop: 16 }}
          preserve={false}
          onFinish={onFinish}
        >
          <Form.Item label="Mã xác thực" name="otp" rules={otpRules}>
            <Input
              onFocus={() => dispatch(actions.member.verifyOtp.doResetState())}
              type="text"
              placeholder="Nhập mã xác thực của bạn"
              inputMode={'text'}
              size={'large'}
            />
          </Form.Item>

          <Form.Item style={{ marginTop: 48 }}>
            <Space
              direction={'horizontal'}
              style={{ width: '100%', justifyContent: 'center' }}
            >
              <Button
                type={'primary'}
                htmlType={'button'}
                size={'large'}
                ghost
                onClick={onResent}
                disabled={timeLeft > 0 || timeLeft === -1}
              >
                Gửi lại mã {timeLeft > 0 ? '(' + timeLeft + 's)' : null}
              </Button>
              <Button
                type={'primary'}
                htmlType={'submit'}
                className="button-primary"
                size={'large'}
              >
                Xác nhận
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    );
    // #endregion
  };

  /**
   * A component allowed to the change new e-mail address of the member.
   * @since 1.0
   *
   * @returns
   */
  const ChangeNewMobileComponent = (props: {
    baseAuthApiUrl: string;
    baseNotificationApiUrl: string;
  }) => {
    // #region "Validation Rules"
    /**
     * The validation rules of an email address.
     * @since 1.0
     */
    const mobileRules = [
      { required: true, message: 'Số điện thoại không được để trống.' },
      {
        pattern: /^(((\\+|)84)|0)(3|5|7|8|9)+([0-9]{8})$/,
        message: 'Số điện thoại không đúng định dạng.',
      },
      {
        validator: async (_, value): Promise<void> => {
          // Validation an e-mail address is the unique.
          if (value) {
            const response = await fetch(
              props.baseAuthApiUrl + '/members/unique-mobile/' + value
            );
            if (response.status === 200) {
              const jsonBody = await response.json();
              if (jsonBody.errCode === '00') {
                return Promise.resolve();
              }
            }

            return Promise.reject('The mobile number was existed.');
          }
        },
        message: 'Số điện thoại đã tồn tại trên hệ thống.',
      },
    ] as FormRule[];

    /**
     * The validation rules of an one-time password.
     * @since 1.0
     */
    const otpRules = [
      { pattern: /^\d{6}$/, message: 'Mã xác thực không đúng định dạng.' },
      {
        validator: async (_, values) => {
          if (values === '') {
            return Promise.reject(
              new Error('Mã xác thực không được để trống.')
            );
          } else if (values !== '') {
            if (isOtpValid || isOtpValid === null) {
              return Promise.resolve();
            }
            return Promise.reject(new Error('Mã xác thực không chính xác.'));
          }
          return Promise.resolve();
        },
      },
    ] as FormRule[];
    // #endregion

    // #region "Business"
    /**
     * Verification an one-time password was send to the new e-mail address.
     * @since 1.0
     *
     * @param values The form values.
     */
    const onFinish = async (values: {
      newOtpNumber: string;
    }): Promise<void> => {
      if (trackingId && values.newOtpNumber) {
        // Validation new e-mail address.
        await dispatch(
          actions.member.verifyOtp.doVerifyAsync({
            baseAuthApiUrl: props.baseAuthApiUrl,
            trackingId: trackingId,
            otp: values.newOtpNumber,
          })
        );
        await dispatch(
          actions.member.profile.getAsync({
            baseAuthApiUrl: props.baseAuthApiUrl,
          })
        );
      }
      formNewPhone.validateFields();
      formVerify.validateFields();
    };

    /**
     * Send new the one-time password.
     * @since 1.0
     *
     * @param values The form values.
     */
    const onSend = (values: { newMobileNumber: string }): void => {
      const newMobileNumber = values.newMobileNumber.replace(/^0/, '84');

      dispatch(
        actions.member.sendOtp.doSendAsync({
          baseNotificationApiUrl: props.baseNotificationApiUrl,
          emailOrMobile: newMobileNumber,
          type: 'mobile',
        })
      );

      // reset the time left
      setTimeLeft(TIME_LEFT_IN_THE_SECONDS);
      setDisableOtpNewPhoneInput(false);
    };
    // #endregion

    // #region "Business"
    return (
      <div className={styles['modal-content']}>
        <Form
          layout="vertical"
          autoComplete="off"
          preserve={false}
          size={'large'}
          onFinish={onSend}
          form={formNewPhone}
        >
          <Form.Item
            label="Số điện thoại"
            name={'newMobileNumber'}
            rules={mobileRules}
          >
            <Row justify={'space-between'}>
              <Col span={16}>
                <Input
                  onChange={(e) => setNewPhone(e.target.value)}
                  placeholder="Nhập số điện thoại mới của bạn"
                  type="text"
                />
              </Col>
              <Col span={8}>
                <div style={{ paddingInlineStart: 8 }}>
                  <Button
                    htmlType={'submit'}
                    type={'primary'}
                    ghost
                    style={{ width: '100%' }}
                    disabled={timeLeft > 0}
                  >
                    {sendOtpStatus === 'idle'
                      ? 'Gửi mã'
                      : timeLeft > 0
                      ? 'Gửi lại sau (' + timeLeft + 's)'
                      : 'Gửi lại'}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form.Item>
        </Form>

        <Form
          form={formVerify}
          layout="vertical"
          autoComplete="off"
          preserve={false}
          size={'large'}
          onFinish={onFinish}
        >
          <Form.Item label="Mã xác thực" rules={otpRules} name={'newOtpNumber'}>
            <Input
              disabled={disableOtpNewPhoneInput}
              onChange={(e) => setNewPhoneOtp(e.target.value)}
              onFocus={() => dispatch(actions.member.verifyOtp.doResetState())}
              placeholder="Nhập mã xác thực của bạn"
              type="text"
            />
          </Form.Item>

          <Form.Item style={{ textAlign: 'center' }}>
            <Button
              type={'primary'}
              htmlType={'submit'}
              disabled={newPhone === '' || newPhoneOtp === ''}
            >
              Xác nhận
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
    // # endregion
  };
  // #endregion

  // #region "View render"
  return (
    <Modal
      title={
        <div>
          <div
            className={styles['modal-title__btn_back']}
            onClick={() => setCurrentScreen(Screen.currentMobile)}
          >
            <h4 className={styles['modal-title__text']}>Đổi Số điện thoại</h4>
          </div>
        </div>
      }
      centered
      footer={null}
      open={props.isModalOpen}
      destroyOnClose
      onCancel={() => {
        props.onCancel();
        setCurrentScreen(Screen.currentMobile);
        setTimeLeft(0);
        setDisableOtpNewPhoneInput(true);
        setNewPhone('');
        setNewPhoneOtp('');
      }}
    >
      {currentScreen === Screen.currentMobile ? (
        sendOtpStatus === 'successed' && trackingId && props.mobileNumber ? (
          CurrentMobileComponent({
            baseAuthApiUrl: props.baseAuthApiUrl,
            baseNotificationApiUrl: props.baseNotificationApiUrl,
            trackingId: trackingId,
            mobileNumber: props.mobileNumber,
          })
        ) : (
          <Skeleton />
        )
      ) : currentScreen === Screen.newMobile ? (
        ChangeNewMobileComponent({
          baseAuthApiUrl: props.baseAuthApiUrl,
          baseNotificationApiUrl: props.baseNotificationApiUrl,
        })
      ) : (
        <Skeleton />
      )}
    </Modal>
  );
  //#endregion
}

export default ChangeMobile;
