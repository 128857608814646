// @ts-check

//#region "Imports"
import {
  Skeleton,
  Tooltip,
} from 'antd';
import React from 'react';
import QRCode from 'react-qr-code';
import { useParams } from 'react-router-dom';
import { io } from 'socket.io-client';

import { BreadcrumbWrapper } from '@payment-mfe/shared/commons';
import { QrCodeHelper } from '@payment-mfe/shared/service';
import actions, { useAppDispatch, useAppSelector } from '@payment-mfe/shared/store';

import styles from '../payment.module.css';
//#endregion

//#region "Properties"
/**
 * The properties of this component.
 * @version 1.0
 */
export interface PmQrcodeProps {
  baseAuthApiUrl: string;
  basePaymentApiUrl: string;
  baseNotificationApiUrl: string;
}
//#endregion

//#region "Components"
/**
 * A component using to generate the QR code, for each payment action,
 * every when the FE payment via internet banking method.
 */
export function PaymentQRCodeMobile(props: PmQrcodeProps): JSX.Element {
  //#region "Redux"
  const dispatch = useAppDispatch();
  
  // the list of a supported banks.
  const listBankings = useAppSelector(
    (state) => state.payment.paymentBank.entities
  );

  // a bank selected by a member.
  const selectedBank = useAppSelector((state) => state.payment.paymentUI.bank);

  // the amount of an order
  const amount = useAppSelector(
    (state) => state.payment.paymentUI.conversionRate?.currencyAmount
  );

  // a member profile.
  const profile = useAppSelector((state) => state.member.profie.profile);

  // the authenticated user.
  const currentUser = useAppSelector((state) => state.appState.currentUser)

  /**
   * A name of the bank, selected by FE.
   */
  const paymentBankName = useAppSelector(
    (state) => state.payment.paymentUI.bank?.name
  );
  //#endregion

  // #region "States"
  const { slug } = useParams(); // get the query string.
  const [scroll, setScroll] = React.useState(false); // control scrollable of browser.
  const [
    showCopyVirtualAccount,
    setShowCopyVirtualAccount
  ] = React.useState(false); // control the state of copy to clipboard button.
  const [
    showCopyTransactionContent,
    setShowCopyTransactionContent
  ] = React.useState(false); // control the state of copy transaction informatiton to the clipboard button.
  // #endregion

  // #region "Variable"
  const rateString = localStorage.getItem('rate');
  const rateLocalValue = rateString ? JSON.parse(rateString) : null;
  // #endregion

  //#region "Effect"

  /**
   * Scroll to a position after page load.
   * @version 1.0
   */
  React.useEffect(() => {
    window.scrollTo({
      top: 435,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  /**
   * Scroll the screen to a position.
   * @version 1.0
   */
  React.useEffect(() => {
    window.scrollTo({ top: 150, behavior: 'smooth', });
    setScroll(false);
  }, [scroll]);
  
  /**
   * Get the banks supported.
   * @version 1.0
   */
  React.useEffect(() => {
    dispatch(
      actions.payment.bank.getAll({
        basePaymentApiUrl: props.basePaymentApiUrl,
      })
    );
  }, [dispatch, props.basePaymentApiUrl]);

  /**
   * Get the member information.
   * @since 1.0
   */
  React.useEffect(() => {
    if (props.baseAuthApiUrl) {
      dispatch(
        actions.member.profile.getAsync({
          baseAuthApiUrl: props.baseAuthApiUrl,
        })
      );
    }
  }, [dispatch, props.baseAuthApiUrl]);

  /**
   * Make the default bank is selected.
   * @since 1.0
   */
  React.useEffect(() => {
    if (selectedBank === null) {
      const defaultBank = [...Object.values(listBankings)][0];

      if (typeof defaultBank !== 'undefined') {
        dispatch(
          actions.payment.ui.changesBank({
            id: defaultBank.id,
            name: defaultBank.name,
            bankAccountNumber: defaultBank.bank_account_number,
            bnbId: defaultBank.bnb_id,
          })
        );
      }
    }
  }, [dispatch, listBankings, selectedBank]);

  /**
   * Handles notification received from socket (namespace qr-pay)
   * @since 1.1
   */
  React.useEffect(() => {
    if (currentUser && props.baseNotificationApiUrl) {
      const socket = io(`${props.baseNotificationApiUrl}/qr-pay`, {
        autoConnect: true,
        reconnection: true,
      })

      socket.on(`auth-${currentUser.uid}`, (msg) => {
        const result = msg as {success: boolean; transactionId: string};
        if (result.success === true && result.transactionId) {
            dispatch(
              actions.payment.notification.qrReceived({
                success: result.success,
                transactionId: result.transactionId,
              })
            )
        }
      });
    }
  }, [dispatch, props.baseNotificationApiUrl, currentUser])
  
  //#endregion

  // #region QR code generator
  /**
   * Generate a QR code component.
   * @version 1.0
   */
  const QRCodeGenerator = React.useCallback(() => {
    const qrService = new QrCodeHelper();
    if (selectedBank && profile) {
      const qrEncode = qrService.generateQrCode(
        selectedBank.bnbId,
        selectedBank.bankAccountNumber,
        amount ? amount : rateLocalValue.currencyAmount,
        'BOOM' + profile.uid.toString()
      );

      return (
        <div className={styles['qr-wrapper']}>
          <QRCode size={196} value={qrEncode} />
        </div>
      );
    }

    return <Skeleton />;
  }, [selectedBank, amount, profile, rateLocalValue]);

  //#region "Render the view"
  return (
    <BreadcrumbWrapper link={`/nap-game/${slug}/va`} text="Quay lại">
      <div
        // style={{ display: isOpenModal ? 'block' : 'none' }}
        className={styles['bank-tranfer']}
      >
        <div>
          <div style={{ position: 'relative' }}>
            <p className={styles['payment-confirm-title']}>Chọn ngân hàng</p>
          </div>
          <div className={styles['bank-option']}>
            {[...Object.values(listBankings)].map((item) => {
              if (item !== null && typeof item !== 'undefined') {
                return (
                  <div
                    key={item.id}
                    style={{
                      backgroundColor:
                        selectedBank?.id === item.id
                          ? 'rgba(255, 154, 0, 0.30)'
                          : '#FFF',
                    }}
                    onClick={(event) => {
                      event.preventDefault();
                      setScroll(true);
                      //  changes the payment bank
                      dispatch(
                        actions.payment.ui.changesBank({
                          id: item.id,
                          name: item.name,
                          bankAccountNumber: item.bank_account_number,
                          bnbId: item.bnb_id,
                        })
                      );
                    }}
                    className={styles['bank-item']}
                  >
                    <img src={item.icon_path} alt={item.name} />
                    <span>{item.code.toUpperCase()}</span>
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
        {selectedBank && (
          <>
            <div className={styles['va-confirm']}>
              <p>Thông tin chuyển khoản</p>
            </div>
            <div className={styles['bank-info']}>
              <div className={styles['bank-info__item']}>
                <div className={styles['pay-block']}>
                  <div className={styles['pay-option']}>Cách 1:</div>
                  <ul className={styles['pay-step']}>
                    <li>Quét mã QR để thanh toán</li>
                    <li>- Bước 1: Mở ứng dụng mobile banking của bạn</li>
                    <li>- Bước 2: Đăng nhập và quét mã QR bên dưới</li>
                    <li>- Bước 3: Hoàn thành các bước thanh toán</li>
                    <li>{QRCodeGenerator()}</li>
                    <li
                      className={styles['bank-owner']}
                      style={{
                        flexDirection: 'column',
                        gap: '2px',
                        marginBottom: '12px',
                        marginTop: '4px',
                        marginLeft: '-70px',
                      }}
                    >
                      <span>CTY CP BOOM GAMES</span>
                      <span>{selectedBank?.bankAccountNumber || ''}</span>
                      <span>{paymentBankName}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={styles['bank-info__item']}>
                <div className={styles['pay-block']}>
                  <ul className={styles['pay-step']}>
                    <div>
                      <span className={styles['pay-option']}>Cách 2:</span>
                      <span>Chuyển tiền vào tài khoản dưới đây:</span>
                    </div>
                    <li>
                      <p>
                        Số tài khoản:{' '}
                        <strong>{selectedBank?.bankAccountNumber}</strong>
                      </p>
                      <Tooltip
                        title="Số tài khoản đã được sao chép"
                        arrow={showCopyVirtualAccount}
                        trigger={'click'}
                      >
                        <button
                          onClick={() => {
                            navigator.clipboard.writeText(
                              selectedBank?.bankAccountNumber || ''
                            );
                            setShowCopyVirtualAccount(true);
                          }}
                        >
                          Copy
                        </button>
                      </Tooltip>
                    </li>
                    <li style={{ display: 'block' }}>
                      Chủ tài khoản:{'  '}
                      <strong>CTY CP BOOM GAMES</strong>
                    </li>
                    <li style={{ display: 'block' }}>
                      Ngân hàng thụ hưởng: <strong>{paymentBankName}</strong>
                    </li>
                    <li>
                      <p>
                        Nội dung chuyển khoản:{' '}
                        <strong>BOOM{profile?.uid}</strong>
                      </p>
                      <Tooltip
                        title="Nội dung chuyển khoản đã được sao chép"
                        arrow={showCopyTransactionContent}
                        trigger={'click'}
                      >
                        <button
                          onClick={() => {
                            navigator.clipboard.writeText(
                              `BOOM${profile?.uid.toString()}` || ''
                            );
                            setShowCopyTransactionContent(true);
                          }}
                        >
                          Copy
                        </button>
                      </Tooltip>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <h5>
              Chú ý: Mỗi Boom ID được liên kết với một nội dung chuyển khoản khác nhau,
              vui lòng copy để lấy chính xác số tài khoản và nội dung chuyển khoản.
            </h5>
          </>
        )}
      </div>
    </BreadcrumbWrapper>
  );
  //#endregion
}
//#endregion
