import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import type { LoginRequest, OAuthRequest } from '@payment-mfe/shared/models';
import { LoginService } from '@payment-mfe/shared/service';

const LOGIN_FEATURE_KEY = 'auth/login';
const ACTION_LOGIN_USING_USERNAME_KEY = LOGIN_FEATURE_KEY + '/username';
const ACTION_LOGIN_USING_OAUTH2_KEY = LOGIN_FEATURE_KEY + '/oauth2';

export interface LoginState {
  redirectPath: string | null
  status: 'idle' | 'loading' | 'successed' | 'failed';
  error: string | null;
}

const initialState: LoginState = {
  redirectPath: null,
  status: 'idle',
  error: null,
};

export const loginByUsernameAndPassword = createAsyncThunk(
  ACTION_LOGIN_USING_USERNAME_KEY,
  async (payload: { authApiUrl: string; loginRequest: LoginRequest }) => {
    const service = new LoginService();
    const response = await service.loginAsync(
      payload.authApiUrl,
      payload.loginRequest
    );
    return response;
  }
);

export const loginByOAuth2Async = createAsyncThunk(
  ACTION_LOGIN_USING_OAUTH2_KEY,
  async (payload: { authApiUrl: string; req: OAuthRequest }) => {
    const service = new LoginService();
    const response = await service.oauthLoginAsync(
      payload.authApiUrl,
      payload.req
    );

    return response;
  }
);

const loginSlice = createSlice({
  name: LOGIN_FEATURE_KEY,
  initialState,
  reducers: {
    resetState: (state) => {
      state.status = 'idle';
      state.error = null;
    },
    setRedirectPath: (state, action) => {
      state.redirectPath = action.payload;
    },
    resetRedirectPath: (state) => {
      state.redirectPath = null
    }
  },
  extraReducers: (builder) => {
    // add reducers for aditional action types here, and handle loading state as needed
    builder.addCase(loginByUsernameAndPassword.pending, (state) => {
      state.status = 'loading';
      state.error = null;
    });
    builder.addCase(loginByUsernameAndPassword.fulfilled, (state, action) => {
      if (action.payload != null) {
        // store the token to local storage
        const token = action.payload.accessToken;
        localStorage.removeItem('token');
        localStorage.removeItem('userLoggedIn')
        localStorage.setItem('token', token);

        state.status = 'successed';
        state.error = 'the member was logged in';
      } else {
        state.status = 'failed';
        state.error = 'login failure due wrong logical';
      }
    });
    builder.addCase(loginByUsernameAndPassword.rejected, (state) => {
      state.status = 'failed';
      state.error = 'send request to login service failure';
    });

    builder.addCase(loginByOAuth2Async.pending, (state) => {
      state.status = 'loading';
      state.error = null;
    });
    builder.addCase(loginByOAuth2Async.fulfilled, (state, action) => {
      if (action.payload != null) {
        // store the token to local storage
        const token = action.payload.accessToken;
        localStorage.clear();
        localStorage.setItem('token', token);

        state.status = 'successed';
        state.error = 'the member was logged in';
      } else {
        state.status = 'failed';
        state.error = 'login failure due wrong logical';
      }
    });
    builder.addCase(loginByOAuth2Async.rejected, (state) => {
      state.status = 'failed';
      state.error = 'send request to login service failure';
    });
  },
});

export const actions = {
  ...loginSlice.actions,
  loginByUsernameAndPassword,
  loginByOAuth2Async
}

export default loginSlice.reducer;
