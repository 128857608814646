// @ts-check
import { BaseService } from '../base';

export type QrResponse = {
  qrCode: string;
  transaction: string;
  amount: number;
  account: string;
};

export class QrService extends BaseService<QrResponse> {
  /**
   * Generate the qr code base64 string.
   * @since 1.0
   *
   * @param basePaymentApiUrl
   * @param transactionId
   * @returns { Promise<QrResponse | null> }
   */
  async generateBase64QrString(
    basePaymentApiUrl: string,
    transactionId: string
  ): Promise<QrResponse | null> {
    const baseResponse = await this.postAsync(
      basePaymentApiUrl + '/qr',
      JSON.stringify({ transactionId: transactionId })
    );
    if (baseResponse.errCode === '00') {
      return baseResponse.data as QrResponse;
    }

    return null;
  }
}
