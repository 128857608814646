import styles from './collapse-custom.module.css';
import { Collapse } from 'antd';
import { DownOutlined } from '@ant-design/icons';

export interface CollapseCustomProps {
  children: React.ReactNode;
  label: string;
}

export function CollapseCustom(props: CollapseCustomProps) {
  return (
    <div className={styles['container']}>
      <Collapse
        expandIconPosition={'end'}
        expandIcon={({ isActive }) => (
          <DownOutlined
            className={styles['expand-icon']}
            rotate={isActive ? 180 : 0}
          />
        )}
        className={styles['collapse']}
        bordered={false}
        items={[
          {
            key: '1',
            label: <p className={styles['title']}>{props.label}</p>,
            children: props.children,
          },
        ]}
      />
    </div>
  );
}

export default CollapseCustom;
