import { Button } from 'antd';
import styles from './not-found.module.css';
import { useNavigate } from 'react-router-dom';

/* eslint-disable-next-line */
export interface NotFoundProps {}

export function NotFound(props: NotFoundProps) {
  const navigate = useNavigate();
  return (
    <div className={styles['container']}>
      <img src="assets/images/not-found.png" alt="" />
      <div className={styles['not-found-text']}>
        Không tìm thấy đường dẫn này
      </div>
      <Button onClick={() => navigate('/')}>Về trang chủ</Button>
    </div>
  );
}

export default NotFound;
