import styles from './direct-support.module.css';

export function DirectSupport() {
  return (
    <div className={styles['container']}>
      <p className={styles['title']}>Bạn cần hỗ trợ trực tiếp?</p>
      <p className={styles['sub-title']}>
        Chúng tôi luôn ở đây hỗ trợ bạn
        <br />
        Thời gian: 08:00 - 24:00 tất cả các ngày (GMT+7)
      </p>
      <div className={styles['support-method-container']}>
        <div
          onClick={() => window.open('https://m.me/boom.cskh')}
          className={styles['support-method']}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
          >
            <path
              d="M50 12.5C29.4067 12.5 12.5 28.5522 12.5 48.4375C12.5 59.0332 17.4072 68.4448 25 75V89.4531L38.8672 82.5195C42.395 83.5815 46.0815 84.375 50 84.375C70.5933 84.375 87.5 68.3228 87.5 48.4375C87.5 28.5522 70.5933 12.5 50 12.5ZM50 18.75C67.3706 18.75 81.25 32.0801 81.25 48.4375C81.25 64.7949 67.3706 78.125 50 78.125C46.2646 78.125 42.7002 77.417 39.3555 76.2695L38.0859 75.8789L31.25 79.2969V72.2656L30.0781 71.2891C23.1445 65.8203 18.75 57.6294 18.75 48.4375C18.75 32.0801 32.6294 18.75 50 18.75ZM46.4844 38.5742L27.6367 58.4961L44.5312 49.1211L53.5156 58.7891L72.168 38.5742L55.6641 47.8516L46.4844 38.5742Z"
              fill="#FF6C00"
            />
          </svg>
          <span>Nhắn Tin</span>
        </div>
        <div
          className={styles['support-method']}
          onClick={() => (window.location.href = 'mailto:hotro@boomstudio.vn')}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="101"
            height="100"
            viewBox="0 0 101 100"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.33594 31.25C6.33594 29.8693 7.45523 28.75 8.83594 28.75H25.5026C26.8833 28.75 28.0026 29.8693 28.0026 31.25C28.0026 32.6307 26.8833 33.75 25.5026 33.75H11.3359V82.9167H89.6693V33.75H75.5026C74.1219 33.75 73.0026 32.6307 73.0026 31.25C73.0026 29.8693 74.1219 28.75 75.5026 28.75H92.1693C93.55 28.75 94.6693 29.8693 94.6693 31.25V85.4167C94.6693 86.7974 93.55 87.9167 92.1693 87.9167H8.83594C7.45523 87.9167 6.33594 86.7974 6.33594 85.4167V31.25Z"
              fill="#FF6C00"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M50.5 7.91663C51.8807 7.91663 53 9.03591 53 10.4166V39.5833C53 40.964 51.8807 42.0833 50.5 42.0833C49.1193 42.0833 48 40.964 48 39.5833V10.4166C48 9.03591 49.1193 7.91663 50.5 7.91663Z"
              fill="#FF6C00"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M48.7322 8.64886C49.7085 7.67255 51.2915 7.67255 52.2678 8.64886L64.7678 21.1489C65.7441 22.1252 65.7441 23.7081 64.7678 24.6844C63.7915 25.6607 62.2085 25.6607 61.2322 24.6844L50.5 13.9522L39.7678 24.6844C38.7915 25.6607 37.2085 25.6607 36.2322 24.6844C35.2559 23.7081 35.2559 22.1252 36.2322 21.1489L48.7322 8.64886Z"
              fill="#FF6C00"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.83608 29.75C7.66451 28.6455 9.23151 28.4216 10.3361 29.25L50.5028 59.375L90.6694 29.25C91.774 28.4216 93.341 28.6455 94.1694 29.75C94.9978 30.8546 94.774 32.4216 93.6694 33.25L52.0028 64.5C51.1139 65.1667 49.8916 65.1667 49.0028 64.5L7.33608 33.25C6.23152 32.4216 6.00766 30.8546 6.83608 29.75Z"
              fill="#FF6C00"
            />
          </svg>
          <span>Email</span>
        </div>
      </div>
    </div>
  );
}

export default DirectSupport;
