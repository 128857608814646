//#region "Decleration"

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GiftCodeResponse } from '@payment-mfe/shared/models';
import { GiftcodeService } from '@payment-mfe/shared/service';

//#endregion

//#region "Constant"

const GIFTCODE_FEATURE_KEY = 'home/giftcode';

const ACTION_GIFTCODE_KEY = GIFTCODE_FEATURE_KEY + '/list';

//#endregion

//#region "AsyncThunk"

export const listGiftcode = createAsyncThunk(ACTION_GIFTCODE_KEY, async () => {
  const service = new GiftcodeService();
  const response = await service.listAsync('');
  return response;
});

//#endregion

//#region "States"

export interface GiftcodeState {
  status: 'idle' | 'loading' | 'successed' | 'failed';
  error: string | null;
  giftcodes: GiftCodeResponse[];
}

const initialState: GiftcodeState = {
  status: 'idle',
  error: null,
  giftcodes: [],
};

//#endregion

//#region "Slice"

export const giftcodeSlice = createSlice({
  name: GIFTCODE_FEATURE_KEY,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // for pending
    builder.addCase(listGiftcode.pending, (state) => {
      state.status = 'loading';
      state.error = 'fetching giftcode from service';
    });

    // for get result
    builder.addCase(listGiftcode.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.status = 'successed';
        state.error = 'fetch giftcode successful';

        state.giftcodes = [];
        state.giftcodes.push(...action.payload);
      } else {
        state.status = 'failed';
        state.error = 'fetch giftcode failure due logical';
      }
    });

    // for reject
    builder.addCase(listGiftcode.rejected, (state) => {
      state.status = 'failed';
      state.error = 'fetch gift code failure due http service';
    });
  },
});

//#endregion

export const actions = {
  ...giftcodeSlice.actions,
  listGiftcode
}

//#region "Reducer"

export default giftcodeSlice.reducer;

//#endregion
