//@ts-check
//#region "Import modules"
import { BaseService } from '../base';
//#endregion

//#region "Request & Response"
export type VerifyResponse = {
  isSuccess: boolean;
};
//#endregion

//#region "Business"
export class VirtualAccountVerifyService extends BaseService<VerifyResponse> {
  /**
   * Verifying a transaction.
   *
   * @param basePaymentApiUrl
   * @param transactionId
   * @returns {Promise<VerifyResponse | null>}
   */
  async getVerifyAsync(
    basePaymentApiUrl: string,
    transactionId: string
  ): Promise<VerifyResponse | null> {
    const baseResponse = await this.getAsync(
      basePaymentApiUrl + '/virtual-account/verify-transaction/' + transactionId
    );

    if (baseResponse.errCode === '00') {
      return baseResponse.data as VerifyResponse;
    }

    return null;
  }
}
//#endregion
