//@ts-check

//#region "Imports"
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
//#endregion

//#region "Contants"
export const APP_FEATURE_KEY = 'payment/ui';
//#endregion

//#region "State"
export interface UIState {
  // using to showing component inside payment.
  step: 'method' | 'confirmation' | 'qr';

  // selected payment method
  method: {
    code: string;
    name: string;
  } | null;

  // selected bank
  bank: {
    id: string;
    name: string;
    bankAccountNumber: string;
    bnbId: string;
  } | null;

  // selected conversion rate.
  conversionRate: {
    id: string;
    currencyAmount: number;
    gameAmount: number;
  } | null;

  currentRate: string
}

const initialState: UIState = {
  step: 'method',
  method: null,
  bank: null,
  conversionRate: null,
  currentRate: ''
};
//#endregion

//#region "Slice"
const uiStateSlice = createSlice({
  name: APP_FEATURE_KEY,
  initialState: initialState,
  reducers: {
    selectedRate: (state, action: PayloadAction<string>) => {
      state.currentRate = action.payload
    },
    resetPayment: (state) => {
      state.currentRate = ''
      state.method = null
    },
    /**
     * Changes the payment method.
     * @since 1.0
     * @param state Current state of reducer.
     * @param action The action payload.
     */
    changesMethod: (
      state,
      action: { payload: { code: string; name: string } }
    ) => {
      state.step = 'confirmation';
      state.method = {
        code: action.payload.code,
        name: action.payload.name,
      };
      state.conversionRate = null;
      state.bank = null;
    },
    /**
     * Changes the payment bank.
     * @since 1.0
     *
     * @param state Current state of reducer.
     * @param action The action payload.
     */
    changesBank: (state, action: { payload: { id: string; name: string, bankAccountNumber: string, bnbId: string } }) => {
      state.step = 'confirmation';
      state.bank = {
        id: action.payload.id,
        name: action.payload.name,
        bankAccountNumber: action.payload.bankAccountNumber,
        bnbId: action.payload.bnbId,
      };
    },
    /**
     * Changes the conversion rate.
     * @since 1.0
     * @param state Current state of reducer.
     * @param action The action payload.
     */
    changesConversionRate: (
      state,
      action: {
        payload: {
          id: string;
          currencyAmount: number;
          gameAmount: number;
        };
      }
    ) => {
      state.conversionRate = {
        id: action.payload.id,
        currencyAmount: action.payload.currencyAmount,
        gameAmount: action.payload.gameAmount,
      };
    },
    /**
     * Changs the current step.
     * @since 1.0
     * @param state Current state of reducer.
     * @param action The action payload.
     */
    changesStep: (
      state,
      action: {
        payload: { newStep: 'method' | 'confirmation' | 'qr' };
      }
    ) => {
      state.step = action.payload.newStep;
    },
  },
});
//#endregion

//#region "Exports"
export const actions = {
  ...uiStateSlice.actions,
};

export default uiStateSlice.reducer;
//#endregion
