// @ts-check

//#region "Modules"
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { RateService } from '@payment-mfe/shared/service';
import type { RateResponse } from '@payment-mfe/shared/service';
//#endregion

//#region "Constants"
const RATE_FEATURE_KEY = 'payment/rate';
const LIST_ALL_ACTION_KEY = RATE_FEATURE_KEY + '/list';
//#endregion

const service = new RateService();

//#region "AsyncThunk"
const listAll = createAsyncThunk(
  LIST_ALL_ACTION_KEY,
  async (payload: { basePaymentApiUrl: string; type: string }) => {
    const response = await service.listAsync(
      payload.basePaymentApiUrl,
      payload.type
    );
    return response;
  }
);
//#endregion

//#region "Adapters"
const adapter = createEntityAdapter<RateResponse>({
  selectId: (conversionRate) => conversionRate.id,
  sortComparer: (a, b) =>
    (a.priority + '_' + a.name).localeCompare(b.priority + '_' + b.name),
});
//#endregion

//#region "States"

export interface RateState {
  status: 'idle' | 'loading' | 'successed' | 'failed';
  error: string | null;
}

const initialState: RateState = {
  status: 'idle',
  error: null,
};

//#endregion

//#region "Slice"
export const rateStateSlice = createSlice({
  name: RATE_FEATURE_KEY,
  initialState: adapter.getInitialState({
    ...initialState,
  }),
  reducers: {
    resetRates: (state) => {
      adapter.setAll(state, []);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listAll.rejected, (state) => {
      state.status = 'failed';
      state.error =
        'Cannot get list of conversion rate from service. Check your network';
    });
    builder.addCase(listAll.pending, (state) => {
      state.status = 'loading';
      state.error = 'Get list of the conversion rate is processing';
    });
    builder.addCase(listAll.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.status = 'successed';
        state.error = 'Get list of the conversion rate successful';

        adapter.setAll(state, action.payload);
      } else {
        state.status = 'failed';
        state.error = 'The server return empty of list';
      }
    });
  },
});
//#endregion

//#region "Export"
export const actions = {
  ...rateStateSlice.actions,
  listAll,
};

export default rateStateSlice.reducer;
//#endregion
