// @ts-check

/**
 * A storage use to save application information
 * inside browser.
 *
 * @since 1.0
 * @version 1.0 - 02/20/23
 */
export class StorageService {
  private readonly TOKEN: string = 'token';
  private readonly TRANSACTION: string = 'transactionId';

  /**
   * Store an access token to local storage
   *
   * @since 1.0
   *
   * @param token An access token
   * @returns {void}
   */
  saveToken = (token: string): void => {
    localStorage.setItem(this.TOKEN, token);
  };

  /**
   * Get an access token
   *
   * @returns {string | null} An user access token if present
   */
  getToken = (): string | null => {
    return localStorage.getItem(this.TOKEN);
  };

  /**
   * Delete an existed access token
   *
   * @since 1.0
   *
   * @returns {void}
   */
  clearToken = (): void => {
    localStorage.removeItem(this.TOKEN);
  };

  /**
   * Delete all storage of the site on browser
   *
   * @since 1.0
   *
   * @returns {void}
   */
  clearAll = (): void => {
    localStorage.clear();
    sessionStorage.clear();
  };

  /**
   * Storage an order information in sessionStorage
   *
   * @param payload The information about an order
   * @returns {void}
   */
  saveTransaction = (transactionId: string): void => {
    localStorage.setItem(this.TRANSACTION, transactionId);
  };

  getTransaction = (): string | null => {
    const transactionId = localStorage.getItem(this.TRANSACTION);
    return transactionId;
  };

  /**
   * Delete an existed order info
   */
  clearTransaction = (): void => {
    localStorage.removeItem(this.TRANSACTION);
  };
}
