import styles from './support-pay.module.css';
import CollapseCustom from '../collapse-custom/collapse-custom';

export function SupportPay() {
  const data = [
    {
      id: '1',
      label: 'Hướng dẫn nạp chuyển khoản ngân hàng',
      content: (
        <div className={styles['content']}>
          <p style={{ color: '#3b3835' }}>
            Bước 1. Đăng nhập trang{' '}
            <a
              href="https://pay.boomgames.vn/"
              target="_blank"
              rel="noreferrer"
              style={{ color: '#FF6C00' }}
            >
              https://pay.boomgames.vn/
            </a>
          </p>
          <br />
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src="/assets/images/support/pay-step-1.png" alt="buoc-1" />
          </div>
          <br />
          <p style={{ color: '#3b3835' }}>
            Bước 2. Chọn game và phương thức thanh toán Chuyển khoản trực tiếp,
            chọn ngân hàng và Mệnh giá nạp
          </p>
          <br />
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src="/assets/images/support/pay-step-2.png" alt="buoc-2" />
          </div>
          <br />
          <p style={{ color: '#3b3835' }}>
            Bước 3. Hệ thống hiển thị Thông tin thanh toán. Sau khi kiểm tra
            thông tin, thực hiện quét mã QR hoặc làm theo hướng dẫn để tiến hành
            thanh toán. {'=>'} Thanh toán thành công, hệ thống sẽ tự động cộng
            BXu vào tài khoản Boom ID.
          </p>
          <br />
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src="/assets/images/support/pay-step-3.png" alt="buoc-3" />
          </div>
          <br />
          <p style={{ color: '#3b3835' }}>
            Bước 4. Vào app ngân hàng, chuyển khoản theo nội dung Copy.
          </p>
          <br />
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src="/assets/images/support/pay-step-4.png" alt="buoc-4" />
          </div>
          <br />
          <p style={{ color: '#3b3835' }}>
            Bước 5. Quay lại trang{' '}
            <a
              style={{ color: '#FF6C00' }}
              href="https://pay.boomgames.vn/"
              target="_blank"
              rel="noreferrer"
            >
              https://pay.boomgames.vn/
            </a>{' '}
            kiểm tra số dư Bxu.
          </p>
          <i style={{ color: '#696969' }}>
            <span style={{ color: '#FF6C00' }}>Lưu ý:</span>
            <br />
            - Số tiền chuyển khoản tối thiểu 10.000 VNĐ, tối đa 100.000.000 VNĐ
            <br />
            - Chọn hình thức chuyển khoản nhanh nếu chuyển khoản liên ngân hàng
            <br />
            - Sau khi chuyển khoản thành công, vui lòng chờ ít nhất 5-10 phút để
            giao dịch có thể về đến tài khoản của Boom ID (lâu hơn vào cuối tuần
            và ngày lễ)
            <br />- Mọi thắc mắc liên hệ gửi yêu cầu tới{' '}
            <a style={{ color: '#FF6C00' }} href="mailto:hotro@boomstudio.vn">
              hotro@boomstudio.vn{' '}
            </a>
            hoặc fanpage CSKH của Boom Games trên Facebook để được hỗ trợ.
          </i>
        </div>
      ),
    },
    {
      id: '2',
      label: 'Các lỗi có thể xảy ra trong quá trình thanh toán',
      content: (
        <div className={styles['content']}>
          <p style={{ color: '#3b3835' }}>
            1. Lỗi không nhận được vật phẩm khi mua hàng trực tiếp trong game sẽ
            xảy ra khi nào?
          </p>
          <ul>
            <li>
              Khi mua vật phẩm thông qua cửa hàng Play của Google và appstore
              của Apple, bạn cần chú ý đến kết nối internet trong quá trình
              thanh toán, đồng thời tránh thực hiện các thao tác khác khi
              Google/Apple đang lấy thông tin nhật vật trong game để có thể gửi
              tiên phiếu.
            </li>
            <li>
              Gián đoạn kết nối đến máy chủ của Google/Apple là nguyên nhân
              chính dẫn đến việc không nhận được vật phẩm trong game kể cả khi
              đã thanh toán thành công.
            </li>
          </ul>
          <p style={{ color: '#3b3835' }}>
            2. Tôi không nhận được vật phẩm (Ruby, Bxu…) khi mua hàng trực tiếp
            trong game thông qua Appstore (hệ máy iOS) hoặc Google Play (hệ máy
            Android), giờ cần làm gì?
          </p>
          <ul>
            <li>
              Trong trường hợp lỗi, bạn có thể liên hệ gửi email về{' '}
              <a style={{ color: '#FF6C00' }} href="mailto:hotro@boomstudio.vn">
                hotro@boomstudio.vn{' '}
              </a>
            </li>
            <li>
              Ngoài ra bạn có thể liên hệ trực tiếp với
              <a
                style={{ color: '#FF6C00' }}
                href="https://m.me/boom.cskh"
                target="_blank"
                rel="noreferrer"
              >
                {' '}
                fanpage CSKH{' '}
              </a>
              của Boom Games trên Facebook kèm các thông tin sau:
              <ul>
                <li>- Tên Tài Khoản</li>
                <li>- Tên Nhân Vật</li>
                <li>- Server</li>
                <li>- Hình ảnh hóa đơn</li>
                <ul>
                  <li>
                    + Android: Hình ảnh có mã GPA Google gửi về email của bạn.
                  </li>
                  <li>
                    + iOS: Hình ảnh hóa đơn Apple ở mục Lịch Sử Mua Hàng trong
                    phần Cài đặt iCloud của thiết bị.
                  </li>
                </ul>
              </ul>
            </li>
          </ul>
          <p style={{ color: '#3b3835' }}>
            3. Tôi gặp lỗi khi thanh toán dùng các kênh thanh toán sử dụng ATM,
            Visa/Mastercard (Onepay, Momo, VNpay, ...)
          </p>
          <ul>
            <li>
              Trong trường hợp lỗi, bạn có thể liên hệ gửi email về{' '}
              <a style={{ color: '#FF6C00' }} href="mailto:hotro@boomstudio.vn">
                hotro@boomstudio.vn{' '}
              </a>
            </li>
          </ul>
          <p style={{ color: '#3b3835' }}>
            4. Ngoài ra bạn có thể liên hệ trực tiếp với
            <a
              style={{ color: '#FF6C00' }}
              href="https://m.me/boom.cskh"
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              fanpage CSKH{' '}
            </a>
            của Boom Games trên Facebook kèm các thông tin sau:
          </p>
          <ul>
            <li>- Tên Tài Khoản</li>
            <li>- Tên Nhân Vật</li>
            <li>- Server</li>
            <li>- Hình ảnh lịch sử giao dịch trên trang https://...</li>
            <li>- Hình ảnh giao dịch bị lỗi trong app banking mà bạn dùng.</li>
          </ul>
        </div>
      ),
    },
    {
      id: '3',
      label: 'Các câu hỏi thường gặp khi thanh toán',
      content: (
        <div className={styles['content']}>
          <p style={{ color: '#3b3835' }}>
            1. Nếu tôi nạp tiền gặp lỗi trong thời gian event, tôi có được nhận
            thưởng bù không?
          </p>
          <ul>
            Có. Nếu kiểm tra bạn đã nạp thành công, bạn sẽ được đền bù số tiền
            tương ứng trong game (Sapphire, Ruby, Vàng,..) chưa nhận được và
            phần thưởng event. Tuy nhiên bạn nên báo ngay sau khi phát hiện lỗi
            thẻ để chúng tôi dễ dàng kiểm tra được sự cố và hỗ trợ bạn.
          </ul>
          <p style={{ color: '#3b3835' }}>
            2. Tôi có thể chuyển tiền từ game này sang game khác hoặc từ nhân
            vật này sang nhân vật khác không?
          </p>
          <ul>
            Không. Bạn không thể chuyển tiền từ game này sang game khác hoặc từ
            nhân vật này sang nhân vật khác
          </ul>
          <p style={{ color: '#3b3835' }}>
            3. Tôi có thể huỷ giao dịch nạp được không?
          </p>
          <ul>
            Rất tiếc, chúng tôi không có chính sách hoàn trả các giao dịch đã
            nạp vào game. Do vậy bạn sẽ không thể huỷ được các giao dịch nạp
            thành công trên hệ thống của chúng tôi. Nếu bạn cần trợ giúp về vấn
            đề nạp tiền, vui lòng liên hệ với CSKH hoặc qua Email.
          </ul>
          <p style={{ color: '#3b3835' }}>
            4. Tôi hiện tại không sinh sống tại Việt Nam vậy tôi có thể thanh
            toán bằng cách nào?
          </p>
          <ul>
            Hiện tại trong cách kênh thanh toán đang được Boom Games sử dụng,
            bạn có thể sử dụng OnePay để thanh toán với thẻ Visa/Mastercard quốc
            tế. Danh sách các kênh thanh toán quốc tế sẽ được cập nhập liên tục.
          </ul>
        </div>
      ),
    },
    {
      id: '4',
      label: 'Các phương thức thanh toán nạp game',
      content: (
        <div className={styles['content']}>
          Boom Games có hỗ trợ các hình thức thanh toán sau bạn có thể tham
          khảo:
          <br />
          <p style={{ color: '#3b3835' }}>1. Nạp Bxu</p>
          <ul>
            - Chuyển Khoản Ngân Hàng: kênh Thanh Toán chuyển khoản ngân hàng
            chiết khấu lên đến 10% liên hệ fanpage CSKH, Hoặc tham khảo hướng
            dẫn nạp tại:{' '}
            <a
              href="https://www.shv3d.boomgames.vn/tin-tuc/Huong-Dan-Nap-Game"
              target="_blank"
              rel="noreferrer"
              style={{ color: '#FF6C00' }}
            >
              https://www.shv3d.boomgames.vn/tin-tuc/Huong-Dan-Nap-Game
            </a>
            <br />- Thẻ ATM/QR Pay
            <br />- Ví Thanh Toán Momo
            <br />- Visa Card/ Master Card
            <br />
          </ul>
          <p style={{ color: '#3b3835' }}>2. Nạp Vào Game</p>
          <ul>
            - Các bạn tham khảo tại hướng dẫn sau:{' '}
            <a
              href="http://bit.ly/3yFlNap"
              target="_blank"
              rel="noreferrer"
              style={{ color: '#FF6C00' }}
            >
              bit.ly/3yFlNap
            </a>
          </ul>
        </div>
      ),
    },
  ];

  return (
    <div className={styles['container']}>
      {data.map((item, index) => (
        <CollapseCustom key={index} label={item.label}>
          {item.content}
        </CollapseCustom>
      ))}
    </div>
  );
}
export default SupportPay;
