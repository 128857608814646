import { combineReducers } from '@reduxjs/toolkit'
import giftCodeReducer, {actions as giftcode} from './giftcode.slice'
import gameReducer, {actions as game} from './game.slice'

export const actions = {
    game,
    giftcode
}

export default combineReducers({
    game: gameReducer,
    giftcode: giftCodeReducer,
})