// @ts-check

// #region "Imports"
import { actions as app } from './app.slice';
import { actions as auth } from './auth';
import { actions as home } from './home';
import { actions as member } from './members'
import { actions as payment } from './payment';
import { actions as wallet } from './wallet';
import { actions as banner } from './banner';

/**
 * List all of Redux action.
 * 
 * @version 1.0
 */
const actions = {
  app,
  auth,
  home,
  member,
  payment,
  wallet,
  banner
};

export default actions;
