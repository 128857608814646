// @ts-check

// #region "Imports"
import { Image } from 'antd';
import React from 'react';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';

import actions, { 
  useAppDispatch, 
  useAppSelector 
} from '@payment-mfe/shared/store';
import styles from './style.module.css';
// #endregion

/**
 * A properties of the payment callback components.
 * @since 1.0
 */
interface IPaymentCallbackProps {
  basePaymentApiUrl: string;
}

/**
 * The properites of the callback function.
 *
 * @param props The properties.
 * @returns
 */
export function PaymentCallback(props: IPaymentCallbackProps) {
  // #region "Component State"
  // get query string
  const [searchParams] = useSearchParams();
  // the navigation hooks
  const navigate = useNavigate();
  // the state of a transaction
  const [state, setState] = React.useState<{
    title: string;
    message: string;
    icon: string;
  }>({
    title: 'Hệ thống đang tiến hành thanh toán.',
    message: 'Vui lòng đợi trong giây lát...',
    icon: '/assets/images/icon-loading.png',
  });
  
  const [timeLeft, setTimeLeft] = React.useState<number | null>(null);
  const currentTransactionStatusRef = React.useRef<boolean>(false);

  const paymentCallbackUrl = localStorage.getItem('paymentCallbackUrl') || '/nap-game';

  // #endregion

  // #region "Redux"
  // dispatching to an action
  const dispatch = useAppDispatch();
  const statusTransactionCallBack = searchParams.get('vpc_Message');
  /**
   * The state of an order.
   * @since 1.0
   */
  const verifyingStatus = useAppSelector(
    (state) => state.payment.paymentGateway.status
  );
  //#endregion

  //#region "Hooks"

  /**
   * Get the transaction code.
   * @since 1.0
   */
  const getTransactionCode = React.useCallback(() => {
    return searchParams.get('orderId') || searchParams.get('vpc_MerchTxnRef') || searchParams.get('vnp_TxnRef')
  }, [searchParams]);

  // Check status transaction
  React.useEffect(() => {
    const transactionCode = getTransactionCode();
    let dispatchCount = 0;
    if (transactionCode) {
      const intervalId = setInterval(() => {
        if (
          dispatchCount < 3 &&
          currentTransactionStatusRef.current === false
        ) {
          dispatch(
            actions.payment.gateway.getTransactionState({
              basePaymentApiUrl: props.basePaymentApiUrl,
              transactionCode: transactionCode,
            })
          );
          dispatchCount = dispatchCount + 1;
        } else {
          clearInterval(intervalId); // Clear the interval after 3 dispatches
        }
      }, 3000);

      // Clear the interval when the component is unmounted or when transactionCode changes
      return () => clearInterval(intervalId);
    }
  }, [dispatch, getTransactionCode, props.basePaymentApiUrl]);
  //#endregion

  /**
   * An effect setting the title of a document
   */
  React.useEffect(() => {
    document.title = 'Trạng thái giao dịch';
  }, []);

  //#region Effects

  /**
   * An effect to setup the countdown.
   * @since 1.0
   */
  React.useEffect(() => {
    if (verifyingStatus === 'failed' || verifyingStatus === 'successed') {
      currentTransactionStatusRef.current = true;
      setTimeLeft(5);
    }
  }, [verifyingStatus]);

  React.useEffect(() => {
    if (timeLeft === 0 && statusTransactionCallBack !== 'Canceled') {
      setTimeLeft(null);
      navigate(paymentCallbackUrl);
    }

    // exit early when reach 0
    if (!timeLeft || statusTransactionCallBack === 'Canceled') return;

    // save intervalId to clear the interval when the component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks.
    return () => clearInterval(intervalId);
  }, [
    navigate, 
    timeLeft,
    paymentCallbackUrl,
    statusTransactionCallBack,
  ]);

  React.useEffect(() => {
    statusTransactionCallBack === 'Canceled' &&
      navigate(paymentCallbackUrl);
  }, [
    navigate,
    statusTransactionCallBack,
    paymentCallbackUrl,
  ]);

  /**
   * Verify status of the verify request back from onepay/momo
   */
  React.useEffect(() => {
    switch (verifyingStatus) {
      case 'loading':
      case 'process':
        setState({
          title: 'Giao dịch của bạn đang được xử lý.',
          message: 'Vui lòng đợi trong giây lát...',
          icon: '/assets/images/icon-loading.png',
        });
        break;
      case 'failed':
        setState({
          title: 'Thất bại!',
          message:
            'Vui lòng thử lại hoặc liên hệ với quản trị viên để được hỗ trợ.',
          icon: '/assets/images/icon-error.png',
        });
        dispatch(actions.payment.transaction.reset());
        break;
      case 'successed':
        setState({
          title: 'Thành công!',
          message: 'Số dư của bạn đã được cập nhật thành công vào hệ thống.',
          icon: '/assets/images/icon-success.png',
        });
        // dispatcher to get wallet infos
        dispatch(
          actions.wallet.wallet.getByMemberAsync(props.basePaymentApiUrl)
        );
        dispatch(actions.payment.transaction.reset());
        break;
    }
  }, [dispatch, verifyingStatus, props.basePaymentApiUrl]);
  //#endregion

  return (
    <div className={styles['container']}>
      <div className={styles['box']}>
        <div className={styles['header']}>
          <Image
            preview={false}
            height={70}
            alt={state.title}
            src={state.icon}
          />
        </div>
        <div className={styles['body']}>
          <h3
            className={
              verifyingStatus === 'successed'
                ? styles['title-success']
                : styles['title-error']
            }
          >
            {state.title}
          </h3>
          <div className={styles['message']}>{state.message}</div>
          {verifyingStatus === 'failed' || verifyingStatus === 'successed' ? (
            <div className={styles['auto-redirect']}>
              Hệ thống sẽ tự động chuyển sang trang nạp game sau {timeLeft}{' '}
              giây. Hoặc nhấp{' '}
              <NavLink to={paymentCallbackUrl}>vào đây</NavLink> để
              chuyển trang nạp game.
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default PaymentCallback;
