// @ts-check

// #region "Import(s)"
/* eslint-disable @nx/enforce-module-boundaries */
import 'dayjs/locale/vi';
import locale from 'antd/locale/vi_VN';
import { ConfigProvider } from 'antd';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import { GameList } from '@payment-mfe/game-list';
import { GuidePage } from '@payment-mfe/guide-page';
import { HomePage } from '@payment-mfe/home-page';
import { NotFound } from '@payment-mfe/not-found';
import {
  PackageConfirmationMobile,
  Payment,
  PaymentCallback,
  PaymentPackageMobile,
  PaymentQRCodeMobile,
} from '@payment-mfe/payment';
import { PaymentHistory } from '@payment-mfe/payment-history';
import { Policy } from '@payment-mfe/policy';
import { PaymentLayout } from '@payment-mfe/shared/layout';
import actions, {
  checkLoggedinAsync,
  logout,
  useAppDispatch,
} from '@payment-mfe/shared/store';
import { Terms } from '@payment-mfe/terms';
import { UserInfo } from '@payment-mfe/user-info';
import { VerifyForgotPassword } from '@payment-mfe/verify-forgot-password';
import { WapCallback } from '@payment-mfe/wap-callback';
import { Faq } from '@payment-mfe/faq';
import { VnpayInstruction } from '@payment-mfe/vnpay-instruction';

import { environment as env } from '../environments/environment';
import { Support } from '@payment-mfe/support';

// #endregion

ReactGA.initialize('G-QRPNRSWSQ0');
ReactGA.send({
  hitType: 'pageview',
  page: '/',
});

export function App() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const PrivateOutlet = () => {
    const token = localStorage.getItem('token');
    return token ? <Outlet /> : <Navigate to={'/'} />;
  };

  useEffect(() => {
    if (typeof env.authApiUrl !== 'undefined' && env.authApiUrl !== null) {
      dispatch(
        checkLoggedinAsync({
          authApiUrl: env.authApiUrl,
        })
      );
    }
  }, [dispatch]);

  /**
   * An effect setting the title of a document
   */
  useEffect(() => {
    document.title = 'Cổng Thanh toán Trò chơi Trực tuyến';
  }, []);

  useEffect(() => {
    const handleResize = () => {
      dispatch(actions.app.setIsSmallScreen(window.innerWidth <= 610));
    };

    handleResize(); // Check initial screen size
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dispatch]);

  useEffect(() => {
    // Check the flag in local storage periodically (e.g., every second).
    const checkLogOutStatus = () => {
      const userLoggedIn = localStorage.getItem('userLoggedIn');

      if (userLoggedIn === 'false') {
        // Log out the user in this tab.
        dispatch(logout());
        const allowedPaths = [
          '/',
          '/huong-dan-nap',
          '/danh-sach-game',
          '/faq',
          '/dieu-khoan-su-dung',
          '/chinh-sach-quyen-rieng-tu',
          '/ho-tro',
          '/huong-dan-thanh-toan-vnpay',
        ];
        if (
          !allowedPaths.includes(location.pathname) &&
          !location.pathname.includes('/quen-mat-khau')
        ) {
          navigate('/');
        }
      }
    };

    const interval = setInterval(checkLogOutStatus, 2000);

    return () => clearInterval(interval);
  }, [dispatch, location.pathname, navigate]);

  return (
    <ConfigProvider
      locale={locale}
      theme={{
        token: {
          // Seed Token
          colorPrimary: '#ff6c00',
        },
      }}
    >
      {/* Home page */}
      <Routes>
        {/* Public Routes */}
        <Route
          path="/"
          index
          element={
            <HomePage
              authApiUrl={env.authApiUrl}
              paymentApiUrl={env.paymentApiUrl}
              notificationApiUrl={env.notificationApiUrl}
              productApiUrl={env.productApiUrl}
              fileApiUrl={env.fileApiUrl}
            />
          }
        />
        <Route
          element={
            <PaymentLayout
              authApiUrl={env.authApiUrl}
              paymentApiUrl={env.paymentApiUrl}
              notificationApiUrl={env.notificationApiUrl}
            />
          }
        >
          <Route path="/dieu-khoan-su-dung" index element={<Terms />} />
          <Route path="/chinh-sach-quyen-rieng-tu" index element={<Policy />} />
          <Route path="/faq" index element={<Faq />} />
        </Route>

        <Route
          element={
            <PaymentLayout
              authApiUrl={env.authApiUrl}
              paymentApiUrl={env.paymentApiUrl}
              notificationApiUrl={env.notificationApiUrl}
            />
          }
        >
          <Route
            path="/wap-callback/:clientId/:token"
            index
            element={<WapCallback />}
          />
          <Route
            path="/danh-sach-game"
            index
            element={<GameList productApiUrl={env.productApiUrl} />}
          />

          <Route
            path="/quen-mat-khau/:salt"
            index
            element={
              <VerifyForgotPassword
                baseAuthApiUrl={env.authApiUrl}
                baseNotificationApiUrl={env.notificationApiUrl}
              />
            }
          />

          <Route path="/huong-dan-nap" index element={<GuidePage />} />

          <Route
            path="/huong-dan-thanh-toan-vnpay"
            index
            element={<VnpayInstruction />}
          />

          <Route path="/ho-tro" index element={<Support />} />

          {/* End Public Routes */}

          {/* Private Routes */}
          <Route element={<PrivateOutlet />}>
            <Route
              path="/nap-game/:slug"
              index
              element={
                <Payment
                  baseAuthApiUrl={env.authApiUrl}
                  basePaymentApiUrl={env.paymentApiUrl}
                  baseNotificationUrl={env.notificationApiUrl}
                  productApiUrl={env.productApiUrl}
                />
              }
            />

            <Route
              path="/nap-game/:slug/:method"
              index
              element={
                <PaymentPackageMobile
                  basePaymentApiUrl={env.paymentApiUrl}
                  productApiUrl={env.productApiUrl}
                />
              }
            />

            <Route
              path="/nap-game/:slug/va/:rateID"
              index
              element={
                <PaymentQRCodeMobile
                  baseAuthApiUrl={env.authApiUrl}
                  basePaymentApiUrl={env.paymentApiUrl}
                  baseNotificationApiUrl={env.notificationApiUrl}
                />
              }
            />

            <Route
              path="/nap-game/:slug/:method/:rateID"
              index
              element={
                <PackageConfirmationMobile
                  basePaymentApiUrl={env.paymentApiUrl}
                  productApiUrl={env.productApiUrl}
                />
              }
            />

            <Route
              path="/lich-su-giao-dich"
              index
              element={<PaymentHistory basePaymentApiUrl={env.paymentApiUrl} />}
            />

            <Route
              path="/thong-tin-tai-khoan"
              index
              element={
                <UserInfo
                  baseAuthApiUrl={env.authApiUrl}
                  notificationApiUrl={env.notificationApiUrl}
                />
              }
            />

            <Route
              path="/nap-game/callback"
              index
              element={
                <PaymentCallback basePaymentApiUrl={env.paymentApiUrl} />
              }
            />

            <Route path="*" element={<NotFound />} />
          </Route>
        </Route>

        {/* End Private Routes */}
      </Routes>
    </ConfigProvider>
  );
}

export default App;
