//@ts-check

//#region "Imports"
import { BaseService } from '../base';
//#endregion

//#region "Models"
export type BannerResponse = {
  id: string,
  name: string,
  description: string,
  imageUrl: string,
  imageRedirectUrl: string,
  imageUrlTarget: string,
  alternativeText: string,
  priority: number
  isMobile: boolean
};
//#endregion

//#region "Service"
export class BannerService extends BaseService<BannerResponse> {
  async getAllBanner(apiUrl: string, bannerGroup: string): Promise<BannerResponse[] | null> {
    const baseResponse = await this.getAllAsync(apiUrl + `/banners/public?bannerGroup=${bannerGroup}`);
    // if (baseResponse.errCode === '00') {
    //   return baseResponse.data as BannerResponse[];
    // }
    return baseResponse.data as BannerResponse[];
  }
}
//#endregion
