import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RegisterRequest } from '@payment-mfe/shared/models';
import { RegisterService } from '@payment-mfe/shared/service';

//#region "Constants"
export const REGISTER_FEATURE_KEY = 'auth/register';
export const ACTION_REGISTER_KEY = REGISTER_FEATURE_KEY + '/postAsync';

//#endregion

// #region "Declaration AsyncThunk"
export const register = createAsyncThunk(
  ACTION_REGISTER_KEY,
  async (payload: { baseAuthApiUrl: string; request: RegisterRequest }) => {
    sessionStorage.setItem(
      'credentials_register',
      JSON.stringify(payload.request)
    );

    const service = new RegisterService();
    const response = await service.registerAsync(
      payload.baseAuthApiUrl + '/members',
      payload.request
    );

    return response;
  }
);
// #endregion

// #region "States"
export interface RegisterState {
  status: 'idle' | 'loading' | 'successed' | 'failed';
  error: string | null;
}

const initialState: RegisterState = {
  status: 'idle',
  error: null,
};
// #endregion

// #region "Slice"
const registerSlice = createSlice({
  name: REGISTER_FEATURE_KEY,
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = 'idle'
    }
  },
  extraReducers: (builder) => {
    builder.addCase(register.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(register.fulfilled, (state, action) => {
      if (action.payload != null) {
        state.status = 'successed';
        state.error = 'a member was registered';
      } else {
        state.status = 'failed';
        state.error = 'a request to register server failure at business level';
        sessionStorage.removeItem('credentials_register');
      }
    });
    builder.addCase(register.rejected, (state) => {
      state.status = 'failed';
      state.error = 'a request to register server failure at infra level';
      sessionStorage.removeItem('credentials_register');
    });
  },
});

// #endregion.

export const actions = {
  ...registerSlice.actions,
  register
}

export default registerSlice.reducer;
