import { BreadcrumbWrapper } from '@payment-mfe/shared/commons';
import { useState } from 'react';
import { ATMTab, DirectTranferTab, MomoTab, VisaTab } from './components';
import styles from './guide-page.module.css';

/* eslint-disable-next-line */
export interface GuidePageProps {}

interface TabType {
  id: number;
  name: string;
  content: JSX.Element;
}

export function GuidePage(props: GuidePageProps) {
  const [activeTab, setActiveTab] = useState(0);

  const paymentMethodTabList: TabType[] = [
    {
      id: 0,
      name: ' Chuyển khoản trực tiếp',
      content: <DirectTranferTab />,
    },
    {
      id: 1,
      name: 'Thẻ ATM nội địa',
      content: <ATMTab />,
    },
    {
      id: 2,
      name: 'Ví MoMo',
      content: <MomoTab />,
    },
    {
      id: 3,
      name: 'Thẻ Visa/Master quốc tế',
      content: <VisaTab />,
    },
  ];

  return (
    <BreadcrumbWrapper link='/' text='Trở lại trang chủ'>
      <div className={styles['guide-page']}>
        <h2>Hướng dẫn nạp</h2>
        <div className={styles['guide-tabs']}>
          {paymentMethodTabList.map((item) => (
            <button
              key={item.name}
              className={`${styles.guide_tabs__item} ${
                activeTab === item.id ? styles.active : ''
              }`}
              onClick={() => setActiveTab(item.id)}
            >
              {item.name}
            </button>
          ))}
        </div>
        <div className={styles['guide-content']}>
          {paymentMethodTabList[activeTab].content}
        </div>
      </div>
    </BreadcrumbWrapper>
  );
}

export default GuidePage;
