//@ts-check

//#region "Imports"
import { useEffect } from 'react';

import actions, {
  loadingLoginModal,
  useAppDispatch,
  useAppSelector,
} from '@payment-mfe/shared/store';
import styles from '../payment.module.css';
import { formatWithCommas } from '@payment-mfe/shared/utils';
import ReactGA from 'react-ga4';
//#endregion

//#region "Component Props"
/* eslint-disable-next-line */
export interface PmConfirmationProps {
  basePaymentApiUrl: string;
}

//#endregion

//#region "Components"
/**
 * Confirmation a payment transaction.
 * 
 * @param props The component properties.
 * @returns 
 */
export function PaymentConfirmation(props: PmConfirmationProps) {
  //#region "redux state"
  // A custom dispatcher, using to dispatching an event to Redux.
  const dispatch = useAppDispatch();
  
  // The authenticated state of a member.
  const isLoggedIn = useAppSelector((state) => state.appState.isLoggedIn);

  // The selected method.
  const selectedMethod = useAppSelector(
    (state) => state.payment.paymentUI.method
  );

  // The selected bank.
  const selectedBank = useAppSelector((state) => state.payment.paymentUI.bank);

  // The selected conversion rate.
  const selectedConversionRate = useAppSelector(
    (state) => state.payment.paymentUI.conversionRate
  );

  // The transaction was created.
  const transactionId = useAppSelector(
    (state) => state.payment.paymentTransaction.transactionId
  );

  // The current transaction.
  const transaction = useAppSelector(
    (state) => state.payment.paymentTransaction.transaction
  );

  // the gateway methods
  const gateways = useAppSelector((state) => 
    state.payment.paymentConfig.gateways
  );

  //#endregion

  //#region "Effects"

  /**
   * The payment direct url, in the case using payment gateway.
   */
  const paymentRedirectUrl = useAppSelector(
    (state) => state.payment.paymentGateway.redirectLink
  );

  /**
   * A callback function, fetch transaction information
   */
  useEffect(() => {
    if (transactionId !== null) {
      dispatch(
        actions.payment.transaction.getTransaction({
          basePaymentApiUrl: props.basePaymentApiUrl,
        })
      );
    }
  }, [dispatch, transactionId, props.basePaymentApiUrl]);

  /**
   * Handler transaction result.
   */
  useEffect(() => {
    if (transaction !== null) {
      switch (transaction.extras.source) {
        case 'gw':
          dispatch(
            actions.payment.gateway.generatePaymentLink({
              basePaymentApiUrl: props.basePaymentApiUrl,
              transactionId: transaction.id,
            })
          );

          break;
        default:
          console.error(
            'The source "' + transaction.extras.source + '" is not supported'
          );
      }
    }
  }, [dispatch, transaction, props.basePaymentApiUrl]);

  /**
   * Redirect to payment gateway.
   *
   */
  useEffect(() => {
    if (
      selectedMethod !== null &&
      selectedMethod.code !== 'ib' &&
      paymentRedirectUrl !== null
    ) {
      window.location.href = paymentRedirectUrl;
    }
  }, [selectedMethod, paymentRedirectUrl]);

  /**
   * fetching the payment gateway.
   */
  useEffect(() => {
    if (props.basePaymentApiUrl) {
      dispatch(
        actions.payment.config.fetchGatewayConfig({
          basePaymentApiUrl: props.basePaymentApiUrl,
        })
      )
    }
  }, [dispatch, props.basePaymentApiUrl]);

  //#endregion

  //#region "business"

  /**
   * Create new a transaction.
   */
  const _createTransaction = (): void => {
    if (selectedConversionRate !== null) {
      //#region "request builder"
      let request = {
        conversionRate: {
          rateId: selectedConversionRate.id,
          currencyAmount: selectedConversionRate.currencyAmount,
          gameAmount: selectedConversionRate.gameAmount,
        },
      };

      const [atmGw]  = gateways.filter((record) => record.type === 'atm');
      const [visaGw] = gateways.filter((record) => record.type === 'visa');

      switch (selectedMethod?.code) {
        case 'atm':
          // VNPAYQR
          if (atmGw && atmGw.code === 'vnpay') {
            request = Object.assign({}, request, {
              source: 'gw',
              gateway: {
                code: 'vnpay',
                cardType: 'VNPAYQR'
              }
            })
          } else {
            request = Object.assign({}, request, {
              source: 'gw',
              gateway: {
                code: 'onepay',
                cardType: 'DOMESTIC',
              },
            });
          }          
          break;
        case 'visa':
          if (visaGw && visaGw.code === 'vnpay') {
            request = Object.assign({}, request, {
              source: 'gw',
              gateway: {
                code: 'vnpay',
                cardType: 'INTCARD'
              }
            });
          } else {
            request = Object.assign({}, request, {
              source: 'gw',
              gateway: {
                code: 'onepay',
                cardType: 'INTERNATIONAL',
              },
            });
          }
          break;
        case 'momo':
          request = Object.assign({}, request, {
            source: 'gw',
            gateway: {
              code: 'momo',
            },
          });
          break;
        case 'va':
          request = Object.assign({}, request, {
            source: 'ib',
            bankId: selectedBank?.id,
          });
          break;
        default:
          throw new Error(
            'The payment method "' +
              selectedMethod?.code +
              '" is not supported.'
          );
      }
      //#endregion

      //#region "create new an credit transaction"
      dispatch(
        actions.payment.transaction.createTransaction({
          basePaymentApiUrl: props.basePaymentApiUrl,
          transaction: request,
        })
      );
      //#endregion
    }
  };
  //#endregion

  //#region "action"
  /**
   * Handler confirmation button action.
   */

  const confirmationOnClick = (): void => {
    // check if the user is logged in.
    if (isLoggedIn === true) {
      // create new a transaction
      _createTransaction();

      // Push event payment
      ReactGA.event({
        category: 'Button Click',
        action: `${selectedMethod?.name}`,
        label: 'Confirmation purchase button.',
      });
    } else {
      // show login popup
      dispatch(loadingLoginModal());
    }
  };
  //#endregion

  //#region "Render view"
  return (
    <div className={styles['container']}>
      {/*  */}
      <div style={{ position: 'relative' }}>
        <p className={styles['payment-confirm-title']}>Xác nhận gói nạp</p>
      </div>
      <div className={styles['payment-info']}>
        <div className={styles['payment-info__item']}>
          <span>Vật phẩm</span>
          <strong>
            {selectedConversionRate?.gameAmount &&
              formatWithCommas(selectedConversionRate?.gameAmount, '.')}{' '}
            BXu
          </strong>
        </div>
        <div className={styles['payment-info__item']}>
          <span>Giá</span>
          <strong>
            {selectedConversionRate?.currencyAmount &&
              formatWithCommas(selectedConversionRate?.currencyAmount, '.')}
          </strong>
        </div>
        <div className={styles['payment-info__item']}>
          <span style={{ textAlign: 'left' }}>Phương thức thanh toán</span>
          <strong style={{ textAlign: 'right' }}>
            {selectedMethod?.code === 'ib'
              ? selectedMethod.name + ' qua ' + selectedBank?.name
              : selectedMethod?.name}
          </strong>
        </div>
        <button
          className={styles['payment-submit']}
          onClick={confirmationOnClick}
        >
          Xác nhận
        </button>
      </div>
    </div>
  );
  //#endregion
}
//#endregion
