import { useNavigate } from 'react-router-dom';
import './style.css';

interface BreadcrumbProps {
  children?: string | JSX.Element | JSX.Element[];
  link: string
  text: string
}

export function BreadcrumbWrapper(props: BreadcrumbProps) {
  const navigate = useNavigate()
  return (
    <>
      <div className='layout-header'>
        <img src='/assets/images/back.svg' onClick={() => navigate(`${props.link}`)} alt=''/>
        <span>{props.text}</span>
      </div>
      {props.children}
    </>
  );
}

export default BreadcrumbWrapper;
