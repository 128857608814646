//@ts-check

//#region "Imports"
import { BaseService } from '../base';
//#endregion

//#region "Models"
export type BankResponse = {
  id: string;
  name: string;
  code: string;
  icon_path: string;
  priority: number;
  bank_account_number: string,
  bnb_id: string,
};
//#endregion

//#region "Service"
export class BankService extends BaseService<BankResponse> {
  /**
   * List all banks.
   *
   * @param apiUrl The end-point of list all bank service.
   * @returns {Promise<BankResponse[] | null>}
   */
  async getAll(apiUrl: string): Promise<BankResponse[] | null> {
    const baseResponse = await this.getAllAsync(apiUrl + '/banks');
    if (baseResponse.errCode === '00') {
      return baseResponse.data as BankResponse[];
    }

    return null;
  }
}
//#endregion
