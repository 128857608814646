import { Link } from 'react-router-dom';
import styles from './style.module.css';

export function BottomMenu() {
  return (
    <div className={styles['bottom-menu']}>
      <Link to="/" className={styles['bottom-menu-btn']}>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M8.63478 20.7733V17.7156C8.63478 16.9351 9.27217 16.3023 10.0584 16.3023H12.9326C13.3102 16.3023 13.6723 16.4512 13.9393 16.7163C14.2063 16.9813 14.3563 17.3408 14.3563 17.7156V20.7733C14.3539 21.0978 14.4821 21.4099 14.7124 21.6402C14.9427 21.8705 15.2561 22 15.5829 22H17.5438C18.4596 22.0023 19.3388 21.6428 19.9872 21.0008C20.6356 20.3588 21 19.487 21 18.5778V9.86686C21 9.13246 20.6721 8.43584 20.1046 7.96467L13.434 2.67587C12.2737 1.74856 10.6111 1.7785 9.48539 2.74698L2.96701 7.96467C2.37274 8.42195 2.01755 9.12064 2 9.86686V18.5689C2 20.4639 3.54738 22 5.45617 22H7.37229C8.05123 22 8.603 21.4562 8.60792 20.7822L8.63478 20.7733Z"
              fill="#989898"
            />
          </svg>
        </div>
        <span>Trang chủ</span>
      </Link>

      <div className={styles['float-btn']}>
        <Link to="/danh-sach-game" className={styles['bottom-menu-submit']}>
          Nạp
          <br />
          BXu
        </Link>
      </div>

      <Link
        to="https://m.me/boom.cskh"
        className={styles['bottom-menu-btn']}
        target="_"
      >
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M3.82672 22.6965C3.36141 22.5357 3.04849 22.2353 3.01243 21.7066C2.99459 21.4173 2.99595 21.127 3.0165 20.8378C3.13923 19.0111 3.84301 17.4662 5.14472 16.2229C5.93881 15.4687 6.90162 14.9308 7.94647 14.6575C8.00256 14.6359 8.06371 14.6328 8.12159 14.6485C8.17946 14.6643 8.23124 14.6981 8.26986 14.7454C8.85331 15.3553 9.56839 15.813 10.3562 16.0808C10.4312 16.1115 10.493 16.169 10.5307 16.2428C10.8256 16.8611 11.3072 17.2061 11.9656 17.223C12.7799 17.2428 13.5942 17.2495 14.4038 17.1965C15.6503 17.114 16.6536 16.5125 17.4423 15.5179L17.5586 15.3716C18.3964 15.8738 19.1153 16.5633 19.663 17.3897C20.5354 18.6962 20.8844 20.1533 20.8071 21.7247C20.7861 22.1462 20.5552 22.4472 20.1737 22.6188C20.1155 22.6447 20.0573 22.6694 19.9957 22.6946L3.82672 22.6965Z"
              fill="#979797"
            />
            <path
              d="M8.06292 11.8587C7.85411 12.1796 7.58365 12.3783 7.22013 12.4397C6.56928 12.5493 5.91144 12.0141 5.90272 11.3313C5.88934 10.2807 5.89457 9.22885 5.90272 8.17762C5.90272 7.87658 6.04289 7.62732 6.23483 7.40154C6.31953 7.30588 6.37568 7.18691 6.39653 7.05896C6.67455 4.5513 8.59919 2.45788 10.9915 2.07376C13.9328 1.60173 16.602 3.43565 17.3191 6.42196C17.345 6.51901 17.3644 6.61778 17.3773 6.71758C17.4081 7.03367 17.475 7.31304 17.6995 7.56952C17.8408 7.73027 17.9124 8.00843 17.9176 8.23662C17.9403 9.24691 17.9281 10.2584 17.9269 11.2693C17.9269 11.6028 17.792 11.8768 17.5779 12.1212C17.5127 12.1977 17.474 12.2945 17.468 12.3964C17.468 12.8624 17.4517 13.323 17.3139 13.7739C17.102 14.4872 16.6775 15.1128 16.101 15.5609C15.5245 16.009 14.8258 16.2566 14.105 16.2683C13.4216 16.2798 12.7375 16.2732 12.0541 16.2683C11.6185 16.2683 11.3189 15.9763 11.3149 15.5597C11.3108 15.1431 11.6057 14.8372 12.039 14.83C12.6643 14.8203 13.2889 14.8228 13.9142 14.83C14.8134 14.8372 15.4759 14.441 15.883 13.6053C16.0907 13.1791 16.0965 12.7161 16.0523 12.2615C16.0389 12.1224 15.8685 11.9996 15.769 11.869C15.6149 12.1935 15.4782 12.5168 15.3101 12.8245C15.1868 13.046 15.0466 13.257 14.8908 13.4554C14.6668 13.7468 14.3632 13.8769 14.0026 13.8769C13.3582 13.8769 12.7137 13.8769 12.0692 13.8769C11.5126 13.8769 11.0531 14.0894 10.7198 14.5524C10.6332 14.6728 10.5634 14.6367 10.4738 14.5958C9.52632 14.1593 8.84522 13.4362 8.35897 12.4987C8.25486 12.3 8.1682 12.0893 8.06292 11.8587ZM8.09608 7.67488C8.85221 5.97823 9.98349 4.74397 11.9029 4.73976C13.8316 4.73494 14.9705 5.9704 15.7312 7.6803C15.8094 7.5927 15.8822 7.50021 15.9494 7.40335C15.9987 7.33337 16.0255 7.24913 16.0261 7.16252C15.7592 4.93182 13.7368 3.14003 11.3195 3.47961C9.50713 3.73489 7.99952 5.30631 7.78723 7.20346C7.7806 7.25377 7.79176 7.30487 7.81863 7.34735C7.9053 7.46235 8.00359 7.56771 8.09491 7.67488H8.09608Z"
              fill="#979797"
            />
          </svg>
        </div>
        <div>Hỗ trợ</div>
      </Link>
    </div>
  );
}

export default BottomMenu;
