// #region "Import modules"

import { createSlice } from '@reduxjs/toolkit';

// #endregion

const MODAL_FEATURE_KEY = 'modal';

// #region "Declare Redux State"

export interface ModalState {
  loading:
  | 'idle'
  | 'changePassword'
  | 'changePasswordFailure'
  | 'changePasswordSuccess'
  | 'forgotPassword'
  | 'forgotPasswordFailure'
  | 'forgotPasswordFailureByServerError'
  | 'forgotPasswordFailureByEmailVerified'
  | 'forgotPasswordSuccess'
  | 'forgotPasswardVerifyFailure'
  | 'forgotPasswardVerifySuccess'
  | 'login'
  | 'loginFailure'
  | 'loginSuccess'
  | 'register'
  | 'registerFailure'
  | 'registerSuccess'
  | 'updateProfileFailure'
  | 'updateProfileSuccess'
  | 'oauth2LoginFailure'
  | 'forgotPassByOTPSuccess'
  | 'forgotPassByOTPFailure'
  // Verify for member profile
  | 'inputMobileOTP'
  | 'verifyEmailSuccess'
  | 'verifyMobilePhoneNumberSuccess'
  | 'changeEmailSuccess'
  | 'changeMobilePhoneNumberSuccess'
  //
  | 'server_error'
  // 
  | 'coming_soon'
}

const initialState: ModalState = {
  loading: 'idle',
};

// #endregion

// #region "Business Handlers"

const modalStateSlice = createSlice({
  name: MODAL_FEATURE_KEY,
  initialState,
  reducers: {
    hidenModal: (_) => initialState,
    resetLoading: (_) => ({ loading: 'idle' }),
    loadingChangePasswordModal: (_) => ({ loading: 'changePassword' }),
    loadingChangePasswordFailureModal: (_) => ({
      loading: 'changePasswordFailure',
    }),
    loadingChangePasswordSuccessModal: (_) => ({
      loading: 'changePasswordSuccess',
    }),
    // Email
    loadingForgotPasswordModal: (_) => ({ loading: 'forgotPassword' }),
    loadingForgotPasswordFailureModal: (_) => ({
      loading: 'forgotPasswordFailure',
    }),
    loadingForgotPasswordServer_errModal: (_) => ({
      loading: 'forgotPasswordFailureByServerError',
    }),
    loadingForgotPasswordEmailVerifyFailedModal: (_) => ({
      loading: 'forgotPasswordFailureByEmailVerified',
    }),
    loadingForgotPasswordSuccessModal: (_) => ({
      loading: 'forgotPasswordSuccess',
    }),
    // OTP
    loadingForgotPassByOTPFailureModal: (_) => ({
      loading: 'forgotPassByOTPFailure',
    }),
    loadingForgotPassByOTPSuccessModal: (_) => ({
      loading: 'forgotPassByOTPSuccess',
    }),
    loadingForgotPasswardVerifyFailureModal: (_) => ({
      loading: 'forgotPasswardVerifyFailure',
    }),
    loadingForgotPasswardVerifySuccessModal: (_) => ({
      loading: 'forgotPasswardVerifySuccess',
    }),
    loadingLoginModal: (_) => ({ loading: 'login' }),
    loadingLoginFailureModal: (_) => ({ loading: 'loginFailure' }),
    loadingLoginSuccessModal: (_) => ({ loading: 'loginSuccess' }),
    loadingRegisterModal: (_) => ({ loading: 'register' }),
    loadingRegisterFailureModal: (_) => ({ loading: 'registerFailure' }),
    loadingRegisterSuccessModal: (_) => ({ loading: 'registerSuccess' }),
    loadingUpdateProfileFailureModal: (_) => ({
      loading: 'updateProfileFailure',
    }),
    loadingUpdateProfileSuccessModal: (_) => ({
      loading: 'updateProfileSuccess',
    }),
    loadingOAuth2FailureModal: (_) => ({
      loading: 'oauth2LoginFailure',
    }),
    // Verify for member profile
    loadingInputMobileOTP: (_) => ({
      loading: 'inputMobileOTP',
    }),
    loadingVerifyEmailSuccess: (_) => ({
      loading: 'verifyEmailSuccess',
    }),
    loadingVerifyMobileSuccess: (_) => ({
      loading: 'verifyMobilePhoneNumberSuccess',
    }),
    loadingChangeEmailSuccess: (_) => ({
      loading: 'changeEmailSuccess',
    }),
    loadingChangeMobileSuccess: (_) => ({
      loading: 'changeMobilePhoneNumberSuccess',
    }),
    // Server error
    loadingServerError: (_) => ({
      loading: 'server_error',
    }),
    // Coming soon
    loadingComingSoon: (_) => ({
      loading: 'coming_soon',
    })
  },
});

// #endregion

// #region "Export actions"

export const {
  hidenModal,
  resetLoading,
  loadingChangePasswordFailureModal,
  loadingChangePasswordModal,
  loadingChangePasswordSuccessModal,
  loadingForgotPasswordModal,
  loadingForgotPasswordFailureModal,
  loadingForgotPasswordSuccessModal,
  loadingForgotPasswardVerifyFailureModal,
  loadingForgotPasswardVerifySuccessModal,
  loadingLoginModal,
  loadingLoginFailureModal,
  loadingLoginSuccessModal,
  loadingRegisterModal,
  loadingRegisterFailureModal,
  loadingRegisterSuccessModal,
  loadingUpdateProfileFailureModal,
  loadingUpdateProfileSuccessModal,
  loadingOAuth2FailureModal,
  loadingForgotPassByOTPFailureModal,
  loadingForgotPassByOTPSuccessModal,

  loadingForgotPasswordServer_errModal,
  // Verify for member profile
  loadingInputMobileOTP,
  loadingVerifyEmailSuccess,
  loadingForgotPasswordEmailVerifyFailedModal,
  loadingVerifyMobileSuccess,
  loadingChangeEmailSuccess,
  loadingChangeMobileSuccess,
  // Server error
  loadingServerError,

  // Coming soon
  loadingComingSoon,
} = modalStateSlice.actions;

// #endregion

// #region "Export reducer"
export default modalStateSlice.reducer;
// #endregion
