// @ts-check

//#region "Modules"
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  GatewayService,
  GatewayVerifyService,
  StorageService,
  TransactionService,
} from '@payment-mfe/shared/service';
//#endregion

//#region "Constants"
const GATEWAY_FEATURE_KEY = 'payment/gateway';
const GENERATE_PAYMENT_LINK_ACTION_KEY =
  GATEWAY_FEATURE_KEY + '/generatePaymentLink';
const VERIFY_PAYMENT_ACTION_KEY = GATEWAY_FEATURE_KEY + '/verify';
const GET_TRANSACTION_STATE_ACTION_KEY =
  GATEWAY_FEATURE_KEY + '/getTransactionState';
//#endregion

//#region "service"
const service = new GatewayService();
const storageService = new StorageService();
const verifyService = new GatewayVerifyService();
//#endregion

//#region "asyncthunk"

/**
 * An async thunk to generate the payment link
 * @since 1.0
 */
export const generatePaymentLink = createAsyncThunk(
  GENERATE_PAYMENT_LINK_ACTION_KEY,
  async (payload: { basePaymentApiUrl: string; transactionId: string }) => {
    const response = await service.generatePaymentLink(
      payload.basePaymentApiUrl + '/gateways',
      payload.transactionId
    );

    return response;
  }
);

/**
 * An async thunk to verify a payment
 * @since 1.0
 */
export const verifyPayment = createAsyncThunk(
  VERIFY_PAYMENT_ACTION_KEY,
  async (payload: {
    basePaymentApiUrl: string;
    transactionId: string;
    queryParam: string;
  }) => {
    const response = await verifyService.verifyPayment(
      payload.basePaymentApiUrl + '/gateways/verify',
      {
        transactionId: payload.transactionId,
        queryParam: payload.queryParam,
      }
    );

    return response;
  }
);

/**
 * Get the state of a transaction.
 * @since 1.0
 */
const getTransactionState = createAsyncThunk(
  GET_TRANSACTION_STATE_ACTION_KEY,
  async (payload: { basePaymentApiUrl: string; transactionCode: string }) => {
    const transactionService = new TransactionService();
    const succeed = await transactionService.getTransactionState(
      payload.basePaymentApiUrl,
      payload.transactionCode
    );

    return succeed;
  }
);
//#endregion

//#region "states"
export interface GatewayState {
  status: 'idle' | 'loading' | 'successed' | 'failed' | 'process';
  error: string | null;
  redirectLink: string | null;
}

/**
 * @since 1.0
 *
 * Initial defaul state of the gateway
 */
const initialState: GatewayState = {
  status: 'idle',
  error: null,
  redirectLink: null,
};
//#endregion

//#region "slices"

/**
 * @since 1.0
 *
 * A business handle everywhen end user dispatching
 * an event to the states module.
 */
export const gatewayStateSlice = createSlice({
  name: GATEWAY_FEATURE_KEY,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(generatePaymentLink.rejected, (state) => {
      state.status = 'failed';
      state.error = 'Cannot generate the payment link. Check your network.';
    });

    builder.addCase(generatePaymentLink.pending, (state) => {
      state.status = 'loading';
      state.error = 'Generate the payment link in progress.';
    });

    builder.addCase(generatePaymentLink.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.status = 'successed';
        state.error = 'Generate the payment link is successed.';
        state.redirectLink = action.payload.redirectUrl;
      } else {
        state.status = 'failed';
        state.error = 'Generate the payment link is failured.';
      }
    });

    builder.addCase(verifyPayment.rejected, (state) => {
      state.status = 'failed';
      state.error = 'Cannot verifying a payment. Check your network.';
    });

    builder.addCase(verifyPayment.pending, (state) => {
      state.status = 'loading';
      state.error = 'Verifying the payment in progress';
    });

    builder.addCase(verifyPayment.fulfilled, (state, action) => {
      if (action.payload !== null && action.payload.status === 'success') {
        state.status = 'successed';
        state.error = 'Verifying the payment success';

        storageService.clearTransaction();
      } else {
        state.status = 'failed';
        state.error = 'Verifying the payment failure';
      }
    });

    builder.addCase(getTransactionState.pending, (state) => {
      state.status = 'loading';
      state.error = 'loading transaction state is progress';
    });

    builder.addCase(getTransactionState.rejected, (state) => {
      state.status = 'failed';
      state.error = 'get transaction state is rejected.';
    });

    builder.addCase(getTransactionState.fulfilled, (state, action) => {
      if (action.payload === 'success') {
        state.status = 'successed';
        state.error = 'get transaction state succeed';
        // storageService.clearTransaction();
      } else if (action.payload === 'process') {
        state.status = 'process';
        state.error = 'get transaction state in process';
      } else {
        state.status = 'failed';
        state.error = 'get transaction state failured';
      }
    });
  },
});
//#endregion

//#region "exports"
export const actions = {
  ...gatewayStateSlice.actions,
  generatePaymentLink,
  verifyPayment,
  getTransactionState,
};

export default gatewayStateSlice.reducer;
//#endregion
