// @ts-check

// #region "Import(s)"
import { Image } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import styles from './wap-callback.module.css';
// #endregion

/* eslint-disable-next-line */
export interface WapCallbackProps {}

export function WapCallback(props: WapCallbackProps) {
  // #region "State(s)"
  const params = useParams();
  const navigate = useNavigate();
  // #endregion

  // #region "Effects"
  useEffect(() => {
    const token = params.token;
    const clientId = params.clientId;

    if (token && clientId) {
      localStorage.setItem('token', token);
      localStorage.setItem('userLoggedIn', 'true');
      navigate('/nap-game/sieu-hoc-vien-3d'); 
    }

  }, [navigate, params]);
  // #endregion

  return (
    <div className={styles['container']}>
      <div className={styles['card']}>
        <div className={styles['card-body']}>
          <Image src='/assets/images/icon-loading.png' alt='Đang tải trang' preview={false} />
          <h5>Vui lòng chờ, hệ thống sẽ tự động điều hướng sau giây lát...</h5>
        </div>
      </div>
    </div>
  );
}

export default WapCallback;
