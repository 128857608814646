// @ts-check

import { VerifyInfoService } from "@payment-mfe/shared/service";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

//# region "Constants"
const APP_FEATURE_KEY = "members/verify-otp";
const ACTION_VERIFY_OTP_KEY = APP_FEATURE_KEY + "/doVerify";
// #endregion

// #region "Service"
const service = new VerifyInfoService();
// #endregion

// #region "AsyncThunks"
const doVerifyAsync = createAsyncThunk(ACTION_VERIFY_OTP_KEY, async (payload: { baseAuthApiUrl: string, trackingId: string, otp: string }) => {
  return service.verifyOTP(payload.baseAuthApiUrl, { trackingId: payload.trackingId, otp: payload.otp })
})
// #endregion

// #region "Business"

/**
 * The state of property will management by this tree.
 * @since 1.0
 */
interface IState {
  status: 'idle' | 'loading' | 'successed' | 'failed';
  error: string | null;
  trackingId: string | null;
  isOtpValid: boolean | null;
}

/**
 * Default state of the tree.
 * @since 1.0
 */
const initialState: IState = {
  status: 'idle',
  error: null,
  trackingId: null,
  isOtpValid: null
}

/**
 * Defination the slice.
 * @since 1.0
 */
const slice = createSlice({
  name: APP_FEATURE_KEY,
  initialState: initialState,
  reducers: {
    doResetState(state) {
      state.status = 'idle';
      state.error = null;
      state.trackingId = null;
      state.isOtpValid = null;
    },
    resetTrackingId(state) {
      state.status = 'idle'
      state.trackingId = null
    },
    resetOtpStatus(state) {
      state.isOtpValid = null;
    },
    resetVerifyStatus(state) {
      state.status = 'idle'
    }
  },
  extraReducers: (builder) => {

    /**
     * A handler of the verify one-time password result.
     * @since 1.0
     */
    builder.addCase(doVerifyAsync.fulfilled, (state, action) => {
      state.status = 'successed';
      state.error = 'The verificated an one time passsword is success.';

      state.isOtpValid = (action.payload.errCode === "00");
    });

    /**
     * A handler ot the verify one-time password failure.
     * @since 1.0
     */
    builder.addCase(doVerifyAsync.rejected, (state) => {
      state.status = 'failed';
      state.error = 'The verificated an one time passsword is failured.';
      state.isOtpValid = false;
    });

    /**
     * A handler of the during verification one time password process.
     * @since 1.0
     */
    builder.addCase(doVerifyAsync.pending, (state) => {
      state.status = 'loading';
      state.error = 'Make an request to verify the one time password.';
      state.isOtpValid = null;
    });

  }
})

// #endregion

// #region "Exports"
/**
 * Dispatcher actions
 */
export const actions = {
  ...slice.actions,
  doVerifyAsync,
};

export default slice.reducer;

// #endregion