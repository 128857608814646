import { useEffect } from 'react';
import styles from './faq.module.css';

/* eslint-disable-next-line */
export interface FaqProps {}

export function Faq(props: FaqProps) {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div className={styles['container']}>
      <p className={styles['title']}>
        <strong>Hỏi & Đáp</strong>
      </p>
      <br />

      <div>
        <h3>Làm thế nào để nạp BXu?</h3>
        <p>Truy cập trang pay.boomgames.vn.</p>
        <p>Người dùng cần có tài khoản để đăng nhập.</p>
        <p>Người dùng có thể nạp BXu bằng:</p>
        <p>Thanh Toán Trực Tiếp.</p>
        <p>Ví điện tử Momo.</p>
        <p>
          Tài khoản ATM có Internet Banking hoặc tài khoản thanh toán quốc tế:
          VISA, Master Card, JCB.
        </p>
      </div>
      <br />
      <div>
        <h3>Làm thế nào để nạp BXu Boomgames bằng Thanh Toán Trực Tiếp?</h3>
        <p>Người dùng có thẻ ATM và ví điện tử Momo.</p>
      </div>
      <br />

      <div>
        <h3>Làm thế nào để nạp BXu Boomgames bằng ví điện tử Momo?</h3>
        <p>Người dùng có thể tải ví điện tử Momo tại:</p>
        <p>Google play hoặc tải APK từ website của Momo.</p>
      </div>
      <br />

      <div>
        <h3>Làm thế nào để nạp BXu Boomgames bằng tài khoản thẻ ATM?</h3>
        <p>Tài khoản ATM cần có kết nối Internet banking.</p>
      </div>
      <br />

      <div>
        <h3>Cách nạp tiền để mua Ruby trong game Siêu Học Viện 3D?</h3>
        <p>Tài khoản có sẵn BXu.</p>
        <p>Vào trò chơi “Siêu Học Viên 3D” và tiến hành mua Ruby bằng BXu.</p>
      </div>
      <br />

      <div>
        <h3>Nạp bằng gì lợi nhất khi chơi game của Boomgames?</h3>
        <p>
          Nạp game bằng Thanh Toán Trực Tiếp được ưu đãi lên tới 10% so với các
          hình thức khác.
        </p>
      </div>
    </div>
  );
}

export default Faq;
