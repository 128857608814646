//#region "Import"
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PasswordService } from '@payment-mfe/shared/service';
//#endregion

//#region "Constant"
const APP_FEATURE_KEY = 'members/password';
const ACTION_CHANGES_PASSWORD_KEY = APP_FEATURE_KEY + '/changesPassword';
//#endregion

//#region "Services"
const service = new PasswordService();
//#endregion

//#region "AsyncThunks"
const changesPasswordAsync = createAsyncThunk(
  ACTION_CHANGES_PASSWORD_KEY,
  async (payload: {
    baseAuthApiUrl: string;
    password: string;
    newPassword: string;
  }) => {
    const isSuccess = await service.changesPassword(payload.baseAuthApiUrl, {
      password: payload.password,
      new_password: payload.newPassword,
    });
    return isSuccess;
  }
);
//#endregion

//#region "State"
export interface PasswordState {
  status: 'idle' | 'loading' | 'successed' | 'failed';
  error: string | null;
}

const initialState: PasswordState = {
  status: 'idle',
  error: null,
};
//#endregion

//#region "Slice"
export const passwordSlice = createSlice({
  name: APP_FEATURE_KEY,
  initialState: initialState,
  reducers: {
    reset: (state) => {
      state = initialState;
      return state;
    }

  },
  extraReducers: (builder) => {
    builder.addCase(changesPasswordAsync.pending, (state) => {
      state.status = 'loading';
      state.error = 'processing a changes password request';
    });
    builder.addCase(changesPasswordAsync.rejected, (state) => {
      state.status = 'failed';
      state.error = 'cannot process change password request, due http server';
    });
    builder.addCase(changesPasswordAsync.fulfilled, (state, action) => {
      if (action.payload === true) {
        state.status = 'successed';
        state.error = 'the password was changes successful';
      } else {
        state.status = 'failed';
        state.error = 'changes the password failure, due business';
      }
    });
  },
});
//#endregion

//#region "Exports"
export const actions = {
  ...passwordSlice.actions,
  changesPasswordAsync,
};

export default passwordSlice.reducer;
//#endregion
