// eslint-disable-next-line @nx/enforce-module-boundaries
import { BreadcrumbWrapper } from '@payment-mfe/shared/commons';
import actions, {
  loadingComingSoon,
  loadingInputMobileOTP,
  loadingServerError,
  loadingUpdateProfileFailureModal,
  loadingUpdateProfileSuccessModal,
  useAppDispatch,
  useAppSelector,
} from '@payment-mfe/shared/store';
import { hideEmailVerified, hideNumber } from '@payment-mfe/shared/utils';
import {
  Button,
  Col,
  DatePicker,
  Form,
  FormRule,
  Input,
  Modal,
  Radio,
  Row,
} from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import 'moment/locale/vi';
import { useEffect, useState } from 'react';
import { ChangeEmail, ChangeMobile, VerifyOTPModal } from './components';
import { Message } from './components/message';
import './user-info.css';
import styles from './user-info.module.css';

moment.locale('vi');

export interface UserInfoProps {
  baseAuthApiUrl: string;
  notificationApiUrl: string;
}

type Profile = {
  uid: number;
  username: string;
  name: string;
  email: string;
  mobile: string;
  birthday: Date;
  address: string;
  location: string;
  gender: string;
  identification_number: string | number;
  identification_address: string;
  identification_registration_date: string;
};

export function UserInfo(props: UserInfoProps) {
  // #region local State
  const [showChangePhone, setShowChangePhone] = useState<boolean>(false);
  const [showChangeEmail, setShowChangeEmail] = useState<boolean>(false);
  const [otpType, setOtptype] = useState<
    'phone' | 'email' | 'change-email' | 'change-phone' | null
  >(null);
  // #endregion

  //#region Form
  const [form] = Form.useForm();
  const [viewWidth, setViewWidth] = useState(window.innerWidth);
  const formLayout = viewWidth >= 768 ? 'horizontal' : 'vertical';
  //#endregion

  //#region "Redux State"
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state) => state.appState.currentUser);

  const currentProfile = useAppSelector((state) => state.member.profie.profile);
  const updated = useAppSelector((state) => state.member.profie.updated);
  const loading = useAppSelector((state) => state.member.profie.status);
  const sendOTP_status = useAppSelector(
    (state) => state.member.profie.sendOTP_status
  );

  const trackingID = useAppSelector((state) => state.member.profie.trackingId);

  //#endregion

  //#region "Component State"
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [identificationNumber, setIdentificationNumber] = useState<
    string | number
  >();
  const [idenIsReadonly, setIdenIsReadonly] = useState<boolean>(false);

  const [isChangesEmailSuccess, setIsChangesEmailSuccess] =
    useState<boolean>(false);
  const [isChangesMobileSuccess, setIsChangesMobileSuccess] =
    useState<boolean>(false);

  //#endregion

  //#region "Effects"
  /**
   * Inital profile data.
   */

  useEffect(() => {
    dispatch(
      actions.member.profile.getAsync({
        baseAuthApiUrl: props.baseAuthApiUrl,
      })
    );
  }, [dispatch, props.baseAuthApiUrl]);

  /**
   * Binding the profile data to form.
   */
  useEffect(() => {
    if (currentProfile !== null) {
      let birthdayDate = null;
      let identification_registration_date = null;
      if (currentProfile.birthday !== null) {
        birthdayDate = dayjs(Date.parse(currentProfile.birthday));
      }

      if (currentProfile.identification_registration_date !== null) {
        identification_registration_date = dayjs(
          Date.parse(currentProfile.identification_registration_date)
        );
      }

      if (currentProfile.identification_number) {
        setIdenIsReadonly(true);
      }
      setPhoneNumber(currentProfile.mobile);
      setIdentificationNumber(currentProfile.identification_number);
      setEmail(currentProfile.email);

      form.setFieldsValue({
        username: currentUser?.username,
        uid: currentProfile.uid,
        name: currentProfile.name,
        email:
          currentProfile.email_verified && currentProfile.email
            ? hideEmailVerified(currentProfile.email)
            : currentProfile.email,
        mobile:
          currentProfile?.mobile_verified && currentProfile.mobile
            ? hideNumber(currentProfile.mobile)
            : currentProfile.mobile,
        birthday: birthdayDate,
        address: currentProfile.address,
        gender: currentProfile.gender,
        identification_number:
          currentProfile.identification_number &&
          hideNumber(currentProfile.identification_number),
        identification_address: currentProfile.identification_address,
        identification_registration_date: identification_registration_date,
      });
    }
  }, [currentProfile, currentUser, form]);

  /**
   * Handles the update profile result
   */
  useEffect(() => {
    if (isSubmitted === true) {
      if (updated === true) {
        dispatch(loadingUpdateProfileSuccessModal());
      } else if (updated === false) {
        dispatch(loadingUpdateProfileFailureModal());
      }
    }
  }, [dispatch, updated, isSubmitted]);

  /**
   * Change Form layput when responsive
   */
  useEffect(() => {
    window.addEventListener('resize', () => setViewWidth(window.innerWidth));
    return () =>
      window.removeEventListener('resize', () =>
        setViewWidth(window.innerWidth)
      );
  }, []);

  /**
   * Show OTP Input Modal For Verify
   */
  useEffect(() => {
    if (
      sendOTP_status === 'successed' &&
      (otpType === 'phone' || otpType === 'email')
    ) {
      dispatch(loadingInputMobileOTP());
    } else if (
      sendOTP_status === 'failed' &&
      (otpType === 'phone' || otpType === 'email')
    ) {
      dispatch(loadingServerError());
    }
  }, [sendOTP_status, dispatch, otpType]);

  /**
   * Show OTP Input Modal For Change Mobile Phone Number
   */
  useEffect(() => {
    if (sendOTP_status === 'successed' && otpType === 'change-phone') {
      setShowChangePhone(true);
      dispatch(actions.member.profile.resetStatus());
    } else if (sendOTP_status === 'failed' && otpType === 'change-phone') {
      dispatch(loadingServerError());
    }
  }, [sendOTP_status, dispatch, otpType]);

  //#endregion

  //#region "Actions"
  /**
   * Update the member profile.
   * @since 1.0
   */
  const onSaveChanges = (values: Profile): void => {
    const birthdayDateString = dayjs(values.birthday).format('YYYY-MM-DD');
    const identificationDateString =
      values.identification_registration_date !== null
        ? dayjs(values.identification_registration_date).format('YYYY-MM-DD')
        : null;

    dispatch(
      actions.member.profile.updateAsync({
        baseAuthApiUrl: props.baseAuthApiUrl,
        req: {
          name: values.name,
          gender: values.gender,
          birthday: birthdayDateString,
          address: values.address,
          identification_number: idenIsReadonly
            ? identificationNumber
            : values.identification_number,
          identification_address: values.identification_address,
          identification_registration_date: identificationDateString,
          email: currentProfile?.email_verified ? email : values.email,
          mobile: currentProfile?.mobile_verified ? phoneNumber : values.mobile,
        },
      })
    );

    setIsSubmitted(true);
  };

  /**
   * Send an one-time password to the member, via Zalo application.
   * @since 1.0
   */
  const handleSendMobileOTP = () => {
    setOtptype('phone');
    dispatch(
      actions.member.profile.sendOTP({
        notificationApiUrl: props.notificationApiUrl,
        req: {
          emailOrMobile: form.getFieldValue('mobile').replace(/^0/, '84'),
          type: 'mobile',
        },
      })
    );
    // Save draft mobile phone
    dispatch(
      actions.member.profile.draftMobile(
        form.getFieldValue('mobile').replace(/^0/, '84')
      )
    );
  };

  /**
   * Send an one-time password to the e-mail address.
   * @since 1.0
   */
  const handleSendEmailOTP = () => {
    dispatch(
      actions.member.profile.sendOTP({
        notificationApiUrl: props.notificationApiUrl,
        req: {
          emailOrMobile: form.getFieldValue('email'),
          type: 'email',
        },
      })
    );
    // Save draft Email
    dispatch(actions.member.profile.draftMobile(form.getFieldValue('email')));
  };

  //#endregion

  //#region "Validations"
  const nameRules: FormRule[] = [
    { required: true, message: 'Họ và tên không được để trống.' },
  ];

  const handleEmailValidation = async (email: string): Promise<boolean> => {
    try {
      const response = await fetch(
        props.baseAuthApiUrl + '/members/unique-email/' + email,
        {
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
          },
          method: 'get',
        }
      );

      const jsonBody = await response.json();
      const isEmailUniqueValidate = jsonBody.errCode === '00';
      if (!isEmailUniqueValidate) {
        return false;
      }
      return true;
    } catch (e) {
      return false;
    }
  };

  const birthdayRules: FormRule[] = [
    { required: true, message: 'Ngày sinh không được để trống.' },
  ];

  const disabledDate = (current: dayjs.Dayjs) => {
    // Get the current date
    const currentDate = new Date();
    const validDate = dayjs(currentDate).subtract(5, 'years');

    // Disable dates that are after the current date
    return current && current > validDate;
  };

  const handleMobileValidation = async (mobile: string) => {
    try {
      const response = await fetch(
        props.baseAuthApiUrl + '/members/unique-mobile/' + mobile,
        {
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
          },
          method: 'get',
        }
      );

      const jsonBody = await response.json();
      const isMobileUniqueValidate = jsonBody.errCode === '00';
      if (!isMobileUniqueValidate) {
        return false;
      }
      return true;
    } catch (e) {
      return false;
    }
  };

  const emailRules: FormRule[] = [
    { required: true, message: 'Địa chỉ e-mail không được để trống.' },
    { type: 'email', message: 'Địa chỉ e-mail không đúng định dạng.' },
    {
      validator: async (rule, value) => {
        // Your condition to determine whether to apply handleMobileValidation
        if (value !== currentProfile?.email && value !== '') {
          const isUnique = await handleEmailValidation(value);
          if (!isUnique) {
            throw new Error('Tài khoản e-mail đã tồn tại trên hệ thống.');
          }
        }
      },
    },
  ];

  const identificationNumberRules: FormRule[] = [
    { max: 12, message: 'Số CCCD/CMT không được vượt quá 12 kí tự.' },
  ];

  const identificationAddressRules: FormRule[] = [
    { max: 250, message: 'Nơi cấp không được vượt quá 250 kí tự.' },
  ];

  const mobileRules: FormRule[] = [
    { required: true, message: 'Số điện thoại không được để trống.' },
    {
      pattern: /(84|0[3|5|7|8|9])+([0-9]{8})$/,
      message: 'Số điện thoại không hợp lệ.',
    },
    {
      validator: async (rule, value) => {
        // Your condition to determine whether to apply handleMobileValidation
        if (value !== currentProfile?.mobile && value !== '') {
          const isUnique = await handleMobileValidation(value);
          if (!isUnique) {
            throw new Error('Số điện thoại đã tồn tại trên hệ thống.');
          }
        }
      },
    },
  ];
  //#endregion

  // #region "View render"
  return (
    <>
      <BreadcrumbWrapper link="/" text="Trở lại trang chủ">
        <div className={styles['userInfo-page']}>
          <p className={styles['mobile-avatar']}>AVATAR</p>
          <div className={styles['user-avatar']}>
            <img src="/assets/images/icons/default_avatar.png" alt="" />
            <button
              style={{ cursor: 'pointer' }}
              onClick={() => dispatch(loadingComingSoon())}
            >
              Tải ảnh
            </button>
          </div>

          <div className={styles['user-info']}>
            <p className={styles['section-title']}>THÔNG TIN TÀI KHOẢN</p>
            <Form
              form={form}
              className="user-info-form"
              labelAlign="left"
              autoComplete={'off'}
              onFinish={onSaveChanges}
              layout={formLayout}
              labelCol={{ xxl: 4, xl: 6, lg: 6, md: 6, sm: 6, xs: 8 }}
              wrapperCol={{ xxl: 20, xl: 18, lg: 18, md: 18, sm: 18, xs: 16 }}
            >
              <Form.Item label="Mã khách hàng" name="uid" className="form-item">
                <Input readOnly />
              </Form.Item>

              <Form.Item
                label="Tên tài khoản"
                name="username"
                className="form-item"
              >
                <Input readOnly />
              </Form.Item>

              {/* Name */}
              <Form.Item label="Họ và tên" name="name" rules={nameRules}>
                <Input placeholder="Nhập họ tên" />
              </Form.Item>

              {/* Gender */}
              <Form.Item label="Giới tính" name="gender">
                <Radio.Group value={1}>
                  <Radio value={'1'} checked={currentProfile?.gender === '1'}>
                    Nam
                  </Radio>
                  <Radio value={'2'} checked={currentProfile?.gender === '2'}>
                    Nữ
                  </Radio>
                </Radio.Group>
              </Form.Item>

              {/* DOB */}
              <Form.Item
                label="Ngày sinh"
                name="birthday"
                rules={birthdayRules}
              >
                <DatePicker
                  defaultPickerValue={dayjs().subtract(5, 'year')}
                  placeholder="Nhập ngày"
                  format={'DD/MM/YYYY'}
                  disabledDate={disabledDate}
                />
              </Form.Item>

              {/* Address */}
              <Form.Item label="Địa chỉ" name="address">
                <Input placeholder="Nhập địa chỉ" />
              </Form.Item>

              <p className={styles['section-title']}>THÔNG TIN BẢO MẬT</p>
              {/* CCCD/CMT */}
              <Form.Item
                label="Số CCCD/CMT"
                name="identification_number"
                rules={identificationNumberRules}
              >
                <Input
                  placeholder="Nhập số Căn cước công dân / Chứng minh thư của bạn"
                  readOnly={idenIsReadonly}
                />
              </Form.Item>

              {/* Date */}
              <Form.Item
                label="Ngày cấp"
                name="identification_registration_date"
              >
                <DatePicker placeholder="Nhập ngày" format={'DD/MM/YYYY'} />
              </Form.Item>

              {/* Location */}
              <Form.Item
                label="Nơi cấp"
                name="identification_address"
                rules={identificationAddressRules}
              >
                <Input placeholder="Nhập nơi cấp" />
              </Form.Item>

              {/* Phone */}
              <Row
                gutter={{ xs: 12, sm: 16, lg: 16, xl: 24 }}
                align={viewWidth >= 768 ? 'top' : 'middle'}
              >
                <Col xxl={20} xl={18} lg={18} md={18} sm={18} xs={15}>
                  <Form.Item
                    labelCol={{ xxl: 5, xl: 8, lg: 8, md: 8 }}
                    wrapperCol={{ xxl: 19, xl: 16, lg: 16, md: 16 }}
                    label="Số điện thoại"
                    rules={currentProfile?.mobile_verified ? [] : mobileRules}
                    name="mobile"
                    className="custom-field"
                  >
                    <Input
                      readOnly={currentProfile?.mobile_verified}
                      placeholder="Nhập số điện thoại"
                      suffix={
                        currentProfile?.mobile_verified ? (
                          <img
                            src="/assets/images/icons/validated.png"
                            alt="Số điện thoại đã được xác thực"
                          />
                        ) : null
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xxl={4} xl={6} lg={6} md={6} sm={6} xs={9}>
                  {currentProfile?.mobile_verified ? (
                    <div
                      onClick={() => {
                        setShowChangePhone(true);
                        setOtptype('change-phone');

                        // dispatching to the send an email
                        dispatch(
                          actions.member.sendOtp.doSendAsync({
                            baseNotificationApiUrl: props.notificationApiUrl,
                            emailOrMobile: currentProfile.mobile.replace(
                              /^0/,
                              '84'
                            ),
                            type: 'mobile',
                          })
                        );
                      }}
                      className="validate-status active"
                    >
                      Đổi SĐT
                    </div>
                  ) : (
                    <Button
                      disabled={
                        !currentProfile?.mobile ||
                        !currentProfile.birthday ||
                        !currentProfile.email ||
                        !currentProfile.name
                      }
                      className="validate-status inactive"
                      onClick={handleSendMobileOTP}
                      loading={
                        sendOTP_status === 'loading' && otpType === 'phone'
                      }
                    >
                      Chưa xác thực
                    </Button>
                  )}
                </Col>
              </Row>

              {/* E-mail address */}
              <Row
                gutter={{ xs: 16, sm: 16, lg: 16, xl: 24 }}
                align={viewWidth >= 768 ? 'top' : 'middle'}
              >
                <Col xxl={20} xl={18} lg={18} md={18} sm={18} xs={15}>
                  <Form.Item
                    labelCol={{ xxl: 5, xl: 8, lg: 8, md: 8 }}
                    wrapperCol={{ xxl: 19, xl: 16, lg: 16, md: 16 }}
                    label="Địa chỉ e-mail"
                    name="email"
                    rules={currentProfile?.email_verified ? [] : emailRules}
                    className="custom-field"
                  >
                    <Input
                      readOnly={currentProfile?.email_verified}
                      placeholder="Nhập email"
                      suffix={
                        currentProfile?.email_verified ? (
                          <img
                            src="/assets/images/icons/validated.png"
                            alt="Địa chỉ e-mail đã được xác thực"
                          />
                        ) : null
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xxl={4} xl={6} lg={6} md={6} sm={6} xs={9}>
                  {currentProfile?.email_verified ? (
                    <div
                      onClick={() => {
                        // show the change email address modal.
                        setShowChangeEmail(true);
                        setOtptype('change-email');

                        // dispatching to the send an email
                        dispatch(
                          actions.member.sendOtp.doSendAsync({
                            baseNotificationApiUrl: props.notificationApiUrl,
                            emailOrMobile: currentProfile.email,
                            type: 'email',
                          })
                        );
                      }}
                      className="validate-status active"
                    >
                      Đổi Email
                    </div>
                  ) : (
                    <Button
                      disabled={
                        !currentProfile?.mobile ||
                        !currentProfile.birthday ||
                        !currentProfile.email ||
                        !currentProfile.name
                      }
                      className="validate-status inactive"
                      onClick={() => {
                        handleSendEmailOTP();
                        setOtptype('email');
                      }}
                      loading={
                        sendOTP_status === 'loading' && otpType === 'email'
                      }
                    >
                      Chưa xác thực
                    </Button>
                  )}
                </Col>
              </Row>
              <Button
                className={styles['submit-btn']}
                htmlType="submit"
                type="primary"
                size="large"
                loading={loading === 'loading'}
              >
                Cập nhật thông tin
              </Button>
            </Form>
          </div>
        </div>
      </BreadcrumbWrapper>

      <ChangeMobile
        isModalOpen={showChangePhone}
        mobileNumber={currentProfile?.mobile ?? null}
        baseAuthApiUrl={props.baseAuthApiUrl}
        baseNotificationApiUrl={props.notificationApiUrl}
        otpType={otpType}
        onSuccess={() => {
          console.log('Change mobile address success');
          setShowChangePhone(false);
          Modal.destroyAll();

          setIsChangesMobileSuccess(true);
        }}
        onCancel={() => {
          setShowChangePhone(false);
          Modal.destroyAll();
        }}
      />

      {/** BEGIN changes e-mail address modal */}
      <ChangeEmail
        isModalOpen={showChangeEmail}
        emailAddress={currentProfile?.email ?? null}
        baseAuthApiUrl={props.baseAuthApiUrl}
        baseNotificationApiUrl={props.notificationApiUrl}
        otpType={otpType}
        onSuccess={() => {
          setShowChangeEmail(false);
          Modal.destroyAll();

          // display the success message
          setIsChangesEmailSuccess(true);
        }}
        onCancel={() => {
          setShowChangeEmail(false);
          Modal.destroyAll();
        }}
      />
      {/** ./END changes e-mail address modal */}

      <VerifyOTPModal
        emailAddress={currentProfile?.email ?? null}
        mobileNumber={currentProfile?.mobile ?? null}
        trackingID={trackingID || null}
        type={otpType}
        notificationApiUrl={props.notificationApiUrl}
        authApiUrl={props.baseAuthApiUrl}
      />

      {isChangesEmailSuccess === true ? (
        <Message title="Thành công" message="Đổi địa chỉ e-mail thành công" />
      ) : null}
      {isChangesMobileSuccess === true ? (
        <Message title="Thành công" message="Đổi số điện thoại thành công" />
      ) : null}
    </>
  );
  // #endregion
}

export default UserInfo;
