// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

export const environment = {
  production: false,
  // authApiUrl: 'http://localhost:8080/ms-auth-svc/api/v1',
  // paymentApiUrl: 'http://localhost:8082/ms-payment-svc/api/v1',
  // notificationApiUrl:
  //   'http://localhost:8083/ms-notification-svc/api/v1',
  // productApiUrl: 'http://localhost:8081/ms-product-svc/api/v1',
  authApiUrl: 'https://api.sandbox.notagames.vn/ms-auth-svc/api/v1',
  paymentApiUrl: 'https://api.sandbox.notagames.vn/ms-payment-svc/api/v1',
  notificationApiUrl:
    'https://api.sandbox.notagames.vn/ms-notification-svc/api/v1',
  productApiUrl: 'https://api.sandbox.notagames.vn/ms-product-svc/api/v1',
  fileApiUrl: 'https://api.boomgames.vn/ms-file-svc/api/v2'
};
