import { Modal } from 'antd';
import styles from './message.module.css';
import { useState } from 'react';

/* eslint-disable-next-line */
export interface MessageProps {
  title: string;
  message: string;
}

const SuccessIconSvg = (
  <svg
    width="80"
    height="100"
    viewBox="0 0 80 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M73.4294 0.638287C78.6167 2.62135 81.2853 8.62867 79.3899 14.056L52.9781 89.687C49.1216 100.73 35.4565 103.579 27.8495 94.9249L2.63967 66.2461C-1.09889 61.9932 -0.834392 55.3745 3.23044 51.4628C7.29528 47.5512 13.6212 47.828 17.3597 52.081L37.0113 74.4366L60.6053 6.87469C62.5006 1.44736 68.2422 -1.34478 73.4294 0.638287Z"
      fill="#56B947"
    />
  </svg>
);

export function Message(props: MessageProps) {
  const [isOpened, setIsOpened] = useState<boolean>(true);

  return (
    <Modal
      open={isOpened}
      title={
        <div className={styles['modal__header']}>
          <h4 className={styles['modal__title']}>{props.title}</h4>
        </div>
      }
      destroyOnClose
      footer={null}
      onCancel={(e) => {
        e.stopPropagation();
        setIsOpened(false);
      }}
    >
      <div className={styles['modal__body']}>
        <p className={styles['text__message']}>{props.message}</p>

        <div className={styles['icon']}>{SuccessIconSvg}</div>
      </div>
    </Modal>
  );
}

export default Message;
