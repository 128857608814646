// @ts-check

// #region "Imports"
import { Skeleton, Tooltip } from 'antd';
import React from 'react';
import QRCode from 'react-qr-code';
import { io } from 'socket.io-client';

import { QrCodeHelper } from '@payment-mfe/shared/service';
import actions, { useAppDispatch, useAppSelector} from '@payment-mfe/shared/store';

import styles from '../payment.module.css';
// #endregion

// #region "Props"
/**
 * The component properties.
 * @version 1.0
 */
export interface PmQrcodeProps {
  baseAuthApiUrl: string;
  baseNotificationApiUrl: string;
}
// #endregion

//#region "Components"
/**
 * The component using to generate the QR code, for each payment action,
 * every when the FE payment via internet banking method.
 */
export function PaymentQRCode(props: PmQrcodeProps): JSX.Element {
  // #region "Redux"
  // control the dispatching to an action.
  const dispatch = useAppDispatch();
  
  // an authenticated user.
  const currentUser = useAppSelector(
    (state) => state.appState.currentUser
  );

  // the user profile
  const profile = useAppSelector(
    (state) => state.member.profie.profile
  );

  // list of the bank supported
  const selectedBank = useAppSelector(
    (state) => state.payment.paymentUI.bank
  );

  // the conversion rate amount
  const amount = useAppSelector(
    (state) => state.payment.paymentUI.conversionRate?.currencyAmount
  );

  // the name of a bank
  const paymentBankName = useAppSelector(
    (state) => state.payment.paymentUI.bank?.name
  );
  // #endregion

  // #region "State"
  // control the visibility of copy the bank number button.
  const [
    showCopyVirtualAccount,
    setShowCopyVirtualAccount
  ] = React.useState(false);

  // control the visibility of copy a transaction content button.
  const [
    showCopyTransactionContent,
    setShowCopyTransactionContent
  ] = React.useState(false);
  // #endregion

  // #region "Generate QR code"
  // the destination number of the bank.
  const QRCodeGenerator = React.useCallback(() => {
    const qrService = new QrCodeHelper();
    if (selectedBank && amount && profile) {
      const qrEncode = qrService.generateQrCode(
        selectedBank.bnbId,
        selectedBank.bankAccountNumber,
        amount,
        'BOOM' + profile.uid.toString()
      );

      return (
        <div className={styles['qr-wrapper']}>
          <QRCode size={196} value={qrEncode} />
        </div>
      );
    }

    return <Skeleton />;
  }, [selectedBank, amount, profile]);
  // #endregion

  // #region "Effect"
  /**
   * Load the default profile.
   * @since 1.0
   */
  React.useEffect(() => {
    if (props.baseAuthApiUrl) {
      dispatch(
        actions.member.profile.getAsync({
          baseAuthApiUrl: props.baseAuthApiUrl,
        })
      );
    }
  }, [dispatch, props.baseAuthApiUrl]);

  /**
   * Establish connection to the socket.
   * @since 1.0
   */
  React.useEffect(() => {
    if (currentUser && props.baseNotificationApiUrl) {
      const socket = io(`${props.baseNotificationApiUrl}/qr-pay`, {
        autoConnect: true,
        reconnection: true,
      })
      
      socket.on(`auth-${currentUser.uid}`, (msg) => {
        const { success, transactionId } = JSON.parse(msg);
        if (success && transactionId) {
          dispatch(
            actions.payment.notification.qrReceived({
              success: success,
              transactionId: transactionId,
            })
          )
        }
      })
    }
  }, [dispatch, props.baseNotificationApiUrl, currentUser])
  // #endregion

  //#region "Views"
  return (
    <div className={styles['bank-tranfer']}>
      <div className={styles['bank-info']}>
        <div className={styles['bank-info__item']}>
          <div className={styles['pay-block']}>
            <div className={styles['pay-option']}>Cách 1:</div>
            <ul className={styles['pay-step']}>
              <li>Quét mã QR để thanh toán</li>
              <li>- Bước 1: Mở ứng dụng mobile banking của bạn</li>
              <li>- Bước 2: Đăng nhập và quét mã QR bên dưới</li>
              <li>- Bước 3: Hoàn thành các bước thanh toán</li>
              <li>{QRCodeGenerator()}</li>
              <li
                className={styles['bank-owner']}
                style={{
                  flexDirection: 'column',
                  gap: '2px',
                  marginBottom: '16px',
                  marginTop: '4px',
                  marginLeft: '-101px',
                }}
              >
                <span>CONG TY CO PHAN BOOM GAMES</span>
                <span>{selectedBank?.bankAccountNumber || ''}</span>
                <span>{paymentBankName}</span>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles['bank-info__item']}>
          <div className={styles['pay-block']}>
            <div className={styles['pay-option']}>Cách 2:</div>
            <ul className={styles['pay-step']}>
              <li>Chuyển tiền vào tài khoản dưới đây:</li>
              <li>
                <p>
                  Số tài khoản:{' '}
                  <strong>{selectedBank?.bankAccountNumber}</strong>
                </p>

                <Tooltip
                  title="Số tài khoản đã được sao chép"
                  arrow={showCopyVirtualAccount}
                  trigger={'click'}
                >
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(
                        selectedBank?.bankAccountNumber || ''
                      );
                      setShowCopyVirtualAccount(true);
                    }}
                  >
                    Copy
                  </button>
                </Tooltip>
              </li>
              <li style={{ display: 'block' }}>
                Chủ tài khoản:{'  '}
                <strong>CONG TY CO PHAN CONG NGHE BOOM GAMES</strong>
              </li>
              <li style={{ display: 'block' }}>
                Ngân hàng thụ hưởng: <strong>{paymentBankName}</strong>
              </li>
              <li>
                <p>
                  Nội dung chuyển khoản: <strong>BOOM{profile?.uid}</strong>
                </p>
                <Tooltip
                  title="Nội dung chuyển khoản đã được sao chép"
                  arrow={showCopyTransactionContent}
                  trigger={'click'}
                >
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `BOOM${profile?.uid.toString()}` || ''
                      );
                      setShowCopyTransactionContent(true);
                    }}
                  >
                    Copy
                  </button>
                </Tooltip>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <h5>
        Chú ý: Mỗi Boom ID được liên kết với một nội dung chuyển khoản khác nhau,
        vui lòng copy để lấy chính xác số tài khoản và nội dung chuyển khoản.
      </h5>
    </div>
  );
  //#endregion
}
//#endregion

//#region "Exports"
export default PaymentQRCode;
//#endregion
