// @ts-check

// #region "imports"
import { BaseService } from '../base';
// #endregion

// #region "model"
export type ConfigGatewayResponse = {
  id: string;
  name: string;
  code: string;
  type: string;
}
// #endregion

// #region "service"
export class ConfigGatewayService extends BaseService<ConfigGatewayResponse> {

  /**
   * list all activated gateway.
   * @param apiUrl the base api url.
   * @returns 
   */
  async getAll(apiUrl: string): Promise<ConfigGatewayResponse[] | null> {
    const baseResponse = await this.getAllAsync(apiUrl + '/config/gateways');
    if (baseResponse.errCode === '00') {
      return baseResponse.data as ConfigGatewayResponse[];
    }
    return null;
  }
}
// #endregion