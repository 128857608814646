// @ts-check

import { BaseService } from '../base';
import { TokenRequest, TokenResponse } from '@payment-mfe/shared/models';

export class TokenService extends BaseService<TokenResponse> {
  /**
   * Verifying the user access token
   *
   * @param apiUrl An end-point use to verify access token at NotA auth service
   * @param request A payload contains the user access token
   * @returns The token information if present
   */
  async verifyAsync(
    apiUrl: string,
    request: TokenRequest
  ): Promise<TokenResponse | null> {
    const baseResponse = await this.postAsync(apiUrl, JSON.stringify(request));
    if (baseResponse.errCode === '00') {
      return baseResponse.data;
    }
    // localStorage.removeItem('token');
    return null;
  }
}
