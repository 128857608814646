//#region "Auth"
export { TokenService } from './auth/token.service';
export { StorageService } from './storage.service';

export { ForgotPasswordService } from './auth/forgot-password.service';
export { LoginService } from './auth/login.service';
export { PasswordService } from './auth/password.service';
export { RegisterService } from './auth/register.service';

//#endregion

//#region "Wallets"
export { WalletService } from './wallet.service';
export type { Wallet } from './wallet.service';

export { QrService } from './payment/qr.service';
export type { QrResponse } from './payment/qr.service';

export { VirtualAccountVerifyService } from './payment/virtual-account-verify.service';
export type { VerifyResponse } from './payment/virtual-account-verify.service';
//#endregion

//#region Payment
export { BankService } from './payment/bank.service';
export type { BankResponse } from './payment/bank.service';

export { GameService } from './games/game.service';
export { GiftcodeService } from './games/giftcode.service';

export { MethodService } from './payment/method.service';
export type { Method } from './payment/method.service';

export {ConfigGatewayService} from './payment/config.service';
export type {ConfigGatewayResponse} from './payment/config.service';

export { RateGemService } from './payment/rate-gem.service';
export { RateService } from './payment/rate.service';
export type { RateResponse } from './payment/rate.service';

export { GatewayVerifyService } from './payment/gateway-verify.service';
export { GatewayService } from './payment/gateway.service';
export { TransactionService } from './payment/transaction.service';

export { TransactionHistoryService } from './payment/transaction-history.service';
export type {
  TransactionRequest,
  TransactionResponse,
} from './payment/transaction-history.service';
//#endregion

//#region "Firebase"
export { auth as firebaeAuth } from './firebase';
//#endregion

export { QrCodeHelper } from './base/qrcode.service';

// #region Members Profile
export { ProfileService } from './profile/profile.service';
export type {
  ProfileRequest,
  ProfileResponse,
} from './profile/profile.service';
export { VerifyInfoService } from './profile/verifyInfo.service';
// #endregion

//#region Banner
export { BannerService } from './banner/banner.service'

export type { BannerResponse } from './banner/banner.service'
//#endregion
