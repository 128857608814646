import { Outlet } from 'react-router-dom';
import { BottomMenu, PaymentFooter, PaymentHeader } from './components';
import styles from './layout.module.css';

/* eslint-disable-next-line */
export interface LayoutProps {
  children?: string | JSX.Element | JSX.Element[];
  authApiUrl: string;
  paymentApiUrl: string;
  notificationApiUrl: string;
}

export function PaymentLayout(props: LayoutProps) {
  return (
    <div className={styles['payment-layout']}>
      <PaymentHeader
        authApiUrl={props.authApiUrl}
        paymentApiUrl={props.paymentApiUrl}
        notificationApiUrl={props.notificationApiUrl}
      />
      <div className={styles['payment-wrapper']}>
        {/* {isLoggedIn ? props.children : <Navigate to={'/'} />} */}
        {/* {props.children} */}
        <Outlet />
      </div>

      {/* <Link
        to="https://m.me/boom.cskh"
        target="_blank"
        className={styles['payment-support']}
      >
        <img
          src="/assets/images/icons/support.svg"
          alt=""
          className={styles['support-icon']}
        />
      </Link> */}
      <PaymentFooter />
      <div className={styles['mobile-buffer']} />
      <BottomMenu />
    </div>
  );
}

export default PaymentLayout;
