import { BaseService } from '../base';

export type ChangesPasswordRequest = {
  password: string;
  new_password: string;
};

/* eslint-disable-next-line */
export type ChangesPasswordResponse = {};

export class PasswordService extends BaseService<ChangesPasswordResponse> {
  async changesPassword(
    baseApiUrl: string,
    req: ChangesPasswordRequest
  ): Promise<boolean> {
    const baseResponse = await this.putAsync(
      baseApiUrl + '/protected/change-password',
      JSON.stringify(req)
    );

    if (baseResponse.errCode === '00') {
      return Promise.resolve(true);
    }

    return Promise.resolve(false);
  }
}
