//@ts-check

//#region "Modules"
import { BaseService } from '../base';
//#endregion

//#region "Types"
export type RateResponse = {
  id: string;
  name: string;
  currency_amount: number;
  game_amount: number;
  priority: number;
  rate: number;
};
//#endregion

export class RateService extends BaseService<RateResponse> {
  /**
   * List all rates
   *
   * @param basePaymentApiUrl An end-point of the rate service
   * @param type The type of conversion rate.
   * @returns A list of the rate response
   */
  async listAsync(
    basePaymentApiUrl: string,
    type: string
  ): Promise<RateResponse[] | null> {
    const response = await this.getAllAsync(
      basePaymentApiUrl + '/conversion-rate/' + type
    );
    if (response.errCode === '00') {
      return response.data as RateResponse[];
    }

    return null;
  }
}
