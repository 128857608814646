// @ts-check
// #region "Imports"
import { useAppSelector } from '@payment-mfe/shared/store';

import React from 'react';
import { createPortal } from 'react-dom';
import styles from './style.module.css';
// #endregion

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ModalProps {
  isShown: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export function ExchangeModal(props: ModalProps) {
  const isSmallScreen = useAppSelector((state) => state.appState.isSmallScreen);

  const modalRoot = document.getElementById('root');
  if (!modalRoot) return null;

  const exchangeData = [
    {
      amount: 200,
      xu: 6,
    },
    {
      amount: 800,
      xu: 30,
    },
    {
      amount: 2500,
      xu: 98,
    },
    {
      amount: 5000,
      xu: 198,
    },
    {
      amount: 8000,
      xu: 328,
    },
    {
      amount: 16000,
      xu: 648,
    },
  ];

  return createPortal(
    <div
      style={{ display: props.isShown ? 'block' : 'none' }}
      className={styles['modal']}
    >
      <div className={styles['modal-overlay']}></div>
      <div
        className={`${styles['modal-content']} ${
          isSmallScreen && styles['modal-content-mobile']
        }`}
      >
        <div className={styles['modal-header']}>
          <p>Bảng quy đổi</p>
          <span onClick={() => props.setShowModal(false)}>X</span>
        </div>

        <table className={styles['modal-table']}>
          <thead>
            <tr>
              <th>BXu</th>
              <th>Ruby</th>
            </tr>
          </thead>

          <tbody>
            {exchangeData?.map((item, i) => (
              <tr key={i}>
                <td>{item?.amount}</td>
                <td>{item?.xu}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>,
    modalRoot
  );
}

export default ExchangeModal;
