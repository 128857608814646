// @ts-check

// #region "Imports"
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import type { TokenRequest } from '@payment-mfe/shared/models';
import { TokenService } from '@payment-mfe/shared/service';
// #endregion

//#region "Constants"
export const APP_FEATURE_KEY = 'app';
//#endregion

//#region "AsyncThunk"
export const checkLoggedinAsync = createAsyncThunk(
  'app/checkLoggedInAsync',
  async (payload: { authApiUrl: string }) => {
    const token = localStorage.getItem('token');
    if (token !== null) {
      const request: TokenRequest = { token: token };
      const apiUrl = payload.authApiUrl + '/auth/member/verify';
      const service = new TokenService();
      const response = await service.verifyAsync(apiUrl, request);
      return response;
    }
    return null;
  }
);
//#endregion

//#region "Entities"

export interface AppEntity {
  isSmallScreen: boolean;
  isLoggedIn: boolean;
  currentUser: {
    uid: string;
    username: string;
    name: string;
    avatarName: string;
    email: string | null;
    expiresTime: number;
    provider: string;
  } | null;
}

//#endregion

//#region "Entities"
const initialState: AppEntity = {
  isLoggedIn: false,
  currentUser: null,
  isSmallScreen: false
};
//#endregion

//#region "Slices"

export const appStateSlice = createSlice({
  name: APP_FEATURE_KEY,
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem('token');
      localStorage.setItem("userLoggedIn", "false");
      state.isLoggedIn = false
      state.currentUser = null
    },

    setIsSmallScreen: (state, action: PayloadAction<boolean>) => {
      state.isSmallScreen = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(checkLoggedinAsync.pending, (state) => {
      state = { ...initialState };
    });
    builder.addCase(checkLoggedinAsync.fulfilled, (state, action) => {
      if (action.payload !== null) {
        const authUser = action.payload;

        // validate expires time
        const currentTime = Date.now() / 1000 - 60 * 60; // 1 hours
        if (authUser.expiresTime > currentTime) {
          state.currentUser = {
            uid: authUser.id,
            username: authUser.username,
            name: authUser.name,
            avatarName: authUser.avatarName,
            email: authUser.email,
            expiresTime: authUser.expiresTime,
            provider: authUser.provider,
          };
          state.isLoggedIn = true;
        } else {
          state = { ...initialState };
        }
      } else {
        state = { ...initialState };
      }
    });
    builder.addCase(checkLoggedinAsync.rejected, (state) => {
      state = { ...initialState };
    });
  },
});

//#endregion

//#region "Actions"

export const { logout, setIsSmallScreen } = appStateSlice.actions;
export const actions = {
  ...appStateSlice.actions,
  checkLoggedinAsync,
}

//#endregion

export default appStateSlice.reducer;
