import {
  loadingForgotPasswardVerifyFailureModal,
  loadingForgotPasswardVerifySuccessModal,
  logout,
  useAppDispatch,
  useAppSelector,
} from '@payment-mfe/shared/store';
import actions from '@payment-mfe/shared/store';
import { Button, Form, FormRule, Input } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './verify-forgot-password.module.css';

/* eslint-disable-next-line */
export interface VerifyForgotPasswordProps {
  baseAuthApiUrl: string;
  baseNotificationApiUrl: string;
}

export function VerifyForgotPassword(props: VerifyForgotPasswordProps) {
  //#region "State"
  const { salt } = useParams();
  const dispatch = useAppDispatch();
  const state = useAppSelector(
    (state) => state.auth.forgotPassword.verify_email_status
  );
  //#endregion

  //#region "Reset password"
  /**
   * The validation rules of the new password
   */
  const newPasswordRules: FormRule[] = [
    { required: true, message: 'Mật khẩu mới không được để trống.' },
    {
      message: 'Mật khẩu mới phải có độ dài từ 6 tới 32 ký tự.',
      min: 6,
      max: 32,
    },
  ];

  /**
   * The validation rules of the confirm password
   */
  const confirmNewPasswordRules: FormRule[] = [
    { required: true, message: 'Nhập lại mật khẩu mới không được để trống.' },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue('password') === value) {
          return Promise.resolve();
        }
        return Promise.reject(
          new Error('Nhập lại mật khẩu mới không chính xác.')
        );
      },
    }),
  ];

  const [submitted, setSubmitted] = useState(false);

  const onFinish = (values: { password: string; confirm: string }) => {
    if (salt) {
      dispatch(
        actions.auth.forgotPassword.verifyForgotPasswordByEmail({
          baseAuthApiUrl: props.baseAuthApiUrl,
          data: {
            newPassward: values.password,
            confirmNewPassward: values.confirm,
            salt: salt,
          },
        })
      );

      setSubmitted(true);
    }
  };

  useEffect(() => {
    if (submitted === true) {
      if (state === 'successed') {
        dispatch(loadingForgotPasswardVerifySuccessModal());
        dispatch(logout());
      } else if (state === 'failed') {
        dispatch(loadingForgotPasswardVerifyFailureModal());
      }
    }
  }, [dispatch, submitted, state]);

  //#endregion
  return (
    <div className={styles['container']}>
      {/*  */}
      <div className={styles['form']}>
        <h3 className={styles['title']}>Đặt lại mật khẩu</h3>
        <Form
          name="forgot-password-handler"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item
            name={'password'}
            label="Mật khẩu mới"
            rules={newPasswordRules}
          >
            <Input.Password size="large" style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item
            name={'confirm'}
            label={'Nhập lại mật khẩu mới'}
            rules={confirmNewPasswordRules}
          >
            <Input.Password size="large" style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 24 }}>
            <Button type="primary" htmlType="submit">
              Đổi mật khẩu
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default VerifyForgotPassword;
