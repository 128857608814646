// @ts-check

// #region "Imports"
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { NotiModal } from '@payment-mfe/shared/commons';
import { GameResponse } from '@payment-mfe/shared/models';
import actions, {
  useAppDispatch,
  useAppSelector,
} from '@payment-mfe/shared/store';
import { useDebounce } from '@payment-mfe/shared/utils';
import {
  BottomMenu,
  PaymentFooter,
  PaymentHeader,
} from '@payment-mfe/shared/layout';

import styles from './home-page.module.css';
// #endregion

/* eslint-disable-next-line */
/**
 * The home page properties.
 * @since 1.0
 */
export interface HomePageProps {
  children?: JSX.Element | JSX.Element[];
  authApiUrl: string;
  paymentApiUrl: string;
  notificationApiUrl: string;
  productApiUrl: string;
  fileApiUrl: string;
}

export function HomePage(props: HomePageProps) {
  // #region "Redux"
  // dispatching to an action
  const dispatch = useAppDispatch();
  const allGames = useAppSelector((state) => state.home.game.all_games);
  const promoGames = useAppSelector((state) => state.home.game.promo_games);
  const isLoggedIn = useAppSelector((state) => state.appState.isLoggedIn);
  const isSmallScreen = useAppSelector((state) => state.appState.isSmallScreen);
  const banners = useAppSelector((state) => state.banner.getAll.banners);
  // #endregion

  //#region Component State
  const [isOpenLoginModal, setIsOpenLoginModal] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState('');
  const [searchGame, setSearchGame] = React.useState<GameResponse[]>(allGames);
  const debounceValue = useDebounce(searchValue, 800);
  //#endregion

  // #region Effects

  React.useEffect(() => {
    dispatch(
      actions.home.game.getAllGames({
        baseProductApiUrl: props.productApiUrl,
      })
    );
    dispatch(
      actions.home.game.getPromoGames({
        baseProductApiUrl: props.productApiUrl,
      })
    );
  }, [dispatch, props.productApiUrl]);

  React.useEffect(() => {
    setSearchGame(allGames);
  }, [allGames]);

  React.useEffect(() => {
    const game = allGames.filter((game) =>
      game.title.toLowerCase().includes(searchValue.toLocaleLowerCase())
    );
    setSearchGame(game);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceValue, allGames]);

  // Scroll to the top
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // #endregion
  const handleCheckLoggedIn = (id: string) => {
    if (isLoggedIn === false) {
      setIsOpenLoginModal(true);
      dispatch(
        actions.auth.login.setRedirectPath('/nap-game/sieu-hoc-vien-3d')
      );
    } else {
      dispatch(actions.home.game.setCurrentGameID(id));
    }
  };

  const handleLoginFromBanner = () => {
    setIsOpenLoginModal(true);
    dispatch(actions.auth.login.setRedirectPath('/nap-game/sieu-hoc-vien-3d'));
  };

  const handleSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleCheckBanner = (banner: {
    imageRedirectUrl: string;
    imageUrlTarget: string;
    isMobile: boolean;
    imageUrl: string;
  }) => {
    if (
      (isSmallScreen && banner.isMobile) ||
      (!isSmallScreen && !banner.isMobile)
    ) {
      return (
        <SwiperSlide className={styles['swiper-item']}>
          <img
            onClick={() => {
              if (banner.imageRedirectUrl) {
                if (
                  banner.imageRedirectUrl.includes(
                    'https://pay.boomgames.vn/nap-game/'
                  ) &&
                  !isLoggedIn
                ) {
                  handleLoginFromBanner();
                } else if (banner.imageUrlTarget) {
                  window.open(banner.imageRedirectUrl, banner.imageUrlTarget);
                } else {
                  window.location.replace(banner.imageRedirectUrl);
                }
              }
            }}
            src={`${props.fileApiUrl}${banner.imageUrl}`}
            alt=""
          />
        </SwiperSlide>
      );
    }
  };
  useEffect(() => {
    dispatch(
      actions.banner.getAll.fetchSearch({
        baseApiUrl: props.fileApiUrl,
        bannerGroup: 'homeBanner',
      })
    );
  }, [dispatch, props.fileApiUrl]);

  return (
    <>
      <PaymentHeader
        authApiUrl={props.authApiUrl}
        paymentApiUrl={props.paymentApiUrl}
        notificationApiUrl={props.notificationApiUrl}
      />
      <div className={styles['home-page']}>
        {/* Start banner */}
        <Swiper
          slidesPerView={1}
          loop={true}
          pagination={{
            clickable: true,
          }}
          navigation={!isSmallScreen}
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }}
          modules={[Pagination, Navigation, Autoplay]}
          className={styles['swiper-container']}
        >
          {banners && banners.map((banner) => handleCheckBanner(banner))}
        </Swiper>

        {/* End banner */}

        <div className={styles['wrapper-game-list']}>
          {/* Start promote game */}
          <div className={styles['promote-game']}>
            <div className={styles['promote-game-title']}>
              <h2>Game của bạn</h2>
            </div>
            <div className={styles['list-game']}>
              {promoGames?.map((item) => (
                <Link
                  onClick={() => handleCheckLoggedIn(item.id)}
                  to={isLoggedIn ? `/nap-game/${item?.slug}` : '/'}
                  key={item?.id}
                  className={styles['game-item']}
                >
                  <p>{item?.title}</p>
                  <div className={styles['game-card']}>
                    <img
                      src={`/assets/images${
                        item?.avatar_path || '/games/sieu-hoc-vien-3D.png'
                      }`}
                      alt={item?.title}
                    />
                    <button>Nạp game</button>
                  </div>
                </Link>
              ))}
            </div>
          </div>

          {/* End promote game */}

          {/* Start List game */}
          <div className={styles['release-game']}>
            <div className={styles['release-game__title']}>
              <div className={styles['release-game-header']}>
                <h2>Danh sách game</h2>
              </div>
              <div className={styles['search-game']}>
                <input
                  type="text"
                  placeholder="Nhập tên game"
                  onChange={handleSearchValue}
                />
                <img src="/assets/images/icons/search.svg" alt="" />
              </div>
            </div>
            <div className={styles['list-game']}>
              {searchGame?.map((item) => (
                <Link
                  onClick={() => handleCheckLoggedIn(item.id)}
                  to={isLoggedIn ? `/nap-game/${item?.slug}` : ''}
                  key={item?.id}
                  className={styles['game-item']}
                >
                  <p>{item?.title}</p>
                  <div className={styles['game-card']}>
                    <img
                      src={`/assets/images${
                        item?.avatar_path || '/games/sieu-hoc-vien-3D.png'
                      }`}
                      alt={item?.title}
                    />
                    <button>Nạp game</button>
                  </div>
                </Link>
              ))}
              <div className={styles['show-more-wrapper']}>
                <Link to="/danh-sach-game" className={styles['show-more']}>
                  Xem thêm
                </Link>
              </div>
            </div>
          </div>
          {/* End list game */}
        </div>
      </div>

      <PaymentFooter />
      <div></div>
      <NotiModal
        type="require_login"
        open={isOpenLoginModal}
        onCancle={() => {
          setIsOpenLoginModal(false);
        }}
        onCancleRequireLogin={() => {
          setIsOpenLoginModal(false);
          dispatch(actions.auth.login.resetRedirectPath());
        }}
      />
      <div className={styles['mobile-buffer']}></div>
      <BottomMenu />
    </>
  );
}

export default HomePage;
