import { ForgotPasswordRequest } from '@payment-mfe/shared/models';
import { ForgotPasswordService } from '@payment-mfe/shared/service';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

//#region "Contants"
const FORGOT_PASSWORD_FEATURE_KEY = 'forgot-password';
const ACTION_VERIFY_BY_EMAIL = FORGOT_PASSWORD_FEATURE_KEY + '/verify/by-email';
// const ACTION_VERIFY_BY_OTP = FORGOT_PASSWORD_FEATURE_KEY + '/verify/by-top';
//#endregion

//#region "Variables"
const service = new ForgotPasswordService();
//#endregion

// #region "Declaration AsyncThunk"

export const forgotPasswordByEmail = createAsyncThunk(
  'auth/forgotPassword_by_email',
  async (payload: {
    baseAuthApiUrl: string;
    request: ForgotPasswordRequest;
  }) => {
    const response = await service.forgotPasswordAsync(
      payload.baseAuthApiUrl + '/auth/forgot-password/by-email',
      payload.request
    );
    return response;
  }
);

export const verifyForgotPasswordByEmail = createAsyncThunk(
  ACTION_VERIFY_BY_EMAIL,
  async (payload: {
    baseAuthApiUrl: string;
    data: {
      newPassward: string;
      confirmNewPassward: string;
      salt: string;
    };
  }) => {
    return service.verifyForgotPasswordByEmail(
      payload.baseAuthApiUrl + '/auth/forgot-password/verification-email',
      payload.data
    );
  }
);

export const forgotPasswordByOTP = createAsyncThunk(
  'auth/forgotPassword_by_OTP',
  async (payload: {
    baseAuthApiUrl: string;
    request: {
      mobile: string;
    };
  }) => {
    const response = await service.forgotPasswordAsync(
      payload.baseAuthApiUrl + '/auth/forgot-password/by-otp',
      payload.request
    );

    return response;
  }
);

// Phone forgot password
export const doVerifyAsync = createAsyncThunk('auth/forgotPassword_verify_by_OTP', async (payload: { baseNotificationApiUrl: string, trackingId: string, otp: string }) => {
  return service.verifyOTP(payload.baseNotificationApiUrl, { trackingId: payload.trackingId, otp: payload.otp })
})

export const confirmPasswordAsync = createAsyncThunk('auth/forgotPassword_confirm', async (payload: { baseAuthApiUrl: string, tracking_id: string, otp: string, new_password: string }) => {
  return service.confirmPassword(payload.baseAuthApiUrl, { tracking_id: payload.tracking_id, otp: payload.otp, new_password: payload.new_password })
})

// #endregion

// #region "Redux State"
export interface ForgotPasswordState {
  status: 'idle' | 'loading' | 'successed' | 'failed' | 'server_error';
  error: string | null;

  saltNumberIsValid: boolean | null;
  verifyPasswardSucceed: boolean | null;
  salt_email_status: 'idle' | 'loading' | 'successed' | 'failed';
  verify_email_status: 'idle' | 'loading' | 'successed' | 'failed';

  otp_status: 'idle' | 'loading' | 'successed' | 'failed';
  trackingId: string | null;

  isOtpValid: boolean | null;
  otpErrorVerified: string | null;
  confirmPhoneStatus: 'idle' | 'loading' | 'successed' | 'failed';

  status_send_email: 'idle' | 'loading' | 'successed' | 'failed' | 'server_error'
}

const initialState: ForgotPasswordState = {
  status: 'idle',
  error: null,

  saltNumberIsValid: null,
  verifyPasswardSucceed: null,
  salt_email_status: 'idle',

  verify_email_status: 'idle',

  otp_status: 'idle',
  trackingId: null,

  isOtpValid: null,
  otpErrorVerified: null,
  confirmPhoneStatus: 'idle',

  status_send_email: 'idle'

};
// #endregion

// #region "Slice"

export const forgotPasswordStateSlice = createSlice({
  name: FORGOT_PASSWORD_FEATURE_KEY,
  initialState,
  reducers: {
    resetForgotPasswordState: (_) => initialState,
    resetOtpErrorVerified: (state) => {
      state.otpErrorVerified = null
    },
    resetTrackingId: (state) => {
      state.trackingId = null
    }
  },
  extraReducers: (builder) => {
    // #region forgot password by email
    builder.addCase(forgotPasswordByEmail.pending, (state) => {
      state.status_send_email = 'loading';
      state.error = null;
    });
    builder.addCase(forgotPasswordByEmail.rejected, (state) => {
      state.status_send_email = 'failed';
      state.error = 'send a request to forgot password by email failure';
    });
    builder.addCase(forgotPasswordByEmail.fulfilled, (state, action) => {
      if (action.payload) {
        state.status_send_email = 'successed';
        state.error = 'send a request to forgot password successed';
      } else {
        state.status_send_email = 'server_error';
        state.error =
          'send a request to forgot password by email failure by server error';
      }
    });
    // #endregion

    // #region Verigy Changes password by Email
    builder.addCase(verifyForgotPasswordByEmail.pending, (state) => {
      state.verify_email_status = 'loading';
      state.error = null;
      state.verifyPasswardSucceed = null;
    });
    builder.addCase(verifyForgotPasswordByEmail.rejected, (state) => {
      state.verify_email_status = 'failed';
      state.error = 'forgotPassward:verifyByEmail:failure: unexpected error';
      state.verifyPasswardSucceed = false;
    });
    builder.addCase(verifyForgotPasswordByEmail.fulfilled, (state, action) => {
      state.verifyPasswardSucceed = action.payload;
      if (action.payload) {
        state.verify_email_status = 'successed';
        state.error =
          'forgotPassward:verifyByEmail:successed: Changes the passward succeed';
      } else {
        state.verify_email_status = 'failed';
        state.error =
          'forgotPassward:verifyByEmail:failed: Changes the passward failed';
      }
    });
    // #endregion

    // #region Change password by OTP
    builder.addCase(forgotPasswordByOTP.pending, (state) => {
      state.otp_status = 'loading';
      state.error = null;
      state.status = 'idle';
    });
    builder.addCase(forgotPasswordByOTP.rejected, (state) => {
      state.otp_status = 'failed';
      state.error = 'send a request to forgot password by OTP failure';
      state.status = 'idle';
    });
    builder.addCase(forgotPasswordByOTP.fulfilled, (state, action) => {
      if (action.payload) {
        state.trackingId = action.payload.data.trackingId;
        state.otp_status = 'successed';
        state.error = 'send a request to forgot password by OTP successed';
        state.status = 'idle';
      } else {
        state.otp_status = 'failed';
        state.error = 'send a request to forgot password by OTP failure';
        state.status = 'idle';
      }
    });
    // #endregion

    // #region Verify OTP
    builder.addCase(doVerifyAsync.fulfilled, (state, action) => {
      state.status = 'successed';
      state.error = 'The verificated an one time passsword is success.';
      state.otpErrorVerified = action.payload.errCode
      state.isOtpValid = (action.payload.errCode === "00");
    });

    /**
     * A handler ot the verify one-time password failure.
     * @since 1.0
     */
    builder.addCase(doVerifyAsync.rejected, (state) => {
      state.status = 'failed';
      state.error = 'The verificated an one time passsword is failured.';
      state.isOtpValid = false;
    });

    /**
     * A handler of the during verification one time password process.
     * @since 1.0
     */
    builder.addCase(doVerifyAsync.pending, (state) => {
      state.status = 'loading';
      state.error = 'Make an request to verify the one time password.';
      state.isOtpValid = null;
    });
    // #endregion

    // #region ConfirmPassword
    builder.addCase(confirmPasswordAsync.fulfilled, (state, action) => {
      state.confirmPhoneStatus = 'successed';
      state.error = 'The confirm password is success.';
    });

    builder.addCase(confirmPasswordAsync.rejected, (state) => {
      state.confirmPhoneStatus = 'failed';
      state.error = 'The confirm password is failed.';
    });

    builder.addCase(confirmPasswordAsync.pending, (state) => {
      state.confirmPhoneStatus = 'loading';
      state.error = 'Make a confirm request.';
    });
    // #endregion
  },
});
// #endregion

//#region "Export actions"
export const actions = {
  ...forgotPasswordStateSlice.actions,
  forgotPasswordByEmail,
  verifyForgotPasswordByEmail,
  doVerifyAsync,
  confirmPasswordAsync,
  // verifyEmailSaltNumber,

  forgotPasswordByOTP,
};
//#endregion

// #region "Export default reducer"
export default forgotPasswordStateSlice.reducer;
// #endregion
