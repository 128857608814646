import { GameResponse } from '@payment-mfe/shared/models';
import { GameService } from '@payment-mfe/shared/service';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const GAME_FEATURE_KEY = 'home/game';

export const getAllGames = createAsyncThunk(
  '/game/all-games',
  async (payload: {
    baseProductApiUrl: string;
    // params: {
    //   order: string;
    //   sort: string;
    // };
  }) => {
    const service = new GameService();
    const response = await service.getListGame(
      payload.baseProductApiUrl + '/games?order=created_time&sort=asc'
    );
    return response;
  }
);

export const getPromoGames = createAsyncThunk(
  'game/promotion-games',
  async (payload: {
    baseProductApiUrl: string;
    // params: {
    //   order: string;
    //   sort: string;
    // };
  }) => {
    const service = new GameService();
    const response = await service.getListGame(
      payload.baseProductApiUrl + '/games?order=priority&sort=asc'
    );
    return response;
  }
);

// #region "States"

export interface GameState {
  currentGameID?: string
  status: 'idle' | 'loading' | 'successed' | 'failed';
  error: string | null;
  all_games: GameResponse[];
  promo_games: GameResponse[];
}

const initialState: GameState = {
  currentGameID: '',
  status: 'idle',
  error: null,
  all_games: [],
  promo_games: [],
};

// #endregion

// #region "Slice"

export const gameSlice = createSlice({
  name: GAME_FEATURE_KEY,
  initialState: initialState,
  reducers: {
    setCurrentGameID: (state, action: PayloadAction<string>) => {
      state.currentGameID = action.payload
    }
  },
  extraReducers: (builder) => {
    // #region All Games
    builder.addCase(getAllGames.pending, (state) => {
      state.status = 'loading';
    });

    builder.addCase(getAllGames.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.status = 'successed';
        state.error = 'load the list of game success';
        state.all_games = [];
        state.all_games.push(...action.payload);
      } else {
        state.status = 'failed';
        state.error = 'load the game failure due logic';
      }
    });

    builder.addCase(getAllGames.rejected, (state) => {
      state.status = 'failed';
      state.error = 'send a request to game service is failure';
    });
    // #endregion

    // #region Promotion Games
    builder.addCase(getPromoGames.pending, (state) => {
      state.status = 'loading';
    });

    builder.addCase(getPromoGames.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.status = 'successed';
        state.error = 'load the list of game success';
        state.promo_games = [];
        state.promo_games.push(...action.payload);
      } else {
        state.status = 'failed';
        state.error = 'load the game failure due logic';
      }
    });

    builder.addCase(getPromoGames.rejected, (state) => {
      state.status = 'failed';
      state.error = 'send a request to game service is failure';
    });
    // #endregion
  },
});

// #endregion

export const actions = {
  ...gameSlice.actions,
  getAllGames,
  getPromoGames,
};

// #region "Export Reducer"

export default gameSlice.reducer;

// #endregion
