import { useState, useEffect } from "react";

export const hideNumber = (number: string | number) => {

  return number.toString().replace(/^(\d{2})(\d+)(\d{2})$/, "$1******$3");
};

export const hideEmailVerified = (email: string) => {
  // Split the email address into username and domain parts
  const [username, domain] = email.split('@');

  // Hide a portion of the username with asterisks
  const hiddenUsername = username.slice(0, username.length - 3) + '***';

  // Recombine the username and domain with '@' and return the hidden email
  return `${hiddenUsername}@${domain}`;
};

export const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const formatWithCommas = (number: number, symbol: string) => {
  const parts = number.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, symbol);
  return parts.join(symbol);
};

export const calculateDiscount = (price: number, coin: number, rate: number) => {
  return {
    coinDiscount: (coin - price * rate),
    discountPercent: Math.ceil(100 - (price * rate / coin * 100))
  }
}
