// @ts-check

import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { BankService } from '@payment-mfe/shared/service';
import type { BankResponse } from '@payment-mfe/shared/service';

//#region "Imports"
//#endregion

//#region "Contants"
const APP_FEATURE_KEY = 'payment/bank';
const GET_ALL_BANK_ACTION_KEY = APP_FEATURE_KEY + '/getAll';
//#endregion

//#region "Service"
const service = new BankService();
//#endregion

//#region "AsyncThunk"
const getAll = createAsyncThunk(
  GET_ALL_BANK_ACTION_KEY,
  async (payload: { basePaymentApiUrl: string }) => {
    const response = service.getAll(payload.basePaymentApiUrl);
    return response;
  }
);
//#endregion

//#region "Adapter"
const adapter = createEntityAdapter<BankResponse>({
  selectId: (bank) => bank.id,
  sortComparer: (a, b) =>
    (a.priority.toString() + '_' + a.name).localeCompare(
      b.priority.toString() + '_' + b.name
    ),
});
//#endregion

//#region "State"
export interface BankState {
  status: 'idle' | 'loading' | 'successed' | 'failed';
  error: string | null;
}

const initialState: BankState = {
  status: 'idle',
  error: null,
};
//#endregion

//#region "Slice"
const bankStateSlice = createSlice({
  name: APP_FEATURE_KEY,
  initialState: adapter.getInitialState({
    ...initialState,
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAll.rejected, (state) => {
      state.status = 'failed';
      state.error =
        'Cannot get result from service. Please check your network.';
    });
    builder.addCase(getAll.pending, (state) => {
      state.status = 'loading';
      state.error = 'The list of bank in fetching process';
    });
    builder.addCase(getAll.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.status = 'successed';
        state.error = 'List of the bank is fetched.';

        adapter.removeAll(state);
        adapter.addMany(state, action.payload);
      } else {
        state.status = 'failed';
        state.error = 'The server return empty of list.';
      }
    });
  },
});
//#endregion

//#region "Exports"
export const actions = Object.assign({}, bankStateSlice.actions, {
  getAll,
});

export default bankStateSlice.reducer;
//#endregion
