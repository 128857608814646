import { BreadcrumbWrapper, ExchangeModal } from '@payment-mfe/shared/commons';
import actions, {
  loadingLoginModal,
  useAppDispatch,
  useAppSelector,
} from '@payment-mfe/shared/store';
import { formatWithCommas } from '@payment-mfe/shared/utils';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from '../payment.module.css';
import ReactGA from 'react-ga4';

interface PackageConfirmationProps {
  basePaymentApiUrl: string;
  productApiUrl: string;
}

export const PackageConfirmationMobile = (props: PackageConfirmationProps) => {
  const { slug, method } = useParams();
  //#region "Redux State"
  const dispatch = useAppDispatch();
  const selectedBank = useAppSelector((state) => state.payment.paymentUI.bank);
  const isLoggedIn = useAppSelector((state) => state.appState.isLoggedIn);

  // Game
  const allGames = useAppSelector((state) => state.home.game.all_games);
  const currentGame = allGames?.find((game) => game?.slug === slug);

  /**
   * The transaction was created.
   */
  const transactionId = useAppSelector(
    (state) => state.payment.paymentTransaction.transactionId
  );

  /**
   * The current transaction.
   */
  const transaction = useAppSelector(
    (state) => state.payment.paymentTransaction.transaction
  );

  /**
   * The payment direct url, in the case using payment gateway.
   */
  const paymentRedirectUrl = useAppSelector(
    (state) => state.payment.paymentGateway.redirectLink
  );

  /**
   * A callback function, fetch transaction information
   */

  //#region "Component State"
  const [showModal, setShowModal] = useState(false);
  //#endregion

  //#region "LocalStorage State"
  const rateString = localStorage.getItem('rate');
  const methodString = localStorage.getItem('method');
  const rateLocalValue = rateString ? JSON.parse(rateString) : null;
  const methodLocalValue = methodString ? JSON.parse(methodString) : null;
  //#endregion
  useEffect(() => {
    if (transactionId !== null) {
      dispatch(
        actions.payment.transaction.getTransaction({
          basePaymentApiUrl: props.basePaymentApiUrl,
        })
      );
    }
  }, [dispatch, transactionId, props.basePaymentApiUrl]);

  useEffect(() => {
    if (!allGames || allGames?.length === 0) {
      dispatch(
        actions.home.game.getAllGames({
          baseProductApiUrl: props.productApiUrl,
        })
      );
    }
  }, [allGames, dispatch, props.productApiUrl]);

  /**
   * Handler transaction result.
   */
  useEffect(() => {
    if (transaction !== null) {
      switch (transaction.extras.source) {
        case 'gw':
          dispatch(
            actions.payment.gateway.generatePaymentLink({
              basePaymentApiUrl: props.basePaymentApiUrl,
              transactionId: transaction.id,
            })
          );

          break;
        default:
          console.error(
            'The source "' + transaction.extras.source + '" is not supported'
          );
      }
    }
  }, [dispatch, transaction, props.basePaymentApiUrl]);

  /**
   * Redirect to payment gateway.
   *
   */
  useEffect(() => {
    if (
      methodLocalValue !== null &&
      methodLocalValue.code !== 'ib' &&
      paymentRedirectUrl !== null
    ) {
      window.location.href = paymentRedirectUrl;
    }
  }, [methodLocalValue, paymentRedirectUrl]);

  //#endregion

  useEffect(() => {
    if (method) {
      dispatch(
        actions.payment.rate.listAll({
          basePaymentApiUrl: props.basePaymentApiUrl,
          type: method,
        })
      );
    }
  }, [dispatch, method, props.basePaymentApiUrl]);

  /**
   * Create new a transaction.
   */
  const _createTransaction = (): void => {
    if (methodLocalValue !== null) {
      //#region "request builder"
      let request = {
        conversionRate: {
          rateId: rateLocalValue.id,
          currencyAmount: rateLocalValue.currencyAmount,
          gameAmount: rateLocalValue.gameAmount,
        },
      };

      switch (method) {
        case 'atm':
          request = Object.assign({}, request, {
            source: 'gw',
            gateway: {
              code: 'onepay',
              cardType: 'DOMESTIC',
            },
          });
          break;
        case 'visa':
          request = Object.assign({}, request, {
            source: 'gw',
            gateway: {
              code: 'onepay',
              cardType: 'INTERNATIONAL',
            },
          });
          break;
        case 'momo':
          request = Object.assign({}, request, {
            source: 'gw',
            gateway: {
              code: 'momo',
            },
          });
          break;
        case 'va':
          request = Object.assign({}, request, {
            source: 'ib',
            bankId: selectedBank?.id,
          });
          break;
        default:
          throw new Error(
            'The payment method "' +
              methodLocalValue?.code +
              '" is not supported.'
          );
      }
      //#endregion

      //#region "create new an credit transaction"
      dispatch(
        actions.payment.transaction.createTransaction({
          basePaymentApiUrl: props.basePaymentApiUrl,
          transaction: request,
        })
      );
      //#endregion
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 120,
      behavior: 'smooth',
    });
  });
  //#endregion

  const confirmationOnClick = (): void => {
    // check if the user is logged in.
    if (isLoggedIn === true) {
      // create new a transaction
      _createTransaction();

      // Push event payment click
      ReactGA.event({
        category: 'Button Click',
        action: `${methodLocalValue.name}`,
        label: 'Clicked on Payment',
      });
    } else {
      // show login popup
      dispatch(loadingLoginModal());
    }
  };

  return (
    <BreadcrumbWrapper link={`/nap-game/${slug}/${method}`} text="Quay lại">
      <div
        style={{
          width: '100%',
          marginTop: '24px',
          backgroundColor: '#fff',
          padding: '28px 8px',
          marginBottom: ' 20px',
          borderRadius: '10px',
        }}
      >
        <div className={styles['content-top']}>
          <div className={styles['content-top__user']}>
            <div className={styles['user-info']}>
              <img src="/assets/images/avatar_2.png" alt="" />
            </div>
            <div className={styles['user-coint']}>
              <h4>{currentGame?.title}</h4>
              <div className={styles['transaction']}>
                <div
                  onClick={() => setShowModal(true)}
                  className={styles['coint-exchange']}
                >
                  <img src="/assets/images/icons/exchange.svg" alt="" />
                  Bảng quy đổi
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles['container']}>
          <div style={{ position: 'relative' }}>
            <p className={styles['payment-confirm-title']}>Xác nhận gói nạp</p>
          </div>
          <div className={styles['payment-info']}>
            <div className={styles['payment-info__item']}>
              <span>Vật phẩm</span>
              <strong>
                {rateLocalValue &&
                  formatWithCommas(rateLocalValue.gameAmount, '.')}{' '}
                BXu
              </strong>
            </div>
            <div className={styles['payment-info__item']}>
              <span>Giá</span>
              <strong>
                {rateLocalValue !== null &&
                  formatWithCommas(rateLocalValue?.currencyAmount, '.')}
              </strong>
            </div>
            <div className={styles['payment-info__item']}>
              <span style={{ textAlign: 'left' }}>Phương thức thanh toán</span>
              <strong style={{ textAlign: 'right' }}>
                {methodLocalValue !== null &&
                  (methodLocalValue?.code === 'ib'
                    ? methodLocalValue.name + ' qua ' + selectedBank?.name
                    : methodLocalValue?.name)}
              </strong>
            </div>
            <button
              className={styles['payment-submit']}
              onClick={confirmationOnClick}
            >
              Xác nhận
            </button>
          </div>
        </div>
        <ExchangeModal isShown={showModal} setShowModal={setShowModal} />
      </div>
    </BreadcrumbWrapper>
  );
};
