import actions, {
  useAppDispatch,
  useAppSelector,
} from '@payment-mfe/shared/store';
import { calculateDiscount, formatWithCommas } from '@payment-mfe/shared/utils';
import { Col, Row, Skeleton } from 'antd';
import { useEffect, useState } from 'react';
import styles from '../payment.module.css';
import { RateResponse } from '@payment-mfe/shared/service';
import ReactGA from 'react-ga4';

interface PaymentPackageProps {
  type?: string;
  basePaymentApiUrl: string;
}

const PaymentRate = (props: PaymentPackageProps) => {
  //#region "Redux State"
  const dispatch = useAppDispatch();
  const rates = useAppSelector((state) => state.payment.paymentRate.entities);
  const selectedRate = useAppSelector(
    (state) => state.payment.paymentUI.currentRate
  );
  const selectedMethod = useAppSelector(
    (state) => state.payment.paymentUI.method
  );
  //#endregion

  const [showSkeleton, setShowSkeleton] = useState(true);
  const [scroll, setScroll] = useState(false);

  //#region "Effects"
  useEffect(() => {
    if (props.type) {
      dispatch(
        actions.payment.rate.listAll({
          basePaymentApiUrl: props.basePaymentApiUrl,
          type: props.type,
        })
      );
    }
  }, [dispatch, props.type, props.basePaymentApiUrl]);

  useEffect(() => {
    setShowSkeleton(Object.keys(rates).length === 0);
  }, [rates]);
  //#endregion

  useEffect(() => {
    if (window.innerWidth > 1200) {
      window.scrollTo({
        top: 1020,
        left: 0,
        behavior: 'smooth',
      });
    }
    setScroll(false);
  }, [scroll]);

  //#region "Functions"
  const handleSelectedRate = (rate: RateResponse) => {
    dispatch(
      actions.payment.ui.changesConversionRate({
        id: rate.id,
        currencyAmount: rate.currency_amount,
        gameAmount: rate.game_amount,
      })
    );
    dispatch(actions.payment.ui.selectedRate(rate.id));

    // Push event payment when method === va
    if (selectedMethod?.code === 'va') {
      ReactGA.event({
        category: 'Button Click',
        action: 'Thanh toán trực tiếp',
        label: 'Clicked on Payment',
      });
      setScroll(true);
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <div className={styles['package-title']}>
        <span>Chọn gói nạp</span>
      </div>

      {showSkeleton ? (
        <Skeleton style={{ minHeight: '300px' }} />
      ) : (
        <div className={styles['package-wrapper']}>
          <Row gutter={[20, 20]} style={{ width: '100%' }}>
            {[...Object.values(rates)].map((rate) => {
              if (typeof rate !== 'undefined') {
                return (
                  <Col xl={6} lg={8} md={8} sm={12} xs={12} key={rate.id}>
                    <button className={styles['button-wrapper']}>
                      <div
                        className={styles['package-group']}
                        onClick={() => handleSelectedRate(rate)}
                      >
                        <div className={styles['package-info']}>
                          <div>
                            <div className={styles['package-bxu']}>
                              {rate.game_amount &&
                                formatWithCommas(rate.game_amount, '.')}{' '}
                              BXu
                            </div>
                            <div className={styles['package-bonus']}>
                              {selectedMethod?.code === 'va' &&
                              calculateDiscount(
                                rate.currency_amount,
                                rate.game_amount,
                                0.01
                              ).coinDiscount !== 0
                                ? `Nhận thêm
                              ${
                                calculateDiscount(
                                  rate.currency_amount,
                                  rate.game_amount,
                                  0.01
                                ).coinDiscount
                              } BXu`
                                : ''}
                            </div>
                          </div>

                          {selectedMethod?.code === 'va' &&
                            calculateDiscount(
                              rate.currency_amount,
                              rate.game_amount,
                              0.01
                            ).coinDiscount !== 0 && (
                              <div className={styles['package-info__discount']}>
                                <img
                                  className={
                                    styles['package-info__discount-img']
                                  }
                                  src="/assets/images/icons/payments/star.png"
                                  alt=""
                                />
                                <div
                                  className={
                                    styles['package-info__discount-number']
                                  }
                                >
                                  {
                                    calculateDiscount(
                                      rate.currency_amount,
                                      rate.game_amount,
                                      0.01
                                    ).discountPercent
                                  }
                                  %
                                </div>
                              </div>
                            )}
                        </div>

                        {rate.id !== selectedRate && (
                          <div className={styles['package-currency']}>
                            {rate.currency_amount &&
                              formatWithCommas(rate.currency_amount, '.')}{' '}
                            VND
                          </div>
                        )}
                        {rate.id === selectedRate && (
                          <div className={styles['package-selected']}>
                            <img
                              src="/assets/images/icons/payments/tick.svg"
                              alt=""
                            />
                          </div>
                        )}
                      </div>
                    </button>
                  </Col>
                );
              }
              return null;
            })}
          </Row>
        </div>
      )}
    </div>
  );
};

export default PaymentRate;
