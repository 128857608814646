// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAUiKuoTTm7lpzIvOM8DWf7HvEcAiLsxWk',
  authDomain: 'nota-oauth.firebaseapp.com',
  projectId: 'nota-oauth',
  storageBucket: 'nota-oauth.appspot.com',
  messagingSenderId: '356789312767',
  appId: '1:356789312767:web:8047ce92728062088d00ca',
  measurementId: 'G-F0XLKK13NV',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
