import styles from './support-account.module.css';
import CollapseCustom from '../collapse-custom/collapse-custom';

export function SupportAccount() {
  const data = [
    {
      id: '1',
      label: 'Cách cập nhật thông tin tài khoản?',
      content: (
        <div className={styles['content']}>
          Bạn truy cập vào mục Thông tin tài khoản tại trang{' '}
          <a
            href="https://pay.boomgames.vn/"
            target="_blank"
            rel="noreferrer"
            style={{ color: '#FF6C00' }}
          >
            https://pay.boomgames.vn/
          </a>
          <br /> 1. Cập nhật / thay đổi Email
          <br />
          2. Cập nhật / thay đổi Số điện thoại
          <br />
          3. Cập nhật thông tin cá nhân
          <br />
          <i style={{ color: '#696969' }}>
            <span style={{ color: '#FF6C00' }}>Lưu ý:</span>
            <br />
            - Email/SĐT chưa được liên kết cùng tài khoản Boom ID khác.
            <br />- Nếu trước đó đăng nhập bằng tài khoản chơi ngay bạn cần
            chuyển đổi tài khoản sang tài khoản Boom ID.
          </i>
        </div>
      ),
    },
    {
      id: '2',
      label: 'Tài khoản chơi ngay là gì?',
      content: (
        <div className={styles['content']}>
          1. Tài khoản chơi ngay là tài khoản được tạo khi mới bắt đầu chơi
          game, không được liên kết với Email hay SĐT nào khác.
          <br /> 2. Tài khoản chơi ngay được liên kết với thiết bị nên có khả
          năng bị mất dữ liệu nếu xảy ra lỗi với thiết bị.
          <br />
          <i style={{ color: '#696969' }}>
            <span style={{ color: '#FF6C00' }}>Lưu ý:</span>
            <br /> - Nên liên kết tài khoản của mình với Email/SĐT khi mới bắt
            đầu. <br />- Chuyển đổi tài khoản từ Chơi Ngay sang Boom ID.
          </i>
        </div>
      ),
    },
    {
      id: '3',
      label: 'Làm sao để cập nhật thông tin tài khoản chơi ngay?',
      content: (
        <div className={styles['content']}>
          1. Tại giao diện Đăng nhập, bạn nhấn đăng xuất tài khoản, sau đó đăng
          nhập chơi ngay lại sẽ xuất hiện thông báo bảo vệ tài khoản chơi ngay.
          <br />
          <br />
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              src="/assets/images/support/bao-ve-tai-khoan.png"
              alt="Ảnh pop-up bảo vệ Tài Khoản"
            />
          </div>
          <br />
          2. Nhập Tên tài khoản, Mật khẩu đăng nhập game và Nhập lại mật khẩu.
          <br />
          3. Nhấn Xác Nhận để hoàn tất. Như vậy tài khoản của bạn đã được bảo
          vệ. Tài khoản này sẽ không bị mất khi chơi ở thiết bị khác hoặc lỡ tay
          xóa game.
        </div>
      ),
    },
    {
      id: '4',
      label: 'Để bảo vệ tài khoản cần làm gì?',
      content: (
        <div className={styles['content']}>
          1. Không đăng nhập vào game bằng thiết bị của người khác <br />
          <ul style={{ color: '#696969' }}>
            Bạn đăng nhập trên máy của người khác có rủi ro bị lộ thông tin tài
            khoản và bị hack nhân vật. Nếu bạn sử dụng thiết bị của người khác,
            lưu ý cần đăng xuất tài khoản game trước khi trả lại máy. Kiểm tra
            các thiết bị mà tài khoản của bạn hoạt động. <br />
          </ul>
          2. Bảo vệ tài khoản: <br />
          <ul style={{ color: '#696969' }}>
            Bạn nên sử dụng các công cụ bảo vệ tài khoản sẵn có vì khi mất các
            tài khoản Email/SĐT liên kết, trong trường hợp mất tài khoản hoặc
            quên mật khẩu bạn sẽ không lấy lại được tài khoản.
          </ul>
          <ul>
            <i style={{ color: '#696969' }}>
              <span style={{ color: '#FF6C00' }}>Lưu ý:</span>
              <br />- Mỗi Email/SĐT chỉ có thể liên kết được với duy nhất 1 tài
              khoản. <br />- Khi Email/SĐT của bạn đã liên kết với 1 tài khoản
              khác thì sẽ không thể liên kết thêm 1 tài khoản nữa. <br />
            </i>
          </ul>
          3. Không cho mượn tài khoản: <br />
          <ul style={{ color: '#696969' }}>
            Để tránh rủ ro mất tài khoản hoặc vật phẩm ingame, bạn TUYỆT ĐỐI
            KHÔNG chia sẻ, chơi chung tài khoản hoặc cho người khác mượn tài
            khoản.
          </ul>
        </div>
      ),
    },
    {
      id: '5',
      label: 'Quên mật khẩu game?',
      content: (
        <div className={styles['content']}>
          <span style={{ color: '#696969' }}>
            Nếu bạn quên mất mật khẩu đăng nhập thì làm theo các bước sau để lấy
            lại mật khẩu
          </span>
          <br />
          1. Vào giao diện đăng nhập game hoặc vào trang
          <a
            href="https://pay.boomgames.vn/"
            target="_blank"
            rel="noreferrer"
            style={{ color: '#FF6C00' }}
          >
            {' '}
            https://pay.boomgames.vn/
          </a>{' '}
          , nhấn nút <b>"Quên mật khẩu"</b> <br /> <br />
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              src="/assets/images/support/quen-mat-khau.png"
              alt="Ảnh quên mật khẩu"
            />
          </div>
          <ul>
            <br />
            <span style={{ color: '#696969' }}>
              Nhập Email/SĐT đã liên kết với tài khoản để nhận mã yêu cầu đổi
              lại mật khẩu
            </span>
            <br />
            <span style={{ color: '#696969' }}>- Kiểm tra mã xác thực </span>
            <br />
            <b>TH sử dụng Email: </b>
            <span style={{ color: '#696969' }}></span> Kiểm tra trong hòm thư
            của email (kiểm tra cả thư spam). <br />
            <b>TH sử dụng SĐT: </b>
            <span style={{ color: '#696969' }}>
              Kiểm tra mã sẽ được gửi về số điện thoại liên kết với tài khoản.
            </span>
            <br />
            <span style={{ color: '#696969' }}>
              - Làm theo hướng dẫn của hệ thống.
            </span>
          </ul>
          2. Tôi không liên kết email vào tài khoản, vậy có cách nào để lấy lại
          mật khẩu? <br />
          <ul>
            <span style={{ color: '#696969' }}>
              Với các trường hợp không liên kết email/sđt, bạn cần gửi Email tới{' '}
              <a style={{ color: '#FF6C00' }} href="mailto:hotro@boomstudio.vn">
                hotro@boomstudio.vn{' '}
              </a>
              hoặc fanpage CSKH của Boom Games trên Facebook kèm các thông tin
              sau: <br /> + Tên tài khoản: <br /> + Tên nhân vật:
              <br /> + Server: <br /> + Kèm theo toàn bộ hóa đơn giao dịch đã
              thực hiện vào tài khoản gần nhất
            </span>
            <br />
            <i style={{ color: '#696969' }}>
              <span style={{ color: '#FF6C00' }}>LƯU Ý:</span>
              <br /> - Với những trường hợp tài khoản không liên kết Email/SĐT
              và không cung cấp được hình ảnh giao dịch/hóa đơn theo yêu cầu sẽ
              không được cấp mật khẩu mới.
            </i>
          </ul>
        </div>
      ),
    },
  ];
  return (
    <div className={styles['container']}>
      {data.map((item, index) => (
        <CollapseCustom
          key={index}
          children={item.content}
          label={item.label}
        />
      ))}
    </div>
  );
}

export default SupportAccount;
