//@ts-check
//#region "Import modules"
import { BaseService } from '../base';
import type {
  RateGemRequest,
  RateGemResponse,
} from '@payment-mfe/shared/models';
//#endregion

export class RateGemService extends BaseService<RateGemResponse> {
  async getRatesByGameAysnc(
    apiUrl: string,
    req: RateGemRequest
  ): Promise<RateGemResponse | null> {
    const response: RateGemResponse = {
      id: '123',
      name: 'rate 1',
      currency_icon: '/assets/images/icons/knb.png',
      rates: [
        {
          in_game: { amount: 20, unit: 'KNB' },
          in_wallet: { amount: 20, unit: 'gem' },
        },
        {
          in_game: { amount: 50, unit: 'KNB' },
          in_wallet: { amount: 50, unit: 'gem' },
        },
        {
          in_game: { amount: 100, unit: 'KNB' },
          in_wallet: { amount: 100, unit: 'gem' },
        },
        {
          in_game: { amount: 200, unit: 'KNB' },
          in_wallet: { amount: 200, unit: 'gem' },
        },
      ],
    };

    return response;
  }
}
