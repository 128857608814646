import actions, {
  hidenModal,
  loadingVerifyEmailSuccess,
  loadingVerifyMobileSuccess,
  useAppDispatch,
  useAppSelector,
} from '@payment-mfe/shared/store';
import { Button, Form, FormRule, Input, Modal, Space } from 'antd';
import { useEffect, useState } from 'react';
import styles from './style.module.css';

interface PropsType {
  type: 'phone' | 'email' | 'change-email' | 'change-phone' | null;
  notificationApiUrl: string;
  emailAddress: string | null;
  mobileNumber: string | null;
  trackingID: string | null;
  authApiUrl: string;
  mobile?: string;
}

interface IFormValue {
  otp: string;
}

export function VerifyOTPModal({
  type,
  notificationApiUrl,
  authApiUrl,
  trackingID,
  emailAddress,
  mobileNumber,
}: PropsType) {
  // #region local state
  /**
   * Countdown button state
   */
  const [countdown, setCountdown] = useState<number>(60);

  /**
   * disable button state
   */
  const [disabled, setDisabled] = useState<boolean>(false);
  // #endregion

  // #region Form
  const [form] = Form.useForm();
  // #endregion

  // #region Redux
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.modalState.loading);
  const isOtpValid = useAppSelector(
    (state) => state.member.verifyOtp.isOtpValid
  );
  const resentTrackingID = useAppSelector(
    (state) => state.member.sendOtp.trackingId
  );

  // #endregion

  // #region Actions
  const renderMessage = () => {
    switch (type) {
      case 'phone':
        return (
          <>
            <p>
              Số điện thoại của bạn chưa được xác thực, hãy kiểm tra tin nhắn
              Zalo để lấy mã OTP, nếu không thấy tin nhắn vui lòng nhấn nút gửi
              lại.
            </p>
            <p style={{ marginTop: '2px' }}>
              <span>Lưu ý:</span> Mã OTP chỉ có hiệu lực trong 5 phút.
            </p>
          </>
        );

      case 'email':
        return (
          <p>
            Email của bạn chưa được xác thực, hãy kiểm tra email để lấy mã OTP,
            nếu không thấy thư vui lòng nhấn nút gửi lại
          </p>
        );
    }
  };

  const otpRules = [
    { pattern: /^\d{6}$/, message: 'Mã xác thực không đúng định dạng.' },
    {
      validator: async (_, values) => {
        if (values === '' || values === undefined) {
          return Promise.reject(new Error('Mã xác thực không được để trống.'));
        } else if (
          values !== undefined &&
          values !== '' &&
          isOtpValid === false
        ) {
          return Promise.reject(new Error('Mã xác thực không chính xác.'));
        }
        return Promise.resolve();
      },
    },
  ] as FormRule[];

  /**
   * Verify OTP
   */

  const handleVerifyOTP = async (value: IFormValue) => {
    if (trackingID) {
      await dispatch(
        actions.member.verifyOtp.doVerifyAsync({
          baseAuthApiUrl: authApiUrl,
          trackingId: resentTrackingID !== null ? resentTrackingID : trackingID,
          otp: value.otp,
        })
      );
      await dispatch(
        actions.member.profile.getAsync({
          baseAuthApiUrl: authApiUrl,
        })
      );
    }
  };

  useEffect(() => {
    if (isOtpValid) {
      dispatch(actions.member.verifyOtp.resetOtpStatus());
      form.resetFields();
    }
  }, [isOtpValid, form, dispatch]);

  useEffect(() => {
    if (isOtpValid && type === 'phone') {
      dispatch(hidenModal());
      dispatch(loadingVerifyMobileSuccess());
    } else if (isOtpValid && type === 'email') {
      dispatch(hidenModal());
      dispatch(loadingVerifyEmailSuccess());
    }
  }, [isOtpValid, type, dispatch]);

  // Resending OTP
  const handleResendOTP = () => {
    setDisabled(true);
    setCountdown(30);

    setTimeout(() => {
      setDisabled(false);
    }, 30000);

    if (type === 'phone' && mobileNumber) {
      dispatch(
        actions.member.sendOtp.doSendAsync({
          baseNotificationApiUrl: notificationApiUrl,
          emailOrMobile: mobileNumber.replace(/^0/, '84'),
          type: 'mobile',
        })
      );
    } else if (type === 'email' && emailAddress) {
      dispatch(
        actions.member.sendOtp.doSendAsync({
          baseNotificationApiUrl: notificationApiUrl,
          emailOrMobile: emailAddress,
          type: 'email',
        })
      );
    }
  };

  const handleCloseModal = () => {
    dispatch(hidenModal());
    dispatch(actions.member.sendOtp.doResetState());
    form.resetFields();
  };

  // #endregion

  // #region Effect
  /**
   * Countdown number
   */
  useEffect(() => {
    if (countdown > 0 && disabled) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [countdown, disabled]);

  useEffect(() => {
    handleResendOTP();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // #endregion

  // Validate opt wrong or true when submit otp
  useEffect(() => {
    form.validateFields();
  }, [isOtpValid, form]);

  return (
    <Modal
      centered
      footer={null}
      maskClosable={false}
      title=""
      open={
        loading === 'inputMobileOTP' && (type === 'phone' || type === 'email')
      }
      onCancel={handleCloseModal}
    >
      <div className={styles['change-phone-form']}>
        <h2>THÔNG BÁO</h2>
        {/* Message */}
        {renderMessage()}

        {/* Form */}
        <Form
          form={form}
          onFinish={handleVerifyOTP}
          labelAlign="left"
          layout="vertical"
          autoComplete={'off'}
        >
          <Form.Item
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            label=""
            name="otp"
            className="form-item"
            rules={otpRules}
          >
            <Input
              placeholder="Mã OTP"
              size="large"
              onFocus={() => dispatch(actions.member.verifyOtp.doResetState())}
            />
          </Form.Item>

          <Space
            direction={'horizontal'}
            style={{ width: '100%', justifyContent: 'center' }}
          >
            <Button
              type="primary"
              size="large"
              onClick={handleResendOTP}
              disabled={disabled}
            >
              {disabled ? `Gửi lại mã (${countdown}s)` : 'Gửi lại mã'}
            </Button>
            <Button className={styles['confirm-btn']} htmlType={'submit'}>
              Xác nhận
            </Button>
          </Space>
        </Form>
      </div>
    </Modal>
  );
}

export default VerifyOTPModal;
