// @ts-check

//#region "Import modules"
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { WalletService } from '@payment-mfe/shared/service';
import type { Wallet } from '@payment-mfe/shared/service';

//#endregion

//#region "Declaration constants"
const WALLET_FEATURE_KEY = 'wallet';
const ACTION_GET_WALLET_KEY = WALLET_FEATURE_KEY + '/getByMemberAsync';
//#endregion

//#region "AsyncThunk"
const getByMemberAsync = createAsyncThunk(
  ACTION_GET_WALLET_KEY,
  async (basePaymentApiUrl: string): Promise<Wallet | null> => {
    const service = new WalletService();
    const response = await service.getByMemberAsync(
      basePaymentApiUrl + '/get-wallet-by-member'
    );
    return response;
  }
);
//#endregion

//#region "State"
export interface WalletState {
  status: 'idle' | 'loading' | 'successed' | 'failed';
  error: string | null;
  wallet: Wallet | null;
}

const initialState: WalletState = {
  status: 'idle',
  error: null,
  wallet: null,
};
//#endregion

//#region "Slice"
const walletStateSlice = createSlice({
  name: WALLET_FEATURE_KEY,
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getByMemberAsync.pending, (state) => {
      state.status = 'loading';
      state.error = 'get a wallet information request is fetching';
    });
    builder.addCase(getByMemberAsync.rejected, (state) => {
      state.status = 'failed';
      state.error =
        'get a wallet information by member failure, due http server error.';
    });
    builder.addCase(getByMemberAsync.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.status = 'successed';
        state.wallet = action.payload;
      } else {
        state.status = 'failed';
        state.error = 'get a wallet infos is failure, due business logical.';
      }
    });
  },
});
//#endregion

//#region "Exports"
export const actions = {
  ...walletStateSlice.actions,
  getByMemberAsync,
};

export default walletStateSlice.reducer;
//#endregion
