import { BaseMessage } from '@payment-mfe/shared/models';

export type Paging<T> = {
  totalItems: number;
  itemsPerPage: number;
  page: number;
  totalPages: number;
  items: T[];
};

/**
 * Declaration simple http method: GET, POST, PUT, PATCH, DELETE
 * for all service need communication to RestfulAPI
 */
export abstract class BaseService<T> {
  private token: string | null = localStorage.getItem('token');

  private headers: [string, string][] = [
    ['accept', 'application/json'],
    ['content-type', 'application/json'],
  ];

  private buildHeaders(): [string, string][] {
    const token = localStorage.getItem('token');
    if (token) {
      return [
        ['accept', 'application/json'],
        ['content-type', 'application/json'],
        ['authorization', 'Bearer ' + token],
      ];
    }

    return [
      ['accept', 'application/json'],
      ['content-type', 'application/json'],
    ];
  }

  private buildFormDataHeaders(): [string, string][] {
    const token = localStorage.getItem('token');
    if (token) {
      return [
        ['content-type', 'multipart/form-data'],
        ['authorization', 'Bearer ' + token],
      ];
    }

    return [['content-type', 'multipart/form-data']];
  }

  constructor() {
    if (this.token) {
      this.headers.push(['authorization', 'Bearer ' + this.token]);
    }
  }

  /**
   * Make a POST request
   *
   * @param apiUrl An end-point of the RestfulAPI
   * @param body A request body
   * @returns The response from service
   */
  protected async postAsync(
    apiUrl: string,
    body: string
  ): Promise<BaseMessage<T>> {
    try {
      const response = await fetch(apiUrl, {
        headers: this.buildHeaders(),
        method: 'post',
        body: body,
      });

      const jsonBody = await response.json();
      return jsonBody as BaseMessage<T>;
    } catch (error: unknown) {
      if (error instanceof Error) {
        return {
          errCode: '50',
          errMessage: 'the post request to server error',
          data: null,
        };
      }

      throw error;
    }
  }

  /**
   * Make a POST request
   *
   * @param apiUrl An end-point of the RestfulAPI
   * @param formData A request form data
   * @returns The response from service
   */
  protected async postAsyncFormData(
    apiUrl: string,
    formData: string
  ): Promise<BaseMessage<T>> {
    try {
      const response = await fetch(apiUrl, {
        headers: this.buildFormDataHeaders(),
        method: 'post',
        body: formData,
      });

      const jsonBody = await response.json();
      return jsonBody as BaseMessage<T>;
    } catch (error: unknown) {
      if (error instanceof Error) {
        return {
          errCode: '50',
          errMessage: 'the post request to server error',
          data: null,
        };
      }

      throw error;
    }
  }

  /**
   * Make a PUT request
   *
   * @param apiUrl An end-point of the RestfulAPI resource.
   * @param body A request body
   * @returns The response from service
   */
  protected async putAsync(
    apiUrl: string,
    body: string
  ): Promise<BaseMessage<T>> {
    try {
      const response = await fetch(apiUrl, {
        headers: this.buildHeaders(),
        method: 'put',
        body: body,
      });

      const jsonBody = await response.json();
      return Promise.resolve(jsonBody as BaseMessage<T>);
    } catch (err: unknown) {
      if (err instanceof Error) {
        return Promise.reject({
          errCode: '50',
          errMessage: 'the post request to server error',
          data: null,
        });
      }

      throw err;
    }
  }

  /**
   * Make a GET request
   *
   * @param apiUrl An end-point of the Restful api.
   * @returns
   */
  protected async getAsync(apiUrl: string): Promise<BaseMessage<T>> {
    try {
      const response = await fetch(apiUrl, {
        headers: this.buildHeaders(),
        method: 'get',
      });
      const json = await response.json();
      return json as BaseMessage<T>;
    } catch (error: unknown) {
      if (error instanceof Error) {
        return {
          errCode: '50',
          errMessage:
            'the get request to server error. detail: ' +
            (error.message as string),
          data: null,
        };
      }
      throw error;
    }
  }

  /**
   * Send an GET request
   *
   * @param apiUrl An end-point of the Restful api.
   * @returns
   */
  protected async searchAsync(apiUrl: string): Promise<BaseMessage<Paging<T>>> {
    try {
      const response = await fetch(apiUrl, {
        headers: this.buildHeaders(),
        method: 'get',
      });
      const json = await response.json();
      return json as BaseMessage<Paging<T>>;
    } catch (err: unknown) {
      if (err instanceof Error) {
        return {
          errCode: '50',
          errMessage:
            'the get request to server error. detail: ' +
            (err.message as string),
          data: null,
        };
      }
      throw err;
    }
  }

  /**
   * List of the bank support by NotA.
   * @since 1.0
   *
   * @param apiUrl An end-point to get bank information.
   * @returns
   */
  protected async getAllAsync(apiUrl: string): Promise<BaseMessage<T[]>> {
    try {
      const response = await fetch(apiUrl, {
        headers: this.buildHeaders(),
        method: 'get',
      });
      const json = await response.json();
      return json as BaseMessage<T[]>;
    } catch (err: unknown) {
      if (err instanceof Error) {
        return {
          errCode: '50',
          errMessage:
            'the get request to server error. detail: ' +
            (err.message as string),
          data: null,
        };
      }

      throw err;
    }
  }
}
