import { BaseService } from '../base';

interface IVerifyInfoResponse {
  errCode: string;
  errMessage: string;
  data?: {
    trackingId: string;
  };
}

export class VerifyInfoService extends BaseService<IVerifyInfoResponse> {
  /**
   *
   * @param notificationApiUlorauthApiUrl
   * @param request
   * @returns
   */

  async sendOTP(
    notificationApiUrl: string,
    request: {
      type: 'email' | 'mobile';
      emailOrMobile: string | undefined;
    }
  ): Promise<IVerifyInfoResponse | null> {
    const response = await this.postAsync(
      notificationApiUrl + '/otp/send',
      JSON.stringify(request)
    );

    return response;
  }

  async verifyOTP(
    authApiUrl: string,
    request: {
      trackingId?: string;
      otp: string;
    }
  ) {
    const response = await this.postAsync(authApiUrl + '/protected/profiles/verify-otp', JSON.stringify(request));

    return response;
  }
}
