import actions, {
  checkLoggedinAsync,
  hidenModal,
  loadingForgotPasswordModal,
  loadingLoginFailureModal,
  loadingLoginSuccessModal,
  loadingRegisterModal,
  useAppDispatch,
  useAppSelector,
} from '@payment-mfe/shared/store';
import { Checkbox, Col, Form, Input, Modal, Row } from 'antd';
import { Rule } from 'antd/es/form';
import { useCallback, useEffect } from 'react';
import styles from './style.module.css';

interface LoginModalProps {
  authApiUrl: string;
}

export function LoginModal(props: LoginModalProps) {
  const CREDENTIAL_KEY = 'nt_credentials';

  //#region "Redux"
  const dispatch = useAppDispatch();
  const status = useAppSelector((state) => state.auth.login.status);
  const loading = useAppSelector((state) => state.modalState.loading);
  const isLoggedIn = useAppSelector((state) => state.appState.isLoggedIn);
  const redirectPath = useAppSelector((state) => state.auth.login.redirectPath);
  //#endregion

  // #region "Hooks"
  const loadDataCallback = useCallback(() => {
    const credentials = localStorage.getItem(CREDENTIAL_KEY);

    return credentials
      ? (JSON.parse(credentials) as { username: string; password: string })
      : null;
  }, []);

  //#region "Handler Login Form"
  const [form] = Form.useForm();

  const onFinish = (values: {
    username: string;
    password: string;
    remember: boolean;
  }) => {
    localStorage.removeItem(CREDENTIAL_KEY);
    if (values.remember === true) {
      // storage
      localStorage.setItem(
        CREDENTIAL_KEY,
        JSON.stringify({ username: values.username, password: values.password })
      );
    }

    dispatch(
      actions.auth.login.loginByUsernameAndPassword({
        authApiUrl: props.authApiUrl,
        loginRequest: {
          username: values.username,
          password: values.password,
        },
      })
    );
  };
  // #endregion

  useEffect(() => {
    const credentials = loadDataCallback();
    if (loading === 'login' && credentials)
      form.setFieldsValue({
        username: credentials.username,
        password: credentials.password,
        remember: true,
      });
  }, [loadDataCallback, isLoggedIn, loading, form]);
  // #endregion

  //#region "Validation rules"
  const usernameRules: Rule[] = [
    { required: true, message: 'Tên đăng nhập không được để trống.' },
    {
      min: 6,
      max: 32,
      message: 'Tên đăng nhập phải có độ dài từ 6 tới 32 ký tự.',
    },
    {
      message: 'Tên đăng nhập không được chứa những ký tự đặc biệt.',
      pattern: /[a-zA-Z0-9]{6,30}/,
    },
  ];

  const passwordRules: Rule[] = [
    { required: true, message: 'Mật khẩu đăng nhập không được để trống.' },
    {
      message: 'Mật khẩu đăng nhập phải có độ dài từ 6 tới 32 ký tự.',
      min: 6,
      max: 32,
    },
  ];

  //#endregion

  //#region "Async Login Effects"
  useEffect(() => {
    if (status === 'failed') {
      dispatch(loadingLoginFailureModal());
    } else if (status === 'successed') {
      dispatch(loadingLoginSuccessModal());
      dispatch(checkLoggedinAsync({ authApiUrl: props.authApiUrl }));
      form.resetFields();
    }
  }, [dispatch, status, props.authApiUrl, form]);
  // #endregion

  return (
    <div className={styles['login-modal']}>
      <Modal
        centered
        maskClosable={false}
        title=""
        open={loading === 'login'}
        onCancel={() => {
          form.resetFields();
          dispatch(hidenModal());
          redirectPath !== null &&
            dispatch(actions.auth.login.resetRedirectPath());
        }}
        footer={null}
      >
        <div className={styles['login-content']}>
          <div className={styles['login-logo']}>
            <img
              src="/assets/images/Logo_Boom.png"
              alt=""
              style={{ width: '102px' }}
            />
          </div>
          <h2>Đăng nhập</h2>
          <div className={styles['login-form']}>
            <Form form={form} onFinish={onFinish} layout="vertical">
              <Form.Item
                label="Tài khoản"
                name="username"
                rules={usernameRules}
              >
                <Input size="large" placeholder="Nhập BoomID của bạn" />
              </Form.Item>
              <Form.Item name="password" label="Mật khẩu" rules={passwordRules}>
                <Input.Password size="large" placeholder="Mật khẩu" />
              </Form.Item>

              <Row justify="space-between" align="middle">
                <Col span={12}>
                  <Form.Item name="remember" valuePropName="checked">
                    <Checkbox checked>Nhớ mật khẩu</Checkbox>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item>
                    <p
                      onClick={() => {
                        dispatch(loadingForgotPasswordModal());
                      }}
                      className={styles['forgot-password']}
                    >
                      Quên mật khẩu
                    </p>
                  </Form.Item>
                </Col>
              </Row>
              <button className={styles['login-btn']}>Đăng Nhập</button>
            </Form>
          </div>
        </div>

        <p
          className={styles['navigate-login']}
          onClick={() => {
            dispatch(loadingRegisterModal());
          }}
        >
          Chưa có tài khoản? <span>Đăng ký</span>
        </p>
      </Modal>
    </div>
  );
}

export default LoginModal;
