// @ts-check

//#region "Imports"
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  StorageService,
  TransactionService,
} from '@payment-mfe/shared/service';
//#endregion

//#region "Contants"
const APP_FEATURE_KEY = 'payment/transaction';
const CREATE_TRANSACTION_ACTION_KEY = APP_FEATURE_KEY + '/createTransaction';
const GET_TRANSACTION_ACTION_KEY = APP_FEATURE_KEY + '/getTransaction';
//#endregion

//#region "Variables"
const storageService = new StorageService();
const transactionService = new TransactionService();
//#endregion

//#region "AsyncThunk"
/**
 * Create new the credit transaction.
 * @since 1.0
 */
const createTransaction = createAsyncThunk(
  CREATE_TRANSACTION_ACTION_KEY,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (payload: { basePaymentApiUrl: string; transaction: any }) => {
    const response = await transactionService.createTransaction(
      payload.basePaymentApiUrl,
      payload.transaction
    );

    return response;
  }
);

/**
 * Get a transaction.
 * @since 1.0
 */
const getTransaction = createAsyncThunk(
  GET_TRANSACTION_ACTION_KEY,
  async (payload: { basePaymentApiUrl: string }) => {
    const transactionId = storageService.getTransaction();

    if (transactionId !== null) {
      const response = await transactionService.getTransaction(
        payload.basePaymentApiUrl,
        transactionId
      );

      return response;
    }

    return null;
  }
);

//#endregion

//#region "State"
/**
 * Declaration the states of transaction, management by Redux.
 * @since 1.0
 */
export interface TransactionState {
  status: 'idle' | 'loading' | 'successed' | 'failed';
  error: string | null;
  transactionId: string | null;
  transaction: {
    id: string;
    extras: {
      type: string;
      source: string;
      bankId: string;
      gateway: {
        code: string;
        cardType: string;
      } | null;
    };
  } | null;
}

/**
 * Initial default state of transaction.
 * @since 1.0
 */
const initialState: TransactionState = {
  status: 'idle',
  error: null,
  transactionId: null,
  transaction: null,
};
//#endregion

//#region "Slice"
const transactionStateSlice = createSlice({
  name: APP_FEATURE_KEY,
  initialState: initialState,
  reducers: {
    reset: (state) => {
      state.transaction = null;
      state.transactionId = null;
      storageService.clearTransaction();
    },
  },
  extraReducers: (builder) => {
    /**
     * Create new a transaction rejected.
     * @since 1.0
     */
    builder.addCase(createTransaction.rejected, (state) => {
      state.status = 'failed';
      state.error = 'create new the credit transaction is rejected.';
    });

    /**
     * Create a transaction is progress
     * @since 1.0
     */
    builder.addCase(createTransaction.pending, (state) => {
      state.status = 'loading';
      state.error = 'create new the credit transaction was starting';
    });

    /**
     * Create a transaction is receiving response
     * @since 1.0
     */
    builder.addCase(createTransaction.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.status = 'successed';
        state.error = 'create new the credit transaction is successed';
        state.transactionId = action.payload.id;
        // storage a transaction
        storageService.saveTransaction(action.payload.id);
      } else {
        state.status = 'failed';
        state.error = 'create new the credit transaction is failured.';
      }
    });

    /**
     * Get a transaction is reject.
     * @since 1.0
     */
    builder.addCase(getTransaction.rejected, (state) => {
      state.status = 'failed';
      state.error = 'Get a transaction is rejected. Check your network.';
    });

    /**
     * Get a transaction is progress
     * @since 1.0
     */
    builder.addCase(getTransaction.pending, (state) => {
      state.status = 'loading';
      state.error = 'Get a transaction is progress.';
    });

    /**
     * Get a transaction is received response
     * @since 1.0
     */
    builder.addCase(getTransaction.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.status = 'successed';
        state.error = 'Get a transaction is successed';

        state.transaction = {
          id: action.payload.id,
          extras: {
            type: action.payload.extras.type,
            source: action.payload.extras.source,
            bankId: action.payload.extras.bankId,
            gateway: action.payload.extras.gateway,
          },
        };
      } else {
        state.status = 'failed';
        state.error = 'Get a transaction failure.';
      }
    });
  },
});
//#endregion

//#region "Exports"
export const actions = {
  ...transactionStateSlice.actions,
  createTransaction,
  getTransaction,
};

export default transactionStateSlice.reducer;
//#endregion
