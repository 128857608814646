import { Modal } from 'antd';
import styles from './style.module.css';

interface RechargeGuideModalProps {
  open: boolean;
  onOk: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onCancle: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export function RechargeGuideModal({
  open,
  onOk,
  onCancle,
}: RechargeGuideModalProps) {
  return (
    <Modal
      centered
      title=""
      open={open}
      onOk={onOk}
      onCancel={onCancle}
      footer={null}
      className={styles['guide-modal']}
      width={'max-content'}
    >
      <div className={styles['guide-content']}>
        <h2>Hướng dẫn nạp</h2>
        <p>Chào mừng bạn đến với NOTA GAMES!</p>
        <p>
          - Để nạp game trên hệ thống của NOTA, đầu tiên bạn cần nạp BXu vào tài
          khoản.
        </p>
        <p>
          - Sau đó, bạn có thể vào game mình đang chơi và dùng BXu để mua các
          gói nạp.
        </p>
        <p>
          - Đặc biệt: BXu có thể dùng để nạp vào tất cả các game trên hệ thống
          của BOOM GAMES.
        </p>
      </div>
    </Modal>
  );
}

export default RechargeGuideModal;
