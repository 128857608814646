// @ts-check

//#region "imports"
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { ConfigGatewayService } from "@payment-mfe/shared/service";
import type { ConfigGatewayResponse } from '@payment-mfe/shared/service';
//#endregion

// #region "constants"
const FEATURE_KEY = "payment/config"
//#endregion

//#region "service"
const service = new ConfigGatewayService()
//#endregion

//#region "async thunk"
export const fetchGatewayConfig = createAsyncThunk(
  FEATURE_KEY + "/gateways", async (payload: {basePaymentApiUrl: string; }) => {
    const response = await service.getAll(payload.basePaymentApiUrl);

    return response;
  }
)
//#endregion

// #region "state"
export interface ConfigState {
  status: 'idle' | 'loading' | 'successed' | 'failed' | 'process';
  error: string | null;
  gateways: ConfigGatewayResponse[];
}

// initialize the default state of config
const initialState = {
  status: 'idle',
  error: null,
  gateways: [],
} as ConfigState;
// #endregion

// #region "slices"
export const configStateSlice = createSlice({
  name: FEATURE_KEY,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGatewayConfig.rejected, (state) => {
      state.status = 'failed';
      state.error = 'please check your network.'
    });
    builder.addCase(fetchGatewayConfig.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.status = 'successed';
        state.error = 'fetch the gateway configured success.';
        state.gateways = action.payload;
      } else {
        state.status = 'failed';
        state.error = 'fetch the gateway configured fail.'
      }
    });
    builder.addCase(fetchGatewayConfig.pending, (state) => {
      state.status = 'loading';
      state.error = 'fetch the gateway configure in progress.'
    }); 
  }
})
// #endregion

// #region "exports"
export const actions = {
  ...configStateSlice.actions,
  fetchGatewayConfig,
}

export default configStateSlice.reducer;
// #endregion