import { useEffect } from 'react';
import styles from './policy.module.css';

/* eslint-disable-next-line */
export interface PolicyProps {}

export function Policy(props: PolicyProps) {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div className={styles['container']}>
      <p className={styles['title']}>
        <strong>CHÍNH SÁCH BẢO MẬT (POLICY)</strong>
      </p>
      <br />
      <p>
        Các điều khoản và điều kiện của Chính sách Bảo mật hiển thị dưới đây
        (sau đây gọi là ‘Chính sách Bảo mật’) quy định các điều khoản và điều
        kiện được đồng ý bởi và giữa Công ty Cổ phần Boom Games (sau đây gọi tắt
        là ‘Công ty’, hoặc ‘Boom Games’, hoặc ‘chúng tôi’) và người sử dụng (sau
        đây gọi tắt là ‘người dùng’, hoặc ‘bạn’) về việc, hoặc có liên quan tới
        việc cung cấp, sử dụng dịch vụ trò chơi điện tử và các dịch vụ có liên
        quan bởi Công ty (sau đây gọi tắt là ‘Dịch vụ’). Chính sách Bảo mật này
        là một phần không thể tách rời của Điều khoản và Điều kiện của Công ty
        (được hiển thị tại một mục khác, bạn nên tham khảo đầy đủ Điều khoản và
        Điều kiện này). Điều khoản và Điều kiện cùng với Chính sách Bảo mật cấu
        thành những thỏa thuận thống nhất giữa Công ty và người dùng.
      </p>
      <br />
      <p>1. ĐỊNH NGHĨA:</p>
      <br />
      <p>
        Các từ ngữ và điều khoản sau sẽ có ý nghĩa như được định nghĩa dưới đây
        khi được sử dụng trong Chính sách Bảo mật:
      </p>
      <br />
      <p>
        1.1. ‘Trò chơi điện tử’ hoặc ‘game’ được hiểu là trò chơi điện tử có thể
        được cung cấp trực tuyến (online) hoặc không trực tuyến (offline). Trò
        chơi điện tử có thể được cung cấp bởi Boom Games, hoặc bởi các Đối tác
        của chúng tôi thông qua kênh phân phối/cung cấp của chúng tôi.
      </p>
      <br />
      <p>
        1.2. ‘Dịch vụ’ được hiểu là dịch vụ trò chơi điện tử và các dịch vụ khác
        bao gồm trong đó, có hoặc được cung cấp kèm theo, hoặc các dịch vụ khác
        có liên quan tới việc cung cấp, khai thác, sử dụng dịch vụ trò chơi điện
        tử mà thông qua đó người dùng có thể truy cập, tải về, và sử dụng được
        game theo nhưng mong muốn. Dịch vụ bao gồm nhưng không giới hạn tới Dịch
        vụ game, Dịch vụ hỗ trợ thanh toán,…
      </p>
      <br />
      <p>
        1.3. ‘Website’ được hiểu là trang thông tin điện tử có địa chỉ
        https://boomgames.vn, và các trang thông tin điện tử khác được sử dụng
        bởi Công ty để duy trì, cung cấp, vận hành dịch vụ, hoặc có liên quan
        tới việc cung cấp Dịch vụ. Website cũng được dùng để đề cập tới các
        trang thông tin điện tử khác có thể truy cập trực tuyến trên tài nguyên
        Internet bởi người dùng.
      </p>
      <br />
      <p>
        1.4. ‘Đối tác’ được hiểu là các đối tác, có thể là thể nhân hoặc pháp
        nhân mà có hợp tác với Boom Games trong việc cung cấp Dịch vụ, Nội
        dung,…
      </p>
      <br />
      <p>
        1.5. ‘Tài khoản’ có nghĩa là một tập hợp các chuỗi ký tự hoặc bất kỳ các
        dấu khác mà phát hành bởi Công ty tại thời điểm bắt đầu việc sử dụng
        Dịch vụ để định danh một người dùng duy nhất. Trong số đó, định danh
        (các định danh) được thiết lập bởi các công ty dựa trên ứng dụng của
        người dùng được gọi là ‘ID Người Dùng’.
      </p>
      <br />
      <p>2. QUY ĐỊNH CHUNG</p>
      <br />
      <p>
        Thông qua việc người dùng sử dụng Dịch vụ, hoặc truy cập vào website của
        Công ty, Công ty sẽ thu thập một số thông tin nhất định từ người dùng
        như quy định dưới đây. Công ty sẽ sử dụng nỗ lực cao nhất để cung cấp
        cho người dùng Dịch vụ ngày càng chất lượng hơn, bằng cách đưa ra cho
        người dùng các Dịch vụ phù hợp và liên quan nhất tới mục đích và nhu cầu
        sử dụng Dịch vụ của mỗi người dùng. Công ty sẽ bảo mật thông tin của
        người dùng một cách cẩn thận và chuẩn mực. Mỗi người dùng, thông qua
        việc sử dụng Dịch vụ, hoặc truy cập vào website của Công ty, đồng ý và
        cho phép Công ty được thu thập thông tin từ người dùng đó theo phương
        thức phù hợp, cũng như đồng ý cho phép Công ty có quyền sử dụng các
        thông tin thu thập được để sử dụng, bao gồm nhưng không giới hạn tới
        việc lưu trữ thông tin người dùng, thống kê, phân tích thói quen người
        dùng để cải thiện Dịch vụ, nâng cấp phương thức cung cấp Dịch vụ,… Trong
        trường hợp người dùng chấm dứt việc sử dụng Dịch vụ và có nhu cầu ngừng
        liên kết Dịch vụ với các nền tảng đã đăng ký trước đây, người dùng có
        thể yêu cầu Công ty vô hiệu hóa, xóa tài khoản và xóa thông tin cá nhân
        bất kỳ lúc nào bằng cách liên hệ trực tiếp với bộ phận hỗ trợ
        HOTRO@BOOMSTUDIO.VN Bảo mật rất quan trọng đối với Công ty và Công ty có
        thực hiện các biện pháp cần thiết để bảo đảm sự bảo mật cho người dùng.
        Công ty sẽ sử dụng khả năng nguồn lực và kỹ thuật của mình cũng như hợp
        tác với các bên cung cấp dịch vụ thứ ba khác để đảm bảo bảo mật thông
        tin cho người dùng một cách tốt nhất.
      </p>
      <br />
      <p>3. THU THẬP THÔNG TIN</p>
      <br />
      <p>
        Khi người dùng sử dụng Dịch vụ hoặc truy cập vào website của Công ty,
        Công ty có thể thu thập thông tin theo nhiều cách khác nhau để Dịch vụ
        có thể được hoạt động hiệu quả hơn và cung cấp các sản phẩm, dịch vụ
        ngày càng chất lượng hơn cho người dùng. Công ty thu thập thông tin bằng
        nhiều cách khác nhau khi người dùng sử dụng Dịch vụ, bao gồm các thông
        tin có từ các mẫu đăng ký trên website, các công nghệ như Log Data,
        cookies, e-mail truy vấn thông tin,…. Các hình thức thu thập thông tin
        mà Công ty sử dụng, bao gồm tới:
      </p>
      <br />
      <p>
        3.1. Thông tin nhận được từ người dùng/thông tin người dùng cung cấp cho
        Công ty:
      </p>
      <br />
      <p>
        Đây là các thông tin mà người dùng cung cấp cho Công ty thông qua việc
        đăng ký/tạo lập tài khoản. Công ty có thể yêu cầu người dùng cung cấp
        một số thông tin nhất định, bao gồm nhưng không giới hạn tới họ và tên
        đầy đủ, ngày tháng năm sinh, địa chỉ thường trú và/hoặc địa chỉ tạm trú,
        mã bưu chính của nơi cư trú, số điện thoại, các e-mail có thể sử dụng để
        trao đổi thông tin với người dùng, số thẻ tín dụng,… để phục vụ cho việc
        cung cấp Dịch vụ tới người dùng, cũng như đáp ứng yêu cầu của pháp luật
        về việc lưu trữ một số thông tin bắt buộc của mỗi người dùng.
      </p>
      <br />
      <p>3.2. Thông tin thu thập bởi Công ty:</p>
      <br />
      <p>
        Khi người dùng truy cập website và/hoặc sử dụng Dịch vụ, Công ty có thể
        sử dụng phương thức thu thập thông tin Log Data và Cookies, cũng như các
        phương thức/tính năng khác để thu thập một số thông tin của người dùng,
        bao gồm nhưng không giới hạn tới: địa chỉ IP, tên và đặc điểm, phiên bản
        của trình duyệt (browser) của người dùng, phiên bản và thông số của hệ
        điều hành của thiết bị của người dùng, thông số phần cứng và các phần
        mềm khác của thiết bị của người dùng, thói quen sử dụng Dịch vụ của
        người dùng, thông số và các thống kê về việc sử dụng Dịch vụ, về việc sử
        dụng các dịch vụ trực tuyến khác của người dùng,…
      </p>
      <br />
      <p>
        - Log Data: Khi người dùng truy cập website và/hoặc sử dụng Dịch vụ, hệ
        thống của Công ty sẽ tự động thu thập và lưu trữ các thông tin được gửi
        từ trình duyệt (browser) của người dùng (‘Log Data’). Log Data bao gồm
        nhưng không giới hạn tới các thông tin như địa chỉ IP (Internet
        Protocol) của người dùng, đặc điểm và phiên bản của browser của người
        dùng, danh sách website mà người dùng truy cập trước khi truy cập
        website của Công ty, các website của Công ty mà người dùng truy cập,
        thời gian mà người dùng sử dụng/truy cập/lưu lại tại mỗi website, các
        thông tin mà người dùng tìm kiếm/tham vấn trên các website,... và các
        thống kê khác.
      </p>
      <br />
      <p>
        - Cookies: Chúng tôi có sử dụng ‘cookies’ để thu thập thông tin. Cookie
        là một tập tin mà Công ty gửi tới thiết bị của người dùng để lưu trữ
        thông tin. Thông qua cookie, Công ty có thể lưu ID và mật khẩu đã đăng
        ký bởi người dùng để sử dụng cho việc đăng nhập tự động các lần sau đó
        (nếu người dùng cho phép), ngoài ra, Công ty có thể kích hoạt một số
        tính năng nhất định của Dịch vụ và website để hiểu rõ hơn về cách người
        dùng tương tác và sử dụng Dịch vụ. Cookies mà chúng tôi sử dụng có thể
        bao gồm (1) cookie được lưu lại trên máy của người dùng, và (2) cookie
        được xóa sau khi người dùng đăng xuất khỏi Dịch vụ và đóng trình duyệt.
        Người dùng có thể sử dụng các tính năng sẵn có của trình duyệt mà người
        dùng sử dụng để truy cập website và Dịch vụ, để chặn việc tiếp nhận và
        lưu trữ các cookies của Công ty. Tuy nhiên, cùng với đó, người dùng sẽ
        có thể không sử dụng Dịch vụ với đầy đủ các tính năng và tiện ích của
        Dịch vụ.
      </p>
      <br />
      <p>
        - Dữ liệu từ nền tảng mạng xã hội khác: Trong trường hợp người dùng muốn
        sử dụng các tài khoản mạng xã hội như: Facebook, Google, Apple, ... để
        đăng nhập vào hệ thống của Boom Games. Người dùng đồng ý rằng Công ty có
        thể sử dụng các trường dữ liệu được khai báo trên các mạng xã hội này
        bao gồm nhưng không giới hạn Họ tên, Email, Số điện thoại, ... để khởi
        tạo hoặc liên kết với tài khoản Boom Games.
      </p>
      <br />
      <p>4. MỤC ĐÍCH CỦA THU THẬP THÔNG TIN</p>
      <br />
      <p>
        Thông tin của người dùng mà Công ty thu thập như đã nêu ở trên chỉ được
        sử dụng cho mục đích vận hành, cải thiện và thay đổi Dịch vụ phù hợp hơn
        với nhu cầu của người dùng, cũng như để duy trì và nâng cao chất lượng,
        tính bảo mật của Dịch vụ, nhằm bảo vệ tối đa lợi ích của cả Công ty và
        của người dùng. Các thông tin mà Công ty nhận được và thu thập từ người
        dùng sẽ đóng góp một vai trò quan trọng trong việc cải thiện Dịch vụ,
        website, cũng như cung cấp cho Công ty nguồn thông tin quý báu về xu
        hướng sử dụng Dịch vụ, mối quan tâm của người dùng để từ đó chúng tôi
        phát triển, cập nhật các tính năng, tiện ích phù hợp cho nhu cầu của
        người dùng.
      </p>
      <br />
      <p>5. HIỂN THỊ THÔNG TIN VÀ TIẾT LỘ THÔNG TIN</p>
      <br />
      <p>5.1. Hiển thị thông tin:</p>
      <br />
      <p>
        Khi người dùng sử dụng Dịch vụ, chúng tôi có thể hiển thị một số lượng
        nhất định thông tin của người dùng tại trang tin riêng/mục tin riêng
        hoặc tại phần thông tin tài khoản/thông tin nhân vật của người dùng trên
        hệ thống Dịch vụ, mà thông qua đó không chỉ người dùng đó, mà các người
        dùng khác (dù đã đăng ký sử dụng Dịch vụ hoặc chưa đăng ký sử dụng Dịch
        vụ) có thể truy cập và tham khảo. Người dùng có thể tùy chỉnh và chọn
        lựa các thông tin sẽ hiển thị và các thông tin không hiển thị tại trang
        tin riêng/mục tin riêng đó. Người dùng cần cân nhắc kỹ càng khi quyết
        định sẽ hiển thị các thông tin nào.
      </p>
      <br />
      <p>5.2. Tiết lộ thông tin:</p>
      <br />
      <p>
        Công ty có thể tiết lộ thông tin mà Công ty nhận được, hoặc thu thập của
        người dùng trong các trường hợp sau:
      </p>
      <br />
      <p>
        a) Tiết lộ, cung cấp thông tin tới, bao gồm nhưng không giới hạn tới
        người lao động, cố vấn, công ty con, đối tác, nhà thầu, đơn vị thuê
        khoán dịch vụ,… của Công ty với mục đích để những đơn vị này có thể tiếp
        cận, sử dụng thông tin như vậy để thực hiện, hoàn thành các công việc mà
        Công ty yêu cầu/giao cho.
      </p>
      <br />
      <p>
        b) Để thực thi điều khoản của Điều khoản và Điều kiện, Chính sách Bảo
        mật hoặc chính sách của Điều khoản và Điều kiện riêng biệt khác.
      </p>
      <br />
      <p>
        c) Để trả lời các khiếu nại, khiếu kiện rằng bất kỳ thông tin nào trên
        website vi phạm quyền của bên thứ ba.
      </p>
      <br />
      <p>d) Để bảo vệ lợi ích hợp pháp của Công ty hoặc của người dùng khác.</p>
      <br />
      <p>
        e) Tuân thủ pháp luật, hoặc tuân thủ theo các yêu cầu hợp lý từ các thủ
        tục tố tụng có liên quan, hoặc của cơ quan quản lý Nhà nước có thẩm
        quyền.
      </p>
      <br />
      <p>6. THAY ĐỔI CHÍNH SÁCH BẢO MẬT</p>
      <br />
      <p>
        Công ty giữ quyền sửa đổi và/hoặc bổ sung bất kỳ điều khoản nào của
        Chính sách Bảo mật này vào bất kỳ thời điểm nào mà không cần phải thông
        báo trước. Những sự thay đổi và/hoặc sửa đổi như vậy sẽ có hiệu lực ngay
        khi đăng lên website, hoặc được hiển thị, hoặc được công bố bởi Công ty
        theo một phương thức khác. Nếu người dùng tiếp tục truy cập website hoặc
        tiếp tục sử dụng Dịch vụ sau khi các thay đổi và/hoặc sửa đổi được công
        bố, thì người dùng đã chấp nhận và đồng ý với các thay đổi và/hoặc sửa
        đổi đó.
      </p>
      <br />
      <p>7. TÍNH THỐNG NHẤT VỚI ĐIỀU KHOẢN VÀ ĐIỀU KIỆN</p>
      <br />
      <p>
        Chính sách Bảo mật này là một phần không thể tách rời của Điều khoản và
        Điều kiện, các nội dung điều khoản và điều kiện của Chính sách Bảo mật
        có thể được dẫn chiếu tới Điều khoản và Điều kiện và ngược lại.
      </p>
      <br />
      <p>
        Các quy định của Điều khoản và Điều kiện được áp dụng và giải thích, bổ
        sung cho Chính sách Bảo mật và ngược lại.
      </p>
      <br />
      <p>8. LUẬT ĐIỀU CHỈNH</p>
      <br />
      <p>
        Chính sách Bảo mật này được điều chỉnh và giải thích phù hợp với pháp
        luật Việt Nam. Nếu bất kỳ nội dung nào nào trong Chính sách Bảo mật bị
        xem như là bất hợp pháp, vô hiệu hoặc vì bất kỳ lý do nào không thể thực
        hiện được theo phán quyết hợp lý của Tòa án, hoặc cơ quan quản lý Nhà
        nước có thẩm quyền, thì nội dung đó sẽ được xem là tách rời khỏi Chính
        sách Bảo mật và không ảnh hưởng đến hiệu lực và tính thực thi của bất kỳ
        điều khoản còn lại nào của Chính sách Bảo mật.
      </p>
      <br />
      <p>9. CÁC CÂU HỎI VỀ CÁC ĐIỀU KHOẢN TRÊN</p>
      <br />
      <p>
        Nếu bạn có bất kỳ câu hỏi nào về các điều khoản hoặc trò chơi của chúng
        tôi bạn có thể liên hệ với chúng tôi qua email:
        <a href="mailto:hotro@notagames.vn"> hotro@boomstudio.vn</a>
      </p>
      <br />
      <p>Các điều khoản này được cập nhật ngày 07 tháng 11 năm 2023.</p>
      <br />
      <p>Bản quyền ©2023 Boom Games. Tất cả các quyền đều đã được bảo lưu.</p>
    </div>
  );
}

export default Policy;
