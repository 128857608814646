import actions, {
  hidenModal,
  loadingChangePasswordFailureModal,
  loadingChangePasswordSuccessModal,
  logout,
  useAppDispatch,
  useAppSelector,
} from '@payment-mfe/shared/store';
import { Form, FormRule, Input, Modal } from 'antd';
import { useEffect } from 'react';
import styles from './style.module.css';
import { useNavigate } from 'react-router-dom';

interface ChangePasswordProps {
  baseAuthApiUrl: string;
}

type ChangePasswordEntity = {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

export function ChangePasswordModal(props: ChangePasswordProps) {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  //#region "Redux State"
  const dispatch = useAppDispatch();
  const modalLoading = useAppSelector((state) => state.modalState.loading);
  const changesPasswordStatus = useAppSelector(
    (state) => state.member.password.status
  );

  //#endregion

  //#region "Actions"
  const onPasswordChanges = (values: ChangePasswordEntity): void => {
    dispatch(
      actions.member.password.changesPasswordAsync({
        baseAuthApiUrl: props.baseAuthApiUrl,
        password: values.currentPassword,
        newPassword: values.newPassword,
      })
    );
    form.resetFields();
    dispatch(logout());
    navigate('/');
  };
  //#endregion

  //#region "Effects"
  useEffect(() => {
    if (changesPasswordStatus === 'successed') {
      dispatch(loadingChangePasswordSuccessModal());
    } else if (changesPasswordStatus === 'failed') {
      dispatch(loadingChangePasswordFailureModal());
    }
    dispatch(actions.member.password.reset());
  }, [dispatch, changesPasswordStatus]);
  //#endregion

  //#region "Rules"
  const handlePasswordValidation = async (
    password: string
  ): Promise<boolean> => {
    try {
      const response = await fetch(
        props.baseAuthApiUrl + '/protected/check-password',
        {
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            authorization: 'Bearer ' + localStorage.getItem('token'),
          },
          body: JSON.stringify({ password: password }),
          method: 'put',
        }
      );

      const jsonBody = await response.json();
      const isPasswordMatchValidation = jsonBody.errCode !== '00';
      if (isPasswordMatchValidation) {
        return Promise.reject('Mật khẩu hiện tại không chính xác.');
      }
      return Promise.resolve(true);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  /**
   * The validation rules list of current password
   */
  const currentPasswordRules: FormRule[] = [
    {
      validator: async (_, values) => {
        if (!values) {
          return Promise.reject(
            new Error('Mật khẩu hiện tại không được để trống.')
          );
        } else if (values.length < 6 || values.length > 32) {
          return Promise.reject(
            new Error('Mật khẩu đăng nhập phải có độ dài từ 6 tới 32 ký tự.')
          );
        } else {
          const isPasswordValid = await handlePasswordValidation(values);
          if (!isPasswordValid) {
            return Promise.reject(
              new Error('Mật khẩu đăng nhập hiện tại không chính xác.')
            );
          }
        }
        return Promise.resolve();
      },
    },
  ];

  /**
   * The validation rules of the new password
   */
  const newPasswordRules: FormRule[] = [
    { required: true, message: 'Mật khẩu mới không được để trống.' },
    {
      message: 'Mật khẩu mới phải có độ dài từ 6 tới 32 ký tự.',
      min: 6,
      max: 32,
    },
  ];

  /**
   * The validation rules of the confirm password
   */
  const confirmNewPasswordRules: FormRule[] = [
    { required: true, message: 'Nhập lại mật khẩu mới không được để trống.' },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue('newPassword') === value) {
          return Promise.resolve();
        }
        return Promise.reject(
          new Error('Nhập lại mật khẩu mới không chính xác.')
        );
      },
    }),
  ];
  //#endregion

  return (
    <div className={styles['login-modal']}>
      <Modal
        centered
        title=""
        maskClosable={false}
        open={modalLoading === 'changePassword'}
        onCancel={() => {
          form.resetFields();
          dispatch(hidenModal());
        }}
        footer={null}
      >
        <div className={styles['login-content']}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="102"
            height="66"
            viewBox="0 0 102 66"
            fill="none"
          >
            <path
              d="M39.2113 29.9848V29.6531C39.2113 28.4262 38.1679 27.4289 36.8789 27.4289H26.3306V32.2091H36.8789C38.1655 32.2091 39.2113 31.2141 39.2113 29.9848Z"
              fill="#FF6C00"
            />
            <path
              d="M38.3065 40.0741H26.3306V44.8543H38.3065C39.5931 44.8543 40.639 43.8593 40.639 42.63V42.2984C40.639 41.0714 39.5956 40.0741 38.3065 40.0741Z"
              fill="#FF6C00"
            />
            <path
              d="M54.9402 25.9653C55.2759 25.6267 55.6504 25.3275 56.0615 25.0654C51.8392 16.217 42.4851 10.0637 31.6255 10.0637C16.8015 10.0637 4.78662 21.5237 4.78662 35.658C4.78662 49.7922 16.8015 61.2523 31.6255 61.2523C45.2724 61.2523 56.5406 51.5364 58.2382 38.9631C57.9634 38.8912 57.6959 38.8054 57.4381 38.708C56.453 38.3322 55.6212 37.8057 54.9402 37.1262C54.2568 36.4489 53.7363 35.6348 53.3788 34.6839C53.0188 33.7329 52.8388 32.6892 52.8388 31.5527C52.8388 30.4162 53.0188 29.3539 53.3788 28.41C53.7363 27.466 54.2568 26.6519 54.9402 25.9653ZM50.6766 47.0113C50.127 48.3357 49.3268 49.4768 48.2785 50.4301C47.2278 51.3857 45.9534 52.1163 44.4503 52.6242C42.9472 53.1345 41.2447 53.3873 39.3379 53.3873H15.6924V19.0606C15.6924 18.8031 15.9113 18.5944 16.1837 18.5944H37.9808C39.7903 18.5944 41.4369 18.8402 42.9229 19.3319C44.409 19.826 45.6859 20.5334 46.7511 21.4588C47.8189 22.3819 48.6482 23.5138 49.2465 24.8544C49.8448 26.195 50.144 27.7281 50.144 29.4514C50.144 30.6853 49.9421 31.7939 49.5384 32.7797C49.1347 33.7654 48.5777 34.6282 47.8675 35.3658C48.996 36.1984 49.8838 37.2143 50.5307 38.4157C51.1777 39.6172 51.5012 41.0204 51.5012 42.6207C51.5012 44.2211 51.2263 45.687 50.6766 47.0113Z"
              fill="#FF6C00"
            />
            <path
              d="M68.2075 28.9434C67.9205 28.1641 67.5071 27.5008 66.9647 26.9557C66.4223 26.4107 65.7583 25.9885 64.9752 25.694C64.192 25.3994 63.3043 25.251 62.312 25.251H60.9281C59.9114 25.251 59.0042 25.4041 58.204 25.7102C57.4039 26.0164 56.7253 26.4478 56.1708 27.0044C55.6162 27.5611 55.193 28.2244 54.9012 28.9921C54.6093 29.7598 54.4634 30.6134 54.4634 31.5481C54.4634 32.4828 54.6093 33.3224 54.9012 34.0971C55.193 34.8717 55.6162 35.5351 56.1708 36.0848C56.7253 36.6368 57.4039 37.0659 58.204 37.372C59.0042 37.6782 59.9114 37.8312 60.9281 37.8312H62.312C63.3043 37.8312 64.192 37.6828 64.9752 37.3882C65.7583 37.0937 66.4199 36.6716 66.9647 36.1265C67.5071 35.5815 67.923 34.9204 68.2075 34.1458C68.4945 33.3711 68.638 32.506 68.638 31.5481C68.638 30.5902 68.4945 29.7204 68.2075 28.9411V28.9434ZM64.2212 34.0808C63.708 34.6537 62.937 34.9413 61.9082 34.9413H61.2248C60.2325 34.9413 59.4639 34.663 58.9215 34.1063C58.3792 33.5497 58.1068 32.6962 58.1068 31.5504C58.1068 30.4046 58.3792 29.5488 58.9215 28.9852C59.4639 28.4239 60.2325 28.1409 61.2248 28.1409H61.9082C63.9634 28.1409 64.9922 29.2774 64.9922 31.5504C64.9922 32.6637 64.7344 33.5079 64.2212 34.0808Z"
              fill="#FF6C00"
            />
            <path
              d="M82.139 36.1288C82.6814 35.5838 83.0973 34.9228 83.3818 34.1481C83.6688 33.3734 83.8123 32.5083 83.8123 31.5504C83.8123 30.5925 83.6688 29.7227 83.3818 28.9434C83.0948 28.1641 82.6814 27.5008 82.139 26.9557C81.5966 26.4107 80.9327 25.9885 80.1495 25.694C79.3663 25.3994 78.4786 25.251 77.4863 25.251H76.1024C75.0857 25.251 74.1785 25.4041 73.3784 25.7102C72.5782 26.0164 71.8996 26.4478 71.3451 27.0044C70.7905 27.5611 70.3673 28.2244 70.0755 28.9921C69.7836 29.7598 69.6377 30.6134 69.6377 31.5481C69.6377 32.4828 69.7836 33.3224 70.0755 34.0971C70.3673 34.8717 70.7905 35.5351 71.3451 36.0848C71.8996 36.6368 72.5782 37.0659 73.3784 37.372C74.1785 37.6782 75.0857 37.8312 76.1024 37.8312H77.4863C78.4786 37.8312 79.3663 37.6828 80.1495 37.3882C80.9327 37.0937 81.5942 36.6716 82.139 36.1265V36.1288ZM77.085 34.9436H76.4015C75.4092 34.9436 74.6407 34.6653 74.0983 34.1087C73.5559 33.552 73.2835 32.6985 73.2835 31.5527C73.2835 30.4069 73.5559 29.5511 74.0983 28.9875C74.6407 28.4262 75.4092 28.1432 76.4015 28.1432H77.085C79.1402 28.1432 80.169 29.2797 80.169 31.5527C80.169 32.666 79.9111 33.5103 79.398 34.0831C78.8848 34.656 78.1138 34.9436 77.085 34.9436Z"
              fill="#FF6C00"
            />
            <path
              d="M97.4108 25.251L93.4853 34.1226L89.5427 25.251H84.9873V37.8336H88.6137V31.1329L91.7511 37.8336H95.2194L98.3739 31.1167V37.8336H102V25.251H97.4108Z"
              fill="#FF6C00"
            />
            <path
              d="M63.3626 44.4507V45.9606H65.4227C65.0992 46.4848 64.5398 46.7492 63.7493 46.7492H63.3189C62.6768 46.7492 62.1806 46.5706 61.8304 46.2088C61.4801 45.847 61.305 45.2996 61.305 44.5574C61.305 43.8152 61.4801 43.2655 61.8304 42.9014C62.1806 42.5372 62.6768 42.3563 63.3189 42.3563H63.7493C64.5568 42.3563 65.1138 42.6231 65.4227 43.1542H67.9229C67.6822 42.3192 67.2273 41.6675 66.5585 41.1967C65.8921 40.7258 65.0433 40.4892 64.0193 40.4892H63.1146C62.4579 40.4892 61.8741 40.589 61.361 40.7861C60.8478 40.9833 60.4124 41.2616 60.0549 41.6234C59.6974 41.9829 59.4225 42.412 59.2353 42.9083C59.0455 43.4047 58.9531 43.9544 58.9531 44.5597C58.9531 45.1651 59.048 45.7055 59.2353 46.2042C59.4225 46.7052 59.6974 47.1319 60.0549 47.4891C60.4124 47.8463 60.8502 48.1223 61.3683 48.3194C61.8839 48.5166 62.4725 48.6163 63.1267 48.6163H64.0193C64.8049 48.6163 65.4835 48.4772 66.055 48.1965L66.201 48.6163H67.9327L67.9205 44.4507H63.3602H63.3626Z"
              fill="#FF6C00"
            />
            <path
              d="M71.7244 40.4916L68.3413 48.6163H70.7054L71.2477 47.2363H74.5068L75.0492 48.6163H77.4254L74.0301 40.4916H71.722H71.7244ZM71.9506 45.4759L72.8773 43.1032L73.8161 45.4759H71.9482H71.9506Z"
              fill="#FF6C00"
            />
            <path
              d="M83.3209 46.2204L80.7745 40.4916H77.834V48.6163H80.1762V44.2884L82.1997 48.6163H84.4397L86.4779 44.2791V48.6163H88.8201V40.4916H85.8553L83.3209 46.2204Z"
              fill="#FF6C00"
            />
            <path
              d="M92.1351 45.4875H96.5009V43.6204H92.1351V42.3587H96.9776V40.4916H89.793V48.6163H97.1916V46.7492H92.1351V45.4875Z"
              fill="#FF6C00"
            />
            <path
              d="M52.6101 55.8528C47.004 61.1989 39.5518 64.1422 31.623 64.1422C23.6941 64.1422 16.242 61.1989 10.6358 55.8528C5.02971 50.5066 1.94573 43.4 1.94573 35.8389C1.94573 28.2777 5.03214 21.1712 10.6383 15.825C16.2444 10.4789 23.6966 7.53561 31.6254 7.53561C39.5542 7.53561 47.0064 10.4789 52.6125 15.825C55.1468 18.2418 57.1655 21.0228 58.6175 24.0403H60.7384C59.9771 22.3309 59.0529 20.705 57.9876 19.1742L59.2329 17.9426L49.3413 8.84142L48.0863 10.0823C43.2925 7.28976 37.6596 5.6778 31.6303 5.6778C14.16 5.68243 0 19.1835 0 35.8389C0 52.4943 14.16 65.9977 31.6254 65.9977C43.8762 65.9977 54.4999 59.3527 59.7533 49.6368L58.277 48.3194C56.8566 51.0748 54.9547 53.6169 52.6125 55.8504L52.6101 55.8528Z"
              fill="#FF6C00"
            />
            <path
              d="M60.1085 16.9476L65.165 13.6054C65.4568 14.0531 65.9749 14.3523 66.5683 14.3523C67.4804 14.3523 68.2222 13.6472 68.2222 12.7751C68.2222 11.903 67.4828 11.1979 66.5683 11.1979C65.7901 11.1979 65.1382 11.7128 64.9631 12.404L60.5147 12.3414L62.7669 6.07443C62.8666 6.09299 62.9687 6.10227 63.0733 6.10227C63.9878 6.10227 64.7272 5.39718 64.7272 4.52509C64.7272 3.65301 63.9878 2.94792 63.0733 2.94792C62.1588 2.94792 61.4195 3.65301 61.4195 4.52509C61.4195 4.7176 61.4584 4.90083 61.524 5.07246L55.1858 7.14598L54.6751 3.14507C55.5093 3.06621 56.1611 2.39359 56.1611 1.57717C56.1611 0.707408 55.4217 0 54.5073 0C53.5928 0 52.8534 0.705089 52.8534 1.57717C52.8534 2.03177 53.0553 2.43998 53.3763 2.72758L50.3774 7.87427L60.1085 16.95V16.9476Z"
              fill="#FF6C00"
            />
          </svg>
          <h2>Đổi mật khẩu</h2>
          <div className={styles['login-form']}>
            <Form
              form={form}
              onFinish={onPasswordChanges}
              layout="vertical"
              id="change-password-form"
            >
              <Form.Item
                name="currentPassword"
                rules={currentPasswordRules}
                label="Nhập mật khẩu hiện tại"
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                name="newPassword"
                rules={newPasswordRules}
                label="Nhập mật khẩu mới"
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                name="confirmNewPassword"
                rules={confirmNewPasswordRules}
                label="Nhập lại mật khẩu mới"
              >
                <Input.Password />
              </Form.Item>
              <button className={styles['login-btn']}>Xác nhận</button>
            </Form>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ChangePasswordModal;
