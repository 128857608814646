import styles from './vnpay-instruction.module.css';

/* eslint-disable-next-line */
export interface VnpayInstructionProps {}

export function VnpayInstruction(props: VnpayInstructionProps) {
  return (
    <div className={styles['vnpay-container']}>
      <h1>HƯỚNG DẪN THANH TOÁN VNPAY TRÊN WEBSITE</h1>
      <br />
      <div>
        Cổng thanh toán VNPAY là giải pháp thanh toán do Công ty Cổ phần Giải
        pháp Thanh toán Việt Nam (VNPAY) phát triển. Khách hàng sử dụng thẻ/tài
        khoản ngân hàng, tính năng QR Pay/VNPAY-QR được tích hợp sẵn trên ứng
        dụng Mobile Banking của các ngân hàng hoặc Ví điện tử liên kết để thanh
        toán các giao dịch và nhập mã giảm giá (nếu có)
      </div>
      <br />

      {/* Mobile banking */}
      <div>
        <strong>
          Quét mã VNPAY-QR trên 35+ Ứng dụng Mobile Banking và 15+ Ví điện tử
          liên kết
        </strong>
      </div>
      <br />

      <img src="/assets/images/vnpay/mobile-banking.png" alt="" />
      <br />

      {/* ATM */}
      <div>
        <strong>40+ Thẻ ATM/nội địa/tài khoản ngân hàng</strong>
      </div>
      <br />

      <img src="/assets/images/vnpay/atm.png" alt="" />
      <br />

      {/* Visa */}
      <div>
        <strong>4 Thẻ thanh toán quốc tế</strong>
      </div>
      <br />

      <img src="/assets/images/vnpay/visa.png" alt="" />
      <br />

      <div>
        <strong>Các phương thức thanh toán qua VNPAY</strong>
      </div>
      <br />
      <img src="/assets/images/vnpay/vn-pay-method.png" alt="" />
      <br />

      <div>
        <strong>
          1. Phương thức thanh toán qua “Ứng dụng thanh toán hỗ trợ VNPAY-QR”
        </strong>
        <br />
        <br />

        <div>
          <strong>Bước 1: </strong>
          <span>
            Quý khách lựa chọn sản phẩm, dịch vụ và chọn Thanh toán ngay hoặc
            Đặt hàng Tại trang thanh toán, vui lòng kiểm tra lại sản phẩm đã
            đặt, điền đầy đủ thông tin người nhận hàng, chọn phương thức thanh
            toán VNPAY và nhấn nút “Đặt hàng ngay”.
          </span>
        </div>
        <br />

        <div>
          <strong>Bước 2: </strong>
          <span>
            Màn hình thanh toán chuyển sang giao diện cổng thanh toán VNPAY.
            Chọn phương thức “Ứng dụng thanh toán hỗ trợ VNPAY-QR”
          </span>
        </div>
        <br />

        <div>
          <strong>Bước 3: </strong>
          <span>
            Hệ thống hiển thị mã QR cùng với số tiền cần thanh toán, Quý khách
            kiểm tra lại số tiền này. Sử dụng ứng dụng ngân hàng (theo danh sách
            liệt kê), chọn “Quét Mã” và tiến hành quét mã QR trên màn hình thanh
            toán website
          </span>
          <br />
          <i>
            *Lưu ý: Mã QR có hiệu lực trong 15 phút Để quá trình thanh toán
            thành công, khách hàng vui lòng tham khảo trước các điều kiện và
            thao tác quét mã trên điện thoại để sẵn sàng, tránh sự cố hết thời
            gian ảnh hưởng đến thanh toán và mã khuyến mại của quý khách.
          </i>
        </div>
        <br />

        <div>
          <strong>Bước 4: </strong>
          <span>
            Kiểm tra thông tin, nhập mã giảm giá (nếu có) và hoàn tất thanh toán
          </span>
          <br />
          <span>
            Khi thực hiện thanh toán hoàn tất Quý khách sẽ nhận được thông báo
            xác nhận đơn hàng đặt hàng thành công tại website
          </span>
          <br />
          <br />
          <br />

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src="/assets/images/vnpay/qr-pay.png" alt="" />
          </div>
          <br />
          <br />
        </div>
      </div>

      <div>
        <strong>
          2. Phương thức thanh toán qua “Thẻ nội địa và tài khoản ngân hàng”
        </strong>

        <br />
        <br />

        <div>
          <strong>Bước 1: </strong>
          <span>
            Quý khách lựa chọn sản phẩm, dịch vụ và chọn Thanh toán ngay hoặc
            Đặt hàng Tại trang thanh toán, vui lòng kiểm tra lại sản phẩm đã
            đặt, điền đầy đủ thông tin người nhận hàng, chọn phương thức thanh
            toán VNPAY và nhấn nút “Đặt hàng ngay”.
          </span>
        </div>
        <br />

        <div>
          <strong>Bước 2: </strong>
          <span>
            Màn hình thanh toán chuyển sang giao diện cổng thanh toán VNPAY.
            Chọn phương thức “Thẻ nội địa và tài khoản ngân hàng” và chọn ngân
            hàng muốn thanh toán thẻ trong danh sách
          </span>
        </div>
        <br />

        <div>
          <strong>Bước 3: </strong>
          <span>
            Màn hình thanh toán chuyển sang giao diện cổng thanh toán VNPAY.
            Chọn phương thức “Thẻ nội địa và tài khoản ngân hàng” và chọn ngân
            hàng muốn thanh toán thẻ trong danh sách
          </span>
          <br />
          <i>*Lưu ý: Giao dịch sẽ hết hạn sau 15 phút </i>
        </div>
        <br />
        <div>
          <strong>Bước 4: </strong>
          <span>
            Khi thực hiện thanh toán hoàn tất Quý khách sẽ nhận được thông báo
            xác nhận đơn hàng đặt hàng thành công tại website
          </span>
          <br />
          <br />
          <br />

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src="/assets/images/vnpay/confirm-atm.png" alt="" />
          </div>
        </div>
        <br />
        <br />
      </div>

      <div style={{ width: '100%' }}>
        <strong>
          3. Phương thức thanh toán qua “Thẻ thanh toán quốc tế (Visa,
          MasterCard, JCB, UnionPay)”
        </strong>
        <br />

        <div>
          Tương tự như phương thức thanh toán “Thẻ nội địa và tài khoản ngân
          hàng”
        </div>
      </div>
      <br />
      <div style={{ width: '100%' }}>
        <strong>4. Phương thức thanh toán qua “Ví điện tử VNPAY”</strong>
        <br />

        <div>
          Tương tự như phương thức thanh toán “Ứng dụng thanh toán hỗ trợ
          VNPAY-QR
        </div>
      </div>

      <div style={{ width: '100%' }}>
        <span>---------------------</span>
        <div></div>
        <strong>KÊNH HỖ TRỢ VNPAY</strong>

        <div>- Tổng đài: *3388 hoặc 1900 55 55 77</div>
        <div>
          - Zalo OA:
          <a
            href="https://zalo.me/4134983655549474109"
            target="_blank"
            rel="nor noreferrer"
          >
            {' '}
            zalo.me/4134983655549474109
          </a>
        </div>
        <div>
          - Email:
          <a href="mailto:hotro@vnpay.vn"> hotro@vnpay.vn</a>
        </div>
        <div>
          - Fanpage:
          <a
            href="https://www.facebook.com/VNPAYQR.vn"
            target="_blank"
            rel="nor noreferrer"
          >
            {' '}
            facebook.com/VNPAYQR.vn
          </a>
        </div>
      </div>
    </div>
  );
}

export default VnpayInstruction;
