import actions from './lib';

export * from './lib/hook';
export { default as store } from './lib/store';
export default actions;

//#region For App Slice
export { checkLoggedinAsync, logout } from './lib/app.slice';
//#endregion

//#region For Modal Module
export {
  hidenModal,
  resetLoading,
  loadingChangePasswordFailureModal,
  loadingChangePasswordModal,
  loadingChangePasswordSuccessModal,
  loadingForgotPasswordModal,
  loadingForgotPasswordFailureModal,
  loadingForgotPasswordSuccessModal,
  loadingLoginModal,
  loadingLoginFailureModal,
  loadingLoginSuccessModal,
  loadingRegisterModal,
  loadingRegisterFailureModal,
  loadingRegisterSuccessModal,
  loadingUpdateProfileFailureModal,
  loadingUpdateProfileSuccessModal,
  loadingForgotPasswardVerifyFailureModal,
  loadingForgotPasswardVerifySuccessModal,
  loadingOAuth2FailureModal,
  loadingForgotPassByOTPFailureModal,
  loadingForgotPassByOTPSuccessModal,
  loadingForgotPasswordServer_errModal,
  loadingForgotPasswordEmailVerifyFailedModal,
  // Verify for member profile
  loadingInputMobileOTP,
  loadingChangeEmailSuccess,
  loadingChangeMobileSuccess,
  loadingVerifyMobileSuccess,
  loadingVerifyEmailSuccess,
  // other errors, server errors
  loadingServerError,
  loadingComingSoon,
} from './lib/modal.slice';
//#endregion