// @ts-check

//#region "Import modules"
import { BaseService } from '../base';
//#endregion

//#region "types"
export type GatewayVerifyRequest = {
  transactionId: string;
  queryParam: string;
};

export type GatewayVerifyResponse = {
  transactionId: string;
  status: string;
};
//#endregion

//#region "Main class"
export class GatewayVerifyService extends BaseService<GatewayVerifyResponse> {
  /**
   * @since 1.0
   * Make a verify payment gateway responsed.
   *
   * @param apiUrl An end-point of the NotA payment system, to do verifying response
   *   received from payment gateway.
   * @param req Data of response need to verifying.
   * @returns The object contains data about transaction.
   */
  async verifyPayment(
    basePaymentApiUrl: string,
    req: GatewayVerifyRequest
  ): Promise<GatewayVerifyResponse | null> {
    const baseResponse = await this.postAsync(
      basePaymentApiUrl,
      JSON.stringify(req)
    );

    if (baseResponse.errCode === '00') {
      return baseResponse.data;
    }

    return null;
  }
}
//#endregion
