import styles from '../guide-page.module.css';

export function MomoTab() {
  return (
    <>
      <div className={styles['guide-content__step']}>
        <p>
          <span>Bước 1:</span> Đăng nhập trang{' '}
          <a
            href=" https://pay.boomgames.vn/nap-game/sieu-hoc-vien-3d"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#1139C6' }}
          >
            https://pay.boomgames.vn/nap-game/sieu-hoc-vien-3d
          </a>
        </p>
      </div>
      <div className={styles['guide-content__step']}>
        <p>
          <span>Bước 2:</span> Chọn phương thức thanh toán Ví Momo và Mệnh giá
          nạp ={'>'} Nhấn Xác Nhận
        </p>
        <img src="/assets/images/guide/momo/momo-2.png" alt="" />
      </div>
      <div className={styles['guide-content__step']}>
        <p>
          <span>Bước 3:</span> Kiểm tra thông tin đơn hàng, tiến hành quét mã QR
          để thanh toán. ={'>'} Sau khi thanh toán thành công, hệ thống tự động
          cộng BXu vào Tài khoản.
        </p>
        <img src="/assets/images/guide/momo/momo-3.png" alt="" />
      </div>
      <div className={styles['guide-content__step']}>
        <p>
          <span>Bước 4:</span> Đăng nhập game, chọn Cửa Hàng, vào mục Ruby và
          thực hiện mua gói ingame. Nếu tài khoản đủ BXu, hệ thống sẽ tự động
          trừ BXu trong tài khoản để thanh toán.
        </p>
        <img src="/assets/images/guide/momo/momo-4.png" alt="" />
      </div>
    </>
  );
}

export default MomoTab;
