import { combineReducers } from '@reduxjs/toolkit';
import forgotPasswordReducer, { actions as forgotPassword} from './forgot-password.slice';
import loginReducer, { actions as login} from './login.slice';
import registerReducer, { actions as register} from './register.slice';


export const actions = {
  forgotPassword,
  login,
  register,
};

export default combineReducers({
  forgotPassword: forgotPasswordReducer,
  login: loginReducer,
  register: registerReducer
})

