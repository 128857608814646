/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable prefer-const */

// 970416
import { crc16ccitt } from "crc";

export class QrCodeHelper {

  generateQrCode(bnbId: string, accountNumber: string, amount: number, description: string) {
    // const {bnbId, accountNumber, amount, description} = payload;

    // #region "default value"
    /**
     * Payload format indicator.
     * @since 1.0
     */
    const pfi = '000201';
    /**
     * Point of initiation method.
     * @since 1.0
     */
    const poim = '010212';
    // #endregion

    // #region "consumer account information"
    const cai = (() => {
      const qrPrefix = '0010A000000727';
      const qrSuffix = '0208QRIBFTTA';

      const qrBnbId = '0006' + bnbId;
      const qrAccountNumber = '01' + ('0' + accountNumber.length).slice(-2) + accountNumber;
      const qrBankAndAccount = '01' + (qrBnbId + qrAccountNumber).length + qrBnbId + qrAccountNumber;

      return '38' + (qrPrefix + qrBankAndAccount + qrSuffix).length + qrPrefix + qrBankAndAccount + qrSuffix;
    })();
    // #endregion

    // #region "transaction information"
    /**
     * transaction currency
     * @since 1.0
     */
    const tc = '5303704';

    /**
     * transaction amount
     * @since 1.0
     */
    const ta = '54' + ('0' + amount.toString().length).slice(-2) + amount.toString();

    /**
     * country code
     * @since 1.0
     */
    const cc = '5802VN';
    // #endregion

    // #region "additional data field template"
    const adft = '62' + (description.length + 15) + '0107NPS686908' +
      ('0' + description.length).slice(-2) + description;
    // #region

    // #region "CRC number"
    const crcString = '6304' + this._generateCRC(
      pfi + poim + cai + tc + ta + cc + adft + '6304',
    );
    // #endregion

    return pfi + poim + cai + tc + ta + cc + adft + crcString;
  }

  /**
   * Generate new a CRC string.
   * @since 1.0
   * 
   * @param text The plain text string.
   * @returns 
   */
  private _generateCRC(text: string): string | null {
    if (text === null || text === '') {
      return null;
    }
    const crcString = crc16ccitt(text).toString(16);

    // get latest 4 character in crc string
    return ('0000' + crcString).slice(-4);
  }
}
