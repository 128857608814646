import { BaseService } from '../base';
import { GiftCodeResponse } from '@payment-mfe/shared/models';

export class GiftcodeService extends BaseService<GiftCodeResponse> {
  async listAsync(apiUrl: string): Promise<GiftCodeResponse[] | null> {
    const giftcodes: GiftCodeResponse[] = [
      {
        id: '123',
        title: 'Tân thủ',
        avatar: '/assets/images/giftcode.png',
        game: 'Võ lâm truyền kỳ',
      },
      {
        id: '234',
        title: 'Tân thủ',
        avatar: '/assets/images/giftcode.png',
        game: 'Võ lâm truyền kỳ',
      },
      {
        id: '345',
        title: 'Tân thủ',
        avatar: '/assets/images/giftcode.png',
        game: 'Võ lâm truyền kỳ',
      },
      {
        id: '456',
        title: 'Tân thủ',
        avatar: '/assets/images/giftcode.png',
        game: 'Võ lâm truyền kỳ',
      },
    ];

    return giftcodes;
  }
}
