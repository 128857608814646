import actions, {
  useAppDispatch,
  useAppSelector,
} from '@payment-mfe/shared/store';
import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from '../payment.module.css';
import { BreadcrumbWrapper, ExchangeModal } from '@payment-mfe/shared/commons';
import { calculateDiscount, formatWithCommas } from '@payment-mfe/shared/utils';
import { RateResponse } from '@payment-mfe/shared/service';
import ReactGA from 'react-ga4';
interface PaymentPackageProps {
  basePaymentApiUrl: string;
  productApiUrl: string;
}

export const PaymentPackageMobile = (props: PaymentPackageProps) => {
  const { slug, method } = useParams();
  //#region "Redux State"
  const dispatch = useAppDispatch();
  const rates = useAppSelector((state) => state.payment.paymentRate.entities);
  const allGames = useAppSelector((state) => state.home.game.all_games);
  const currentGame = allGames?.find((game) => game?.slug === slug);
  const isSmallScreen = useAppSelector((state) => state.appState.isSmallScreen);

  //#endregion

  //#region "Component State"
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  //#endregion

  //#region "Effects"
  useEffect(() => {
    if (!allGames || allGames?.length === 0) {
      dispatch(
        actions.home.game.getAllGames({
          baseProductApiUrl: props.productApiUrl,
        })
      );
    }
  }, [allGames, dispatch, props.productApiUrl]);

  useEffect(() => {
    if (method) {
      dispatch(
        actions.payment.rate.listAll({
          basePaymentApiUrl: props.basePaymentApiUrl,
          type: method,
        })
      );
    }
  }, [dispatch, method, props.basePaymentApiUrl]);

  useEffect(() => {
    window.scrollTo({
      top: 120,
      behavior: 'smooth',
    });
  }, []);

  //#region "Functions"
  const handleSelectedRate = (rate: RateResponse) => {
    localStorage.setItem(
      'rate',
      JSON.stringify({
        id: rate.id,
        currencyAmount: rate.currency_amount,
        gameAmount: rate.game_amount,
      })
    );
    dispatch(
      actions.payment.ui.changesConversionRate({
        id: rate.id,
        currencyAmount: rate.currency_amount,
        gameAmount: rate.game_amount,
      })
    );
    dispatch(actions.payment.ui.selectedRate(rate.id));
    isSmallScreen && method !== 'va'
      ? navigate(`/nap-game/${slug}/${method}/${rate.id}`)
      : navigate(`/nap-game/${slug}/va/${rate.id}`);

    // Push event payment when method === va
    if (method === 'va') {
      ReactGA.event({
        category: 'Button Click',
        action: 'Thanh toán trực tiếp',
        label: 'Clicked on Payment',
      });
    }
  };

  //#endregion

  return (
    <BreadcrumbWrapper link={`/nap-game/${slug}`} text="Quay lại">
      <div className={styles['payment-rate-wrapper']}>
        <div className={styles['content-top']}>
          <div className={styles['content-top__user']}>
            <div className={styles['user-info']}>
              <img src="/assets/images/avatar_2.png" alt="" />
            </div>
            <div className={styles['user-coint']}>
              <h4>{currentGame?.title}</h4>
              <div className={styles['transaction']}>
                <div
                  onClick={() => setShowModal(true)}
                  className={styles['coint-exchange']}
                >
                  <img src="/assets/images/icons/exchange.svg" alt="" />
                  Bảng quy đổi
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles['package-title']}>
          <span>Chọn gói nạp</span>
        </div>

        <div className={styles['package-wrapper']}>
          <Row gutter={[20, 20]} style={{ width: '100%' }}>
            {[...Object.values(rates)].map((rate) => {
              if (typeof rate !== 'undefined') {
                return (
                  <Col
                    xl={6}
                    lg={8}
                    md={8}
                    sm={12}
                    xs={12}
                    key={rate.id}
                    style={{ paddingRight: '0px' }}
                  >
                    <button className={styles['button-wrapper']}>
                      <div
                        className={styles['package-group']}
                        onClick={() => handleSelectedRate(rate)}
                      >
                        <div
                          className={`${styles['package-bxu']} ${styles['package-bxu-mobile']}`}
                        >
                          <div className={styles['bxu']}>
                            {rate.game_amount &&
                              formatWithCommas(rate.game_amount, '.')}{' '}
                            BXu
                          </div>
                          {method === 'va' &&
                            calculateDiscount(
                              rate.currency_amount,
                              rate.game_amount,
                              0.01
                            ).coinDiscount !== 0 && (
                              <div
                                className={
                                  styles['package-info__discount-mobile']
                                }
                              >
                                <img
                                  className={styles['img-discount']}
                                  src="/assets/images/icons/payments/star.png"
                                  alt=""
                                />
                                <div
                                  className={styles['discount-number-mobile']}
                                >
                                  {
                                    calculateDiscount(
                                      rate.currency_amount,
                                      rate.game_amount,
                                      0.01
                                    ).discountPercent
                                  }
                                  %
                                </div>
                              </div>
                            )}
                        </div>
                        <div className={styles['package-bonus']}>
                          {method === 'va' &&
                          calculateDiscount(
                            rate.currency_amount,
                            rate.game_amount,
                            0.01
                          ).coinDiscount !== 0
                            ? `Nhận thêm
                              ${
                                calculateDiscount(
                                  rate.currency_amount,
                                  rate.game_amount,
                                  0.01
                                ).coinDiscount
                              } BXu`
                            : ''}
                        </div>
                        {
                          <div className={styles['package-currency']}>
                            {rate.currency_amount &&
                              formatWithCommas(rate.currency_amount, '.')}{' '}
                            VND
                          </div>
                        }
                      </div>
                    </button>
                  </Col>
                );
              }
              return null;
            })}
          </Row>
        </div>
        <ExchangeModal isShown={showModal} setShowModal={setShowModal} />
      </div>
    </BreadcrumbWrapper>
  );
};

export default PaymentPackageMobile;
