// @ts-check

// #region "Imports"
import actions, {
  hidenModal,
  loadingForgotPasswardVerifySuccessModal,
  loadingForgotPasswordEmailVerifyFailedModal,
  loadingForgotPasswordFailureModal,
  loadingForgotPasswordServer_errModal,
  loadingForgotPasswordSuccessModal,
  loadingLoginModal,
  useAppDispatch,
  useAppSelector,
} from '@payment-mfe/shared/store';
import type { FormRule, TabsProps } from 'antd';
import { Button, Col, Form, Input, Modal, Row, Tabs } from 'antd';
import { Rule } from 'antd/es/form';
import { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import styles from './style.module.css';
// #endregion

/**
 * A properties list of the forgot password component.
 * @since 1.0
 */
interface ForgotPasswordProps {
  title?: string;
  baseAuthApiUrl: string;
  baseNotificationApiUrl: string;
}

//#region "Svg Icons"

/**
 * A svg format of the arrow to left icon.
 * @since 1.0
 *
 * @returns
 */
const BackIconSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M26.666 5L11.666 20L26.666 35"
        stroke="#05422C"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

// #endregion

/**
 * The forgot password components.
 * @since 1.0
 *
 * @param props The compoennt properties.
 * @returns
 */
export function ForgotPasswordModal(props: ForgotPasswordProps) {
  //#region "States"
  const [otp, setOtp] = useState('');
  const [tabKey, setTabKey] = useState('1');
  const [showVerify, setShowVerify] = useState(false);
  const trackingId = useAppSelector(
    (state) => state.auth.forgotPassword.trackingId
  );
  const confirmPhoneStatus = useAppSelector(
    (state) => state.auth.forgotPassword.confirmPhoneStatus
  );

  const otpErrorVerified = useAppSelector(
    (state) => state.auth.forgotPassword.otpErrorVerified
  );

  const [showError, setShowError] = useState(otpErrorVerified);

  const statusSendEmail = useAppSelector(
    (state) => state.auth.forgotPassword.status_send_email
  );
  const redirectPath = useAppSelector((state) => state.auth.login.redirectPath);
  // #endregion

  // Handle count down time
  const TIME_LEFT_IN_THE_SECONDS = 0;
  const [timeLeft, setTimeLeft] = useState<number>(TIME_LEFT_IN_THE_SECONDS);

  // #region "Form"
  const [form] = Form.useForm();
  // #endregion

  // #region "Redux State"
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.modalState.loading);
  const isOtpValid = useAppSelector(
    (state) => state.auth.forgotPassword.isOtpValid
  );

  const otpStatus = useAppSelector(
    (state) => state.auth.forgotPassword.otp_status
  );

  // #endregion

  // #region "Validation rules"
  const emailRules: Rule[] = [
    {
      type: 'email',
      message: 'Địa chỉ email không đúng định dạng',
    },
    {
      required: true,
      message: 'Địa chỉ email không được để trống.',
    },
  ];

  const mobileRules = [
    { required: true, message: 'Số điện thoại không được để trống.' },
    // {
    //   pattern: /^(((\\+|)84)|0)(3|5|7|8|9)+([0-9]{8})$/,
    //   message: 'Số điện thoại không đúng định dạng.',
    // },
    {
      validator: async (_, value): Promise<void> => {
        // Validation an e-mail address is the unique.
        if (value) {
          const response = await fetch(
            props.baseAuthApiUrl + '/members/unique-mobile/' + value
          );
          if (response.status === 200) {
            const jsonBody = await response.json();
            if (jsonBody.errCode === '00') {
              return Promise.reject(
                new Error('Số điện thoại không tồn tại trên hệ thống.')
              );
            }
          }
          if (otpStatus === 'failed') {
            return Promise.reject(
              new Error('Số điện thoại chưa được xác thực')
            );
          } else if (otpStatus === 'idle') {
            return Promise.resolve();
          }
          return Promise.resolve();
        }
      },
    },
  ] as FormRule[];

  //#region "Verify Reset password"
  /**
   * The validation rules of the new password
   */
  const newPasswordRules: FormRule[] = [
    { required: true, message: 'Mật khẩu mới không được để trống.' },
    {
      message: 'Mật khẩu mới phải có độ dài từ 6 tới 32 ký tự.',
      min: 6,
      max: 32,
    },
  ];

  /**
   * The validation rules of the confirm password
   */
  const confirmNewPasswordRules: FormRule[] = [
    { required: true, message: 'Nhập lại mật khẩu mới không được để trống.' },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue('password') === value) {
          return Promise.resolve();
        }
        return Promise.reject(
          new Error('Nhập lại mật khẩu mới không chính xác.')
        );
      },
    }),
  ];
  // #endregion

  //#region "Effects"
  useEffect(() => {
    // exit early when we reach 0
    if (!timeLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const interval = setInterval(() => {
      setTimeLeft((timeLeft) => timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(interval);
    // add timeLeft as a dependency to the re-return the effect
    // when we update it
  }, [props, timeLeft]);

  useEffect(() => {
    if (trackingId !== null) setTimeLeft(30);
  }, [trackingId]);
  // End region

  // Verify otp and show error message
  useEffect(() => {
    setShowError(otpErrorVerified);
  }, [otpErrorVerified]);

  useEffect(() => {
    if (showError === '05') {
      dispatch(loadingForgotPasswordServer_errModal());
      dispatch(actions.auth.forgotPassword.resetOtpErrorVerified());
      setOtp('');
    }
  }, [showError, dispatch]);

  // Validate field when click send otp button
  useEffect(() => {
    if (otpStatus !== 'idle') form.validateFields();
  }, [otpStatus, form]);

  // #region "Handler actions"

  const forgotPasswordByOtp = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    form
      .validateFields()
      .then((values) => {
        // Validation successful, dispatch the action
        dispatch(
          actions.auth.forgotPassword.forgotPasswordByOTP({
            baseAuthApiUrl: props.baseAuthApiUrl,
            request: {
              mobile: values.mobile, // Use the value from the form
            },
          })
        );
      })
      .catch((errorInfo) => {
        // Validation failed, handle errors (if needed)
      });
  };

  // #endregion

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: <span className={styles['tab-label']}>Số điện thoại</span>,
      children: (
        <>
          <p>
            Nhập SĐT đăng ký tài khoản, mã OTP sẽ được gửi đến Zalo của bạn. Vui
            lòng nhập vào ô dưới để đổi mật khẩu
          </p>
          <Form.Item name="mobile" rules={tabKey === '1' ? mobileRules : []}>
            <Row gutter={10}>
              <Col span={16}>
                <Input
                  placeholder="Nhập số điện thoại"
                  onFocus={() =>
                    dispatch(
                      actions.auth.forgotPassword.resetForgotPasswordState()
                    )
                  }
                />
              </Col>
              <Col span={8}>
                <Button
                  disabled={timeLeft > 0}
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    forgotPasswordByOtp(e);
                  }}
                  className={styles['otp-submit']}
                  style={{ cursor: 'pointer' }}
                >
                  Gửi mã {timeLeft > 0 ? '(' + timeLeft + 's)' : null}
                </Button>
              </Col>
            </Row>
          </Form.Item>

          <p
            style={{
              textAlign: 'left',
              marginBottom: '8px',
              color: '#05422C',
            }}
          >
            Nhập mã OTP
          </p>
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            containerStyle="otp-input"
            inputStyle="inputStyle"
            renderInput={(props) => (
              <input
                {...props}
                onFocus={() => {
                  setShowError(null);
                  dispatch(actions.auth.forgotPassword.resetOtpErrorVerified());
                }}
              />
            )}
          />
          {showError === '01' && (
            <span className={styles['otp-err']}>
              Mã xác thực không chính xác.
            </span>
          )}
        </>
      ),
    },
    {
      key: '2',
      label: <span className={styles['tab-label']}>Email</span>,
      children: (
        <>
          <p>
            Vui lòng nhập email đăng ký tài khoản. Bạn sẽ nhận được một đường
            link thay đổi mật khẩu qua email.
          </p>
          <Form.Item name="email" rules={tabKey === '2' ? emailRules : []}>
            <Input placeholder="Nhập email đăng ký tài khoản" />
          </Form.Item>
        </>
      ),
    },
  ];

  // #endregion

  // #region "Effects"
  useEffect(() => {
    if (statusSendEmail === 'server_error' && tabKey === '2') {
      dispatch(loadingForgotPasswordEmailVerifyFailedModal());
      setTabKey('1');
    }
  }, [statusSendEmail, dispatch, tabKey]);

  // Show success message
  useEffect(() => {
    if (tabKey === '2') {
      if (statusSendEmail === 'failed') {
        dispatch(loadingForgotPasswordFailureModal());
        setTabKey('1');
      } else if (statusSendEmail === 'successed') {
        dispatch(loadingForgotPasswordSuccessModal());
        setTabKey('1');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusSendEmail, dispatch]);

  useEffect(() => {
    if (confirmPhoneStatus === 'failed') {
      dispatch(loadingForgotPasswardVerifySuccessModal());
    } else if (confirmPhoneStatus === 'successed') {
      dispatch(loadingForgotPasswardVerifySuccessModal());
    }
  }, [confirmPhoneStatus, dispatch]);
  // #endregion

  // Handle Change send otp and validate otp
  useEffect(() => {
    if (isOtpValid === true) setShowVerify(true);
  }, [isOtpValid]);

  useEffect(() => {
    dispatch(actions.auth.forgotPassword.resetForgotPasswordState());
  }, [dispatch, tabKey]);

  const handleBackButton = () => {
    dispatch(hidenModal());
    dispatch(loadingLoginModal());
    dispatch(actions.auth.forgotPassword.resetOtpErrorVerified());
    dispatch(actions.auth.forgotPassword.resetForgotPasswordState());
    dispatch(actions.auth.forgotPassword.resetTrackingId());
    // reset field and state
    setOtp('');
    setTimeLeft(0);
    setShowVerify(false);
    form.resetFields();
  };

  const handleCancelModal = () => {
    dispatch(hidenModal());
    dispatch(actions.auth.forgotPassword.resetOtpErrorVerified());
    dispatch(actions.auth.forgotPassword.resetTrackingId());
    redirectPath !== null && dispatch(actions.auth.login.resetRedirectPath());
    // reset field and state
    form.resetFields();
    setShowVerify(false);
    setOtp('');
    setTimeLeft(0);
  };

  const handleChangePassword = (values: { email: string }) => {
    if (tabKey === '1' && trackingId) {
      dispatch(
        actions.auth.forgotPassword.doVerifyAsync({
          baseNotificationApiUrl: props.baseNotificationApiUrl,
          trackingId: trackingId,
          otp: otp,
        })
      );
    }
    if (tabKey === '2') {
      dispatch(
        actions.auth.forgotPassword.forgotPasswordByEmail({
          baseAuthApiUrl: props.baseAuthApiUrl,
          request: {
            email: values.email,
          },
        })
      );
    }
  };

  // Confirm Change For Phone
  const handleConfirmChange = (values: {
    password: string;
    new_password: string;
  }) => {
    if (trackingId) {
      dispatch(
        actions.auth.forgotPassword.confirmPasswordAsync({
          baseAuthApiUrl: props.baseAuthApiUrl,
          otp: otp,
          new_password: values.new_password,
          tracking_id: trackingId,
        })
      );
      dispatch(hidenModal());
      setShowVerify(false);
    }
  };

  // #region "Render View"
  return (
    <div className={styles['login-modal']}>
      <Modal
        centered
        maskClosable={false}
        title={
          <div className={styles['modal-header']}>
            <div
              style={{ cursor: 'pointer', display: 'inline-block' }}
              className={styles['modal-header__back']}
              onClick={handleBackButton}
            >
              <BackIconSvg />
            </div>
            <div className={styles['modal-header__logo']}>
              <img
                src="/assets/images/Logo_Boom.png"
                alt=""
                style={{ width: '102px' }}
              />
            </div>
          </div>
        }
        destroyOnClose={true}
        open={loading === 'forgotPassword'}
        onCancel={handleCancelModal}
        footer={null}
        style={{ minHeight: '575px' }}
      >
        <div className={styles['login-content']}>
          {showVerify ? (
            /* Verify to change password */
            <div className={styles['container']}>
              <div className={styles['form']}>
                <h2 style={{ fontSize: '24px' }}>Đổi mật khẩu</h2>
                <Form
                  name="forgot-password-verify"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  onFinish={handleConfirmChange}
                  layout="vertical"
                  className={styles['form-container']}
                >
                  <Form.Item
                    name={'password'}
                    label="Nhập mật khẩu mới"
                    rules={newPasswordRules}
                  >
                    <Input.Password size="large" style={{ width: '100%' }} />
                  </Form.Item>

                  <Form.Item
                    name={'new_password'}
                    label={'Nhập lại mật khẩu mới'}
                    rules={confirmNewPasswordRules}
                  >
                    <Input.Password size="large" style={{ width: '100%' }} />
                  </Form.Item>

                  <Form.Item wrapperCol={{ offset: 8, span: 24 }}>
                    <Button type="primary" htmlType="submit" size="large">
                      Xác nhận
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          ) : (
            /* Get OTP / Email */
            <div className="">
              <h2>Quên mật khẩu</h2>

              <div className={styles['login-form']}>
                <Form
                  form={form}
                  onFinish={handleChangePassword}
                  layout="vertical"
                  autoComplete="false"
                  id="forgot-password-form"
                >
                  <Tabs
                    tabBarGutter={100}
                    defaultActiveKey="1"
                    centered
                    items={items}
                    size={'large'}
                    onTabClick={(key) => {
                      setTabKey(key);
                      form.resetFields();
                      setOtp('');
                    }}
                  />

                  <Button
                    disabled={trackingId === null && tabKey === '1'}
                    className={styles['login-btn']}
                    htmlType={'submit'}
                  >
                    Xác nhận
                  </Button>
                  <br />
                </Form>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
  // #endregion
}

export default ForgotPasswordModal;
