//#region "Import Modules"
import type {
  ProfileRequest,
  ProfileResponse,
} from '@payment-mfe/shared/service';
import { ProfileService, VerifyInfoService } from '@payment-mfe/shared/service';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
//#endregion

//#region "Contants"
const APP_FEATURE_KEY = 'members/profile';
const ACTION_GET_PROFILE_KEY = APP_FEATURE_KEY + '/getAsync';
const ACTION_UPDATE_PROFILE_KEY = APP_FEATURE_KEY + '/updateAsync';
//#endregion

//#region "AsyncThunks"
const service = new ProfileService();
const verifyService = new VerifyInfoService();

/**
 * An async thunk get the profile
 */
const getAsync = createAsyncThunk(
  ACTION_GET_PROFILE_KEY,
  async (payload: { baseAuthApiUrl: string }) => {
    const response = await service.getProfileAsync(payload.baseAuthApiUrl);

    return response;
  }
);

/**
 * An async thunk to update profile
 */
const updateAsync = createAsyncThunk(
  ACTION_UPDATE_PROFILE_KEY,
  async (payload: { baseAuthApiUrl: string; req: ProfileRequest }) => {
    const response = await service.updateProfileAsync(
      payload.baseAuthApiUrl,
      payload.req
    );

    return response;
  }
);

/**
 * An async thunk to send OTP to member's email
 */
const sendOTP = createAsyncThunk(
  APP_FEATURE_KEY + '/send-email-otp',
  async (payload: {
    notificationApiUrl: string;
    req: {
      type: 'email' | 'mobile';
      emailOrMobile: string | undefined;
    };
  }) => {
    const response = await verifyService.sendOTP(
      payload.notificationApiUrl,
      payload.req
    );

    return response;
  }
);

/**
 * An async thunk to send OTP to member's email
 */
const verifyOTP = createAsyncThunk(
  APP_FEATURE_KEY + '/verify-otp',
  async (payload: {
    authApiUrl: string;
    req: {
      trackingId: string;
      otp: string;
    };
  }) => {
    const response = await verifyService.verifyOTP(
      payload.authApiUrl,
      payload.req
    );

    return response;
  }
);

//#endregion

//#region "States"
export interface ProfileState {
  status: 'idle' | 'loading' | 'successed' | 'failed';
  error: string | null;
  profile: ProfileResponse | null;
  updated: boolean | null;

  sendOTP_status: 'idle' | 'loading' | 'successed' | 'failed';
  verify_status: 'idle' | 'loading' | 'successed' | 'failed';
  trackingId: string | undefined;
  draftMobile: string;
  draftEmail: string;
}

const initialState: ProfileState = {
  status: 'idle',
  error: null,
  profile: null,
  updated: null,
  // Verify
  sendOTP_status: 'idle',
  verify_status: 'idle',
  trackingId: undefined,
  draftMobile: '',
  draftEmail: '',
};
//#endregion

//#region "Slices"
const profileSlice = createSlice({
  name: APP_FEATURE_KEY,
  initialState: initialState,
  reducers: {
    resetState: (state) => {
      state = initialState;
    },
    resetStatus: (state) => {
      state.status = 'idle';
      state.sendOTP_status = 'idle';
      state.verify_status = 'idle';
    },
    draftMobile: (state, { payload }) => {
      state.draftMobile = payload;
    },
    draftEmail: (state, { payload }) => {
      state.draftEmail = payload;
    },
  },
  extraReducers: (builder) => {
    // #region Member Profile
    builder.addCase(getAsync.pending, (state) => {
      state.status = 'loading';
      state.error = 'during in progress get a profile from service';
      state.updated = null;
    });
    builder.addCase(getAsync.rejected, (state) => {
      state.status = 'failed';
      state.updated = null;
      state.error = 'cannot establish connection to http server';
    });
    builder.addCase(getAsync.fulfilled, (state, action) => {
      state.updated = null;
      if (action.payload !== null) {
        state.status = 'successed';
        state.error = 'get the profile of a member successful';
        state.profile = action.payload;
      } else {
        state.status = 'failed';
        state.error = 'get the profile of a member failure, due logical error.';
      }
    });
    builder.addCase(updateAsync.pending, (state) => {
      state.status = 'loading';
      state.updated = null;
      state.error = 'during progress update the profile';
    });
    builder.addCase(updateAsync.rejected, (state) => {
      state.status = 'failed';
      state.error = 'update the profile failure, due http server';
      state.updated = false;
    });
    builder.addCase(updateAsync.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.status = 'successed';
        state.error = 'the profile was updated';
        state.profile = action.payload;
        state.updated = true;
      } else {
        state.status = 'failed';
        state.error = 'the profile update failure, due business';
        state.updated = false;
      }
    });
    // #endregion

    // #region send OTP
    builder.addCase(sendOTP.pending, (state) => {
      state.sendOTP_status = 'loading';
    });
    builder.addCase(sendOTP.rejected, (state) => {
      state.sendOTP_status = 'failed';
    });
    builder.addCase(sendOTP.fulfilled, (state, { payload }) => {
      if (payload?.errCode === '00') {
        state.sendOTP_status = 'successed';
        state.trackingId = payload.data?.trackingId;
      } else {
        state.sendOTP_status = 'failed';
      }
    });
    // #endregion

    // #region verify OTP
    builder.addCase(verifyOTP.pending, (state) => {
      state.verify_status = 'loading';
    });
    builder.addCase(verifyOTP.rejected, (state) => {
      state.verify_status = 'failed';
    });
    builder.addCase(verifyOTP.fulfilled, (state, { payload }) => {
      if (payload.errCode === '00') {
        state.verify_status = 'successed';
      } else {
        state.verify_status = 'failed';
      }
    });
    // #endregion
  },
});
//#endregion

//#region "Exports"
export const actions = {
  ...profileSlice.actions,
  getAsync,
  updateAsync,
  // Verify
  sendOTP,
  verifyOTP,
};
export default profileSlice.reducer;
//#endregion
