//@ts-check

//#region "Import modules"
import { BaseService } from './base';
//#endregion

//#region "Declaration types"
export type Wallet = {
  id: string;
  balance: string;
};
//#endregion

//#region "Handler"
export class WalletService extends BaseService<Wallet> {
  /**
   * Get wallet information of the member.
   * @param apiUrl An end-point get wallet information of an member.
   * @returns The wallet information
   */
  async getByMemberAsync(apiUrl: string): Promise<Wallet | null> {
    const baseResponse = await this.getAsync(apiUrl);
    if (baseResponse.errCode === '00') {
      return baseResponse.data as Wallet;
    }

    return null;
  }
}
//#endregion
