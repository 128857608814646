// @ts-check

//#region "Declaration Modules"
import { BaseService } from '../base';
import type { Paging } from '../base';
//#endregion

//#region "Data Type"
export type TransactionRequest = {
  fromDate: string | null;
  toDate: string | null;
  type: string | null;
  page: number;
  itemsPerPage: number;
};
export type TransactionResponse = {
  id: string;
  amount: number;
  nxu_amount: number;
  code: string;
  merchant: string;
  source: string;
  trans_time: string;
  type: string;
  game?: {
    id: string;
    title: string;
    avatar_path: string;
  };
};
//#endregion

//#region "Business"
export class TransactionHistoryService extends BaseService<TransactionResponse> {
  /**
   * Search the history of transactiona
   * @param apiUrl A base url of the payment service
   * @param req The query params
   * @returns
   */
  async searchTransactionAsync(
    apiUrl: string,
    req: TransactionRequest
  ): Promise<Paging<TransactionResponse> | null> {
    // #region "build query string"
    let queryString = null;
    if (req.fromDate != null && typeof req.fromDate !== 'undefined') {
      queryString = 'fromDate=' + req.fromDate;
    }
    if (req.toDate != null && typeof req.fromDate !== 'undefined') {
      queryString = queryString
        ? queryString + '&toDate=' + req.toDate
        : 'toDate=' + req.toDate;
    }
    if (req.type != null) {
      queryString = queryString
        ? queryString + '&type=' + req.type
        : 'type=' + req.type;
    }

    queryString = queryString
      ? queryString + '&page=' + req.page
      : 'page=' + req.page;
    queryString = queryString + '&itemsPerPage=' + req.itemsPerPage;
    //#endregion

    // #region "search transaction history"
    const baseResponse = await this.searchAsync(
      apiUrl + '/histories/by-member?' + queryString
    );
    if (baseResponse.errCode === '00') {
      return baseResponse.data as Paging<TransactionResponse>;
    }

    return null;
    // #endregion
  }
}
//#endregion
