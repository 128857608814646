//@ts-check

//#region "Import Modules"
import { BaseService } from '../base';
//#endregion

//#region "Declaration Types"
export type ProfileRequest = {
  name: string;
  email: string;
  mobile: string;
  address: string;
  birthday: string;
  gender: string;
  identification_number: string | number | undefined;
  identification_address: string,
  identification_registration_date: string | null,
};

export type ProfileResponse = {
  uid: number
  name: string
  email: string;
  mobile: string;
  identification_number: string | number;
  identification_address: string,
  identification_registration_date: string,
  address: string;
  birthday: string;
  gender: string;
  mobile_verified: boolean;
  email_verified: boolean;
};
//#endregion

//#region "Service"
export class ProfileService extends BaseService<ProfileResponse> {
  /**
   * Get the profile of a member.
   * @param baseAuthApiUrl The base end point url of the auth service
   * @returns
   */
  async getProfileAsync(
    baseAuthApiUrl: string
  ): Promise<ProfileResponse | null> {
    const baseResponse = await this.getAsync(
      baseAuthApiUrl + '/protected/profiles'
    );

    if (baseResponse.errCode === '00') {
      return Promise.resolve(baseResponse.data);
    }
    return Promise.resolve(null);
  }

  /**
   * Update the member profile.
   * @version 1.0
   * 
   * @param baseAuthApiUrl The base end-point url of the auth service.
   * @param profile The profile information.
   * @returns
   */
  async updateProfileAsync(
    baseAuthApiUrl: string,
    profile: ProfileRequest
  ): Promise<ProfileResponse | null> {
    const baseResponse = await this.putAsync(
      baseAuthApiUrl + '/protected/profiles',
      JSON.stringify(profile)
    );
    if (baseResponse.errCode === '00') {
      return Promise.resolve(
        baseResponse.data as ProfileResponse
      );
    }

    return Promise.resolve(null);
  }
}
//#endregion
