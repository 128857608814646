// @ts-check

//#region "imports"
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { QrService } from '@payment-mfe/shared/service';
//#endregion

//#region "services"
const service = new QrService();
//#endregion

//#region "contants"
const APP_FEATURE_KEY = 'payment/virtual-account';
const GENERATE_QR_STRING_ACTION_KEY = APP_FEATURE_KEY + '/qr';
//#endregion

//#region "asyncthunk"
const generateQrCode = createAsyncThunk(
  GENERATE_QR_STRING_ACTION_KEY,
  async (payload: { basePaymentApiUrl: string; transactionId: string }) => {
    const response = await service.generateBase64QrString(
      payload.basePaymentApiUrl,
      payload.transactionId
    );

    return response;
  }
);
//#endregion

//#region "state"
export interface VirtualAccountState {
  status: 'idle' | 'loading' | 'successed' | 'failed';
  error: string | null;
  base64QrString: string | null;
  account: string | null;
  amount: number | null;
  transaction: string | null;
}

const initialState: VirtualAccountState = {
  status: 'idle',
  error: null,
  base64QrString: null,
  account: null,
  amount: null,
  transaction: null,
};
//#endregion

//#region "slice"
export const virtualAccountStateSlice = createSlice({
  name: APP_FEATURE_KEY,
  initialState: initialState,
  reducers: {
    reset: (state) => {
      state.base64QrString = null;
      state.account = null;
      state.amount = null;
      state.transaction = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(generateQrCode.rejected, (state) => {
      state.status = 'failed';
      state.error = 'Generate the qr code string failure. Check your network.';
    });
    builder.addCase(generateQrCode.pending, (state) => {
      state.status = 'loading';
      state.error = 'Generate the qr base64 string in progress';
    });
    builder.addCase(generateQrCode.fulfilled, (state, action) => {
      if (action.payload === null) {
        state.status = 'failed';
        state.error = 'Generate the qr code string failure.';
      } else {
        state.status = 'successed';
        state.error = 'Generate the qr code string successed.';
        state.base64QrString = action.payload.qrCode;
        state.account = action.payload.account;
        state.amount = action.payload.amount;
        state.transaction = action.payload.transaction;
      }
    });
  },
});
//#endregion

//#region "exports"
export const actions = {
  ...virtualAccountStateSlice.actions,
  generateQrCode,
};

export default virtualAccountStateSlice.reducer;
//#endregion
