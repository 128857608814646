// @ts-check
import { 
  Method,
} from '@payment-mfe/shared/service';
import actions, {
  useAppDispatch,
  useAppSelector,
} from '@payment-mfe/shared/store';

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import styles from '../payment.module.css';

export interface PaymentMethodProps {
  basePaymentApiUrl: string;
  slug?: string;
}

export function PaymentMethod(props: PaymentMethodProps) {
  //#region "Redux State"
  const dispatch = useAppDispatch();
  /**
   * Selected the payment method.
   */
  const method = useAppSelector((state) => state.payment.paymentUI.method);
  /**
   * The list of active payment methods.
   */
  const methods = useAppSelector(
    (state) => state.payment.paymentMethod.entities
  );
  //#endregion

  // #region "Component State"
  const navigate = useNavigate();
  const { slug } = useParams();
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  //#endregion

  //#region "Effects"
  //  Dispatching to action loading all payment methods
  useEffect(() => {
    dispatch(
      actions.payment.method.getAll({
        basePaymentApiUrl: props.basePaymentApiUrl,
      })
    );
  }, [dispatch, props.basePaymentApiUrl]);

  /**
   * Setup default payment method.
   */
  useEffect(() => {
    if (methods !== null && method === null) {
      const defaultMethod = [...Object.values(methods)][0];
      if (typeof defaultMethod !== 'undefined') {
        // Select default of payment method.
        dispatch(
          actions.payment.ui.changesMethod({
            code: defaultMethod.code,
            name: defaultMethod.name,
          })
        );
        // After select method, show confirmation screen.
        dispatch(
          actions.payment.ui.changesStep({
            newStep: 'confirmation',
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, methods, method]);
  //#endregion

  useEffect(() => {
    isSmallScreen && navigate(`/nap-game/${slug}/${method?.code}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [method, slug, navigate]);

  //#region "Check screen and scroll"
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 610);
    };

    handleResize(); // Check initial screen size
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 190,
      behavior: 'smooth',
    });
  }, []);
  //#endregion

  const handleSelectMethod = (item: Method) => {
    dispatch(
      actions.payment.ui.changesMethod({
        code: item.code,
        name: item.name,
      })
    );
    localStorage.setItem(
      'method',
      JSON.stringify({
        code: item.code,
        name: item.name,
      })
    );
    dispatch(actions.payment.va.reset());
    dispatch(actions.payment.transaction.reset());
    dispatch(actions.payment.ui.selectedRate(''));
    if (method?.code === item?.code) {
      // do some thing
      return;
    }
    dispatch(actions.payment.rate.resetRates());
  };

  return (
    <>
      <div style={{ position: 'relative' }}>
        <h3 className={styles['choose-payment']}>
          Chọn phương thức thanh toán
        </h3>
      </div>
      <div className={styles['payment-method']}>
        {[...Object.values(methods)]?.map((item, i) => (
          <div
            key={i}
            className={`${styles['payment-method-item']} ${
              method?.code === item?.code && styles['payment-method-active']
            } ${isSmallScreen && styles['payment-method-item-mobile']}`}
            onClick={() => handleSelectMethod(item as Method)}
          >
            <div className={styles['payment-method-info']}>
              <img src={item?.avatarUrl} alt={item?.name} />
              <p>{item?.name}</p>
            </div>
            {item?.code === 'va' && !isSmallScreen && (
              <div className={styles['promote-tag']}>Hot</div>
            )}
            {item?.code === 'va' && isSmallScreen && (
              <img
                src="/assets/images/icons/payments/hot.svg"
                className={styles['promote-tag-mobile']}
                alt=""
              />
            )}
          </div>
        ))}
      </div>
    </>
  );
}

export default PaymentMethod;
