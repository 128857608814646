import { RegisterRequest } from '@payment-mfe/shared/models';
import actions, {
  hidenModal,
  loadingLoginModal,
  loadingRegisterFailureModal,
  loadingRegisterSuccessModal,
  useAppDispatch,
  useAppSelector,
} from '@payment-mfe/shared/store';
import { Button, Checkbox, Form, Input, Modal } from 'antd';
import { Rule, RuleObject } from 'antd/es/form';
import styles from './style.module.css';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';
interface RegisterModalProps {
  baseAuthApiUrl: string;
}

export function RegisterModal(props: RegisterModalProps) {
  //#region "Local state"
  const [agreement, setAgreement] = useState<boolean>(false);
  //#endregion

  //#region "Form"
  const [form] = Form.useForm();
  const onFinish = async (values: RegisterRequest) => {
    await dispatch(
      actions.auth.register.register({
        baseAuthApiUrl: props.baseAuthApiUrl,
        request: {
          name: values.username,
          username: values.username,
          password: values.password,
        },
      })
    );
    if (status === 'successed') {
      await dispatch(
        actions.auth.login.loginByUsernameAndPassword({
          authApiUrl: props.baseAuthApiUrl,
          loginRequest: {
            username: values.username,
            password: values.password,
          },
        })
      );
    }
  };

  //#endregion

  //#region "Redux"
  const dispatch = useAppDispatch();
  const status = useAppSelector((state) => state.auth.register.status);
  const loading = useAppSelector((state) => state.modalState.loading);
  const redirectPath = useAppSelector((state) => state.auth.login.redirectPath);
  //#endregion

  //#region "Form validation rules"

  const handleUsernameValidation = async (
    _: RuleObject,
    username: string
  ): Promise<boolean> => {
    try {
      const response = await fetch(
        props.baseAuthApiUrl + '/members/get-by-username/' + username,
        {
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
          },
          method: 'get',
        }
      );

      const jsonBody = await response.json();
      const isUsernameUniqueValidate = jsonBody.errCode !== '00';
      if (!isUsernameUniqueValidate) {
        return Promise.reject('the username "' + username + '" was existed.');
      }
      return Promise.resolve(true);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  /**
   * Validate rule for username address
   */
  const usernameRule: Rule[] = [
    { message: 'Tên đăng nhập không được để trống.', required: true },
    {
      message: 'Tên đăng nhập phải có độ dài từ 6 tới 32 ký tự.',
      min: 6,
      max: 32,
    },
    {
      message: 'Tên đăng nhập không được chứa những ký tự đặc biệt.',
      pattern: /^[a-zA-Z0-9]{6,30}$/,
    },
    {
      message: 'Tên đăng nhập đã tồn tại trên hệ thống.',
      validator: handleUsernameValidation,
    },
  ];

  /**
   * Validate rule for password
   *
   */
  const passwordRules: Rule[] = [
    { required: true, message: 'Mật khẩu đăng nhập không được để trống.' },
    {
      message: 'Mật khẩu đăng nhập phải có độ dài từ 6 tới 32 ký tự.',
      min: 6,
      max: 32,
    },
  ];

  /**
   * Validate rule for confirm password
   *
   */
  const confirmPasswordRules: Rule[] = [
    { required: true, message: 'Xác nhận mật khẩu không được để trống.' },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue('password') === value) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('Xác nhận mật khẩu không chính xác.'));
      },
    }),
  ];
  //#endregion

  // #region "Effects"

  // Show notification modal when register.
  useEffect(() => {
    if (status === 'successed') {
      dispatch(loadingRegisterSuccessModal());
      // Push event register successfully
      ReactGA.event({
        category: 'Button Click',
        action: 'Register payment page',
        label: 'Clicked on Register',
      });
    } else if (status === 'failed') {
      dispatch(loadingRegisterFailureModal());
    }
    dispatch(actions.auth.register.resetStatus());
  }, [status, dispatch]);
  // #endregion

  useEffect(() => {
    form.resetFields();
  }, [form]);

  return (
    <div className={styles['login-modal']}>
      <Modal
        centered
        maskClosable={false}
        title=""
        open={loading === 'register'}
        onCancel={() => {
          form.resetFields();
          dispatch(hidenModal());
          redirectPath !== null &&
            dispatch(actions.auth.login.resetRedirectPath());
        }}
        footer={null}
      >
        <div className={styles['login-content']}>
          <div className={styles['login-logo']}>
            <img
              src="/assets/images/Logo_Boom.png"
              alt=""
              style={{ width: '102px' }}
            />
          </div>
          <h2>Đăng ký</h2>
          <div className={styles['login-form']}>
            <Form
              form={form}
              layout="vertical"
              autoComplete="off"
              initialValues={{ policyAgreement: agreement }}
              onFinish={onFinish}
            >
              {/* User name field */}
              <Form.Item
                label="Tài khoản"
                name="username"
                hasFeedback
                rules={usernameRule}
              >
                <Input size="large" placeholder="Nhập BoomID" />
              </Form.Item>

              {/* Password field */}
              <Form.Item
                name="password"
                label="Mật khẩu"
                hasFeedback
                rules={passwordRules}
              >
                <Input.Password size="large" placeholder="Mật khẩu" />
              </Form.Item>

              {/* Confirm password field */}
              <Form.Item
                name="confirm-password"
                label="Nhập lại mật khẩu"
                hasFeedback
                rules={confirmPasswordRules}
              >
                <Input.Password size="large" placeholder="Nhập lại mật khẩu" />
              </Form.Item>

              {/* Policy field*/}
              <Form.Item name="policyAgreement">
                <Checkbox
                  className={styles['policy']}
                  onChange={() => {
                    setAgreement(!agreement);
                  }}
                >
                  Chấp nhận{' '}
                  <strong>
                    <Link
                      to={'https://pay.boomgames.vn/dieu-khoan-su-dung'}
                      target="_blank"
                    >
                      Điều khoản
                    </Link>
                  </strong>{' '}
                  và{' '}
                  <strong>
                    <Link
                      to={'https://pay.boomgames.vn/chinh-sach-quyen-rieng-tu'}
                      target="_blank"
                    >
                      Điều kiện
                    </Link>
                  </strong>
                </Checkbox>
              </Form.Item>

              {/* Submit button */}
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                className={styles['login-btn']}
                disabled={!agreement}
              >
                Đăng ký
              </Button>
            </Form>
          </div>
        </div>

        {/* Navigate to login modal */}
        <p className={styles['navigate-login']}>
          Đã có tài khoản?{' '}
          <span
            onClick={() => {
              dispatch(loadingLoginModal());
              form.resetFields();
            }}
          >
            Đăng nhập
          </span>
        </p>
      </Modal>
      {/* End Modal */}
    </div>
  );
}

export default RegisterModal;
