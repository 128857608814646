import { useAppSelector } from '@payment-mfe/shared/store';
import styles from './support.module.css';
import { BreadcrumbWrapper } from '@payment-mfe/shared/commons';
import { DirectSupport, SupportAccount, SupportPay } from './component';

import { Tabs, Typography } from 'antd';
import type { TabsProps } from 'antd';
import { useState } from 'react';

export function Support() {
  /**
   * A variable reference to information of an user when user logged in.
   *
   * @type {({uid: string, name: string, email: string | null, expiresTime: number} | null)}
   */
  const currentUser: {
    uid: string;
    username: string;
    name: string;
    avatarName: string;
    email: string | null;
    expiresTime: number;
    avatar?: string | null;
  } | null = useAppSelector((state) => state.appState.currentUser);
  //#endRegion

  //region "state"
  const [activeTab, setActiveTab] = useState(1);
  //#endRegion

  //region "tab custom"
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <Typography
          className={
            activeTab === 1 ? styles['tab-label-active'] : styles['tab-label']
          }
        >
          Tài khoản
        </Typography>
      ),
      children: <SupportAccount />,
    },
    {
      key: '2',
      label: (
        <Typography
          className={
            activeTab === 2 ? styles['tab-label-active'] : styles['tab-label']
          }
        >
          Thanh toán
        </Typography>
      ),
      children: <SupportPay />,
    },
  ];
  //#endRegion

  //region "function"
  const onChange = (key: string) => {
    setActiveTab(Number(key));
  };
  //#endRegion

  return (
    <BreadcrumbWrapper link="/" text="Trở lại trang chủ">
      <div className={styles['support-page']}>
        <p className={styles['user-info-name']}>
          Chào{' '}
          {currentUser?.username ? (
            <span>{currentUser?.username}</span>
          ) : (
            <span>Khách,</span>
          )}
        </p>
        <span className={styles['support-text']}>
          Hãy lựa chọn danh mục bạn đang cần hỗ trợ
        </span>
        <Tabs
          style={{ width: '100%' }}
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
          centered
        />
        <DirectSupport />
      </div>
    </BreadcrumbWrapper>
  );
}

export default Support;
