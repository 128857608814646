// @ts-check

//#region "Imports"
import { BaseService } from '../base';
//#endregion

//#region "Types"
/**
 * Describe the payload of credit transaction
 * to creating new a transaction.
 * @since 1.0
 */
export type TransactionRequest = {
  source: 'ib' | 'gw';
  gateway: {
    code: 'momo' | 'onepay';
    cardType: string;
  };
  bankId: string;
  conversionRate: {
    rateId: string;
    currencyAmount: number;
    gameAmount: number;
  };
};

/**
 * Describe the response from NotA trnsaction service.
 * @since 1.0
 */
export type TransactionResponse = {
  id: string;
  extras: {
    type: string;
    source: string;
    bankId: string;
    gateway: {
      code: string;
      cardType: string;
    } | null;
  };
};
//#endregion

//#region "Services"
export class TransactionService extends BaseService<TransactionResponse> {
  /**
   * Create new an transaction.
   * @since 1.0
   *
   * @param basePaymentApiUrl The base of purchase end-point.
   * @param request A payload of transaction.
   * @returns { Promise<TransactionResponse | null> }
   */
  async createTransaction(
    basePaymentApiUrl: string,
    request: TransactionRequest
  ): Promise<TransactionResponse | null> {
    const baseResponse = await this.postAsync(
      basePaymentApiUrl + '/transactions/credit',
      JSON.stringify(request)
    );

    if (baseResponse.errCode === '00') {
      return baseResponse.data as TransactionResponse;
    }

    return null;
  }

  /**
   * Get an transaction by id and member.
   * @since 1.0
   * @param basePaymentApiUrl A base api url of payment system.
   * @param id A transaction id
   * @returns { Promise<TransactionResponse | null> }
   */
  async getTransaction(
    basePaymentApiUrl: string,
    id: string
  ): Promise<TransactionResponse | null> {
    const baseResponse = await this.getAsync(
      basePaymentApiUrl + '/transactions/' + id
    );

    if (baseResponse.errCode === '00') {
      return baseResponse.data as TransactionResponse;
    }

    return null;
  }

  /**
   * Get transaction state.
   * @since 1.0
   * @param basePaymentApiUrl A base api url of payment system.
   * @param transactionCode The transaction code.
   * @returns
   */
  async getTransactionState(
    basePaymentApiUrl: string,
    transactionCode: string
  ): Promise<string | null> {
    const baseResponse = await this.getAsync(
      basePaymentApiUrl + '/transactions/get-state/' + transactionCode
    );

    if (baseResponse.errCode === '00' && baseResponse.data !== null) {
      return baseResponse.data.state;
    }
    return null;
  }
}
//#endregion
