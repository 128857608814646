import { useEffect } from 'react';
import styles from './terms.module.css';

/* eslint-disable-next-line */
export interface TermsProps {}

export function Terms(props: TermsProps) {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div className={styles['container']}>
      <p className={styles['title']}>
        <strong>ĐIỀU KHOẢN SỬ DỤNG</strong>
      </p>
      <br />
      <p>
        <i>
          Các điều khoản và điều kiện sử dụng dịch vụ hiển thị dưới đây (sau đây
          gọi là&nbsp;<strong>“Điều Khoản và Điều kiện”</strong>, hoặc&nbsp;
          <strong>“Điều Khoản Sử Dụng”</strong>) quy định các điều khoản và điều
          kiện được đồng ý bởi và giữa Công ty Cổ phần Boom Games (sau đây gọi
          tắt là&nbsp;<strong>“Công ty”</strong>, hoặc&nbsp;
          <strong>“Boom”</strong>, hoặc&nbsp;
          <strong>“chúng tôi”</strong>) và người sử dụng (sau đây gọi tắt
          là&nbsp;<strong>“người dùng”</strong>, hoặc&nbsp;
          <strong>“bạn”</strong>) về việc, hoặc có liên quan tới việc cung cấp,
          sử dụng dịch vụ trò chơi điện tử và các dịch vụ có liên quan bởi Công
          ty (sau đây gọi tắt là&nbsp;<strong>“Dịch vụ”</strong>).
        </i>
      </p>
      <br />
      <p>
        <strong>I. Định nghĩa</strong>
      </p>
      <p>
        Các từ ngữ và điều khoản sau sẽ có ý nghĩa như được định nghĩa dưới đây
        khi được sử dụng trong Điều Khoản và Điều kiện:
      </p>
      <br />
      <p>
        <i>
          <strong>1. “Trò chơi điện tử”</strong>
        </i>
        , hoặc&nbsp;
        <i>
          <strong>“Trò Chơi”</strong>
        </i>
        &nbsp;hoặc
        <i>
          <strong>“game”</strong>
        </i>
        , hoặc&nbsp;
        <i>
          <strong>“Sản Phẩm”</strong>
        </i>
        được hiểu là trò chơi điện tử có thể được cung cấp trực tuyến (online)
        hoặc không trực tuyến (offline). Trò chơi điện tử có thể được cung cấp
        bởi Boom, hoặc bởi các Đối tác của chúng tôi thông qua kênh phân
        phối/cung cấp của chúng tôi.
      </p>
      <br />
      <p>
        <i>
          <strong>2. “Dịch vụ”</strong>
        </i>
        &nbsp;được hiểu là dịch vụ trò chơi điện tử và các dịch vụ khác bao gồm
        trong đó, có hoặc được cung cấp kèm theo, hoặc các dịch vụ khác có liên
        quan tới việc cung cấp, khai thác, sử dụng dịch vụ trò chơi điện tử mà
        thông qua đó người dùng có thể truy cập, tải về, và sử dụng được game
        theo nhưng mong muốn. Dịch vụ bao gồm nhưng không giới hạn tới Dịch vụ
        game, Dịch vụ hỗ trợ thanh toán,…
      </p>
      <br />
      <p>
        <i>
          <strong>3. “Website”</strong>
        </i>
        &nbsp;được hiểu là trang thông tin điện tử có địa chỉ
        https://boomgames.vn, và các trang thông tin điện tử khác được sử dụng
        bởi Công ty để duy trì, cung cấp, vận hành dịch vụ, hoặc có liên quan
        tới việc cung cấp Dịch vụ.
      </p>
      <br />
      <p>
        <i>
          <strong>4. “Đối tác”</strong>
        </i>
        &nbsp;được hiểu là các đối tác, có thể là thể nhân hoặc pháp nhân mà có
        hợp tác với Boom trong việc cung cấp Dịch vụ, Nội dung,…
      </p>
      <br />
      <p>
        <i>
          <strong>5. “Tài khoản”</strong>
        </i>
        , hoặc&nbsp;
        <i>
          <strong>“BoomID”</strong>
        </i>
        &nbsp;có nghĩa là một tập hợp các chuỗi ký tự hoặc bất kỳ các dấu khác
        mà phát hành bởi Công ty tại thời điểm bắt đầu việc sử dụng Dịch vụ để
        định danh một người dùng duy nhất. Trong số đó, định danh (các định
        danh) được thiết lập bởi các công ty dựa trên ứng dụng của người dùng
        được gọi là&nbsp;
        <i>
          <strong>“ID Người Dùng”</strong>
        </i>
        .
      </p>
      <br />
      <p>
        <i>
          <strong>6. “Điều Khoản và Điều kiện riêng biệt”</strong>
        </i>
        &nbsp;có nghĩa là các tài liệu đã phát hành, hoặc sẽ được Công ty phát
        hành. Việc phát hành Điều Khoản và Điều kiện riêng biệt được thể hiện
        thông qua việc tải lên bởi Công ty liên quan đến Dịch vụ, dưới tiêu
        đề&nbsp;
        <i>
          <strong>“thỏa thuận”</strong>
        </i>
        ,&nbsp;
        <i>
          <strong>“hướng dẫn”</strong>
        </i>
        ,
        <i>
          <strong>“chính sách”</strong>
        </i>
        , hoặc dưới dạng tiêu đề khác tại website cung cấp Dịch vụ đó. Điều
        Khoản và Điều kiện riêng biệt là một bộ phận cấu thành của Điều Khoản và
        Điều kiện này. Việc đề cập tới Điều Khoản và Điều kiện trong văn bản này
        cũng ngụ ý và bao hàm/bao gồm cả các Điều Khoản và Điều kiện riêng biệt.
      </p>
      <br />
      <p>
        <i>
          <strong>7. “Nội dung”</strong>
        </i>
        &nbsp;có nghĩa là những thông tin như văn bản, tập tin âm thanh, âm
        nhạc, hình ảnh, video, phần mềm, chương trình, mã máy tính, vv... mà có
        thể được truy cập, hoặc sử dụng thông qua Dịch vụ.
      </p>
      <br />
      <p>
        <i>
          <strong>8. “Nội dung đệ trình”</strong>
        </i>
        &nbsp;có nghĩa là những nội dung mà Người dùng đã gửi, truyền hoặc tải
        lên, bao gồm nhưng không giới hạn tới các ký tự, văn bản, nội dung,
        thông điệp, thông báo, hình ảnh, các tệp tin hình ảnh, tệp tin âm thanh,
        đoạn clip,...
      </p>
      <br />
      <p>
        <i>
          <strong>9. “Dịch vụ trả tiền”</strong>
        </i>
        &nbsp;có nghĩa là Dịch vụ hoặc Nội dung được cung cấp cho Người dùng
        Dịch vụ, mà không phải là miễn phí (bao gồm nhưng không giới hạn những
        trường hợp Dịch vụ hoặc Nội dung được trao đổi trong tiền tiện game mà
        không phải là miễn phí).
      </p>
      <br />
      <p>
        <i>
          <strong>10. “Bất khả kháng”</strong>
        </i>
        &nbsp;là các sự kiện xảy trong quá trình cung cấp Dịch vụ nằm ngoài ý
        chí chủ quan của Công ty, Bất khả kháng xảy ra ngoài dự liệu hợp lý của
        Công ty và khiến Công ty không thể khắc phục được dù đã áp dụng các biện
        pháp hợp lý trong khả năng của mình. Bất khả kháng bao gồm nhưng không
        giới hạn tới thiên tai, chiến tranh, bạo loạn, đình công, sự cố kỹ thuật
        khách quan, tấn công bởi tin tặc, xâm nhập hệ thống trái phép, đánh cắp
        thông tin, các hành động hacking khác,…
      </p>
      <br />
      <p>
        <strong>II. Thoả thuận với các Điều khoản và Điều kiện</strong>
      </p>
      <br />
      <p>
        1. Tất cả Người dùng đồng ý và cam kết sử dụng Dịch vụ phù hợp với các
        quy định trong Điều Khoản và Điều kiện. Người dùng không thể sử dụng
        Dịch vụ trừ khi họ đồng ý với các Điều Khoản và Điều kiện đó. Thỏa
        thuận/cam kết này là hợp lệ và không thể thay đổi.
      </p>
      <br />
      <p>
        2. Khi sử dụng và/hoặc tiếp tục sử dụng Dịch vụ, người dùng được coi là
        đã đồng ý với các Điều Khoản và Điều kiện cũng như với mọi thay đổi,
        chỉnh sửa, bổ sung của Điều Khoản và Điều kiện. Thỏa thuận này là hợp lệ
        và không thể thay đổi.
      </p>
      <br />
      <p>
        3. Nếu có các Điều Khoản và Điều kiện riêng biệt cho các Dịch vụ, hoặc
        từng Dịch vụ, người dùng cũng đồng ý tuân thủ theo các Điều Khoản và
        Điều kiện riêng biệt đó cũng như tuân thủ các Điều Khoản và Điều kiện
        thông thường. Điều Khoản và Điều kiện riêng biệt cho các Dịch vụ có hiệu
        lực ngay lập tức kể từ thời điểm được đăng tải và hiển thị trên website.
      </p>
      <br />
      <p>
        4. Người dùng xác nhận và đồng ý rằng giữa người dùng và Công ty không
        tồn tại bất kỳ một quan hệ liên doanh, đối tác, quan hệ lao động, hoặc
        quan hệ đại lý nào xuất phát từ Điều Khoản và Điều kiện hoặc do việc
        người dùng sử dụng Dịch vụ của Công ty. Công ty thực hiện Điều Khoản và
        Điều kiện này theo quy định của pháp luật hiện hành và không nội dung
        nào trong Điều Khoản và Điều kiện này cản trở quyền của Công ty tuân thủ
        các quy định hoặc yêu cầu của chính phủ, tòa án, cơ quan thi hành án,
        cũng như của các cơ quan quyền lực Nhà nước có thẩm quyền khác liên quan
        đến việc người dùng sử dụng Dịch vụ của Công ty hoặc thông tin do chúng
        tôi nhận được hoặc thu được từ việc sử dụng Dịch vụ của Bạn.
      </p>
      <br />
      <p>
        <strong>III. Sửa đổi Điều khoản và Điều kiện</strong>
      </p>
      <br />
      <p>
        Công ty có thể sửa đổi các Điều Khoản và Điều kiện và/hoặc Điều Khoản và
        Điều kiện riêng biệt khi Công ty cho là cần thiết mà không cần thông báo
        trước cho người dùng. Sửa đổi sẽ có hiệu lực ngay lập tức khi các Điều
        Khoản và Điều kiện sửa đổi đó được đăng tải trên website điều hành bởi
        Công ty dành cho việc cung cấp Dịch vụ đó. Người dùng sẽ được coi là
        chấp thuận với các sửa đổi đó thông qua việc tiếp tục sử dụng Dịch vụ.
      </p>
      <br />
      <p>
        Người dùng đồng ý rằng người dùng có trách nhiệm chú ý đến, cũng như
        kiểm tra các điều khoản và điều kiện một cách thường xuyên khi sử dụng
        Dịch vụ từ khi nhận được một thông báo riêng biệt liên quan đến các sửa
        đổi Điều Khoản và Điều kiện hay sửa đổi Điều Khoản và Điều kiện riêng
        biệt đó có thể không được cung cấp. Mặc dù vậy, việc thông báo liên quan
        đến sửa đổi Điều Khoản và Điều kiện như vậy không phải là bắt buộc và
        không ảnh hưởng tới hiệu lực của việc sửa đổi Điều Khoản và Điều kiện.
      </p>
      <br />
      <p>
        <strong>IV. Tài khoản</strong>
      </p>
      <br />
      <p>
        1. Người dùng khi cung cấp thông tin của mình tới Công ty sẽ phải cung
        cấp những thông tin xác thực, chính xác và đầy đủ trong khi sử dụng Dịch
        vụ cũng như giữ những thông tin đó luôn được cập nhật mỗi khi có sự thay
        đổi, sửa đổi, hoặc thay thế. Những thông tin cơ bản mà người dùng cần
        phải cung cấp bao gồm:
      </p>
      <br />
      <p>- Họ và tên;</p>
      <br />
      <p>- Ngày, tháng, năm sinh;</p>
      <br />
      <p>- Địa chỉ đăng ký thường trú;</p>
      <br />
      <p>
        - Số chứng minh nhân dân/căn cước công dân hoặc số hộ chiếu, ngày cấp,
        nơi cấp;
      </p>
      <br />
      <p>- Số điện thoại, địa chỉ thư điện tử (nếu có);</p>
      <br />
      <p>
        Trường hợp chủ tài khoản dưới 14 tuổi và chưa có Chứng minh nhân dân
        hoặc hộ chiếu, người giám hộ hợp pháp của người chơi quyết định việc
        đăng ký thông tin cá nhân của mình để thể hiện sự đồng ý và chịu trách
        nhiệm trước pháp luật về việc đăng ký đó.
      </p>
      <br />
      <p>
        Trường hợp người dùng không cung cấp hoặc cung cấp không chính xác,
        không đầy đủ các thông tin nêu trên, Boom có quyền:
      </p>
      <br />
      <p>vi. từ chối cung cấp dịch vụ cho người dùng;</p>
      <br />
      <p>
        vii. ngừng cung cấp dịch vụ Trò Chơi cho người dùng; hoặc hạn chế hoặc
        cấm sử dụng một số hoặc toàn bộ tính năng của tài khoản, xóa tài khoản
        và Dữ Liệu trong Trò Chơi, xóa mọi thông tin liên quan, khóa tài khoản
        trò chơi; và/hoặc:
      </p>
      <br />
      <p>
        viii. từ chối tiếp nhận, giải quyết các khiếu nại của người dùng liên
        quan đến Trò Chơi và/hoặc tài khoản của người dùng trong Trò Chơi.
      </p>
      <br />
      <p>
        2.Nếu người dùng đăng ký một mật khẩu khi sử dụng dịch vụ, người dùng sẽ
        phải chú ý và bảo mật đến mật khẩu của mình và có trách nhiệm không sử
        dụng mật khẩu đó theo một cách thức trái pháp luật. Công ty có thể xử lý
        tất cả các hoạt động được thực hiện thông qua tài khoản của Người dùng
        như là hoạt động đã được thực hiện bởi chủ sở hữu của tài khoản đó.
      </p>
      <br />
      <p>
        3. Khi Người dùng thay đổi thiết bị truyền của mình, người dùng có thể
        không tiếp tục sử dụng dữ liệu Tiền tệ game, Nội dung đệ trình, vv...
        trên thiết bị truyền mới của Người dùng trong trường hợp Người dùng đang
        sử dụng Dịch vụ mà không tạo ID Người dùng, hoặc nơi không có chức năng
        thiết lập một ID Người dùng trong dịch vụ.
      </p>
      <br />
      <p>
        4. Công ty có toàn quyền xóa, cũng như chấm dứt việc sử dụng Dịch vụ của
        bất kỳ tài khoản nào mà không hoạt động trong một khoảng thời gian 180
        ngày hoặc nhiều hơn kể từ lần kích hoạt, đăng nhập cuối cùng mà không
        cần thông báo trước cho Người dùng.
      </p>
      <br />
      <p>
        5. Quyền của Người dùng trong việc dùng Dịch vụ sẽ tự động chấm dứt khi
        tài khoản của họ bị xóa vì bất kỳ lý do nào.
      </p>
      <br />
      <p>
        6. Mỗi tài khoản trong Dịch vụ dành cho việc sử dụng độc quyền và chỉ
        thuộc về chủ sở hữu của tài khoản. Người dùng không được phép chuyển
        giao/chuyển nhượng và/hoặc cho mượn tài khoản của họ tới bất kỳ bên thứ
        ba nào và cũng không được thừa kế bởi bất kỳ bên thứ ba nào.
      </p>
      <br />
      <p>
        7. Với Tài khoản/BoomID của mình, Người dùng có thể khởi tạo các Tài
        khoản game trong việc sử dụng các trò chơi điện tử cung cấp bởi Công ty,
        trong đó với mỗi trò chơi điện tử, Người dùng có thể khởi tạo một hoặc
        nhiều Tài khoản game tương ứng.
      </p>
      <br />
      <p>
        8. Trong quá trình chuyển nhượng Tài khoản game, Người dùng, bao gồm cả
        Người dùng chuyển nhượng Tài khoản game và Người dùng đồng ý nhận chuyển
        nhượng Tài khoản game, đồng ý và chấp nhận mọi sai sót, sai lệch, mất
        mát, thất thoát dữ liệu hoặc bất kỳ rủi ro nào khác có thể xảy ra đối
        với Tài khoản game được chuyển nhượng.
      </p>
      <br />
      <p>9. Yêu cầu về độ tuổi đối với chủ tài khoản:</p>
      <br />
      <figure>
        <table className={styles['table']}>
          <thead>
            <tr>
              <th>
                <strong>Tiêu chí</strong>
              </th>
              <th>
                <strong>
                  Trò chơi điện tử dành cho người lớn (từ 18 tuổi trở lên, ký
                  hiệu là 18+)
                </strong>
              </th>
              <th>
                <strong>
                  Trò chơi điện tử dành cho thiếu niên (từ 12 tuổi trở lên, ký
                  hiệu là 12+)
                </strong>
              </th>
              <th>
                <strong>
                  Trò chơi điện tử danh cho mọi lứa tuổi (ký hiệu là 00+)
                </strong>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Hình ảnh, giao diện chung trò chơi</td>
              <td>Thiết kế trò chơi 2D, 3D mô phỏng sống động</td>
              <td>
                - Thiết kế trò chơi 2D, 3D mô phỏng, chân thực, sống động; -
                Hoặc thiết kế dạng hoạt hình.
              </td>
              <td>
                Thiết kế trò chơi dạng hình đơn giản, dễ thương, ngộ nghĩnh.
              </td>
            </tr>
            <tr>
              <td>Hình ảnh nhân vật trong trò chơi</td>
              <td>
                - Không có hình ảnh nhân vật mặc hở hang, khiêu dâm, bộ phận
                nhạy cảm. - Có thể quay cận cảnh nhân vật.
              </td>
              <td>
                - Không có hình ảnh nhân vật mặc hở hang, khiêu dâm. - Không
                quay cận cảnh gây chú ý đến các bộ phận nhạy cảm trên cơ thể
                người.
              </td>
              <td>
                - Không có hình ảnh nhân vật mặc hở hang, khiêu dâm. - Không
                quay cận cảnh gây chú ý đến các bộ phận nhạy cảm trên cơ thể
                người.
              </td>
            </tr>
            <tr>
              <td>Thiết bị vũ khí</td>
              <td>
                - Thiết kế vũ khí dạng mô phỏng, chân thực. - Có thể quay cận
                cảnh hoặc không cận cảnh
              </td>
              <td>
                - Thiết kế vũ khí dạng mô phỏng hoặc dạng hoạt hình. - Hình ảnh
                vũ khí không được quay cận cảnh, rõ ràng.
              </td>
              <td>Không có vũ khí trong hoạt động đối kháng.</td>
            </tr>
            <tr>
              <td>Hoạt động đối kháng trong trò chơi</td>
              <td>Có hoạt động đối kháng.</td>
              <td>Có hoạt động đối kháng.</td>
              <td>Có hoạt động đối kháng nhưng không sử dụng vũ khí.</td>
            </tr>
            <tr>
              <td>Âm thanh trong trò chơi</td>
              <td>Không có âm thanh ma quái, kinh dị, bạo lực, khiêu dâm.</td>
              <td>Không có âm thanh ma quái, kinh dị, bạo lực, khiêu dâm.</td>
              <td>Không có âm thanh ma quái, kinh dị, bạo lực, khiêu dâm.</td>
            </tr>
          </tbody>
        </table>
      </figure>
      <br />
      <p>
        10. Công ty có thực hiện phân loại độ tuổi trò chơi với các game mà Công
        ty phát hành, người dùng có trách nhiệm lựa chọn và chỉ tham gia các
        game phù hợp với độ tuổi của mình.
      </p>
      <br />
      <p>
        11. Người chơi hiểu rõ và đồng ý rằng, để tránh trường hợp bị mất, bị
        can thiệp hoặc xâm nhập vào tài khoản một cách ngoài ý muốn (như bị
        hack), người chơi sẽ không tiết lộ thông tin về tài khoản, mật khẩu cho
        bất kỳ bên thứ ba nào kể cả những người thân thích; đồng thời áp dụng
        các biện pháp hợp lý nhằm bảo mật tài khoản của mình. Trong bất kỳ
        trường hợp nào, người chơi nhận thấy hay nghi ngờ tài khoản của mình bị
        đăng nhập trái với ý muốn của mình, người chơi có thể ngay lập tức thay
        đổi mật khẩu và/hoặc thông báo cho Boom và thực hiện các thủ tục cần
        thiết tạm khóa tài khoản nhằm giảm thiểu các thiệt hại cho ngươi dùng
        theo đúng quy trình hỗ trợ giải quyết khiếu nại khách hàng của Công ty.
      </p>
      <br />
      <p>
        12. Quy tắc đặt tên tài khoản, tên nhân vật:
        <br />
        Việc đặt tên tài khoản, tên nhân vật của Bạn trong Trò Chơi cần phải
        tuân thủ nguyên tắc đặt tên như sau:
      </p>
      <br />
      <p>
        a. Các trường hợp đặt tên:
        <br />
        Nhân vật trong trò chơi (character's name). Tài khoản đăng nhập (account
        name). Các nội dung cho phép đặt tên khác.
      </p>
      <br />
      <p>
        b. Quy tắc đặt tên:
        <br />
        Không đặt bất cứ tên nào có nội dung liên quan hoặc khiến người khác
        liên tưởng đến tên của các vĩ nhân, lãnh đạo chính quyền các cấp, những
        anh hùng trong lịch sử. Không đặt bất cứ tên nào có nội dung liên quan
        đến các sự việc hay nội dung phản động, bài xích tôn giáo, khiêu dâm,
        quảng cáo hay phản thuần phong mỹ tục và văn hóa Việt Nam. Không đặt bất
        cứ tên nào có nội dung xúc phạm, khích bác đến người khác dưới bất kỳ
        hình thức nào. Không đặt bất cứ tên nào có nội dung phá rối hay làm mất
        uy tín của Sản Phẩm cũng như của công ty phát hành. Nghiêm cấm đặt mọi
        tên có thể khiến người khác hiểu lầm hay liên tưởng tên đó (tức nhân vật
        hoặc bang phái sở hữu tên đó) có liên quan đến các thành viên trong công
        ty Boom hay bản thân công ty Boom. Không đặt bất kỳ tên nào ám chỉ hoặc
        có ý ám chỉ đến những phần kín của cơ thể. Không đặt bất kỳ tên nào đi
        ngược lại thuần phong mỹ tục hay truyền thống văn hóa Việt Nam. Nếu vi
        phạm, bạn sẽ bị xóa bỏ nhân vật, tài khoản. Thậm chí trong trường hợp
        nặng hơn, có thể sẽ khóa tài khoản của người vi phạm và sẽ khước từ mọi
        khiếu nại hay đòi bồi thường trong trường hợp này.
      </p>
      <br />
      <p>c. Các bước mở tài khoản và đăng nhập</p>
      <br />
      <p>
        1. Sử dụng tài khoản BoomID. Trong trường hợp này, Bạn cần phải có tài
        khoản BoomID; bên cạnh việc tuân thủ Điều Khoản Sử Dụng này, Bạn còn
        phải tuân thủ đầy đủ Quy chế sử dụng BoomID.
      </p>
      <br />
      <p>
        2. Sử dụng tài khoản liên kết (ví dụ Facebook) (sau đây gọi là&nbsp;
        <i>
          <strong>“Tài Khoản Liên Kết”</strong>
        </i>
        ). Tùy từng thời điểm, Trò Chơi có thể cho phép Bạn sử dụng Tài Khoản
        Liên Kết để đăng nhập, tuy nhiên Bạn tuân thủ đầy đủ Điều Khoản và Điều
        kiện này, và điều khoản sử dụng của nhà cung cấp dịch vụ nơi Bạn khởi
        tạo Tài Khoản Liên Kết, đồng thời cam kết chịu trách nhiệm về việc sử
        dụng các Tài Khoản Liên Kết để đăng nhập vào Trò Chơi.
      </p>
      <br />
      <p>
        3. Sử dụng tính năng&nbsp;
        <i>
          <strong>“Chơi Ngay”</strong>
        </i>
        &nbsp;của Sản Phẩm trong lần đăng nhập đầu tiên. Trong trường hợp này,
        tài khoản Trò Chơi của Bạn sẽ gắn liền với thiết bị mà Bạn đang sử dụng
        để đăng nhập vào Trò Chơi. Bạn có trách nhiệm định danh lại tài khoản
        của mình bằng cách khai báo các thông tin mà Trò Chơi yêu cầu.
      </p>
      <p>
        Trong mọi trường hợp, bạn được yêu cầu phải cung cấp đầy đủ các thông
        tin được quy định tại Điều 4.1 ở trên để bảo đảm tuân thủ Điều Khoản này
        và pháp luật hiện hành của Việt Nam.
      </p>
      <p>
        Nhằm đảm bảo quyền và lợi ích hợp pháp của Bạn, Boom khuyến cáo Bạn nên
        đăng nhập bằng tài khoản BoomID hoặc Tài Khoản Liên Kết khác của chính
        Bạn. Boom sẽ không thể tiếp nhận và hỗ trợ giải quyết các khiếu nại của
        Bạn trong trường hợp Bạn không thể cung cấp đầy đủ thông tin để chứng
        minh Bạn là chủ sở hữu của tài khoản.
      </p>
      <br />
      <p>
        <strong>V. Quy định về dịch vụ</strong>
      </p>
      <br />
      <p>
        1. Người dùng sẽ tự cung cấp máy tính cá nhân cần thiết, thiết bị điện
        thoại di động, thiết bị truyền, Hệ điều hành, kết nối dữ liệu cũng như
        các phương tiện và điều kiện cần thiết khác cho việc sử dụng Dịch vụ bởi
        người dùng theo trách nhiệm của Người dùng với mức kinh phí mà người
        dùng bỏ ra.
      </p>
      <br />
      <p>
        2. Công ty có toàn quyền hạn chế quyền truy cập vào toàn bộ hoặc một
        phần của Dịch vụ tùy thuộc vào điều kiện mà Công ty xem xét cần thiết,
        bao gồm nhưng không giới hạn tới tuổi tác, nhận dạng, tình trạng thành
        viên hiện tại, vv...
      </p>
      <br />
      <p>
        3. Công ty có toàn quyền sửa đổi hoặc chấm dứt toàn bộ hoặc một phần của
        Dịch vụ bất cứ lúc nào theo ý kiến riêng của Công ty mà không cần bất kỳ
        thông báo trước nào cho Người dùng. Việc sửa đổi hoặc chấm dứt như vậy
        có hiệu lực ngay lập tức tại thời điểm Công ty thông báo về sửa đổi hoặc
        chấm dứt, trừ khi trong thông báo đó có quy định về một thời điểm khác.
      </p>
      <br />
      <p>
        4. Đối với Dịch vụ trả tiền, trong mọi trường hợp, Công ty sẽ không cung
        cấp bất kỳ hoàn phí nào đối với số tiền mà Người dùng đã nạp vào game,
        không quy đổi tiền ảo thành tiền mặt hoặc bất kỳ hiện vật nào khác có
        giá trị tương đương. Nếu dịch vụ game của Công ty bị đóng cửa hoặc dừng
        cung cấp, tùy thuộc vào nhu cầu của Người dùng, Công ty sẽ hỗ trợ Người
        dùng chuyển sang sử dụng loại dịch vụ khác do Công ty cung cấp và số
        lượng tiền tệ game trong tài khoản của Người dùng vẫn sẽ được bảo lưu và
        sử dụng được đối với các dịch vụ khác của Công ty.
      </p>
      <br />
      <p>
        5. Đối với Người dùng nạp tiền vào game do Boom phát hành bằng phương
        thức thanh toán thẻ trực tuyến thông qua các kênh trung gian thanh toán
        là đối tác của Công ty, trường hợp xảy ra các giao dịch không thành công
        nhưng tiền trong tài khoản của Người dùng vẫn bị trừ đi tương ứng,
        và/hoặc các trường hợp tra soát khiếu nại khác được xác minh là phát
        sinh do lỗi của Công ty, Công ty sẽ hoàn tiền cho Người dùng thông qua
        kênh trung gian thanh toán vào đúng số thẻ mà khách hàng thực hiện giao
        dịch đó trong thời hạn tối thiểu 10 (mười) ngày, kể từ ngày nhận được
        khiếu nại từ khách hàng và đã xác minh được lỗi của giao dịch thanh
        toán.
      </p>
      <br />
      <p>
        6. Công ty không đảm bảo rằng bạn sẽ có thể truy cập hoặc sử dụng Dịch
        vụ tại thời gian và địa điểm mà bạn chọn, cũng không đảm bảo rằng Dịch
        vụ sẽ không bị gián đoạn hoặc không bị lỗi cũng như những lỗi đó sẽ được
        sửa chữa và các game hoặc Dịch vụ sẽ không có virus hoặc các thành phần
        gây hại khác. Công ty không chịu trách nhiệm cho bất kỳ gián đoạn hoặc
        lỗi, tổn thất, thiệt hại, đột nhập/xâm nhập trái phép, hành vi hacking,
        phá tường lửa (firewall), thất lạc thông tin (nếu có) xảy ra cho người
        dùng.
      </p>
      <br />
      <p>
        7. Khi sử dụng Dịch vụ của Công ty, bạn đồng ý cho phép Công ty trên cơ
        sở không hủy ngang quyền thu thập và lưu trữ thông tin mà bạn cung cấp
        khi mở tài khoản, cũng như các thông tin khác có liên quan đến sở thích,
        thói quen, thị hiếu, thao tác của bạn khi sử dụng Dịch vụ, kể cả việc
        gửi đến thiết bị của bạn các phần mềm, ứng dụng nhằm thu thập một số
        thông tin kỹ thuật được chứa đựng trong thiết bị của Bạn. Việc thu thập
        thông tin như vậy được Công ty sử dụng duy nhất cho mục đích cải tiến
        tính năng của game, của Dịch vụ để từ đó Công ty phát triển, hoàn thiện
        sản phẩm và dịch vụ đáp ứng phù hợp nhất với bạn. Boom cam kết bảo mật
        toàn bộ các thông tin mà Boom thu thập từ bạn.
      </p>
      <br />
      <p>
        8. Bạn hiểu rõ và đồng ý rằng, để tăng cường mức độ bảo mật, an toàn của
        dịch vụ, Công ty có quyền áp dụng một hoặc một vài biện pháp kỹ thuật
        và/hoặc phần mềm, ứng dụng vào trong hoặc bên cạnh Trò Chơi; mặc dù vậy,
        việc áp dụng các biện pháp nêu trên không được là sự đảm bảo rằng tài
        khoản của Bạn không bị mất, bị hack và Boom được miễn trừ hoàn toàn
        trách nhiệm bởi các trường hợp này.
      </p>
      <br />
      <p>
        9. Phù hợp với quy định về đơn vị ảo, vật phẩm ảo, điểm thưởng trong Trò
        Chơi và theo quy định pháp luật, Trò Chơi được cung cấp cho Bạn với mục
        đích giải trí cùng với các trải nghiệm (nhằm giải trí) của Bạn trong Trò
        Chơi; vì vậy, trường hợp Trò Chơi bị tạm ngưng hoặc ngưng cung cấp hoàn
        toàn vì bất kỳ lý do nào, Boom không có nghĩa vụ hoàn trả, bồi thường
        bất kỳ khoản tiền mặt hay hiện vật, hiện kim nào cho Bạn; mặc dù vậy,
        trường hợp trò chơi bị tạm ngưng hoặc ngưng cung cấp hoàn toàn, Boom sẽ
        nỗ lực thông báo trước đến Bạn trong một khoảng thời gian hợp lý và có
        phương án để đảm bảo quyền lợi của Bạn theo đúng quy định pháp luật.
      </p>
      <br />
      <p>
        10. Để cung cấp dịch vụ Trò Chơi đến Bạn tốt nhất và không ngừng cải
        thiện, Boom có quyền điều chỉnh, cập nhật hoặc tối ưu hóa như thiết lập
        các trị số liên quan và/hoặc tính năng của các đơn vị ảo, vật phẩm ảo,
        điểm thưởng trong Trò Chơi mà Bạn đã có hoặc đang sử dụng mà không cần
        báo trước hoặc cần sự đồng ý của Bạn và Bạn sẽ không truy cứu bất kỳ
        trách nhiệm nào của Boom.
      </p>
      <br />
      <p>
        11. Toàn bộ thông tin kỹ thuật, thông tin về tính năng, công thức, giải
        pháp kỹ thuật của Trò Chơi đều là thông tin mật; vì vậy, Boom không có
        nghĩa vụ cung cấp cho Bạn bất kỳ thông tin nào nêu trên dưới bất kỳ hình
        thức nào.
      </p>
      <br />
      <p>
        <strong>VI. Quảng cáo</strong>
      </p>
      <br />
      <p>
        Người dùng hiểu rõ và đồng ý rằng Công ty có quyền cung cấp tới người
        dùng các quảng cáo của Công ty, hoặc quảng cáo của Đối tác, hoặc của một
        bên thứ ba khác thông qua các hình thức như tin nhắn, điện thoại, thư
        điện tử (email), hiển thị trên màn hình trong quá trình sử dụng dịch vụ
        hoặc hiển thị khi người dùng sử dụng thiết bị,... hoặc bất kỳ hình thức
        nào mà người dùng có thể tiếp cận được thông tin; trong trường hợp này,
        Đối tác của Boom (nếu có) cũng có nghĩa vụ bảo mật thông tin cá nhân của
        người dùng như Công ty.
      </p>
      <br />
      <p>
        <strong>VII. Dịch vụ cả các đối tác</strong>
      </p>
      <br />
      <p>
        1. Nội dung hoặc các dịch vụ khác, cũng như quảng cáo được cung cấp bởi
        các Đối tác của Công ty và các trang thông tin điện tử liên kết với các
        Nội dung, dịch vụ, quảng cáo có thể được cung cấp bao gồm, hoặc kèm
        theo, hoặc liên kết với Dịch vụ của Công ty.
      </p>
      <br />
      <p>
        2. Người dùng hiểu và đồng ý rằng, Đối tác chịu tất cả các trách nhiệm
        về nội dung và/hoặc các dịch vụ họ cung cấp. Người dùng đồng ý và công
        nhận rằng Công ty có thể liên kết với hoặc cung cấp đường link của/hoặc
        dẫn tới các websites khác của các Đối tác hoặc bên thứ ba khác, và người
        dùng đồng ý rằng Công ty không kiểm soát và chịu trách nhiệm đối với,
        bao gồm nhưng không giới hạn tới bất kỳ nội dung, quảng cáo, dịch vụ,
        sản phẩm,... mà người dùng sử dụng, hoặc có được hoặc biết được từ các
        websites đó. Đồng thời người dùng cũng xác nhận rằng Công ty sẽ không
        chịu trách nhiệm đối với tổn thất và mất mát mà người dùng phải gánh
        chịu có nguyên nhân từ/hoặc do việc người dùng sử dụng các nội dung,
        quảng cáo, dịch vụ, sản phẩm,... đó từ các website như vậy.
      </p>
      <br />
      <p>
        3. Ngoài ra, Người dùng cũng đồng ý rằng người dùng sẽ, cùng với việc
        tuân thủ Điều Khoản và Điều kiện, tuân theo các điều khoản và điều kiện
        sử dụng dịch vụ do Đối tác cung cấp và /hoặc yêu cầu.
      </p>
      <br />
      <p>
        <strong>VIII. Nội dung</strong>
      </p>
      <br />
      <p>
        1. Công ty đồng ý cho phép người dùng có quyền, dựa trên cơ sở không
        chuyển nhượng, không cấp lại và không độc quyền, trong việc sử dụng Nội
        dung được cung cấp bởi Công ty, chỉ với mục đích sử dụng Dịch vụ.
      </p>
      <br />
      <p>
        2. Khi sử dụng Dịch vụ và Nội dung, người dùng phải tuân thủ theo những
        điều kiện thích hợp tuân theo các chi phí và thời gian sử dụng. Bất kể
        tình huống có các cụm từ như&nbsp;
        <i>
          <strong>“Mua hàng”</strong>
        </i>
        ,&nbsp;
        <i>
          <strong>“Bán hàng”</strong>
        </i>
        , hoặc tương tự vậy khi xuất hiện trên màn hình Dịch vụ hoặc được thông
        báo/hoặc hiển thị dưới hình thức khác, Công ty sẽ vẫn có tất cả các
        quyền sở hữu trí tuệ cũng như tất cả các quyền khác trong/hoặc đối với
        các Nội dung mà Công ty cung cấp cho Người dùng và những quyền đó sẽ
        không được chuyển giao cho Người dùng.
      </p>
      <br />
      <p>
        3. Người dùng không được sử dụng nội dung vượt ra ngoài phạm vi của việc
        sử dụng đã được định trước của Dịch vụ (bao gồm nhưng không giới hạn
        việc sao chép, truyền tải, mô phỏng, sửa đổi).
      </p>
      <br />
      <p>
        4. Người dùng sẽ phải duy trì các quyền liên quan đến Nội dung đệ trình
        của mình như trước, và Công ty sẽ không có bất kỳ quyền nào liên quan
        đến những nội dung đó. Tuy nhiên, nếu không chỉ Người dùng mà tất cả
        những người dùng khác đều có thể nhìn thấy những nội dung đó, hoặc các
        Nội dung đệ trình đó được người dùng đặt dưới chế độ ”công khai”/
        “public” thì Người dùng, người mà đăng Nội dung đệ trình đó sẽ đồng ý
        cấp cho Công ty một giấy phép/quyền mang tính toàn cầu, không độc quyền
        và miễn phí (với quyền cấp phép lại hoặc chuyển nhượng lại những nội
        dung đó cho các bên thứ ba làm việc với Công ty) với khoảng thời gian vô
        hạn để sử dụng (sau khi sửa đổi nội dung đó, nếu Công ty tin rằng nó cần
        thiết và thích hợp) những nội dung đó cho Dịch vụ và/hoặc mục đích quảng
        cáo. Việc cấp phép/quyền đó bởi Người dùng cho Công ty có hiệu lực từ
        thời điểm Người dùng cung cấp, hoặc truyền đưa lên, hoặc đăng tải các
        Nội dung đệ trình đó.
      </p>
      <br />
      <p>
        5. Nếu Công ty tin rằng người sử dụng đã vi phạm luật hiện hành hoặc
        những quy định trong Điều Khoản và Điều kiện liên quan tới những Nội
        dung đệ trình, Công ty có quyền ngăn cản người dùng sử dụng Nội dung đệ
        trình theo các cách thức nhất định, chẳng hạn như xóa Nội dung đệ trình
        mà không thông báo trước tới Người dùng. Công ty không có trách nhiệm
        bồi hoàn và/hoặc bồi thường cho người dùng bất kỳ thiệt hại hay mất mát
        nào xảy ra cho người dùng trong trường hợp này.
      </p>
      <br />
      <p>
        <strong>IX. Tiền tệ Game</strong>
      </p>
      <br />
      <p>
        1. Tiền tệ game sẽ được cung cấp cho Người dùng bởi các phương tiện được
        Công ty chỉ định, bao gồm nhưng không giới hạn tới mua hàng, mua bán,
        phục vụ đặc biệt, khuyến mãi, chiết khấu,… và tương tự vậy. Những chi
        tiết liên quan đến các phương pháp, đơn vị, điều khoản thanh toán và tất
        cả các điều kiện khác liên quan đến tiền tệ game sẽ được quyết định bởi
        Công ty và được hiển thị phù hợp theo Dịch vụ.
      </p>
      <br />
      <p>
        2. Tiền tệ game không thể được trao đổi hoặc sử dụng cho bất cứ mục đích
        nào khác ngoài việc được cung cấp với Dịch vụ theo quy định của Công ty.
        Số lượng Tiền tệ game cần thiết để tiếp nhận các Dịch vụ hoặc nội dung
        cung cấp và các Điều Khoản và Điều kiện của Tiền tệ game sẽ được quyết
        định bởi Công ty và hiển thị phù hợp cùng Dịch vụ.
      </p>
      <br />
      <p>
        3. Tiền tệ game một khi đã được tiêu bởi Người dùng thì không thể hoàn
        lại vì bất kỳ lý do nào. Tuy nhiên, điều này không áp dụng nếu được yêu
        cầu của luật hiện hành, hoặc vào các trường hợp đặc biệt mà Công ty
        quyết định khác. Nếu điều này xảy ra, Công ty sẽ xử lý hoàn phí Tiền tệ
        game theo quy định của pháp luật có liên quan, và những thông tin đó sẽ
        được tạo sẵn trên website của Công ty vv...
      </p>
      <br />
      <p>
        4. Tiền tệ game có thể được sử dụng chỉ trong tài khoản mà Người dùng
        tiêu các Tiền tệ game và không thể được chỉ định hoặc chuyển sang tài
        khoản khác. Hơn nữa, Tiền tệ game không thể được thực hiện hoặc kết hợp
        giữa thiết bị truyền chạy trên những hệ điều hành khác nhau như các loại
        tiền tệ game khác nhau mặc dù chúng được phân loại theo cùng một tên.
      </p>
      <br />
      <p>
        5. Boom chỉ chấp nhận việc thanh toán qua kênh thanh toán của Boom hoặc
        kênh thanh toán do đối tác của Boom cung cấp được giới thiệu, công bố
        trong Trò Chơi và/hoặc tại Trang Chủ Của Trò Chơi; trường hợp Bạn sử
        dụng các kênh thanh toán khác thì Boom sẽ không có bất kỳ trách nhiệm
        nào với Bạn. Mặc dù vậy, trong bất kỳ trường hợp nào sau khi Bạn thanh
        toán thành công, Boom đã ghi nhận số đơn vị quy đổi tương ứng vào tài
        khoản của Bạn trong Trò Chơi nhưng sau đó bị đơn vị cung cấp dịch vụ
        thanh toán, trung gian thanh toán yêu cầu hoàn tiền (do có yêu cầu,
        khiếu nại từ chủ thẻ) thì Boom buộc phải thực hiện yêu cầu hoàn tiền này
        theo các quy định liên quan và sẽ khấu trừ tương ứng vào tài khoản của
        Bạn, và các lợi ích khác mà Bạn có được từ lần thanh toán đó, trường hợp
        lần khấu trừ đó không đủ (do Bạn đã sử dụng) thì Boom có quyền khấu trừ
        vào các lần tiếp theo và/hoặc thu hồi vật phẩm ảo mà Bạn dùng đơn vị quy
        đổi để có được và/hoặc yêu cầu Bạn phải thanh toán đầy đủ số tiền tương
        ứng với vật phẩm ảo đã được quy đổi. Ngoài ra, trong một số trường hợp,
        tùy mức độ vi phạm, Bạn và tất cả các tài khoản thực hiện giao dịch có
        liên quan đến tài khoản thực hiện hoàn tiền này có thể bị khóa tài khoản
        có thời hạn và/hoặc vĩnh viễn liên quan đến hành vi này. Trường hợp
        người chơi giao dịch thanh toán bằng thẻ do tổ chức tín dụng được phép
        phát hành thất bại từ 05 (năm) lần trở lên, Boom có quyền khóa tài khoản
        người chơi cho đến thời điểm người chơi cung cấp được thông tin chứng
        minh không vi phạm điều khoản của Boom và quy định pháp luật hiện hành.
      </p>
      <br />
      <p>
        <strong>X. Hạn chế</strong>
      </p>
      <br />
      <p>
        Người dùng sẽ không được tham gia vào, hoặc thực hiện trực tiếp hoặc
        gián tiếp những nội dung quy định dưới đây khi sử dụng Dịch vụ, Nội
        dung:
      </p>
      <br />
      <p>
        1. Hoạt động vi phạm pháp luật, các tuyên án, các nghị quyết, các lệnh
        hoặc các biện pháp hành chính ràng buộc hợp pháp của Việt Nam.
      </p>
      <br />
      <p>
        2. Các hoạt động có thể cản trở, vi phạm, đi ngược lại trật tự công cộng
        hoặc thuần phong mỹ tục của Việt Nam.
      </p>
      <br />
      <p>
        3. Các hoạt động vi phạm quyền sở hữu trí tuệ, chẳng hạn như bản quyền,
        thương hiệu và bằng sáng chế, danh tiếng, bảo mật, và tất cả các quyền
        khác Công ty và/hoặc một bên thứ ba nào đó được cấp bởi pháp luật hoặc
        hợp đồng.
      </p>
      <br />
      <p>
        4. Hoạt động đăng hoặc truyền tải các biểu hiện tình dục hoặc bạo lực;
        những biểu hiện dẫn đến phân biệt đối xử, hoặc kỳ thị bởi chủng tộc,
        nguồn gốc quốc gia, tín ngưỡng, giới tính, tình trạng xã hội, nguồn gốc
        gia đình,vv...; những biểu hiện tạo ra hoặc khuyến khích hành vi tự sát,
        tự chấn thương hoặc lạm dụng ma túy; hoặc những biểu hiện chống đối xã
        hội bao gồm nội dung chống đối và làm phiền của những người khác.
      </p>
      <br />
      <p>
        5. Các hoạt động dẫn đến việc hiểu sai lệch về Công ty và/hoặc bên thứ
        ba hoặc cố ý lan truyền thông tin sai lệch.
      </p>
      <br />
      <p>
        6. Các hoạt động như gửi thư/tin nhắn/thông báo tùy tiện cho nhiều Người
        dùng (ngoại trừ những người đã được phê duyệt bởi Công ty), hoặc bất cứ
        hoạt động nào khác mà Công ty cho là để gửi thư rác).
      </p>
      <br />
      <p>
        7. Các hoạt động trao đổi quyền sử dụng Nội dung, Nội dung đệ trình,
        hoặc bất kỳ một phần Nội dung, hoặc Dịch vụ mà người dùng sử dụng, hoặc
        có được từ việc cung cấp Dịch vụ bởi Công ty cho người dùng đó. Vào tiền
        mặt, tài sản hoặc lợi ích kinh tế khác mà không có sự cho phép của Công
        ty.
      </p>
      <br />
      <p>
        8. Các hoạt động sử dụng dịch vụ cho doanh số bán hàng, tiếp thị, quảng
        cáo, hoặc các mục đích thương mại hoặc mục đích vì lợi nhuận, hoặc vì
        quyền lợi khác (ngoại trừ những người đã được phê duyệt bởi Công ty); sử
        dụng Dịch vụ cho các mục đích hành vi tình dục hoặc khiêu dâm; sử dụng
        Dịch vụ để một người với mục đích quan hệ tình dục hoặc bôi nhọ Người
        dùng khác hoặc sử dụng Dịch vụ cho những mục đích xâm phạm danh dự khác
        hoặc những cuộc tấn công chống lại những người dùng khác; hoặc sử dụng
        các dịch vụ cho các mục đích khác với mục đích thực sự của Dịch vụ, hoặc
        sử dụng cho các mục đích khác trái với pháp luật Việt Nam, hoặc pháp
        luật nơi người dùng đó cư trú
      </p>
      <br />
      <p>
        9. Các hoạt động có lợi hoặc cộng tác với các tổ chức, cá nhân, nhóm có
        hoạt động, hoặc có biểu hiện hoạt động vi phạm pháp luật của Việt Nam,
        chống đối xã hội, các chính sách của Đảng Cộng sản Việt Nam, của Nhà
        nước Việt Nam,…
      </p>
      <br />
      <p>
        10. Các hoạt động có liên quan đến các hoạt động tôn giáo hoặc lời mời
        đến các nhóm tôn giáo.
      </p>
      <br />
      <p>
        11. Các hoạt động bất hợp pháp hoặc không đúng dẫn đến sự thu thập, tiết
        lộ hoặc cung cấp những thông tin cá nhân lịch sử người dùng hoặc những
        thông tin tương tự của người khác.
      </p>
      <br />
      <p>
        12. Người dùng đồng ý và công nhận rằng người dùng sẽ không tham gia bất
        kỳ hoạt động hay thực hiện bất kỳ hành vi nào có thể hoặc đe dọa làm
        gián đoạn hoặc tạm ngừng việc duy trì, cung cấp Dịch vụ của Công ty hoặc
        các dịch vụ và mạng lưới liên kết với các Dịch vụ của Công ty. Người
        dùng không thực hiện bất kỳ hoạt động nào, bao gồm nhưng không giới hạn
        tới các hoạt động ảnh hưởng đến máy chủ hoặc các hệ thống mạng của Dịch
        vụ; lạm dụng Dịch vụ bằng các phương tiện của BOTs, các công cụ gian lận
        hoặc các biện pháp kỹ thuật khác; cố ý sử dụng lỗi của Dịch vụ; tiếp cận
        với Dịch vụ thông qua các thiết bị truyền mà sự thay đổi đã được thực
        hiện, hoặc trên các thiết bị truyền có sự can thiệp, sửa đổi phần cứng
        hoặc phần mềm (ví dụ, hack hoặc jailbreak), hoặc tương tự đã tạo ra
        nhưng đòi hỏi, yêu cầu bất hợp lý như nhiều lần hỏi cùng một câu hỏi
        không cần thiết, và can thiệp tới hoạt động của Công ty hoặc can thiệp
        tới việc sử dụng Dịch vụ của Người dùng, hoặc tiếp cạn, hoặc sử dụng
        Dịch vụ bằng các tài khoản đã được tạo lập trên các thiết bị bị đánh
        cắp, đánh mất mà tài khoản đó không phải do người dùng như vậy lập ra.
      </p>
      <br />
      <p>
        13. Không tiếp cận, hoặc sử dụng Dịch vụ bằng/thông qua tài khoản của
        người khác nếu không được người đó cho phép và được Công ty chấp thuận.
      </p>
      <br />
      <p>
        14. Các hoạt động hỗ trợ hoặc khuyến khích bất kỳ hoạt động nào được nêu
        trong các Điều Khoản của mục này.
      </p>
      <br />
      <p>15. Các hoạt động khác được Công ty xem xét là không phù hợp.</p>
      <br />
      <p>
        <strong>XI. Hành vi vi phạm và biện pháp xử lý</strong>
      </p>
      <br />
      <p>
        1. Bên cạnh việc tuân thủ các Quy Định Trò Chơi, Bạn sẽ không thực hiện
        bất kỳ hành vi nào, bao gồm nhưng không giới hạn được liệt kê sau đây:
      </p>
      <br />
      <p>a. Lợi dụng việc sử dụng Trò Chơi để:</p>
      <br />
      <p>
        i. Chống lại Nhà nước Cộng Hòa Xã Hội Chủ Nghĩa Việt Nam; gây phương hại
        đến an ninh quốc gia, trật tự an toàn xã hội; phá hoại khối đại đoàn kết
        dân tộc; tuyên truyền chiến tranh, khủng bố; gây hận thù, mâu thuẫn giữa
        các dân tộc, sắc tộc, tôn giáo;
      </p>
      <br />
      <p>
        ii. Tuyên truyền, kích động bạo lực, dâm ô, đồi trụy, tội ác, tệ nạn xã
        hội, mê tín dị đoan, phá hoại thuần phong, mỹ tục của dân tộc;
      </p>
      <br />
      <p>
        iii. Tiết lộ bí mật nhà nước, bí mật quân sự, an ninh, kinh tế, đối
        ngoại và những bí mật khác do pháp Luật quy định;
      </p>
      <br />
      <p>
        iv. Đưa thông tin xuyên tạc, vu khống, xúc phạm uy tín của tổ chức, danh
        dự và nhân phẩm của cá nhân;
      </p>
      <br />
      <p>
        v. Quảng cáo, tuyên truyền, mua bán hàng hóa, dịch vụ trong Trò Chơi,
        đặc biệt là hàng hóa, dịch vụ bị cấm; truyền bá tác phẩm báo chí, văn
        học, nghệ thuật, xuất bản phẩm bị cấm;
      </p>
      <br />
      <p>
        vii. Giả mạo tổ chức, cá nhân và phát tán thông tin giả mạo, thông tin
        sai sự thật xâm hại đến quyền và lợi ích hợp pháp của tổ chức, cá nhân.
      </p>
      <br />
      <p>
        b. Thực hiện các hành vi xâm phạm quyền sở hữu trí tuệ của Boom và Nhà
        phát triển Trò Chơi (hoặc đơn vị giữ bản quyền đối với Trò Chơi, hoặc
        đơn vị trao quyền phát hành Trò Chơi cho Boom), bao gồm nhưng không giới
        hạn tới các hành vi dưới đây:
      </p>
      <br />
      <p>
        i. Xóa các thông tin liên quan đến bản quyền, quyền sở hữu trí tuệ trên
        Sản Phẩm;
      </p>
      <br />
      <p>
        ii. Chỉnh sửa, đảo ngược mã, biên tập lại mã của Sản Phẩm hoặc dùng các
        cách thức khác để phát hiện mã nguồn gốc của Sản Phẩm;
      </p>
      <br />
      <p>
        iii. Rà soát (scan), kiểm tra, kiểm thử để phát hiện hay tìm kiếm lỗi
        (bug) hoặc điểm yếu tồn tại của Sản Phẩm cho mục đích bất chính;
      </p>
      <br />
      <p>
        iv. Tiến hành sao chép, chỉnh sửa, thêm, bớt, thay đổi tính năng hoặc
        hiệu quả vận hành của Sản Phẩm đối với dữ liệu sinh ra và lưu trữ tại
        bất kỳ điểm nào trong quá trình vận hành Sản Phẩm hoặc dữ liệu tương tác
        của máy chủ (server) và máy trạm (client) trong quá trình vận hành Sản
        Phẩm và dữ liệu hệ thống cần thiết để vận hành Sản Phẩm, bao gồm nhưng
        không giới hạn ở plug-in, auto hoặc hệ thống liên quan khác kết nối với
        các công cụ của bên thứ ba khi chưa được Boom cho phép, đồng ý trước
        bằng văn bản;
      </p>
      <br />
      <p>
        v. Sử dụng, cho thuê, cho mượn, sao chép, chỉnh sửa, liên kết, chuyển
        tải, biên tập, xuất bản hay tạo các website, microsite … đối với các nội
        dung sở hữu trí tuệ mà Boom sở hữu hoặc được độc quyền sử dụng, phân
        phối, phát hành;
      </p>
      <br />
      <p>
        vi. Tạo các website, microsite liên quan đến Sản Phẩm hoặc tương tự hoặc
        hoàn toàn giống với Sản Phẩm.
      </p>
      <br />
      <p>
        c. Liên quan đến vận hành, sử dụng Trò Chơi bao gồm nhưng không giới
        hạn:
      </p>
      <br />
      <p>i. Sử dụng trái phép tài khoản, mật khẩu của người chơi khác.</p>
      <br />
      <p>
        ii. Thực hiện việc mua bán, trao đổi đơn vị ảo, vật phẩm ảo, điểm thưởng
        trong Trò Chơi hoặc tài khoản Trò Chơi giữa người chơi với nhau bằng
        tiền, hiện vật hay hiện kim.
      </p>
      <br />
      <p>
        iii. Phá vỡ tính công bằng/tính cân bằng trong Trò Chơi bằng việc (i)
        lợi dụng - bất kể là vô tình hay hữu ý - (các) lỗi trong Trò Chơi, thiếu
        sót trong kịch bản, thiết kế của Trò Chơi nhằm mục đích gian lận hay bất
        kỳ hành vi nào vi phạm Quy Định Trò Chơi; (ii) sử dụng phần mềm, ứng
        dụng và/hoặc thiết bị thứ ba để can thiệp vào Trò Chơi và/hoặc hệ thống
        máy chủ của Trò Chơi và/hoặc (iii) bất kỳ hành vi nào nhằm giành được
        lợi thế nhiều hơn so với người chơi khác trái với Quy Định Trò Chơi.
      </p>
      <br />
      <p>
        iv. Đầu cơ, trục lợi các đơn vị ảo, vật phẩm ảo trong Trò Chơi và/hoặc
        sử dụng các đơn vị ảo, vật phẩm ảo được tạo ra trái với Quy Định Trò
        Chơi.
      </p>
      <br />
      <p>
        v. Bằng cách này hay cách khác để gian lận, không tuân thủ Quy Định Trò
        Chơi nhằm thu lợi bất chính.
      </p>
      <br />
      <p>
        vi. Bàn luận hoặc thể hiện quan điểm chính trị, tôn giáo, sắc tộc trong
        Trò Chơi.
      </p>
      <br />
      <p>
        vii. Nói tục, chửi bậy và/hoặc có hành vi, lời nói xúc phạm đến danh dự,
        uy tín, nhân phẩm của bất kỳ tổ chức, cá nhân nào bất kể là trong Trò
        Chơi hay ngoài Trò Chơi.
      </p>
      <br />
      <p>
        viii. Thực hiện các hành vi dụ dỗ, lôi kéo, hứa hẹn với người chơi khác
        nhằm mục đích lừa đảo hoặc mục đích bất chính khác.
      </p>
      <br />
      <p>
        ix. Mạo nhận hay cố ý làm người khác lầm tưởng Bạn là một người chơi
        khác và/hoặc người khác không phải là Bạn trong Trò Chơi.
      </p>
      <br />
      <p>
        x. Mạo nhận hay cố ý làm cho người khác lầm tưởng Bạn là nhân viên,
        người lao động hay cộng tác viên của Boom.
      </p>
      <br />
      <p>
        xi. Có bất kỳ hành vi, lời nói, cử chỉ hay thái độ làm tổn hại đến uy
        tín, thương hiệu của Trò Chơi và/hoặc Boom.
      </p>
      <br />
      <p>
        xii. Thực hiện các hành vi gây rối, gây mất an ninh trật tự tại trụ sở
        hay bất kỳ chi nhánh, văn phòng đại diện, địa điểm kinh doanh nào Boom
        và/hoặc các đơn vị liên kết với Boom.
      </p>
      <br />
      <p>
        xiii. Có bất kỳ lời nói, cử chỉ hay hành động nào xâm phạm đến thân thể,
        uy tín, danh dự, nhân phẩm hay đe dọa nhân viên, người lao động (bất kể
        là nhân viên thời vụ, cộng tác viên hay nhân viên chính thức) của Boom.
      </p>
      <br />
      <p>
        xiv. Các hành vi khác (i) vi phạm pháp luật; (ii) trái với thuần phong
        mỹ tục của người Việt Nam; (iii) trái với quy tắc hành xử thông thường
        của cộng đồng người sử dụng trò chơi điện tử trên mạng và/hoặc của ngành
        giải trí trực tuyến.
      </p>
      <br />
      <p>
        2. Đối với các hành vi vi phạm Quy Định Trò Chơi, Boom có quyền tự mình
        và/hoặc thông qua hệ thống, tính năng của Trò Chơi để xử lý các hành vi
        vi phạm; tùy theo mức độ, các hành vi vi phạm có thể bị xử lý theo một
        hoặc một vài hình thức sau:
      </p>
      <br />
      <p>
        a. Nhắc nhở: Áp dụng đối với các hành vi vi phạm lần đầu và gây ít ảnh
        hưởng đến Trò Chơi và/hoặc người chơi khác..
      </p>
      <br />
      <p>
        b. Cảnh cáo: Áp dụng đối với các hành vi vi phạm đã bị nhắc nhở nhưng
        không khắc phục và/hoặc tái phạm HOẶC vi phạm quy định tại các tiểu mục
        (vi), (vii), (viii), (ix), (x), (xi) điểm (c), Khoản 11.1 Điều 11 của
        Điều Khoản Sử Dụng này.
      </p>
      <br />
      <p>
        c. Khấu trừ và/hoặc xóa đơn vị ảo, vật phẩm ảo trong trò chơi: Áp dụng
        đối với các hành vi đã bị xử lý theo quy định tại điểm (a)/(b) khoản
        11.2 Điều này mà không khắc phục và/hoặc tái phạm VÀ/HOẶC vi phạm quy
        định tại các tiểu mục (iii), (iv), (v) điểm (c), Khoản 11.1 Điều 11 của
        Điều Khoản Sử Dụng này.
      </p>
      <br />
      <p>
        d. Khóa tài khoản có thời hạn (thời hạn cụ thể sẽ tùy thuộc vào hành vi,
        mức độ vi phạm): Áp dụng đối với các hành vi đã bị xử lý theo quy định
        tại điểm (a)/(b)/(c) khoản 11.2 Điều này mà không khắc phục và/hoặc tái
        phạm HOẶC vi phạm quy định tại các tiểu mục (xii), (xiii) điểm (c) khoản
        11.1 Điều 11 của Điều Khoản Sử Dụng này.
      </p>
      <br />
      <p>
        e. Khóa tài khoản vĩnh viễn: Áp dụng đối với các hành vi đã bị xử lý
        theo quy định tại điểm (d) khoản 11.2 Điều này mà không khắc phục
        và/hoặc tái phạm HOẶC vi phạm quy định tại các điểm (a), (b), tiểu mục
        (i), (ii), (xiv) điểm (c), khoản 11.1 Điều 11 của Điều Khoản Sử Dụng
        này.
      </p>
      <br />
      <p>
        f. Chuyển hồ sơ đến Cơ quan Nhà nước có thẩm quyền để xử lý vi phạm hay
        khởi tố hình sự: Áp dụng cho tất cả các hành vi vi phạm khi có dấu hiệu
        hình sự.
      </p>
      <br />
      <p>
        Mặc dù đã có quy định cụ thể ở trên, trong một số trường hợp đặc biệt,
        Boom có quyền áp dụng bổ sung hoặc áp dụng đồng thời các biện pháp xử lý
        nhằm mục đích răn đe và/hoặc đảm bảo môi trường Trò Chơi lành mạnh, công
        bằng và yêu cầu người chơi cung cấp bao gồm nhưng không giới hạn thông
        tin giải trình, các tài liệu cam kết, bằng chứng chứng minh khắc phục vi
        phạm,... liên quan đến các hành vi vi phạm này. Trường hợp Boom phát
        hiện hoặc nhận thấy, và/hoặc được nhận khiếu nại, tố cáo liên quan đến
        việc vi phạm Quy Định Trò Chơi của Bạn và/hoặc tài khoản trong Trò Chơi
        của Bạn thì tùy theo hành vi và mức độ vi phạm mà Boom có toàn quyền xử
        lý theo Quy Định Trò Chơi; đồng thời, Boom có quyền (nhưng không có
        nghĩa vụ) công bố tên tài khoản của Bạn, hình thức xử lý lên Trang Chủ
        Của Trò Chơi, thông báo bên trong Trò Chơi cho những người chơi khác
        và/hoặc các kênh thông tin khác mà Boom có thể tiếp cận, thông tin đến
        những người chơi khác của Trò Chơi.
      </p>
      <br />
      <p>
        <strong>XII. Trách nhiệm của người dùng</strong>
      </p>
      <br />
      <p>
        1. Thực hiện nghiêm ngặt, đúng và đầy đủ các nội dung điều chỉnh bởi
        pháp luật có liên quan, của Điều Khoản và Điều kiện này.
      </p>
      <br />
      <p>
        2. Người dùng phải sử dụng dịch vụ này và chấp nhận rủi ro của riêng
        mình, phải chịu tất cả trách nhiệm cho các hành động mà họ thực hiện
        cũng như kết quả tại Dịch vụ. Công ty sẽ không có trách nhiệm với việc
        mất các dữ liệu cá nhân (Tài khoản, Mật khẩu, thông tin thanh toán ,..)
        nếu người dùng chia sẻ thông tin của họ trên mạng cộng đồng hoặc với
        những người dùng trong và ngoài game.
      </p>
      <br />
      <p>
        3. Công ty có thể áp dụng một hoặc đồng thời nhiều biện pháp sau đây mà
        không cần thông báo trước cho Người dùng, nếu Công ty nhận thấy người
        dùng đang sử dụng Dịch vụ theo cách vi phạm các Điều Khoản và Điều kiện.
        Tuy nhiên, Công ty sẽ không chịu trách nhiệm sửa chữa hoặc ngăn chặn vi
        phạm đó của Người dùng hoặc những người khác.
      </p>
      <br />
      <p>- Thu hồi hoặc xoá toàn bộ hoặc một phần của Nội dung đệ trình.</p>
      <br />
      <p>
        - Đình chỉ hoặc thu hồi quyền của Người dùng trong việc sử dụng Nội
        dung.
      </p>
      <br />
      <p>- Tạm dừng hoặc xoá các tài khoản.</p>
      <br />
      <p>- Đình chỉ sử dụng toàn bộ hoặc một phần của Dịch vụ.</p>
      <br />
      <p>
        - Bất kỳ biện pháp khác mà Công ty xem xét thấy cần thiết và thích đáng.
      </p>
      <br />
      <p>
        4. Trong trường hợp mà Công ty đã bị mất/thiệt hại hoặc phải trả một chi
        phí (bao gồm nhưng không giới hạn phí luật sư) trực tiếp hoặc gián tiếp
        (bao gồm nhưng không giới hạn đối với trường hợp mà Công ty đã bị một
        bên thứ ba kiện về việc làm hư hại) do Người dùng vi phạm pháp luật hiện
        hành hoặc các Điều Khoản và Điều kiện trong khi sử dụng Dịch vụ, Người
        dùng đó ngay lập tức phải bồi hoàn cho Công ty mọi thiệt hại, chi phí
        theo yêu cầu của Công ty.
      </p>
      <br />
      <p>
        5. Bất cứ khi nào có một vấn đề về lỗi game hoặc lỗi Dịch vụ, người dùng
        nên báo các lỗi đó với Công ty ngay lập tức, Công ty sẽ có quy định cụ
        thể về thời hạn để người dùng báo lỗi, nếu quá thời hạn này mà lỗi không
        được báo, Công ty không cam kết có thể tìm ra và khắc phục được lỗi cho
        người dùng. Công ty có quyền thực hiện bất cứ hành động nào để sửa chữa
        các lỗi đó trong khả năng hợp lý nhất có thể. Việc sử dụng những lỗi
        hoặc các hành vi hack/gian lận sẽ khiến người dùng bị cảnh báo hoặc bị
        cấm.
      </p>
      <br />
      <p>
        6. Người dùng phải đồng ý với bên thứ ba của Công ty về những Điều Khoản
        dịch vụ và chính sách bảo mật của bên cung cấp Dịch vụ.
      </p>
      <br />
      <p>
        7. Người dùng đồng ý rằng người dùng sẽ không tham gia bất kỳ hoạt động
        hay thực hiện bất kỳ hành vi nào có thể hoặc đe dọa làm gián đoạn hoặc
        tạm ngừng Dịch vụ.
      </p>
      <br />
      <p>
        8. Người dùng chỉ được phép khởi tạo, đặt tên tài khoản, mật khẩu, tên
        nhật vật, cũng như truyền tải, đăng tải, truyền đưa Nội dung đệ trình
        với các nội dung phù hợp với pháp luật hiện hành, với thuần phong mỹ
        tục, phù hợp với với quy định tại Điều Khoản và Điều kiện này của Công
        ty.
      </p>
      <br />
      <p>
        <strong>XIII. Chấm dứt tài khoản và dịch vụ</strong>
      </p>
      <br />
      <p>
        1. Chúng tôi có thể hạn chế, đình chỉ, chấm dứt, sửa đổi, hoặc xóa tài
        khoản hoặc quyền truy cập vào dịch vụ, trang web hoặc Dịch vụ, nội dung
        khác cung cấp bởi chúng tôi nếu chúng tôi nghi ngờ bạn đang không tuân
        thủ bất kỳ nội dung nào của Điều Khoản và Điều kiện hoặc sử dụng Dịch vụ
        không đúng cách và không hợp pháp mà có hoặc không thông báo cho bạn.
        Bạn có thể mất tên người dùng và các dữ liệu cá nhân việc bị hủy bỏ hoặc
        giới hạn tài khoản, cũng như bất kỳ phúc lợi, đặc quyền, các hạng mục
        thu được từ việc sử dụng Dịch vụ và chúng tôi không có nghĩa vụ đền bù
        cho bạn bất kỳ những thiệt hại đó.
      </p>
      <br />
      <p>
        2. Công ty có quyền chấm dứt bất kỳ tài khoản nào không hoạt động từ 180
        ngày trở lên. Không hoạt động ở đây được hiểu là tài khoản đó không được
        đăng nhập bởi chủ sở hữu tài khoản. Mọi quyền có liên quan có từ và/hoặc
        liên quan đến tài khoản đó sẽ được chấm dứt cùng với việc chấm dứt tài
        khoản.
      </p>
      <br />
      <p>
        3. Phù hợp theo quy định của pháp luật hiện hành, Công ty có quyền ngừng
        cung cấp và/hoặc hỗ trợ Dịch vụ hoặc một trò chơi cụ thể hoặc một phần
        của Dịch vụ tại bất kỳ thời điểm nào, tại thời điểm đó giấy phép sử dụng
        Dịch vụ hoặc một phần sẽ được tự động chấm dứt. Trong trường hợp đó,
        Boom sẽ không bị yêu cầu cung cấp hoàn lại tiền, lợi ích hoặc các bồi
        thường khác cho Người dùng trong việc ngưng dịch vụ. Việc chấm dứt tài
        khoản của bạn có thể bao gồm vô hiệu hóa truy cập của bạn trong Dịch vụ
        hoặc bất kỳ phần nào đó bao gồm bất kỳ nội dung bạn đã gửi hoặc những
        người khác đã gửi.
      </p>
      <br />
      <p>
        <strong>XIV. Chính sách giải quyết khiếu nại</strong>
      </p>
      <br />
      <p>
        Công ty nhận thức được việc tiếp nhận và giải quyết thắc mắc, khiếu nại
        từ người sử dụng dịch vụ của Công ty là khâu quan trọng trong việc cung
        cấp dịch vụ. Do vậy, Công ty có bố trí các nguồn lực và nhân sự chu đáo
        và đầy đủ để thực hiện công việc này.
      </p>
      <br />
      <p>1. Người sử dụng dịch vụ có thể khiếu nại theo các phương thức sau:</p>
      <br />
      <p>
        Khiếu nại trên trang web boomgames.vn: Thông qua tài khoản&nbsp;
        <a href="https://boomgames.vn/">Boomgames.vn</a>, người dùng có thể khởi
        tạo các mục riêng, hoặc vào phần khiếu nại riêng để nêu ý kiến của mình.
        Bộ phận chăm sóc khách hàng công ty sẽ thu thập các khiếu nại và giải
        quyết theo các quy định của Công ty.
      </p>
      <br />
      <p>
        Khiếu nại trực tiếp trong trò chơi: Trong quá trình sử dụng dịch vụ,
        người dùng có thể gửi ý kiến, nội dung khiếu nại của mình trực tiếp cho
        đội ngũ vận hành trò chơi.
      </p>
      <br />
      <p>
        Khiếu nại bằng email, hoặc trực tiếp tại Công ty: Người dùng có thể gửi
        email tới email hỗ trợ
        <a href="mailto:hotro@notagames.vn">“hotro@boomstudio.vn”</a>
        &nbsp;để yêu cầu giải quyết thắc mắc, khiếu nại với bộ phần Chăm sóc
        khách hàng của Công ty.
      </p>
      <br />
      <p>
        Khi tố cáo, khiếu nại một tài khoản khác, Người dùng cần nêu rõ tên tài
        khoản bị tố cáo, khiếu nại và hành vi vi phạm của khoản đó. Để được bảo
        vệ quyền lợi, người dùng cần cung cấp đủ thông tin về tài khoản của mình
        theo yêu cầu và địa chỉ liên hệ cần thiết.
      </p>
      <br />
      <p>
        Trong vòng 48 giờ (bốn mươi tám giờ) kể từ thời điểm tiếp nhận khiếu
        nại, bộ phận giải quyết khiếu nại của Công ty sẽ gửi phản hồi đến người
        dùng về việc báo lỗi và/hoặc phản hồi về những khiếu nại của người dùng.
      </p>
      <br />
      <p>
        Người dùng đồng ý rằng, phản hồi của Boom về các khiếu nại, tố cáo của
        người dùng thông qua kênh chăm sóc khách hàng trực tuyến là quyết định
        cuối cùng và chính thức của Công ty theo đúng quy định của pháp luật.
      </p>
      <br />
      <p>2. Chính sách chăm sóc khách hàng của công ty:</p>
      <br />
      <p>a. Nguyên Tắc Hỗ Trợ:</p>
      <br />
      <p>
        Bộ phận CS (“Chăm Sóc”) sẽ là một trong những tác nhân lớn nhất để khách
        hàng đánh giá mức độ chuyên nghiệp và chất lượng của sản phẩm (game) nói
        riêng và của công ty nói chung. Vì vậy khi hỗ trợ, nhân viên CS cần có
        thái độ hòa nhã, lịch sự, hướng dẫn cẩn thận, tạo sự thoải mái trong quá
        trình hỗ trợ.
      </p>
      <br />
      <p>
        Các trường hợp quan trọng, liên quan đến quyền lợi người chơi hay có tác
        động đến tài khoản, nhân vật như yêu cầu hỗ trợ về việc cung cấp lại mật
        khẩu, báo hack, lỗi game, xóa nhân vật,... buộc phải dùng email đăng ký
        tài khoản gửi hòm thư hỗ trợ hoặc cung cấp được CMND/passport có số dùng
        để đăng ký tài khoản. Không chấp nhận giải quyết các vấn đề trên mà
        không xác minh họ chính xác là chủ tài khoản hay không.
      </p>
      <br />
      <p>
        Các trường hợp quan trọng, liên quan đến quyền lợi người chơi hay có tác
        động đến tài khoản, nhân vật như yêu cầu hỗ trợ về việc cung cấp lại mật
        khẩu, báo hack, lỗi game, xóa nhân vật,... buộc phải dùng email đăng ký
        tài khoản gửi hòm thư hỗ trợ hoặc hoặc số điện thoại đăng ký tài khoản,
        hoặc cung cấp được CMND/passport có số dùng để đăng ký tài khoản. Không
        chấp nhận giải quyết các vấn đề trên mà không xác minh họ chính xác là
        chủ tài khoản hay không.
      </p>
      <br />
      <p>b. Nguyên Tắc Bảo Mật:</p>
      <br />
      <p>
        Không cung cấp bất kỳ thông tin nào của tài khoản và nhân vật game cho
        người không chứng minh được là chủ tài khoản trừ trường hợp theo yêu cầu
        của cơ quan nhà nước có thẩm quyền.
      </p>
      <br />
      <p>
        Không cung cấp bất kỳ thông tin nào khác về NPH (“Nhà phát hành”), về
        các thông tin về sản phẩm mới hay các thông tin nội bộ ngoài các thông
        tin đăng tải trên trang chủ của sản phẩm hỗ trợ trừ trường hợp theo yêu
        cầu của cơ quan nhà nước có thẩm quyền.
      </p>
      <br />
      <p>3. Chính sách liên quan tới Item (Tài sản ảo):</p>
      <br />
      <p>
        Người chơi sẽ được xem xét phục hồi lại nhân vật, Item khi bị tổn thất
        do lỗi hệ thống.
      </p>
      <br />
      <p>
        Việc yêu cầu phục hồi lại các Item phải được thông báo trong vòng 2 ngày
        (48 giờ) kể từ khi sự việc xảy ra, nếu quá thời hạn trên mà người chơi
        mới thông báo cho bộ phận hỗ trợ thì không được giải quyết.
      </p>
      <br />
      <p>
        Các Item được yêu cầu phục hồi phải nằm trong tài khoản của người thông
        báo. Không tiếp nhận yêu cầu phục hồi với tài khoản của người khác..
      </p>
      <br />
      <p>
        Thông tin cần thiết cho việc phục hồi nhân vật, item phải được thông báo
        chính xác, đầy đủ để việc xử lý được thực hiện nhanh nhất:
      </p>
      <br />
      <p>-Các thông tin về tài khoản (không bao gồm mật khẩu).</p>
      <br />
      <p>-Các mốc thời gian liên quan đến sự cố.</p>
      <br />
      <p>-Các Item quan trọng bị mất.</p>
      <br />
      <p>-Các thông số bị thay đổi.</p>
      <br />
      <p>-Các khả nghi liên quan khác vv...</p>
      <br />
      <p>
        Trong trường hợp báo cáo không trung thực hoặc thiếu các thông tin cần
        thiết, trường hợp đó sẽ không được giải quyết.
      </p>
      <br />
      <p>
        Việc yêu cầu phục hồi nhân vật, item phát sinh do việc sử dụng tài khoản
        để kinh doanh bằng tiền thật hoặc hiện vật, hiện kim thì không được xử
        lý. Trường hợp có yêu cầu cùng hợp tác điều tra của cơ quan nhà nước thì
        Boom phải cung cấp thông tin cá nhân của người sử dụng theo thủ tục hợp
        pháp.
      </p>
      <br />
      <p>
        Không tiếp nhận các yêu cầu khôi phục lại item về những tổn thất phát
        sinh do sự không tuân thủ quy định của các chính sách quản lý hay do sự
        sai sót của chính bản thân người sử dụng dịch vụ.
      </p>
      <br />
      <p>Sẽ không phục hồi các item đối với các trường hợp dưới đây:</p>
      <br />
      <p>-Phát sinh thiệt hại item do đột nhập tài khoản.</p>
      <br />
      <p>
        -Nội dung yêu cầu phục hồi không rõ ràng, cung cấp thông tin không chính
        xác.
      </p>
      <br />
      <p>-Từ bỏ quyền sở hữu item của mình (rơi xuống đất).</p>
      <br />
      <p>-Mua bán item cho NPC trong suốt quá trình chơi.</p>
      <br />
      <p>-Tổn thất item do sử dụng tài khoản chung.</p>
      <br />
      <p>-Tổn thất, thiệt hại phát sinh từ phía người sử dụng.</p>
      <br />
      <p>
        -Phát sinh thiệt hại item do cố tình chơi game trong thời gian server
        kiểm tra, bảo dưỡng định kì.
      </p>
      <br />
      <p>
        -Tổn thất, thiệt hại phát sinh do một số lý do từ hệ thống phía người sử
        dụng (trục trặc về đường truyền Internet, máy client bị lỗi ...).
      </p>
      <br />
      <p>
        4. Quy định về thời gian xử lý các yêu cầu của người chơi (khách hàng)
      </p>
      <br />
      <p>
        Khiếu nại của khách hàng phải được gửi trong vòng 48 giờ kể từ khi xảy
        ra sự việc, nếu sự việc xảy ra quá 48 giờ mà khách hàng mới khiếu nại,
        Boom sẽ không giải quyết bất cứ một khiếu nại nào của khách hàng và
        khách hàng phải tự chịu trách nhiệm về mọi rủi ro (nếu có).
      </p>
      <br />
      <p>
        Các yêu cầu, khiếu nại của khách hàng gửi lên sẽ được tiếp nhận và xử lý
        theo tuần tự. Đội điều hành game của Boom cam kết sẽ xử lý các yêu cầu,
        khiếu nại của khách hàng trong vòng 72 giờ kể từ thời điểm nhận được yêu
        cầu, khiếu nại.
      </p>
      <br />
      <p>
        Trong các trường hợp đặc biệt, đội điều hành game sẽ liên lạc trực tiếp
        với khách hàng về quá trình giải quyết vụ việc.
      </p>
      <br />
      <p>5. Quy trình giải quyết tranh chấp khiếu nại tài sản ảo</p>
      <br />
      <p>
        Quy trình giải quyết tranh chấp khiếu nại/tranh chấp về tài sản ảo trong
        trò chơi được thực hiện như sau:
      </p>
      <br />
      <p>
        <img
          src="assets/images/quytrinhkhieunai.jpg"
          alt="Quy trình giải quyết khiếu nại của NotA"
          style={{
            width: '100%',
            objectFit: 'cover',
            maxWidth: '1000px',
          }}
        />
      </p>
      <br />
      <p>
        Đối với mọi trường hợp mua bán, giao dịch, đội điều hành game sẽ không
        can thiệp và xử lý bất cứ giao dịch cá nhân nào trong game cũng như
        ngoài game. Boom sẽ không chịu bất kỳ trách nhiệm nào nếu xảy ra việc
        mất đồ, vàng, vật dụng trong game do sơ suất của người chơi lúc tiến
        hành giao dịch. Do đó, khi tham gia giao dịch người chơi nên chủ động
        chú ý và cẩn thận để tránh những trường hợp đáng tiếc xảy ra.
      </p>
      <br />
      <p>
        <strong>XV. Từ chối bảo đảm</strong>
      </p>
      <br />
      <p>
        Website và Dịch vụ của chúng tôi được cung cấp dựa trên nguyên tắc không
        bảo hành, không bảo đảm trên cơ sở dịch vụ và tính năng sẵn có mà không
        có bất kỳ sự bảo đảm hay cam kết, hoặc cam đoan bằng bất kỳ hình thức
        nào dù được thể hiện hoặc ngụ ý như vậy. Chúng tôi không đảm bảo rằng
        các tính năng trên website hoặc Dịch vụ của chúng tôi sẽ hoàn toàn đáp
        ứng được các yêu cầu và/hoặc mong muốn của Người dùng. Chúng tôi cũng
        không đảm bảo rằng sự cung cấp, vận hành các Dịch vụ của chúng tôi sẽ
        không bị gián đoạn, hoặc bị lỗi, hoặc gặp phải sự cố khiến cho việc cung
        cấp, vận hành các Dịch vụ diễn ra không được bình thường.
      </p>
      <br />
      <p>
        Chúng tôi không chịu trách nhiệm đối với bất kỳ sự mất mát dữ liệu, tổn
        thất lợi ích nào hoặc các vấn đề khác liên quan đến việc truy cập
        website và sử dụng Dịch vụ của chúng tôi, bao gồm nhưng không giới hạn
        đến tất cả các Nội dung, thông tin, dữ liệu, văn bản, phần mềm, âm nhạc,
        âm thanh, hình ảnh, đồ họa, video, thông điệp hoặc các nguyên liệu khác
        mà người dùng có thể lưu giữ, đưa lên, hoặc truyền tải thông qua Dịch vụ
        của chúng tôi, hoặc các thông tin về tài khoản, nhân vật, vật phẩm và
        các thông tin khác hình thành nên trong quá trình sử dụng Dịch vụ bởi
        người dùng.
      </p>
      <br />
      <p>
        Bạn đồng ý và hiểu rằng, mặc dù chúng tôi luôn nỗ lực để cung cấp Dịch
        vụ cũng như bảo đảm cho việc cung cấp Dịch vụ được diễn ra một cách ổn
        định nhất, nhưng rủi ro luôn có thể xảy ra do nhiều nguyên nhân khách
        quan và chủ quan, bao gồm nhưng không giới hạn tới sự kiện Bất khả
        kháng, sự cố kỹ thuật, lỗi đường truyền, tấn công từ bên ngoài, sự cố
        của nhà thầu/Đối tác cung cấp dịch vụ,... Bạn đồng ý sử dụng Dịch vụ của
        chúng tôi với tất cả sự rủi ro (nếu có) và đồng ý sẽ không tiến hành bất
        kỳ hành động pháp lý, hoặc tuyên bố, yêu cầu nào để yêu cầu bồi thường
        thiệt hại cho bất kỳ thiệt hại nào (nếu có) từ việc bạn sử dụng Dịch vụ
        của chúng tôi.
      </p>
      <br />
      <p>
        <strong>XVI. Quyền sở hữu trí tuệ của công ty</strong>
      </p>
      <br />
      <p>
        Người dùng đồng ý và công nhận rằng, Dịch vụ của chúng tôi, và tất cả
        các phần mềm cần thiết, cũng như các yếu tố/nguyên liệu cấu thành của
        Dịch vụ bao gồm nhưng không giới hạn tới các hình ảnh, nhãn hiệu, logo,
        kiểu dáng, bản vẽ, mô tả, chỉ dẫn, thông báo, mẫu hội thoại, bản đồ, mã
        lập trình,... và các yếu tố/nguyên liệu khác cấu thành nên Dịch vụ, (sau
        đây gọi chung là&nbsp;
        <i>
          <strong>“Tài sản trí tuệ”</strong>
        </i>
        ), là tài sản độc quyền của Công ty và của Đối tác cung cấp Dịch vụ đó
        tại mọi thời điểm và trong mọi trường hợp.
      </p>
      <br />
      <p>
        Người dùng đồng ý và công nhận rằng, tại mọi thời điểm, Tài sản Trí tuệ
        được bảo vệ bởi pháp luật Việt Nam cũng như các Điều ước Quốc tế mà Việt
        Nam là thành viên bất kể các Tài sản Trí tuệ đó đã được Công ty tiến
        hành thủ tục đăng ký bảo hộ quyền sở hữu trí tuệ hay chưa. Người dùng
        không có quyền sử dụng bất kỳ Tài sản Trí tuệ nào của Công ty và Đối tác
        vào mục đích thương mại, hoặc vì mục đích lợi nhuận trừ khi người dụng
        nhận được sự chấp thuận bằng văn bản của Công ty. Trong trường hợp được
        phép sử dụng Tài sản Trí tuệ cho các mục đích phi thương mại, người dùng
        cam kết rằng việc sử dụng Tài sản Trí tuệ như vậy sẽ không tạo ra bất kỳ
        chỉnh sửa, thay đổi, ảnh hưởng, cản trở, tác động tiêu cực tới Tài sản
        Trí tuệ, cũng như uy tín, thương hiệu của Công ty.
      </p>
      <br />
      <p>
        <strong>XVII. Luật điều chỉnh</strong>
      </p>
      <br />
      <p>
        Điều Khoản và Điều kiện được điều chỉnh và giải thích phù hợp với pháp
        luật Việt Nam. Nếu bất kỳ nội dung nào nào trong Điều Khoản và Điều kiện
        bị xem như là bất hợp pháp, vô hiệu hoặc vì bất kỳ lý do nào không thể
        thực hiện được theo phán quyết hợp lý của Tòa án, hoặc cơ quan quản lý
        Nhà nước có thẩm quyền, thì nội dung đó sẽ được xem là tách rời khỏi
        Điều Khoản và Điều kiện và không ảnh hưởng đến hiệu lực và tính thực thi
        của bất kỳ điều khoản còn lại nào của Điều Khoản và Điều kiện.
      </p>
      <br />
      <p>
        Trường hợp có sự mâu thuẫn giữa Điều Khoản và Điều kiện này với Quy chế
        sử dụng BoomID và/hoặc Thỏa thuận sử dụng của các Tài Khoản Liên Kết thì
        quy định tại Điều Khoản và Điều kiện này sẽ được ưu tiên áp dụng.
      </p>
      <br />
      <p>
        Trường hợp có sự mâu thuẫn giữa Điều Khoản và Điều kiện này với các
        Thông báo chính thức của Boom thì nội dung của Thông báo chính thức đó
        được ưu tiên áp dụng trước.
      </p>
      <br />
      <p>
        Nếu bạn có bất kỳ câu hỏi nào về các điều khoản hoặc trò chơi của chúng
        tôi bạn có thể liên hệ với chúng tôi qua email:&nbsp;
        <a href="mailto:hotro@notagames.vn">hotro@boomstudio.vn</a>
      </p>
      <br />
      <p>Các điều khoản này được cập nhật ngày 07 tháng 11 năm 2023.</p>
      <br />
      <p>Bản quyền © 2023 Boom Games. Tất cả các quyền đều đã được bảo lưu.</p>
      <p>&nbsp;</p>
    </div>
  );
}

export default Terms;
