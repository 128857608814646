//@ts-check

//#region "Modules"
import { BaseService } from '../base';
import { MethodResponse } from '@payment-mfe/shared/models';
//#endregion

//#region "Data Types"
export type Method = {
  uid: string;
  name: string;
  code: string;
  priority: number;
  avatarUrl: string;
  promotion: string | null;
};
export type IBanking = {
  uid: string;
  name: string;
  code: string;
  priority: number;
  avatarUrl: string;
};
//#endregion

export class MethodService extends BaseService<MethodResponse> {
  //#region "TODO remove"
  /**
   * List all payment method, supported by NotA.
   * @since 1.0
   * @param baseApiUrl The base api url of the payment server
   * @returns
   */
  async listAsync(baseApiUrl: string): Promise<MethodResponse[] | null> {
    const methods: MethodResponse[] = [
      {
        id: 'onepay',
        name: 'OnePay',
        type: 'gateway',
        avatar: '/assets/images/icons/gateways/onepay.png',
      },
      {
        id: 'momo',
        name: 'MoMo',
        type: 'gateway',
        avatar: '/assets/images/icons/gateways/momo.png',
      },
      {
        id: 'acb',
        name: 'ACB',
        type: 'virtual_account',
        avatar: '/assets/images/icons/banks/acb.png',
      },
    ];

    return methods;
  }
  //#endregion

  /**
   * List all payment method
   *
   * @param baseApiUrl
   * @returns
   */
  async listMethodAsync(baseApiUrl: string): Promise<Method[] | null> {
    const methods: Method[] = [
      {
        uid: 'va',
        name: 'Chuyển khoản trực tiếp',
        code: 'va',
        priority: 1,
        avatarUrl: '/assets/images/icons/payments/i-banking.png',
        promotion: '+5% NXu',
      },
      {
        uid: 'atm',
        name: 'QR Pay / ATM',
        code: 'atm',
        priority: 2,
        avatarUrl: '/assets/images/icons/payments/atm.png',
        promotion: null,
      },
      {
        uid: 'momo',
        name: 'Ví MoMo',
        code: 'momo',
        priority: 3,
        avatarUrl: '/assets/images/icons/payments/momo.png',
        promotion: null,
      },
      {
        uid: 'visa',
        name: 'Visa Card / Master Card',
        code: 'visa',
        priority: 2,
        avatarUrl: '/assets/images/icons/payments/visa.png',
        promotion: null,
      },
    ];

    return Promise.resolve(methods);
  }

  /**
   * List all i-banking
   *
   * @param baseApiUrl The entry-point of payment service
   * @returns
   */
  async listIbankingAsync(baseApiUrl: string): Promise<IBanking[] | null> {
    const iBanks: IBanking[] = [
      {
        uid: 'abc123',
        name: 'Ngân hàng TMCP Á Châu',
        code: 'acb',
        avatarUrl: '/assets/images/icons/banks/acb.png',
        priority: 1,
      },
    ];

    return Promise.resolve(iBanks);
  }
}
