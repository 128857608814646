import actions, {
  hidenModal,
  useAppDispatch,
  useAppSelector,
} from '@payment-mfe/shared/store';
import { Image, ImageProps, Modal, ModalProps } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './message.module.css';

/* eslint-disable-next-line */
export interface MessageProps {
  title: string;
  baseAuthApiUrl: string;
}

export function Message(props: MessageProps) {
  // #region "Redux State"

  const dispatch = useAppDispatch();
  const loadingModal = useAppSelector((state) => state.modalState.loading);
  const navigate = useNavigate();

  // #endregion

  //#region "Props"
  const modalProps: ModalProps = {
    title: null,
    footer: null,
    maskClosable: false,
  };
  //#endregion

  // #region "Local State"

  const [open, setIsOpen] = useState(false);
  const [mode, setMode] = useState('error');
  const [message, setMessage] = useState('message');
  const [iconSource, setIconSource] = useState('/assets/images/icon-error.png');

  // #endregion

  // #region "Styles"

  const iconImageProps: ImageProps = {
    src: iconSource,
    alt: mode === 'error' ? 'Thất bại' : 'Thành công',
    preview: false,
    height: mode === 'custom' ? 120 : 60,
  };

  // #endregion

  // #region "Logical"
  const showMessage = (
    mode: 'error' | 'success' | 'custom',
    message: string
  ) => {
    setIsOpen(true);
    setMode(mode);
    setMessage(message);

    switch (mode) {
      case 'error':
        setIconSource('/assets/images/icon-error.png');
        break;
      case 'success':
        setIconSource('/assets/images/icon-success.png');
        break;
      case 'custom':
        setIconSource('/assets/images/coming-soon.png');
        break;
    }
  };
  // #endregion

  // #region "Effects"
  React.useEffect(() => {
    switch (loadingModal) {
      case 'changePasswordFailure':
        showMessage('error', 'Đổi mật khẩu thất bại<br/>Vui lòng thử lại sau.');
        break;
      case 'changePasswordSuccess':
        showMessage(
          'success',
          'Đổi mật khẩu thành công<br/>Vui lòng đăng nhập để tiếp tục sử dụng hệ thống'
        );
        break;
      case 'forgotPasswordFailure':
        showMessage(
          'error',
          'Không tồn tại email này trên hệ thống<br/>Vui lòng nhập lại email'
        );
        break;
      case 'forgotPasswordSuccess':
        showMessage('success', 'Vui lòng kiểm tra email và làm theo hướng dẫn');
        break;
      case 'forgotPasswardVerifyFailure':
        showMessage(
          'error',
          'Đổi mật khẩu không thành công<br/>Vui lòng thử lại sau'
        );
        break;
      case 'forgotPasswordFailureByServerError':
        showMessage('error', 'Lỗi hệ thống<br/>Vui lòng thử lại sau');
        break;
      case 'forgotPasswordFailureByEmailVerified':
        showMessage('error', 'Địa chỉ e-mail của bạn chưa được xác thực');
        break;
      case 'forgotPasswardVerifySuccess':
        showMessage(
          'success',
          'Mật khẩu đã được đổi thành công<br/>Vui lòng đăng nhập để tiếp tục sử dụng dịch vụ'
        );
        break;
      case 'loginFailure':
        showMessage(
          'error',
          'Đăng nhập không thành công<br/>Sai tên đăng nhập hoặc mật khẩu'
        );
        break;
      case 'registerFailure':
        showMessage('error', 'Đăng ký tài khoản không thành công');
        break;
      case 'registerSuccess':
        showMessage('success', 'Đăng ký tài khoản thành công');
        break;
      case 'updateProfileFailure':
        showMessage('error', 'Không thể cập nhật thông tin hồ sơ cá nhân');
        break;
      case 'updateProfileSuccess':
        showMessage(
          'success',
          'Thông tin hồ sơ cá nhân đã được cập nhật thành công vào hệ thống'
        );
        break;
      case 'oauth2LoginFailure':
        showMessage(
          'error',
          'Đăng nhập thất bại! <br /> Không thể đăng nhập bằng FB/GG'
        );
        break;
      case 'forgotPassByOTPFailure':
        showMessage(
          'error',
          'Không tồn tại số điện thoại này trên hệ thống hoặc số điện thoại không đúng<br/>Vui lòng nhập lại số điện thoại'
        );
        break;
      case 'forgotPassByOTPSuccess':
        showMessage(
          'success',
          'Chúng tôi đã gửi một mã OTP đến số điện thoại của bạn<br/>Vui lòng kiểm tra tin nhắn của bạn'
        );
        break;
      case 'server_error':
        showMessage(
          'error',
          'Có một lỗi xảy ra trong quá trình thực hiện. Vui lòng thử lại sau'
        );
        break;
      case 'changeMobilePhoneNumberSuccess':
        showMessage('success', 'Đổi số điện thoại thành công');
        break;
      case 'changeEmailSuccess':
        showMessage('success', 'Đổi Email thành công');
        break;
      case 'verifyEmailSuccess':
        showMessage('success', 'Xác thực Email thành công');
        break;
      case 'verifyMobilePhoneNumberSuccess':
        showMessage('success', 'Xác thực số điện thoại thành công');
        break;
      case 'coming_soon':
        showMessage('custom', 'Tính năng mới sắp ra mắt');
        break;
      case 'idle':
        setIsOpen(false);
        break;
    }
  }, [loadingModal]);
  // #endregion

  // #region "View Render"

  return (
    <Modal
      {...modalProps}
      open={open}
      destroyOnClose={true}
      onCancel={() => {
        dispatch(hidenModal());
        dispatch(actions.auth.login.resetState());
        dispatch(actions.auth.forgotPassword.resetForgotPasswordState());
        dispatch(actions.member.profile.resetStatus());

        if (loadingModal === 'registerSuccess') {
          // auto logged in at here.
          const credentials = sessionStorage.getItem('credentials_register');
          if (credentials !== null) {
            const objCredentials = JSON.parse(credentials) as {
              username: string;
              password: string;
            };

            // dispatching to the login by username  & password provider.
            dispatch(
              actions.auth.login.loginByUsernameAndPassword({
                authApiUrl: props.baseAuthApiUrl,
                loginRequest: {
                  username: objCredentials.username,
                  password: objCredentials.password,
                },
              })
            );
          }
        } else if (loadingModal === 'forgotPasswardVerifySuccess') {
          navigate('/nap-game/thanh-toan');
        }
      }}
    >
      <div className={styles['container']}>
        <Image {...iconImageProps} />
        <div
          className={styles['wrapper']}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      </div>
    </Modal>
  );

  // #endregion
}

export default Message;
