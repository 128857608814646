import { Modal } from 'antd';
import { Link } from 'react-router-dom';
import styles from './style.module.css';
import { loadingLoginModal, useAppDispatch } from '@payment-mfe/shared/store';

interface NotiModalProps {
  open: boolean;
  onOk?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onCancle: () => void;
  onCancleRequireLogin?: () => void;
  type: 'require_login' | 'select_game' | 'recharge_confirm' | 'not_enough';
}

export function NotiModal({
  open,
  onOk,
  onCancle,
  onCancleRequireLogin,
  type,
}: NotiModalProps) {
  const dispatch = useAppDispatch();
  const handleOpenLoginModal = () => {
    dispatch(loadingLoginModal());
    onCancle();
  };
  const renderNotiContent = () => {
    switch (type) {
      case 'require_login':
        return (
          <div className={styles['noti-content']}>
            <h2>Thông báo</h2>
            <p className={styles['title']}>Vui lòng đăng nhập</p>

            <div className={styles['noti-groupBtn']}>
              <button
                onClick={onCancleRequireLogin}
                className={styles['cancle-btn']}
              >
                Quay lại
              </button>
              <button
                onClick={handleOpenLoginModal}
                className={styles['confirm-btn']}
              >
                <Link to="#">Đăng nhập</Link>
              </button>
            </div>
          </div>
        );
      case 'select_game':
        return (
          <div className={styles['noti-content']}>
            <h2>Thông báo</h2>
            <p className={styles['title']}>
              Vui lòng chọn chính xác server và tài khoản muốn nạp
            </p>

            <div className={styles['noti-groupBtn']}>
              <button onClick={onCancle} className={styles['confirm-btn']}>
                Nạp BXu
              </button>
            </div>
          </div>
        );
      case 'not_enough':
        return (
          <div className={styles['noti-content']}>
            <h2>Thông báo</h2>
            <p className={styles['title']}>
              Không đủ BXu, xin vui lòng nạp thêm
            </p>

            <div className={styles['noti-groupBtn']}>
              <button onClick={onCancle} className={styles['cancle-btn']}>
                Huỷ
              </button>
              <button onClick={onCancle} className={styles['confirm-btn']}>
                Nạp BXu
              </button>
            </div>
          </div>
        );
      case 'recharge_confirm':
        return (
          <div className={styles['noti-content']}>
            <h2>Xác nhận</h2>
            <p className={styles['title']}>
              Bạn có chắc chắc muốn thực hiện giao dịch?
            </p>

            <div className={styles['noti-groupBtn']}>
              <button onClick={onCancle} className={styles['cancle-btn']}>
                Huỷ
              </button>
              <button onClick={onCancle} className={styles['confirm-btn']}>
                Đồng ý
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles['noti-modal']}>
      <Modal
        centered
        title=""
        open={open}
        onOk={onOk}
        onCancel={type !== 'require_login' ? onCancle : onCancleRequireLogin}
        footer={null}
        width={396}
      >
        {renderNotiContent()}
      </Modal>
    </div>
  );
}

export default NotiModal;
