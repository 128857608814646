// @ts-check
//#region "Declaration Modules"
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TransactionHistoryService } from '@payment-mfe/shared/service';
import type {
  TransactionRequest,
  TransactionResponse,
} from '@payment-mfe/shared/service';
//#endregion

//#region "Constants"
const APP_FEATURE_KEY = 'members/transactions';
const ACTION_SEARCH_KEY = APP_FEATURE_KEY + '/searchAsync';
//#endregion

//#region "Variables"
const service = new TransactionHistoryService();
//#endregion

//#region "AsyncThunks"
const searchAsync = createAsyncThunk(
  ACTION_SEARCH_KEY,
  async (payload: { basePaymentApiUrl: string; req: TransactionRequest }) => {
    const response = await service.searchTransactionAsync(
      payload.basePaymentApiUrl,
      payload.req
    );

    return response;
  }
);
//#endregion

//#region "State"
export interface TransactionState {
  status: 'idle' | 'loading' | 'successed' | 'failed';
  error: string | null;
  paging: {
    totalItems: number;
    itemsPerPage: number;
    page: number;
    totalPages: number;
    items: TransactionResponse[];
  } | null;
}

const initialState: TransactionState = {
  status: 'idle',
  error: null,
  paging: null,
};
//#endregion

//#region "Slice"
export const transactionSlice = createSlice({
  name: APP_FEATURE_KEY,
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(searchAsync.pending, (state) => {
      state.status = 'loading';
      state.error = 'the client sending a get request to service';
    });
    builder.addCase(searchAsync.rejected, (state) => {
      state.status = 'failed';
      state.error = 'cannot received search request from service';
    });
    builder.addCase(searchAsync.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.status = 'successed';
        state.error = 'received response from service';
        state.paging = action.payload;
      } else {
        state.status = 'failed';
        state.error = 'cannot received from service, due business error';
      }
    });
  },
});
//#endregion

//#region "Exports"
export const actions = {
  ...transactionSlice.actions,
  searchAsync,
};

export default transactionSlice.reducer;
//#endregion
