import styles from '../guide-page.module.css';

export function DirectTranferTab() {
  return (
    <>
      <div className={styles['guide-content__step']}>
        <p>
          <span>Bước 1:</span> Đăng nhập trang{' '}
          <a
            href=" https://pay.boomgames.vn/nap-game/sieu-hoc-vien-3d"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#1139C6' }}
          >
            https://pay.boomgames.vn/nap-game/sieu-hoc-vien-3d
          </a>
        </p>
      </div>
      <div className={styles['guide-content__step']}>
        <p>
          <span>Bước 2:</span> Chọn phương thức thanh toán Chuyển khoản trực
          tiếp, chọn ngân hàng và Mệnh giá nạp
        </p>
        <img src="/assets/images/guide/cktt/cktt-2.png" alt="" />
      </div>
      <div className={styles['guide-content__step']}>
        <p>
          <span>Bước 3:</span> Hệ thống hiển thị Thông tin thanh toán. Sau khi
          kiểm tra thông tin, thực hiện quét mã QR hoặc làm theo hướng dẫn để
          tiến hành thanh toán. ={'>'} Thanh toán thành công, hệ thống sẽ tự
          động cộng BXu vào tài khoản.
        </p>
        <img src="/assets/images/guide/cktt/cktt-3.png" alt="" />
      </div>
      <div className={styles['guide-content__step']}>
        <p>
          <span>Bước 4:</span> Đăng nhập game, chọn Cửa Hàng, vào mục Ruby và
          thực hiện mua gói ingame. Nếu tài khoản đủ BXu, hệ thống sẽ tự động
          trừ BXu trong tài khoản để thanh toán.
        </p>
        <img src="/assets/images/guide/cktt/cktt-4.png" alt="" />
      </div>
    </>
  );
}

export default DirectTranferTab;
