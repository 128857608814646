//@ts-check

//#region "Imports"
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { MethodService } from '@payment-mfe/shared/service';
import type { Method } from '@payment-mfe/shared/service';
//#endregion

//#region "Contants"
const APP_FEATURE_KEY = 'payment/method';
const GET_ALL_METHOD_ACTION_KEY = APP_FEATURE_KEY + '/getAll';
//#endregion

//#region "Service"
const service = new MethodService();
//#endregion

//#region "Adapters"
/**
 * The entity adapter, management to methods.
 * @since 1.0
 */
const adapter = createEntityAdapter<Method>({
  selectId: (method) => method.uid,
  sortComparer: (a, b) =>
    (a.priority.toString() + '_' + a.name).localeCompare(
      b.priority.toString() + '_' + b.name
    ),
});
//#endregion

//#region "AsyncThunk"
/**
 * List all the payment method.
 * @since 1.0
 */
const getAll = createAsyncThunk(
  GET_ALL_METHOD_ACTION_KEY,
  async (payload: { basePaymentApiUrl: string }) => {
    const response = await service.listMethodAsync(payload.basePaymentApiUrl);
    return response;
  }
);
//#endregion

//#region "State"
/**
 * The state of method.
 * @since 1.0
 */
export interface MethodState {
  status: 'idle' | 'loading' | 'successed' | 'failed';
  error: string | null;
}

const initialState: MethodState = {
  status: 'idle',
  error: null,
};
//#endregion

//#region "Slice"
const methodStateSlice = createSlice({
  name: APP_FEATURE_KEY,
  initialState: adapter.getInitialState({
    ...initialState,
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAll.rejected, (state) => {
      state.status = 'failed';
      state.error = 'Cannot get result from service. Checking our network.';
    });
    builder.addCase(getAll.pending, (state) => {
      state.status = 'loading';
      state.error = 'The methods is fetching process.';
    });
    builder.addCase(getAll.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.status = 'successed';
        state.error = 'The list of method was fetched.';

        adapter.removeAll(state);
        adapter.setAll(state, action.payload);
      } else {
        state.status = 'failed';
        state.error = 'Receiving empty list of method from server.';
      }
    });
  },
});
//#endregion

//#region "Exports"
export const actions = {
  ...methodStateSlice.actions,
  getAll,
};

export default methodStateSlice.reducer;
//#endregion
