import { Button, Form, Input, Modal } from 'antd';
import { useEffect, useState } from 'react';
import styles from './style.module.css';

interface PropsType {
  open: boolean;
  onCancel: (e: React.MouseEvent<HTMLButtonElement>) => void;
  type: 'phone' | 'email' | string;
}

export function CheckOTPModal({ open, onCancel, type }: PropsType) {
  // #region local state
  /**
   * Countdown button state
   */
  const [countdown, setCountdown] = useState<number>(60);

  /**
   * disable button state
   */
  const [disabled, setDisabled] = useState<boolean>(false);
  // #endregion

  // #region Form
  const [form] = Form.useForm();
  // #endregion

  // #region Actions
  const renderMessage = () => {
    switch (type) {
      case 'phone':
        return (
          <>
            <p>
              Mã OTP đã được gửi đến tin nhắn zalo của bạn, kiểm tra tin nhắn và
              nhập OTP
            </p>
            <p style={{ marginTop: '2px' }}>
              <span>Lưu ý:</span> Mã OTP chỉ có hiệu lực trong 5 phút.
            </p>
          </>
        );

      case 'email':
        return (
          <>
            <p>
              Mã OTP đã được gửi đến email của bạn, kiểm tra email và nhập OTP
            </p>
            <p style={{ marginTop: '2px' }}>
              <span>Lưu ý:</span> Mã OTP chỉ có hiệu lực trong 5 phút.
            </p>
          </>
        );
    }
  };

  // Resending OTP
  const handleResendOTP = () => {
    setDisabled(true);
    setCountdown(60);

    setTimeout(() => {
      setDisabled(false);
    }, 60000); // R
  };

  // #endregion

  // #region Effect
  /**
   * Countdown number
   */
  useEffect(() => {
    if (countdown > 0 && disabled) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [countdown, disabled]);
  // #endregion

  return (
    <Modal
      centered
      footer={null}
      maskClosable={false}
      title=""
      open={open}
      onCancel={(e) => onCancel(e)}
    >
      <div className={styles['change-phone-form']}>
        <h2>THÔNG BÁO</h2>
        {/* Message */}
        {renderMessage()}

        {/* Form */}
        <Form
          form={form}
          onFinish={(value) => console.log(value)}
          labelAlign="left"
          layout="vertical"
          autoComplete={'off'}
        >
          <Form.Item
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            label=""
            name="phone"
            className="form-item"
          >
            <Input placeholder="Mã OTP" />
          </Form.Item>

          <div className={styles['btn-group']}>
            <Button
              type="primary"
              size="large"
              onClick={handleResendOTP}
              disabled={disabled}
            >
              {disabled ? `Gửi lại mã (${countdown}s)` : 'Gửi lại mã'}
            </Button>
            <button>Xác nhận</button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

export default CheckOTPModal;
