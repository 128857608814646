import { BaseService } from '../base';
import { GameResponse } from '@payment-mfe/shared/models';

export class GameService extends BaseService<GameResponse> {
  /**
   * List all game.
   *
   * @param productApiUrl
   * @returns {Promise<GameResponse[] | null>}
   */

  async getListGame(productApiUrl: string): Promise<GameResponse[] | null> {
    const response = await this.getAsync(productApiUrl);
    if (response.errCode === '00') {
      return response.data;
    }

    return null;
  }
}
