// @ts-check

// #region "Imports"
import { VerifyInfoService } from "@payment-mfe/shared/service";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// #endregion

// #region "Constants"
const APP_FEATURE_KEY = "members/send-otp";
const ACTION_SEND_OTP_KEY = APP_FEATURE_KEY + "/doSend";

const service = new VerifyInfoService();
// #endregion

// #region "Business"
const doSendAsync = createAsyncThunk(
  ACTION_SEND_OTP_KEY, async (payload: {
    baseNotificationApiUrl: string;
    emailOrMobile: string;
    type: 'email' | 'mobile';
  }) => {
  return service.sendOTP(payload.baseNotificationApiUrl, {
    type: payload.type,
    emailOrMobile: payload.emailOrMobile
  })
})

interface IState {
  status: 'idle' | 'loading' | 'successed' | 'failed';
  error: string | null;
  trackingId: string | null;
}

const initialState: IState = {
  status: 'idle',
  error: null,
  trackingId: null,
}

export const otpSlice = createSlice({
  name: APP_FEATURE_KEY,
  initialState: initialState,
  reducers: {
    doResetState(state) {
      state.status = 'idle';
      state.error = null;
      state.trackingId = null;

    }
  },
  extraReducers: (builder) => {
    builder.addCase(doSendAsync.fulfilled, (state, action) => {
      state.status = 'successed';
      state.error = 'Send the one-time password is successful';
      state.trackingId = action.payload?.data?.trackingId || null;
    });

    /**
     * In the doing progress to send an one-time password request.
     * @since 1.0
     */
    builder.addCase(doSendAsync.pending, (state) => {
      state.status = 'loading';
      state.error = 'In the progress to send the one-time password';
      state.trackingId = null;
    });

    /**
     * A handles of the restful api request is failure.
     * @since 1.0
     */
    builder.addCase(doSendAsync.rejected, (state) => {
      state.status = 'failed';
      state.error = 'Send the one-time password is failure.';
      state.trackingId = null;
    });
  }
})
// #endregion

// #region "Exports"
export const actions = {
  ...otpSlice.actions,
  doSendAsync,
}

export default otpSlice.reducer;
// #endregion
